import { InfoCircleOutlined, WarningOutlined } from '@ant-design/icons';
import React from 'react';

import { BlockchainNetworkTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';

interface NetworkIconProps {
  value: BlockchainNetworkTypeAPIModel;
}

const NetworkIcon: React.FC<NetworkIconProps> = React.memo(({ value }) =>
  value === BlockchainNetworkTypeAPIModel.TestNet ? <WarningOutlined /> : <InfoCircleOutlined />,
);

export default NetworkIcon;
