/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3276
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GasWalletDerivedAPIModel
 */
export interface GasWalletDerivedAPIModel {
    /**
     * 
     * @type {string}
     * @memberof GasWalletDerivedAPIModel
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof GasWalletDerivedAPIModel
     */
    nativeBalance: string;
    /**
     * 
     * @type {boolean}
     * @memberof GasWalletDerivedAPIModel
     */
    isRefunding: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GasWalletDerivedAPIModel
     */
    isOutOfService: boolean;
    /**
     * 
     * @type {string}
     * @memberof GasWalletDerivedAPIModel
     */
    refundHash?: string;
}

/**
 * Check if a given object implements the GasWalletDerivedAPIModel interface.
 */
export function instanceOfGasWalletDerivedAPIModel(value: object): boolean {
    if (!('address' in value)) return false;
    if (!('nativeBalance' in value)) return false;
    if (!('isRefunding' in value)) return false;
    if (!('isOutOfService' in value)) return false;
    return true;
}

export function GasWalletDerivedAPIModelFromJSON(json: any): GasWalletDerivedAPIModel {
    return GasWalletDerivedAPIModelFromJSONTyped(json, false);
}

export function GasWalletDerivedAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): GasWalletDerivedAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'address': json['address'],
        'nativeBalance': json['nativeBalance'],
        'isRefunding': json['isRefunding'],
        'isOutOfService': json['isOutOfService'],
        'refundHash': json['refundHash'] == null ? undefined : json['refundHash'],
    };
}

export function GasWalletDerivedAPIModelToJSON(value?: GasWalletDerivedAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'address': value['address'],
        'nativeBalance': value['nativeBalance'],
        'isRefunding': value['isRefunding'],
        'isOutOfService': value['isOutOfService'],
        'refundHash': value['refundHash'],
    };
}

