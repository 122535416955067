import { useMemo } from 'react';

import type { UseAppSingleData } from '@/app/hooks';
import { useAppSingleData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { makeSelectPending } from '@/features/global/selectors';
import { fetchMerchantWalletRunningBalance } from '@/features/merchant-wallet-balance/actions';
import { makeSelectMerchantWalletRunningBalance } from '@/features/merchant-wallet-balance/selectors';
import type { MerchantWalletRunningBalance } from '@/features/merchant-wallet-balance/types';
import { type LoadingStateWithDirty, storedDataError } from '@/infrastructure/model';

export interface UseMerchantWalletRunningBalance extends UseAppSingleData<MerchantWalletRunningBalance> {}

const noData = storedDataError<MerchantWalletRunningBalance>('no-data');

const fetchFactory = (
  id: string,
  force: boolean,
): AppAsyncThunkAction<
  LoadingStateWithDirty<MerchantWalletRunningBalance>,
  Parameters<typeof fetchMerchantWalletRunningBalance>[0]
> => fetchMerchantWalletRunningBalance({ id, force });

export default function useMerchantWalletRunningBalance(id: string | undefined): UseMerchantWalletRunningBalance {
  const dataFetchingSelector = useMemo(() => makeSelectPending(fetchMerchantWalletRunningBalance, id), [id]);
  return useAppSingleData(fetchFactory, makeSelectMerchantWalletRunningBalance, noData, id, dataFetchingSelector);
}
