import Icon from '@ant-design/icons';
import React, { useMemo } from 'react';

import GoogleIcon from '@/assets/icons/google.svg?react';
import { FormattedMessage, InProgressView } from '@/components';
import { I18nFeatureAuth } from '@/generated/i18n/i18n';

import type { GoogleInProgressProps } from './types';

const GoogleInProgress: React.FC<GoogleInProgressProps> = ({ 'data-test': dataTest, cancel }) => (
  <InProgressView
    data-test={dataTest}
    cancel={cancel && { onAction: cancel }}
    Icon={useMemo(() => (props) => <Icon component={GoogleIcon} {...props} />, [])}
    message={<FormattedMessage id={I18nFeatureAuth.COMPONENTS_GOOGLE_IN_PROGRESS_MESSAGE} />}
  />
);

const GoogleInProgressMemo = React.memo(GoogleInProgress);

export default GoogleInProgressMemo;
