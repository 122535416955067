import React, { useEffect, useMemo, useRef } from 'react';

import { useStateMountSafe } from '@/hooks';

const RecoveryKeyDownloaderRaw: React.FC<{ value: string }> = ({ value }) => {
  const file = useMemo(() => `data:text/plain;base64,${btoa(value)}`, [value]);
  const [ref, setRef] = useStateMountSafe<HTMLAnchorElement | null>(null);
  const clicked = useRef(false);

  useEffect(() => {
    if (!clicked.current && ref) {
      try {
        ref.click();
      } finally {
        clicked.current = true;
      }
    }
  }, [ref]);

  return (
    // eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/control-has-associated-label
    <a
      ref={setRef}
      download="smartypay-recovery-key.txt"
      href={file}
      style={{ display: 'none' }}
      target="_blank"
      rel="noreferrer"
    />
  );
};

const RecoveryKeyDownloader = React.memo(RecoveryKeyDownloaderRaw);

export default RecoveryKeyDownloader;
