import type { DateTimeRelativeQuickFilter } from '@/components';
import type {
  GetWithdrawalScheduleResponseAPIModel,
  UpdateWithdrawalScheduleRequestAPIModel,
  WithdrawalAPIModel,
  WithdrawalBatchAPIModel,
  WithdrawalIntentAPIModel,
  WithdrawalPredicatesAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import { WithdrawalSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { AssetAmountValue } from '@/infrastructure/api';
import { defaultPage } from '@/infrastructure/api';
import type { LoadingStateWithDirty } from '@/infrastructure/model';
import type { ListColumnState, ListSortBy, ListState, ListStateNoColumns } from '@/infrastructure/model/list/types';
import { storedDirtyListDataTyped } from '@/infrastructure/model/list/utils';
import type { LoadingPartialDataState } from '@/infrastructure/model/partial/types';
import type { SingleState } from '@/infrastructure/model/single/types';

import type BigNumber from 'bignumber.js';

export const NAMESPACE = 'settlements';

export enum SettlementType {
  Payout = 'Payout',
  Settlement = 'Settlement',
}

export type Settlement = Omit<
  WithdrawalAPIModel,
  | 'estimatedAmount'
  | 'estimatedAmountInvoice'
  | 'estimatedAmountPush'
  | 'processingFeeUnpaid'
  | 'fullness'
  | 'companyId'
  | 'collectFullness'
  | 'batchCount'
  | 'collectStatus'
  | 'activeProcessingFeeTx'
  | 'mode'
> & {
  estimatedAmount: AssetAmountValue;
  totalAmount: AssetAmountValue;
  type: SettlementType;
};

export type SettlementBatch = Omit<
  WithdrawalBatchAPIModel,
  'totalAmount' | 'gwtId' | 'btcTxId' | 'isPush' | 'isPull' | 'invoiceProofs'
> & {
  assetId: string;
};

export interface SettlementScheduleUpdate extends UpdateWithdrawalScheduleRequestAPIModel {}

export interface SettlementSchedule extends GetWithdrawalScheduleResponseAPIModel {}

export interface SettlementIntent extends WithdrawalIntentAPIModel {
  staleAt: Date;
}

export interface SettlementFilterPredicate
  extends Pick<
    WithdrawalPredicatesAPIModel,
    'statuses' | 'asset' | 'withdrawnAtRange' | 'reconciliationStatusIn' | 'network'
  > {
  withdrawnAtRelative?: DateTimeRelativeQuickFilter;
}

export interface SettlementSortBy extends ListSortBy<WithdrawalSortByAPIModel> {}

export interface SettlementForAssetListState
  extends ListStateNoColumns<string, SettlementFilterPredicate, WithdrawalSortByAPIModel> {}

export const defaultSortBy: SettlementSortBy = { [WithdrawalSortByAPIModel.EstimatedAt]: 'DESC' };

export const defaultSettlementsForAssetListState: SettlementForAssetListState = {
  data: storedDirtyListDataTyped(),
  filter: {},
  sortBy: defaultSortBy,
  page: defaultPage,
};

export const defaultSettlementsListState: ListState<string, SettlementFilterPredicate, WithdrawalSortByAPIModel> = {
  ...defaultSettlementsForAssetListState,
  columnState: {},
};

export interface DistributeFeeId {
  asset: string;
  wallet: string;
  amount: BigNumber;
}

export interface FetchDistributeFeePayload extends DistributeFeeId {
  force?: boolean;
}

export interface PayoutDestination {
  address: string;
  amount: BigNumber;
}

export interface NewPayoutData {
  asset: string;
  destinations: PayoutDestination[];
}

export interface SettlementsState {
  entities: SingleState<Settlement>; // by settlementId
  batches: SingleState<SettlementBatch>; // by settlementId (only the first batch)

  columnState: ListColumnState;
  full: SettlementForAssetListState;
  byAsset: Partial<Record<string, SettlementForAssetListState>>; // by asset

  schedule: LoadingStateWithDirty<SettlementSchedule>;
  intents: {
    scheduled: LoadingPartialDataState<string>;
    entities: SingleState<SettlementIntent>;
  };

  distributeFees: SingleState<BigNumber>; // key - DistributeFeeKey
}
