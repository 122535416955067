import { useMemo } from 'react';

import { useAppSingleData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { makeSelectPending } from '@/features/global/selectors';
import { fetchDistributeFee } from '@/features/settlements/actions';
import { usePayoutMerchantWallet } from '@/features/settlements/hooks/index';
import { makeSelectDistributeFee } from '@/features/settlements/selectors';
import type { DistributeFeeId } from '@/features/settlements/types';
import { createDistributeFeeKey } from '@/features/settlements/utils';
import { type LoadingStateWithDirty, storedDataError } from '@/infrastructure/model';

import type BigNumber from 'bignumber.js';

const noData = storedDataError<BigNumber>('no-data');

const fetchFactory = (
  id: DistributeFeeId,
  force: boolean,
): AppAsyncThunkAction<LoadingStateWithDirty<BigNumber>, Parameters<typeof fetchDistributeFee>[0]> =>
  fetchDistributeFee({ ...id, force });

const useMerchantWalletDistributeFee = (asset: string | undefined, amount: BigNumber | undefined) => {
  const wallet = usePayoutMerchantWallet(asset);
  const id = useMemo<DistributeFeeId | undefined>(
    () => (amount && asset && wallet ? { amount, asset, wallet } : undefined),
    [amount, asset, wallet],
  );
  const dataFetchingSelector = useMemo(
    () => makeSelectPending(fetchDistributeFee, id && createDistributeFeeKey(id)),
    [id],
  );
  return useAppSingleData(fetchFactory, makeSelectDistributeFee, noData, id, dataFetchingSelector);
};

export default useMerchantWalletDistributeFee;
