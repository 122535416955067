import React from 'react';

import { FormattedMessage, HomeBreadcrumb } from '@/components';
import { I18nPageAudit } from '@/generated/i18n/i18n';

import MerchantWalletRunningBalancesLink from '../MerchantWalletRunningBalancesLink';

import type { MerchantWalletRunningBalancesBreadcrumbProps } from './types';

const MerchantWalletRunningBalancesBreadcrumbRaw: React.FC<MerchantWalletRunningBalancesBreadcrumbProps> = React.memo(
  ({ items = [], disabled, 'data-test': dataTest }) => (
    <HomeBreadcrumb
      data-test={dataTest}
      items={[
        {
          title: disabled ? (
            <FormattedMessage id={I18nPageAudit.BALANCES_COMPONENTS_BREADCRUMB} />
          ) : (
            <MerchantWalletRunningBalancesLink
              data-test={dataTest && `${dataTest}-link`}
              mode="text"
              title={<FormattedMessage id={I18nPageAudit.BALANCES_COMPONENTS_BREADCRUMB} />}
            />
          ),
        },
        ...items,
      ]}
    />
  ),
);

const MerchantWalletRunningBalancesBreadcrumb = React.memo(MerchantWalletRunningBalancesBreadcrumbRaw);

export default MerchantWalletRunningBalancesBreadcrumb;
