import { Alert } from 'antd';
import React, { useEffect, useMemo } from 'react';

import { FormattedMessage } from '@/components';
import { useCompanySettings } from '@/features/company-settings/hooks';
import { useAsset, useBlockchainSystemInfo } from '@/features/dictionary/blockchain/hooks';
import { I18nFeaturePayments } from '@/generated/i18n/i18n';
import CompanySettingsLink from '@/pages/settings/company/summary/components/CompanySettingsLink';

import type { QRSupportItemInputProps } from './types';

const QRSupportItemInput: React.FC<QRSupportItemInputProps> = ({ 'data-test': dataTest, value, asset, onChange }) => {
  const { data: assetState } = useAsset(asset);
  const { data: blockchainState } = useBlockchainSystemInfo(assetState.data?.blockchain);
  const { data: settingsState } = useCompanySettings();
  const settings = useMemo(
    () => settingsState.data?.qrSupport.find((btFw) => btFw.blockchain === assetState.data?.blockchain),
    [assetState.data?.blockchain, settingsState.data?.qrSupport],
  );
  useEffect(() => {
    if (!blockchainState.data?.forwarder || !settings) {
      if (value?.hasError) {
        onChange?.({ ...value, hasError: false });
      }
      return;
    }
    if (!settings.isDeployed && blockchainState.data.forwarder === 'required') {
      if (!value?.hasError) {
        onChange?.({ ...value, hasError: true });
      }
    } else if (value?.hasError) {
      onChange?.({ ...value, hasError: false });
    }
  }, [blockchainState.data?.forwarder, settings, onChange, value]);

  return value?.hasError ? (
    <Alert
      data-test={dataTest}
      showIcon
      type="error"
      message={
        <FormattedMessage
          id={I18nFeaturePayments.COMPONENTS_CREATE_PAYMENT_FORM_FORWARDER_SETTINGS_INPUT_ERROR_MESSAGE}
        />
      }
      description={
        <FormattedMessage
          id={I18nFeaturePayments.COMPONENTS_CREATE_PAYMENT_FORM_FORWARDER_SETTINGS_INPUT_ERROR_DESCRIPTION}
          values={{
            ln: (title: React.ReactNode) => (
              <CompanySettingsLink data-test={dataTest && `${dataTest}-link`} title={title} mode="text" />
            ),
          }}
        />
      }
    />
  ) : null;
};

const QRSupportItemInputMemo = React.memo(QRSupportItemInput);

export default QRSupportItemInputMemo;
