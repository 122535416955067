import { createSelector } from 'reselect';

import type { AppRootState } from '@/app/store';
import { makeSelectAuthToken } from '@/features/auth/selectors';
import type { LoadingStateWithDirty } from '@/infrastructure/model';
import { combine, flatmapStoredState, mapStoredState, storedDataError, storedDataLoaded } from '@/infrastructure/model';

import { type CompanyWithSelection, NAMESPACE, type CompanyWithOwner } from './types';

export const makeSelectRawCompanies = () => (state: AppRootState) => state[NAMESPACE].companies;

export const makeSelectCompanyUsers = () => (state: AppRootState) => state[NAMESPACE].users;

export const makeSelectActiveCompany = () =>
  createSelector(makeSelectRawCompanies(), makeSelectAuthToken(), (companiesState, tokenState) => tokenState.isDirty && !tokenState.data
      ? storedDataError<CompanyWithOwner>('Unauthorized')
      : flatmapStoredState(
          combine(companiesState, tokenState, (companies, token) => {
            const active = companies.find(({ id }) => id === token.info.activeCompanyId);
            return active ? { ...active, isOwner: active.owner === token.info.sub } : 'empty';
          }),
          (state): LoadingStateWithDirty<CompanyWithOwner> =>
            state !== 'empty' ? storedDataLoaded(state) : storedDataError('No active'),
        ));

export const makeSelectCompanies = () =>
  createSelector(makeSelectRawCompanies(), makeSelectAuthToken(), (state, token) =>
    mapStoredState(state, (companies) =>
      companies.map(
        (company): CompanyWithSelection => ({
          ...company,
          isOwner: company.owner === token.data?.info.sub,
          isSelected: company.id === token.data?.info.activeCompanyId,
        }),
      ),
    ),
  );
