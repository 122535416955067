import { useCallback, useMemo } from 'react';

import { useListAssets, useListBlockchains } from '@/features/dictionary/blockchain/hooks';
import type { Asset } from '@/features/dictionary/blockchain/types';
import { useActualBalances } from '@/features/statistics/hooks';
import type { BlockchainMetaAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { BlockchainAPITypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { AssetAmountValue } from '@/infrastructure/api';
import type { LoadingStateWithDirty } from '@/infrastructure/model';
import { combine } from '@/infrastructure/model';
import { notEmpty } from '@/infrastructure/utils/ts';

export interface PayoutBalance {
  bc: BlockchainMetaAPIModel;
  asset: Asset;
  balance: AssetAmountValue;
}

const byAsset = (balances: AssetAmountValue[]): Partial<Record<string, AssetAmountValue>> =>
  Object.fromEntries(balances.map((value) => [value.asset, value]));

export default function usePayoutBalances() {
  const assetsState = useListAssets();
  const balancesState = useActualBalances();
  const blockchainsState = useListBlockchains();

  const balances = useMemo<LoadingStateWithDirty<PayoutBalance[]>>(
    () =>
      combine(
        combine(blockchainsState.data, assetsState.data, (blockchains, assets) => ({ blockchains, assets })),
        balancesState.data,
        ({ blockchains, assets }, balances) => {
          const availableByAsset = byAsset(balances.available);
          return Object.keys(availableByAsset)
            .map((balanceAsset) => {
              const assetInfo = assets.find(({ code }) => code === balanceAsset);
              if (!assetInfo) {
                console.warn(`Cannot find asset for code = ${balanceAsset}`);
              }
              return assetInfo;
            })
            .filter(notEmpty)
            .map((asset) => {
              const bc = blockchains.find((bcInfo) => bcInfo.blockchain === asset.blockchain);
              return bc && { bc, asset };
            })
            .filter(notEmpty)
            .filter(({ bc }) => bc.apiType === BlockchainAPITypeAPIModel.WEB3) // only web3 is supported
            .map(({ asset, bc }) => {
              const balance = availableByAsset[asset.code];
              return balance && { asset, balance, bc };
            })
            .filter(notEmpty);
        },
      ),
    [assetsState.data, balancesState.data, blockchainsState.data],
  );
  const forceRefresh = useCallback(
    () =>
      Promise.all([
        balancesState.forceRefresh(),
        ...(!assetsState.data.data ? [assetsState.forceRefresh()] : []),
        ...(!blockchainsState.data.data ? [blockchainsState.forceRefresh()] : []),
      ]),
    [assetsState, balancesState, blockchainsState],
  );

  const loading = balancesState.loading || assetsState.loading || blockchainsState.loading;

  return { balances, forceRefresh, loading };
}
