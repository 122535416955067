/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3276
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { SubscriptionDiscountAPIModel } from './SubscriptionDiscountAPIModel';
import {
    SubscriptionDiscountAPIModelFromJSON,
    SubscriptionDiscountAPIModelFromJSONTyped,
    SubscriptionDiscountAPIModelToJSON,
} from './SubscriptionDiscountAPIModel';
import type { SubscriptionPlanStatusAPIModel } from './SubscriptionPlanStatusAPIModel';
import {
    SubscriptionPlanStatusAPIModelFromJSON,
    SubscriptionPlanStatusAPIModelFromJSONTyped,
    SubscriptionPlanStatusAPIModelToJSON,
} from './SubscriptionPlanStatusAPIModel';

/**
 * 
 * @export
 * @interface SubscriptionPlanAPIModel
 */
export interface SubscriptionPlanAPIModel {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPlanAPIModel
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionPlanAPIModel
     */
    companyId: number;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPlanAPIModel
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPlanAPIModel
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPlanAPIModel
     */
    amount: string;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionPlanAPIModel
     */
    periodSeconds: number;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionPlanAPIModel
     */
    gracePeriodSeconds: number;
    /**
     * 
     * @type {Date}
     * @memberof SubscriptionPlanAPIModel
     */
    createdAt: Date;
    /**
     * 
     * @type {Array<string>}
     * @memberof SubscriptionPlanAPIModel
     */
    tags?: Array<string>;
    /**
     * 
     * @type {SubscriptionPlanStatusAPIModel}
     * @memberof SubscriptionPlanAPIModel
     */
    status: SubscriptionPlanStatusAPIModel;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionPlanAPIModel
     */
    trialPeriodSeconds?: number;
    /**
     * 
     * @type {Array<SubscriptionDiscountAPIModel>}
     * @memberof SubscriptionPlanAPIModel
     */
    discounts?: Array<SubscriptionDiscountAPIModel>;
}

/**
 * Check if a given object implements the SubscriptionPlanAPIModel interface.
 */
export function instanceOfSubscriptionPlanAPIModel(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('companyId' in value)) return false;
    if (!('name' in value)) return false;
    if (!('description' in value)) return false;
    if (!('amount' in value)) return false;
    if (!('periodSeconds' in value)) return false;
    if (!('gracePeriodSeconds' in value)) return false;
    if (!('createdAt' in value)) return false;
    if (!('status' in value)) return false;
    return true;
}

export function SubscriptionPlanAPIModelFromJSON(json: any): SubscriptionPlanAPIModel {
    return SubscriptionPlanAPIModelFromJSONTyped(json, false);
}

export function SubscriptionPlanAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubscriptionPlanAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'name': json['name'],
        'description': json['description'],
        'amount': json['amount'],
        'periodSeconds': json['periodSeconds'],
        'gracePeriodSeconds': json['gracePeriodSeconds'],
        'createdAt': (new Date(json['createdAt'])),
        'tags': json['tags'] == null ? undefined : json['tags'],
        'status': SubscriptionPlanStatusAPIModelFromJSON(json['status']),
        'trialPeriodSeconds': json['trialPeriodSeconds'] == null ? undefined : json['trialPeriodSeconds'],
        'discounts': json['discounts'] == null ? undefined : ((json['discounts'] as Array<any>).map(SubscriptionDiscountAPIModelFromJSON)),
    };
}

export function SubscriptionPlanAPIModelToJSON(value?: SubscriptionPlanAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'name': value['name'],
        'description': value['description'],
        'amount': value['amount'],
        'periodSeconds': value['periodSeconds'],
        'gracePeriodSeconds': value['gracePeriodSeconds'],
        'createdAt': ((value['createdAt']).toISOString()),
        'tags': value['tags'],
        'status': SubscriptionPlanStatusAPIModelToJSON(value['status']),
        'trialPeriodSeconds': value['trialPeriodSeconds'],
        'discounts': value['discounts'] == null ? undefined : ((value['discounts'] as Array<any>).map(SubscriptionDiscountAPIModelToJSON)),
    };
}

