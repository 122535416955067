import { DualAxes } from '@ant-design/charts';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { LineType, useChartPlanLabels } from '@/features/subscription-statistics/hooks';
import { I18nFeatureSubscriptionStatistics } from '@/generated/i18n/i18n';

import type { MonthlyGrowthRateGraphData, SubscriptionMonthlyGrowthGraphProps } from './types';

const yAxisLabel = (text: string) => `${text} %`;

const SubscriptionMonthlyGrowthGraph: React.FC<SubscriptionMonthlyGrowthGraphProps> = ({
  'data-test': dataTest,
  data: { statistics, plans },
}) => {
  const { formatMessage: i18n } = useIntl();
  const { onPlanViewClick, tooltipItemValue } = useChartPlanLabels(plans);
  // TODO: 1. tooltip name configuration is not working, 2. legend configuration ruins the first render
  const tooltip = useMemo(
    () => ({
      // name: 'tooltip',
      channel: 'y',
      valueFormatter: (value?: MonthlyGrowthRateGraphData['value']) =>
        i18n({ id: I18nFeatureSubscriptionStatistics.COMPONENTS_GROWTH_RATE_GRAPH_TOOLTIP_VALUE }, { value }),
    }),
    [i18n],
  );
  const planData = useMemo<MonthlyGrowthRateGraphData[]>(
    () =>
      statistics.perPlan.flatMap(({ planId, perMonth }) =>
        perMonth.map(({ percentage, month }) => ({
          period: month,
          value: percentage,
          name: tooltipItemValue(planId),
          // tooltip: tooltipItemValue(planId),
        })),
      ),
    [statistics.perPlan, tooltipItemValue],
  );
  const overallData = useMemo<MonthlyGrowthRateGraphData[]>(
    () => [
      ...statistics.median.map(({ percentage, month }) => ({
        period: month,
        value: percentage,
        name: tooltipItemValue(LineType.median),
        // tooltip: tooltipItemValue(LineType.median),
      })),
      ...statistics.mean.map(({ percentage, month }) => ({
        period: month,
        value: percentage,
        name: tooltipItemValue(LineType.mean),
        // tooltip: tooltipItemValue(LineType.mean),
      })),
    ],
    [statistics.mean, statistics.median, tooltipItemValue],
  );
  const max = useMemo(
    () =>
      Math.max(
        statistics.perPlan
          .flatMap(({ perMonth }) => perMonth)
          .map(({ percentage }) => percentage)
          .reduce((r, v) => (r > v ? r : v), 0),
        50,
      ),
    [statistics.perPlan],
  );
  const min = useMemo(
    () =>
      Math.min(
        statistics.perPlan
          .flatMap(({ perMonth }) => perMonth)
          .map(({ percentage }) => percentage)
          .reduce((v1, v2) => (v1 < v2 ? v1 : v2), 0),
        0,
      ),
    [statistics.perPlan],
  );

  const axis = useMemo(
    () => ({
      x: { title: i18n({ id: I18nFeatureSubscriptionStatistics.COMPONENTS_REVENUE_GRAPH_X_AXIS_TITLE }) },
      y: { labelFormatter: yAxisLabel },
    }),
    [i18n],
  );

  // const legend = useMemo(() => ({ color: { itemLabelText: legendItemName } }), [legendItemName]);
  const children = useMemo(
    () => [
      {
        data: planData,
        type: 'interval',
        yField: 'value',
        colorField: 'name',
      },
      {
        data: overallData,
        type: 'line',
        yField: 'value',
        colorField: 'name',
        seriesField: 'name',
        axis: { y: { position: 'right' } },
        style: { lineWidth: 2 },
      },
    ],
    [overallData, planData],
  );

  const scale = useMemo(() => ({ y: { domainMin: min, domainMax: max } }), [max, min]);

  return (
    <DualAxes
      data-test={dataTest}
      height={600}
      scale={scale}
      onEvent={onPlanViewClick}
      xField="period"
      children={children}
      axis={axis}
      tooltip={tooltip}
      // legend={legend}
    />
  );
};

const SubscriptionMonthlyGrowthGraphMemo = React.memo(SubscriptionMonthlyGrowthGraph);

export default SubscriptionMonthlyGrowthGraphMemo;
