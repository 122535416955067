import { useMemo } from 'react';

import { FormattedMessage, PageCard } from '@/components';
import { useMerchantWalletRunningBalanceTransfersView } from '@/features/merchant-wallet-balance/hooks';
import type { MerchantWalletTransfer } from '@/features/merchant-wallet-transfers/types';
import { I18nPageAudit } from '@/generated/i18n/i18n';
import { mapFullDataToListData } from '@/infrastructure/model/full/utils';
import type { LoadingListDataState } from '@/infrastructure/model/list/types';
import type { TestableProps } from '@/infrastructure/utils/react';

import type React from 'react';

type ComponentType = Omit<ReturnType<typeof useMerchantWalletRunningBalanceTransfersView>, 'data' | 'fullData'> &
  TestableProps & {
    data: LoadingListDataState<MerchantWalletTransfer>;
  };

const withMerchantWalletRunningBalanceTransfersCardLoading =
  <T extends ComponentType & TestableProps>(
    Component: React.ComponentType<T>,
  ): React.FC<
    Omit<
      T,
      'data' | 'loading' | 'state' | 'filter' | 'forceRefresh' | 'columnState' | 'page' | 'sortBy' | 'updateParameters'
    > & {
      balanceId: string;
    }
  > =>
  ({ balanceId, ...props }) => {
    const { 'data-test': dataTest } = props;

    const { data: filteredData, fullData, ...dataState } = useMerchantWalletRunningBalanceTransfersView(balanceId);
    const data = useMemo(() => mapFullDataToListData(filteredData, fullData), [fullData, filteredData]);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const componentProps: T = { ...props, ...dataState, data };

    return (
      <PageCard
        data-test={dataTest}
        title={<FormattedMessage id={I18nPageAudit.BALANCE_VIEW_COMPONENTS_TRANSFERS_CARD_TITLE} />}
        cardSize="big"
      >
        <Component {...componentProps} />
      </PageCard>
    );
  };

export default withMerchantWalletRunningBalanceTransfersCardLoading;
