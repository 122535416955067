/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3276
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

/**
 * 
 * @export
 * @enum {string}
 */
export enum MerchantWalletRunningBalanceSortByAPIModel {
    companyHid = 'companyHid',
    wallet = 'wallet',
    blockNum = 'blockNum'
}


export function MerchantWalletRunningBalanceSortByAPIModelFromJSON(json: any): MerchantWalletRunningBalanceSortByAPIModel {
    return MerchantWalletRunningBalanceSortByAPIModelFromJSONTyped(json, false);
}

export function MerchantWalletRunningBalanceSortByAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MerchantWalletRunningBalanceSortByAPIModel {
    return json as MerchantWalletRunningBalanceSortByAPIModel;
}

export function MerchantWalletRunningBalanceSortByAPIModelToJSON(value?: MerchantWalletRunningBalanceSortByAPIModel | null): any {
    return value as any;
}

