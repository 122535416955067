/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3276
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import type { UpdatePassEncryptedCompanySeedAPIModel } from './UpdatePassEncryptedCompanySeedAPIModel';
import {
    instanceOfUpdatePassEncryptedCompanySeedAPIModel,
    UpdatePassEncryptedCompanySeedAPIModelFromJSON,
    UpdatePassEncryptedCompanySeedAPIModelFromJSONTyped,
    UpdatePassEncryptedCompanySeedAPIModelToJSON,
} from './UpdatePassEncryptedCompanySeedAPIModel';
import type { UpdateWeb3EncryptedCompanySeedAPIModel } from './UpdateWeb3EncryptedCompanySeedAPIModel';
import {
    instanceOfUpdateWeb3EncryptedCompanySeedAPIModel,
    UpdateWeb3EncryptedCompanySeedAPIModelFromJSON,
    UpdateWeb3EncryptedCompanySeedAPIModelFromJSONTyped,
    UpdateWeb3EncryptedCompanySeedAPIModelToJSON,
} from './UpdateWeb3EncryptedCompanySeedAPIModel';

/**
 * @type UpdateCompanySeedAPIModel
 * 
 * @export
 */
export type UpdateCompanySeedAPIModel = { policyType: 'CompanySeedPassEncryptedStorage' } & UpdatePassEncryptedCompanySeedAPIModel | { policyType: 'CompanySeedWeb3EncryptedStorage' } & UpdateWeb3EncryptedCompanySeedAPIModel | { policyType: 'UpdatePassEncryptedCompanySeed' } & UpdatePassEncryptedCompanySeedAPIModel | { policyType: 'UpdateWeb3EncryptedCompanySeed' } & UpdateWeb3EncryptedCompanySeedAPIModel;

export function UpdateCompanySeedAPIModelFromJSON(json: any): UpdateCompanySeedAPIModel {
    return UpdateCompanySeedAPIModelFromJSONTyped(json, false);
}

export function UpdateCompanySeedAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateCompanySeedAPIModel {
    if (json == null) {
        return json;
    }
    switch (json['policyType']) {
        case 'CompanySeedPassEncryptedStorage':
            return {...UpdatePassEncryptedCompanySeedAPIModelFromJSONTyped(json, true), policyType: 'CompanySeedPassEncryptedStorage'};
        case 'CompanySeedWeb3EncryptedStorage':
            return {...UpdateWeb3EncryptedCompanySeedAPIModelFromJSONTyped(json, true), policyType: 'CompanySeedWeb3EncryptedStorage'};
        case 'UpdatePassEncryptedCompanySeed':
            return {...UpdatePassEncryptedCompanySeedAPIModelFromJSONTyped(json, true), policyType: 'UpdatePassEncryptedCompanySeed'};
        case 'UpdateWeb3EncryptedCompanySeed':
            return {...UpdateWeb3EncryptedCompanySeedAPIModelFromJSONTyped(json, true), policyType: 'UpdateWeb3EncryptedCompanySeed'};
        default:
            throw new Error(`No variant of UpdateCompanySeedAPIModel exists with 'policyType=${json['policyType']}'`);
    }
}

export function UpdateCompanySeedAPIModelToJSON(value?: UpdateCompanySeedAPIModel | null): any {
    if (value == null) {
        return value;
    }
    switch (value['policyType']) {
        case 'CompanySeedPassEncryptedStorage':
            return UpdatePassEncryptedCompanySeedAPIModelToJSON(value);
        case 'CompanySeedWeb3EncryptedStorage':
            return UpdateWeb3EncryptedCompanySeedAPIModelToJSON(value);
        case 'UpdatePassEncryptedCompanySeed':
            return UpdatePassEncryptedCompanySeedAPIModelToJSON(value);
        case 'UpdateWeb3EncryptedCompanySeed':
            return UpdateWeb3EncryptedCompanySeedAPIModelToJSON(value);
        default:
            throw new Error(`No variant of UpdateCompanySeedAPIModel exists with 'policyType=${value['policyType']}'`);
    }

}

