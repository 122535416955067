import type { Func } from '@/infrastructure/utils/ts';

export type Rejected = 'Rejected';

export const rejection: Rejected = 'Rejected';

export type Cancellable = Promise<Rejected>;

export const cancellable = (fn: (reject: () => void) => unknown) =>
  new Promise<Rejected>((resolve) => {
    fn(() => {
      resolve(rejection);
    });
  });

export const isRejected = (error?: unknown) =>
  !!error && typeof error === 'object' && 'message' in error && error.message === rejection;

export const rejected = () => new Error(rejection);

export const withRejectedByUser =
  <V extends unknown[], R>(function_: Func<V, R>): Func<V, R> =>
  async (...arguments_: V): Promise<R> => {
    try {
      return await function_(...arguments_);
    } catch (error) {
      if ((error as Error | undefined)?.message.toLowerCase().includes('user denied')) {
        throw rejected();
      }
      throw error;
    }
  };
