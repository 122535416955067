/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3276
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { AssetFullMetaAPIModel } from './AssetFullMetaAPIModel';
import {
    AssetFullMetaAPIModelFromJSON,
    AssetFullMetaAPIModelFromJSONTyped,
    AssetFullMetaAPIModelToJSON,
} from './AssetFullMetaAPIModel';
import type { BlockchainAPITypeAPIModel } from './BlockchainAPITypeAPIModel';
import {
    BlockchainAPITypeAPIModelFromJSON,
    BlockchainAPITypeAPIModelFromJSONTyped,
    BlockchainAPITypeAPIModelToJSON,
} from './BlockchainAPITypeAPIModel';
import type { BlockchainNetworkTypeAPIModel } from './BlockchainNetworkTypeAPIModel';
import {
    BlockchainNetworkTypeAPIModelFromJSON,
    BlockchainNetworkTypeAPIModelFromJSONTyped,
    BlockchainNetworkTypeAPIModelToJSON,
} from './BlockchainNetworkTypeAPIModel';
import type { BlockchainTypeAPIModel } from './BlockchainTypeAPIModel';
import {
    BlockchainTypeAPIModelFromJSON,
    BlockchainTypeAPIModelFromJSONTyped,
    BlockchainTypeAPIModelToJSON,
} from './BlockchainTypeAPIModel';

/**
 * 
 * @export
 * @interface BlockchainMetaAPIModel
 */
export interface BlockchainMetaAPIModel {
    /**
     * 
     * @type {BlockchainTypeAPIModel}
     * @memberof BlockchainMetaAPIModel
     */
    blockchain: BlockchainTypeAPIModel;
    /**
     * 
     * @type {boolean}
     * @memberof BlockchainMetaAPIModel
     */
    isActive: boolean;
    /**
     * 
     * @type {BlockchainNetworkTypeAPIModel}
     * @memberof BlockchainMetaAPIModel
     */
    net: BlockchainNetworkTypeAPIModel;
    /**
     * 
     * @type {AssetFullMetaAPIModel}
     * @memberof BlockchainMetaAPIModel
     */
    nativeAsset: AssetFullMetaAPIModel;
    /**
     * 
     * @type {BlockchainAPITypeAPIModel}
     * @memberof BlockchainMetaAPIModel
     */
    apiType: BlockchainAPITypeAPIModel;
}

/**
 * Check if a given object implements the BlockchainMetaAPIModel interface.
 */
export function instanceOfBlockchainMetaAPIModel(value: object): boolean {
    if (!('blockchain' in value)) return false;
    if (!('isActive' in value)) return false;
    if (!('net' in value)) return false;
    if (!('nativeAsset' in value)) return false;
    if (!('apiType' in value)) return false;
    return true;
}

export function BlockchainMetaAPIModelFromJSON(json: any): BlockchainMetaAPIModel {
    return BlockchainMetaAPIModelFromJSONTyped(json, false);
}

export function BlockchainMetaAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): BlockchainMetaAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'blockchain': BlockchainTypeAPIModelFromJSON(json['blockchain']),
        'isActive': json['isActive'],
        'net': BlockchainNetworkTypeAPIModelFromJSON(json['net']),
        'nativeAsset': AssetFullMetaAPIModelFromJSON(json['nativeAsset']),
        'apiType': BlockchainAPITypeAPIModelFromJSON(json['apiType']),
    };
}

export function BlockchainMetaAPIModelToJSON(value?: BlockchainMetaAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'blockchain': BlockchainTypeAPIModelToJSON(value['blockchain']),
        'isActive': value['isActive'],
        'net': BlockchainNetworkTypeAPIModelToJSON(value['net']),
        'nativeAsset': AssetFullMetaAPIModelToJSON(value['nativeAsset']),
        'apiType': BlockchainAPITypeAPIModelToJSON(value['apiType']),
    };
}

