/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3276
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { GasWalletFullDetailsAPIModel } from './GasWalletFullDetailsAPIModel';
import {
    GasWalletFullDetailsAPIModelFromJSON,
    GasWalletFullDetailsAPIModelFromJSONTyped,
    GasWalletFullDetailsAPIModelToJSON,
} from './GasWalletFullDetailsAPIModel';

/**
 * 
 * @export
 * @interface GasWalletsDetailsAPIModel
 */
export interface GasWalletsDetailsAPIModel {
    /**
     * 
     * @type {{ [key: string]: GasWalletFullDetailsAPIModel; }}
     * @memberof GasWalletsDetailsAPIModel
     */
    gasWallets: { [key: string]: GasWalletFullDetailsAPIModel; };
}

/**
 * Check if a given object implements the GasWalletsDetailsAPIModel interface.
 */
export function instanceOfGasWalletsDetailsAPIModel(value: object): boolean {
    if (!('gasWallets' in value)) return false;
    return true;
}

export function GasWalletsDetailsAPIModelFromJSON(json: any): GasWalletsDetailsAPIModel {
    return GasWalletsDetailsAPIModelFromJSONTyped(json, false);
}

export function GasWalletsDetailsAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): GasWalletsDetailsAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'gasWallets': json['gasWallets'],
    };
}

export function GasWalletsDetailsAPIModelToJSON(value?: GasWalletsDetailsAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'gasWallets': value['gasWallets'],
    };
}

