import { PageContainer } from '@/components';
import type { TestableProps } from '@/infrastructure/utils/react';

import {
  SettlementViewBreadcrumb,
  SettlementCard,
  SettlementReconciliationCard,
  SettlementReconciliationOutgoingTransfersCard,
  SettlementReconciliationIncomingTransfersCard,
} from './components';
import { withSettlementViewPageParams } from './hocs';

import type React from 'react';
import type { PropsWithChildren } from 'react';

interface SettlementPageProps extends PropsWithChildren<TestableProps & { pageParams: { settlementId: string } }> {}

const SettlementPage: React.FC<SettlementPageProps> = ({
  'data-test': dataTest = 'settlement',
  pageParams: { settlementId },
}) => (
  <PageContainer
    data-test={dataTest && `${dataTest}-container`}
    breadcrumb={
      <SettlementViewBreadcrumb data-test={dataTest && `${dataTest}-breadcrumb`} settlementId={settlementId} disabled />
    }
  >
    <SettlementCard data-test={dataTest && `${dataTest}-card`} settlementId={settlementId} />
    <SettlementReconciliationOutgoingTransfersCard
      data-test={dataTest && `${dataTest}-outgoing`}
      settlementId={settlementId}
    />
    <SettlementReconciliationCard data-test={dataTest && `${dataTest}-reconciliation`} settlementId={settlementId} />
    <SettlementReconciliationIncomingTransfersCard
      data-test={dataTest && `${dataTest}-incoming`}
      settlementId={settlementId}
    />
  </PageContainer>
);

export default withSettlementViewPageParams(SettlementPage);
