import { useMemo } from 'react';

import { useMerchantWallets } from '@/features/merchant-wallets/hooks';
import type { BlockchainTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { mapStoredState } from '@/infrastructure/model';

const useActiveMerchantWallet = (bt: BlockchainTypeAPIModel | undefined) => {
  const { data: walletsState, loading, forceRefresh } = useMerchantWallets();

  return useMemo(
    () => ({
      loading,
      forceRefresh,
      data: mapStoredState(
        walletsState,
        (wallets) =>
          wallets
            .filter((wallet) => wallet.blockchain === bt)
            // looking for the last created means "actual"
            .sort((w1, w2) => w2.createdAt.getTime() - w1.createdAt.getTime())[0],
      ),
    }),
    [bt, forceRefresh, loading, walletsState],
  );
};

export default useActiveMerchantWallet;
