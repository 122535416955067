import { Provider } from 'react-redux';

import storeHolder from '@/app/store.holder';

import configureStore from './configureStore';

import type React from 'react';

let store: ReturnType<typeof configureStore> | undefined;
export const getAppStore = () => {
  if (!store) {
    store = configureStore();
    storeHolder.store = store;
  }
  return store;
};

const withStore =
  <P extends Record<string, unknown>>(Component: React.FC<P>): React.FC<P> =>
  (props: P) => (
    <Provider store={getAppStore()}>
      <Component {...props} />
    </Provider>
  );

export default withStore;
