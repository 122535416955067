import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nFeatureGasHistory } from '@/generated/i18n/i18n';

const GasHistoryReportTitle: React.FC = React.memo(() => (
  <FormattedMessage id={I18nFeatureGasHistory.COMPONENTS_REPORT_TITLE} />
));

export default GasHistoryReportTitle;
