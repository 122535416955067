import type { UseAppListData } from '@/app/hooks';
import { useAppListData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { makeSelectPending } from '@/features/global/selectors';
import { fetchMerchantWalletTransfers } from '@/features/merchant-wallet-transfers/actions';
import {
  makeSelectMerchantWalletTransferListData,
  makeSelectMerchantWalletTransferListParameters,
} from '@/features/merchant-wallet-transfers/selectors';
import type {
  MerchantWalletTransfer,
  MerchantWalletTransferFilterPredicate,
} from '@/features/merchant-wallet-transfers/types';
import type { MerchantWalletTransferSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { LoadingListDataState } from '@/infrastructure/model/list/types';

export interface UseMerchantWalletTransferList
  extends UseAppListData<
    MerchantWalletTransfer,
    MerchantWalletTransferFilterPredicate,
    MerchantWalletTransferSortByAPIModel
  > {}

const fetchFactory = (
  force: boolean,
): AppAsyncThunkAction<
  LoadingListDataState<MerchantWalletTransfer>,
  Parameters<typeof fetchMerchantWalletTransfers>[0]
> => fetchMerchantWalletTransfers({ force });

const dataSelector = makeSelectMerchantWalletTransferListData();
const dataFetchingSelector = makeSelectPending(fetchMerchantWalletTransfers);
const parametersSelector = makeSelectMerchantWalletTransferListParameters();

export default function useMerchantWalletTransferList(): UseMerchantWalletTransferList {
  return useAppListData(fetchFactory, parametersSelector, dataFetchingSelector, dataSelector);
}
