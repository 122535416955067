import Icon, { MailOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { Space, Tooltip, Typography } from 'antd';
import React, { useCallback, useMemo } from 'react';

import GoogleIcon from '@/assets/icons/google.svg?react';
import type { OperationProps } from '@/components';
import { FormattedMessage, FormattedMessageWithMarkup, Operation } from '@/components';
import { EmailEnterForm, GoogleInProgress } from '@/features/auth/components';
import type { EmailAuthOptions } from '@/features/auth/hooks';
import { withFeatureTogglesGuard } from '@/features/feature-toggle/hocs';
import { useFeatureToggle } from '@/features/feature-toggle/hooks';
import { I18nPageAuth } from '@/generated/i18n/i18n';
import { useErrorSafeSubmitting } from '@/hooks';
import type { Func } from '@/infrastructure/utils/ts';
import { withHideIfOtherActive } from '@/pages/auth/login/hocs';

import { useEmailAuth, useGoogleAuth, useWeb3AuthRecoveryKeyRecovery, useWeb3AuthRecoveryKeySecure } from './hooks';

import type { EmailBlockProps } from './types';

const { Text } = Typography;

const buttonStyles = css`
  flex: 1;
  align-items: center;
  margin-left: auto;
  display: flex;
  width: 100%;
`;

const buttonProps: OperationProps['ButtonProps'] = { size: 'large' };

const EmailBlock: React.FC<EmailBlockProps> = ({
  'data-test': dataTest,
  activeProvider,
  withLogIn,
  onSelectProvider,
}) => {
  const featureFlags = useFeatureToggle();
  const { withRecovery, context: recoveryContext } = useWeb3AuthRecoveryKeyRecovery({
    'data-test': dataTest && `${dataTest}-recovery`,
  });
  const { withSecure, context: secureContext } = useWeb3AuthRecoveryKeySecure({
    'data-test': dataTest && `${dataTest}-secure`,
  });
  const withAuth: <V extends unknown[] = unknown[], R = unknown>(
    func: (options: Omit<EmailAuthOptions, 'cancellationPromise'>) => Func<V, R>,
  ) => Func<V, unknown> = useCallback(
    (func) =>
      withSecure((onRecoveryKeySecure) =>
        withRecovery((onRecoveryKeyRequest) =>
          withLogIn((onBeforeSignUp) => func({ onBeforeSignUp, onRecoveryKeyRequest, onRecoveryKeySecure })),
        ),
      ),
    [withLogIn, withRecovery, withSecure],
  );
  const emailAuth = useEmailAuth({ onSelectProvider, withAuth });
  const googleAuth = useGoogleAuth({ onSelectProvider, withAuth });
  const { submitting, withSubmitting } = useErrorSafeSubmitting();

  const doGoogleLogin = useMemo(() => withSubmitting(googleAuth.auth), [googleAuth.auth, withSubmitting]);
  const doEmailLogin = useMemo(() => withSubmitting(emailAuth.select), [emailAuth.select, withSubmitting]);

  if (secureContext ?? recoveryContext) {
    return <>{secureContext ?? recoveryContext}</>;
  }
  if (activeProvider === 'google') {
    return <GoogleInProgress data-test={dataTest && `${dataTest}-google`} />;
  }

  if (activeProvider?.startsWith('email')) {
    return <EmailEnterForm data-test={dataTest && `${dataTest}-confirmation`} onLogin={emailAuth.auth} />;
  }

  return (
    <Space direction="vertical" style={{ display: 'flex', flex: 1 }} size="large">
      <Text data-test={dataTest && `${dataTest}-email-description`}>
        <FormattedMessage
          id={I18nPageAuth.COMPONENTS_EMAIL_BLOCK_DESCRIPTION}
          values={{
            help: (
              <Tooltip
                title={<FormattedMessageWithMarkup id={I18nPageAuth.COMPONENTS_EMAIL_BLOCK_SECURITY_NOTICE} />}
                overlayInnerStyle={{ backgroundColor: 'black', minWidth: 300 }}
              >
                <QuestionCircleOutlined />
              </Tooltip>
            ),
          }}
        />
      </Text>
      <Space direction="vertical" style={{ display: 'flex', flex: 1 }} size="middle">
        {!featureFlags.data.data?.disableEmailAuth && (
          <Operation
            ButtonProps={buttonProps}
            title={<FormattedMessage id={I18nPageAuth.COMPONENTS_EMAIL_BLOCK_EMAIL_LABEL} />}
            className={buttonStyles}
            data-test={dataTest && `${dataTest}-email`}
            disabled={!emailAuth.isAvailable || (submitting && activeProvider !== 'email')}
            inProgress={submitting && activeProvider === 'email'}
            icon={
              <Icon style={{ color: 'blue' }} component={MailOutlined as React.ForwardRefExoticComponent<unknown>} />
            }
            onClick={doEmailLogin}
            mode="button"
          />
        )}
        {!featureFlags.data.data?.disableGoogleAuth && (
          <Operation
            ButtonProps={buttonProps}
            title={<FormattedMessage id={I18nPageAuth.COMPONENTS_EMAIL_BLOCK_GOOGLE_LABEL} />}
            className={buttonStyles}
            data-test={dataTest && `${dataTest}-google`}
            disabled={!googleAuth.isAvailable || (submitting && activeProvider !== 'google')}
            inProgress={submitting && activeProvider === 'google'}
            icon={<Icon component={GoogleIcon} />}
            onClick={doGoogleLogin}
            mode="button"
          />
        )}
      </Space>
    </Space>
  );
};

const EmailBlockMemo: typeof EmailBlock = React.memo(
  withFeatureTogglesGuard((flags) => !flags?.disableWeb3Auth && !(flags?.disableEmailAuth || flags?.disableGoogleAuth))(
    withHideIfOtherActive<EmailBlockProps>(
      (activeProvider) => !activeProvider || activeProvider.startsWith('email') || activeProvider === 'google',
    )(EmailBlock),
  ),
);

export default EmailBlockMemo;
