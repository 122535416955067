import type { Subscription } from '@/features/subscriptions/types';
import type { SubscriptionStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';

import type React from 'react';

const withSubscriptionDataLoading =
  <T extends { data: Subscription }>(expectedStatuses: SubscriptionStatusAPIModel[]) =>
  (Component: React.ComponentType<T>): React.FC<T> =>
  (props: T) =>
    // eslint-disable-next-line react/jsx-props-no-spreading,react/destructuring-assignment
    expectedStatuses.includes(props.data.status) ? <Component {...props} /> : null;

export default withSubscriptionDataLoading;
