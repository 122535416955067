import { Space } from 'antd';

import { FormattedMessage } from '@/components';
import { OperationRefreshGasHistory } from '@/features/gas-history/components';
import { useGasHistory } from '@/features/gas-history/hooks';
import type { GasHistoryRow } from '@/features/gas-history/types';
import { I18nPageGasHistory } from '@/generated/i18n/i18n';
import { withCardDataLoading } from '@/hocs';
import type { TestableProps } from '@/infrastructure/utils/react';

import type React from 'react';

const withGasHistoryDataLoading =
  <
    Original extends { data: GasHistoryRow } & TestableProps,
    Wrapper extends Omit<Original, 'data'> & { historyId: string } = Omit<Original, 'data'> & {
      historyId: string;
    },
  >(
    Component: React.ComponentType<Original>,
  ): React.FC<Wrapper> =>
  (props) => {
    const dataTest = props['data-test'];
    const data = useGasHistory(props.historyId);
    return withCardDataLoading<GasHistoryRow, Original>({
      ...data,
      'data-test': dataTest,
      title: (
        <FormattedMessage id={I18nPageGasHistory.GAS_HISTORY_VIEW_COMPONENTS_CARD} values={{ id: props.historyId }} />
      ),
      hideBack: true,
      CardProps: {
        extra: (
          <Space>
            <OperationRefreshGasHistory id={props.historyId} data-test={dataTest && `${dataTest}-opRefresh`} />
          </Space>
        ),
      },
    })(Component)(props);
  };

export default withGasHistoryDataLoading;
