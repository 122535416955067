import { noop } from '@/infrastructure/utils/functions';

export enum YMGoals {
  USER_LOGGED_IN = 'USER_LOGGED_IN',
  USER_LOGGED_IN_WEB3AUTH = 'USER_LOGGED_IN_WEB3AUTH',
  USER_LOGGED_IN_WEB3 = 'USER_LOGGED_IN_WEB3',
  USER_ONBOARDED = 'USER_ONBOARDED',
  USER_ASSET_UPDATED = 'USER_ASSET_UPDATED',

  USER_EMAIL_UPDATE_INIT = 'USER_EMAIL_UPDATE_INIT',
  USER_EMAIL_UPDATE_REQUESTED = 'USER_EMAIL_UPDATE_REQUESTED',
  USER_EMAIL_UPDATE_CONFIRMED = 'USER_EMAIL_UPDATE_CONFIRMED',

  API_KEY_GENERATED = 'USER_API_KEY_GENERATED',

  API_NOTIFICATION_URL_UPDATE_INIT = 'API_NOTIFICATION_URL_UPDATE_INIT',
  API_NOTIFICATION_URL_UPDATED = 'API_NOTIFICATION_URL_UPDATED',

  GAS_WALLET_CREATE_INIT = 'USER_GAS_WALLET_CREATE_INIT',
  GAS_WALLET_CREATED = 'USER_GAS_WALLET_CREATED',

  GAS_WALLET_UPDATE_INIT = 'USER_GAS_WALLET_UPDATE_INIT',
  GAS_WALLET_UPDATED = 'USER_GAS_WALLET_UPDATED',

  GAS_WALLET_REFUND_INIT = 'GAS_WALLET_REFUND_INIT',
  GAS_WALLET_REFUNDED = 'GAS_WALLET_REFUNDED',

  INVOICE_CREATE_INIT = 'INVOICE_CREATE_INIT',
  INVOICE_CREATED = 'INVOICE_CREATED',

  CUSTOMER_CREATE_INIT = 'CUSTOMER_CREATE_INIT',
  CUSTOMER_CREATED = 'CUSTOMER_CREATED',
  CUSTOMER_UPDATE_INIT = 'CUSTOMER_UPDATE_INIT_INIT',
  CUSTOMER_UPDATED = 'CUSTOMER_UPDATED',
  CUSTOMER_INVOICE_CREATE_INIT = 'CUSTOMER_INVOICE_CREATE_INIT',
  CUSTOMER_INVOICE_CREATED = 'CUSTOMER_INVOICE_CREATED',

  DONATION_CREATE_INIT = 'DONATION_CREATE_INIT',
  DONATION_CREATED = 'DONATION_CREATED',
  DONATION_UPDATE_INIT = 'DONATION_UPDATE_INIT_INIT',
  DONATION_UPDATED = 'DONATION_UPDATED',
  DONATION_REMOVED = 'DONATION_REMOVED',

  REPORT_SCHEDULE_CREATE_INIT = 'REPORT_SCHEDULE_CREATE_INIT',
  REPORT_SCHEDULE_CREATED = 'REPORT_SCHEDULE_CREATED',

  REPORT_SCHEDULE_UPDATE_INIT = 'REPORT_SCHEDULE_UPDATE_INIT',
  REPORT_SCHEDULE_UPDATED = 'REPORT_SCHEDULE_UPDATED',

  REPORT_GENERATED = 'REPORT_GENERATED',

  COMPANY_USER_ADDED = 'COMPANY_USER_ADDED',
  COMPANY_USER_REMOVED = 'COMPANY_USER_REMOVED',

  COMPANY_NAME_UPDATE_INIT = 'COMPANY_NAME_UPDATE_INIT',
  COMPANY_NAME_UPDATED = 'COMPANY_NAME_UPDATED',

  COMPANY_WEBSITE_UPDATE_INIT = 'COMPANY_WEBSITE_UPDATE_INIT',
  COMPANY_WEBSITE_UPDATED = 'COMPANY_WEBSITE_UPDATED',

  SUBSCRIPTION_PLAN_CREATE_INIT = 'SUBSCRIPTION_PLAN_CREATE_INIT',
  SUBSCRIPTION_PLAN_CREATED = 'SUBSCRIPTION_PLAN_CREATED',

  SUBSCRIPTION_PLAN_UPDATE_INIT = 'SUBSCRIPTION_PLAN_UPDATE_INIT',
  SUBSCRIPTION_PLAN_UPDATED = 'SUBSCRIPTION_PLAN_UPDATED',

  WITHDRAWAL_PAYOUT_CREATE_INIT = 'WITHDRAWAL_PAYOUT_CREATE_INIT',
  WITHDRAWAL_PAYOUT_CREATED = 'WITHDRAWAL_PAYOUT_CREATED',
}

const ymId = window.env.YM_ENABLED && window.env.YM_ID ? window.env.YM_ID : undefined;

const goalReachedFunc = (id: number) => (goalName: YMGoals) => {
  try {
    ym(id, 'reachGoal', goalName);
  } catch (e) {
    console.warn(goalName, e);
  }
};

export const goalReached = ymId ? goalReachedFunc(ymId) : noop;
