import { useMemo } from 'react';

import type { UseAppSingleData } from '@/app/hooks';
import { useAppSingleData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { makeSelectPending } from '@/features/global/selectors';
import { fetchSubscriptionPlan } from '@/features/subscription-plans/actions';
import { makeSelectSubscriptionPlan } from '@/features/subscription-plans/selectors';
import type { SubscriptionPlan } from '@/features/subscription-plans/types';
import type { LoadingStateWithDirty } from '@/infrastructure/model';
import { storedDataError } from '@/infrastructure/model';

export interface UseSubscriptionPlanPlan extends UseAppSingleData<SubscriptionPlan> {}

const noData = storedDataError<SubscriptionPlan>('no-data');

const fetchFactory = (
  id: string,
  force: boolean,
): AppAsyncThunkAction<LoadingStateWithDirty<SubscriptionPlan>, Parameters<typeof fetchSubscriptionPlan>[0]> =>
  fetchSubscriptionPlan({ id, force });

export default function useSubscriptionPlan(id: string | undefined): UseSubscriptionPlanPlan {
  const dataFetchingSelector = useMemo(() => makeSelectPending(fetchSubscriptionPlan, id), [id]);
  return useAppSingleData(fetchFactory, makeSelectSubscriptionPlan, noData, id, dataFetchingSelector);
}
