import { FilterOutlined } from '@ant-design/icons';
import { ProTable } from '@ant-design/pro-components';
import { Badge, Button, Modal, Tooltip } from 'antd';
import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useMemo, useRef } from 'react';

import { I18nComponents } from '@/generated/i18n/i18n';
import { useColumnStateNormalization, useFormVisible } from '@/hooks';
import { defaultPage, type OrderBy } from '@/infrastructure/api';
import type { ListColumnState } from '@/infrastructure/model/list/types';
import { sortOrderToModel } from '@/infrastructure/view';

import FormattedMessage from '../FormattedMessage';

import { LoadableDrawer, TableDataFetchError } from './components';

import type { NCPSTableProps } from './types';
import type { ActionType } from '@ant-design/pro-table';
import type { OptionConfig } from '@ant-design/pro-table/lib/components/ToolBar';
import type { FormInstance } from 'antd/es/form';
import type { SorterResult, TablePaginationConfig } from 'antd/lib/table/interface';

const tableScroll: { x: true } = { x: true };
const defaultToolbarRender = (Component: React.ReactNode) => [Component];

const NCPSTableRaw = <Value extends object, Filter, SortBy extends string>({
  'data-test': dataTest,
  filter,
  preview,
  columns,
  data,
  loading,
  page,
  columnsState,
  filterData,
  reload,
  updateParameters,
  extractKey,
  sortColumnToModel,
  toolBarRender = defaultToolbarRender,
  TableProps,
}: NCPSTableProps<Value, Filter, SortBy>) => {
  const { hide, show, withFormHide, visible } = useFormVisible(false);
  const dataIsNotReady = loading || data.isDirty;
  const formRef = useRef<FormInstance>();
  const actionRef = useRef<ActionType>();
  const [modal, contextHolder] = Modal.useModal();
  const columnsStateNormalized = useColumnStateNormalization<Value>(columns, columnsState);

  const onUpdateParameters = updateParameters;
  const updateFilter = useMemo(
    () =>
      withFormHide(async (predicate: Filter) => {
        await onUpdateParameters({ filter: predicate });
      }),
    [onUpdateParameters, withFormHide],
  );
  const resetFilter = useMemo(
    () =>
      withFormHide(async () => {
        await onUpdateParameters({ filter: filter?.empty });
      }),
    [filter?.empty, onUpdateParameters, withFormHide],
  );
  const doClosePreviewHolder = useRef<() => void>();
  const onRowClick = useMemo(
    () =>
      preview
        ? (value: Value) => {
            const doClose = () => doClosePreviewHolder.current?.();
            const { title, content, width } = preview(value, { onClose: doClose });
            return {
              onClick: () => {
                const info = modal.info({
                  title,
                  content,
                  maskClosable: true,
                  width: width ?? 650,
                  onCancel: () => {
                    doClosePreviewHolder.current = undefined;
                  },
                  onOk: () => {
                    doClosePreviewHolder.current = undefined;
                  },
                });
                doClosePreviewHolder.current = () => {
                  info.destroy();
                };
                return info;
              },
            };
          }
        : undefined,
    [modal, preview],
  );

  const onUpdateColumnsState = useCallback(
    async (columnState: ListColumnState) => {
      await onUpdateParameters({ columnState });
    },
    [onUpdateParameters],
  );
  const tableViewRender = useCallback(
    (_: unknown, defaultDom: React.JSX.Element) =>
      !dataIsNotReady && data.error ? (
        <TableDataFetchError<Filter>
          data-test={dataTest && `${dataTest}-dataError`}
          refresh={reload}
          restore={{ action: onUpdateParameters, parameters: { page, filter: filterData } }}
        />
      ) : (
        defaultDom
      ),
    [data.error, dataIsNotReady, dataTest, filterData, onUpdateParameters, page, reload],
  );

  const tableToolBarRender = useCallback(
    () =>
      toolBarRender(
        filter ? (
          <>
            <Tooltip title={<FormattedMessage id={I18nComponents.TABLE_FILTER_APPLY_TITLE} />} key="addFilter">
              <Button onClick={show} data-test={dataTest && `${dataTest}-show-filter`} disabled={loading}>
                <Badge status="success" dot>
                  <FilterOutlined />
                </Badge>
              </Button>
            </Tooltip>
            <Tooltip title={<FormattedMessage id={I18nComponents.TABLE_FILTER_RESET_TITLE} />} key="removeFilter">
              <Button
                disabled={loading || isEmpty(filterData)}
                onClick={resetFilter}
                data-test={dataTest && `${dataTest}-hide-filter`}
              >
                <Badge status="error" dot>
                  <FilterOutlined />
                </Badge>
              </Button>
            </Tooltip>
          </>
        ) : null,
      ),
    [dataTest, filter, filterData, loading, resetFilter, show, toolBarRender],
  );
  const tableColumnsState = useMemo(
    () => ({
      value: columnsStateNormalized,
      onChange: onUpdateColumnsState,
    }),
    [columnsStateNormalized, onUpdateColumnsState],
  );
  const onTableChange = useCallback(
    async (pagination: TablePaginationConfig, _: unknown, sorter: SorterResult<Value> | SorterResult<Value>[]) => {
      const value: SorterResult<Value> | undefined = Array.isArray(sorter) ? sorter[0] : sorter;
      const order = sortOrderToModel(value.order);
      const field = Array.isArray(value.field) ? value.field[0] : value.field;
      const key = field === 0 || field ? sortColumnToModel?.(`${field}`) : undefined;
      const newPage = pagination.current ?? defaultPage.page;
      await onUpdateParameters({
        sortBy: key && order ? ({ [key]: order } as OrderBy<SortBy>) : {},
        page: {
          page: newPage < 500 ? newPage : 500,
          perPage: pagination.pageSize ?? defaultPage.perPage,
        },
      });
      if (newPage >= 500) {
        actionRef.current?.reset?.();
      }
    },
    [onUpdateParameters, sortColumnToModel],
  );
  const pagination = useMemo<TablePaginationConfig>(
    () => ({
      itemRender: (curPage, type, originalElement) =>
        type === 'page' && curPage > 500 ? (
          <Tooltip title={<FormattedMessage id={I18nComponents.TABLE_PAGINATION_MAX_PAGE} values={{ max: 500 }} />}>
            {originalElement}
          </Tooltip>
        ) : (
          originalElement
        ),
      pageSizeOptions: ['10', '20', '50'],
      total: data.data?.total,
      current: page.page,
      defaultCurrent: page.page,
      pageSize: page.perPage,
      defaultPageSize: page.perPage,
    }),
    [data.data?.total, page.page, page.perPage],
  );
  const tableOptions: OptionConfig = useMemo(() => ({ reload, fullScreen: true }), [reload]);

  return (
    <>
      {filter && visible && (
        <LoadableDrawer
          title={filter.title || <FormattedMessage id={I18nComponents.TABLE_FILTER_TITLE} />}
          width={600}
          maskClosable={false}
          onClose={hide}
        >
          <filter.Form
            value={filterData}
            onReset={resetFilter}
            onSubmit={updateFilter}
            onCancel={hide}
            data-test={dataTest && `${dataTest}-filter`}
          />
        </LoadableDrawer>
      )}
      <ProTable<Value>
        actionRef={actionRef}
        tableViewRender={tableViewRender}
        search={false}
        toolBarRender={tableToolBarRender}
        toolbar={{ multipleLine: false }}
        formRef={formRef}
        columnsState={tableColumnsState}
        onChange={onTableChange}
        dataSource={data.data?.data}
        scroll={tableScroll}
        loading={dataIsNotReady}
        pagination={pagination}
        rowKey={extractKey}
        columns={columns}
        options={tableOptions}
        rowSelection={false}
        onRow={onRowClick}
        {...TableProps}
      />
      {contextHolder}
    </>
  );
};

const NCPSTable = React.memo(NCPSTableRaw) as typeof NCPSTableRaw;

export default NCPSTable;
