import { Button } from 'antd';
import React from 'react';

import FormattedMessage from '@/components/FormattedMessage';
import FormFooter from '@/components/FormFooter';
import { defaultPageFormTailLayout } from '@/components/utils';
import { I18nComponents } from '@/generated/i18n/i18n';

import type { FilterFormFooterProps } from './types';

const FilterFormFooter: React.FC<FilterFormFooterProps> = React.memo(
  ({ 'data-test': dataTest, readonly, submitDisabled, onCancel, submitting, layout = defaultPageFormTailLayout }) => (
    <FormFooter
      data-test={dataTest}
      tailLayout={layout}
      submitting={submitting}
      readonly={readonly}
      submitDisabled={submitDisabled}
      messages={{
        submit: <FormattedMessage id={I18nComponents.FILTER_FORM_FOOTER_BUTTONS_SUBMIT} tagName="span" />,
        cancel: <FormattedMessage id={I18nComponents.FILTER_FORM_FOOTER_BUTTONS_RESET} tagName="span" />,
      }}
      extraButtons={{
        mid: onCancel && (
          <Button size="middle" onClick={onCancel} data-test={dataTest && `${dataTest}-close`}>
            <FormattedMessage id={I18nComponents.FILTER_FORM_FOOTER_BUTTONS_CANCEL} />
          </Button>
        ),
      }}
    />
  ),
);

export default FilterFormFooter;
