import type { UseAppListDataView } from '@/app/hooks';
import useListDataView from '@/app/hooks/useAppListDataView';
import { storePaymentListParameters } from '@/features/payments/actions';
import type { Payment, PaymentFilterPredicate } from '@/features/payments/types';
import type { PaymentSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';

import usePaymentList from './usePaymentList';

export interface UsePaymentListView
  extends UseAppListDataView<Payment, PaymentFilterPredicate, PaymentSortByAPIModel> {}

export default function usePaymentListView(): UsePaymentListView {
  return useListDataView(usePaymentList(), storePaymentListParameters);
}
