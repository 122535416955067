import { Form } from 'antd';
import set from 'lodash/set';
import uniq from 'lodash/uniq';
import React, { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { FormattedMessage, SelectWithReloadingItem } from '@/components';
import { useCompanyActiveAssets } from '@/features/company-settings/hooks';
import { useListBlockchains } from '@/features/dictionary/blockchain/hooks';
import type { Asset } from '@/features/dictionary/blockchain/types';
import { BlockchainAPITypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureCompanySettings, I18nFeatureDictionary } from '@/generated/i18n/i18n';
import { useStateMountSafe } from '@/hooks';
import { combine } from '@/infrastructure/model';
import { notEmpty } from '@/infrastructure/utils/ts';

import type { CompanyActiveAssetItemProps } from './types';
import type { NamePath, Store } from 'rc-field-form/es/interface';

const CompanyActiveAssetItem = <Values extends Store = Store>({
  'data-test': dataTest,
  name,
  placeholder,
  readonly,
  required,
  messages,
  selectedAssets: selectedAssetCodes,
  rules: extraRules = [],
  SelectInputProps,
  excludeAPI,
  ItemProps = {},
}: CompanyActiveAssetItemProps<Values>) => {
  const { formatMessage: i18n } = useIntl();
  const { data: assetsData, loading, forceRefresh } = useCompanyActiveAssets();

  const { data: blockchainsData } = useListBlockchains();
  const data = useMemo(
    () =>
      combine(assetsData, blockchainsData, (assets, blockchains) => {
        const valuesExcludedAPI = excludeAPI
          ? assets.filter((v) => blockchains.find((bc) => bc.blockchain === v.blockchain)?.apiType !== excludeAPI)
          : assets;

        return valuesExcludedAPI.filter(
          (v) => blockchains.find((bc) => bc.blockchain === v.blockchain)?.apiType !== BlockchainAPITypeAPIModel.BTC,
        );
      }),
    [assetsData, blockchainsData, excludeAPI],
  );

  const [selectedAssets, setSelectedAssets] = useStateMountSafe<Asset[]>([]);
  useEffect(() => {
    const selected = data.data?.filter(({ code }) => selectedAssetCodes?.includes(code)) ?? [];
    setSelectedAssets(selected);
  }, [data.data, selectedAssetCodes, setSelectedAssets]);
  const rules = useMemo(
    () => [
      // forbids the duplicate selection
      {
        // eslint-disable-next-line @typescript-eslint/require-await
        validator: async (_, newValue: string) => {
          if (!newValue) return;
          if (selectedAssetCodes?.includes(newValue)) {
            throw new Error(
              i18n({ id: I18nFeatureCompanySettings.COMPONENTS_COMPANY_ACTIVE_ASSETS_ITEM_ERROR_ALREADY_SELECTED }),
            );
          }
        },
      },
      // forbids different blockchain selection
      {
        // eslint-disable-next-line @typescript-eslint/require-await
        validator: async (_, newValue: string) => {
          if (!newValue) return;
          const selectedBlockchains = uniq(
            [...(selectedAssetCodes ?? []), newValue]
              .map((selectedCode) => data.data?.find(({ code }) => code === selectedCode)?.blockchain)
              .filter(notEmpty),
          );
          if (selectedBlockchains.length > 1) {
            throw new Error(
              i18n({
                id: I18nFeatureCompanySettings.COMPONENTS_COMPANY_ACTIVE_ASSETS_ITEM_ERROR_BLOCKCHAIN_INCONSISTENT,
              }),
            );
          }
        },
      },
      // forbids the stablecoin mix
      {
        // eslint-disable-next-line @typescript-eslint/require-await
        validator: async (_, newValue: string) => {
          if (!newValue) return;
          const isValueNotStablecoin = data.data?.find(({ code }) => code === newValue)?.isStable === false;
          if (isValueNotStablecoin) {
            const isOneOfSelectedStablecoin = !!selectedAssets.find(({ isStable }) => !!isStable);
            if (isOneOfSelectedStablecoin) {
              throw new Error(
                i18n({ id: I18nFeatureCompanySettings.COMPONENTS_COMPANY_ACTIVE_ASSETS_ITEM_ERROR_STABLECOIN_MIX }),
              );
            }
          }
        },
      },
      ...extraRules,
    ],
    [data.data, extraRules, i18n, selectedAssetCodes, selectedAssets],
  );
  return (
    <>
      <SelectWithReloadingItem<Values, Asset>
        data-test={dataTest}
        readonly={readonly}
        required={required}
        name={name}
        messages={{
          label: messages?.label || <FormattedMessage id={I18nFeatureDictionary.COMPONENTS_ASSET_ITEM_LABEL} />,
          dataEmpty: messages?.empty || (
            <FormattedMessage id={I18nFeatureDictionary.COMPONENTS_ASSET_ITEM_EMPTY_ERROR} />
          ),
          placeholder: placeholder || <FormattedMessage id={I18nFeatureDictionary.COMPONENTS_ASSET_ITEM_PLACEHOLDER} />,
          required: messages?.required ?? (
            <FormattedMessage id={I18nFeatureDictionary.COMPONENTS_ASSET_ITEM_REQUIRED} />
          ),
        }}
        refresh={forceRefresh}
        loading={loading}
        options={data}
        dataToOptions={({ code, name: label }) => ({ value: code, label })}
        ItemProps={{ ...ItemProps, rules }}
        SelectInputProps={SelectInputProps}
      />
      <Form.Item shouldUpdate noStyle hidden>
        {({ getFieldValue, setFieldsValue }) => {
          if (name && !getFieldValue(name) && data.data?.[0]) {
            if (Array.isArray(name) && name.length > 1) {
              const rootValue = (name[0] && getFieldValue(name[0])) || {};
              const value = set(rootValue, name.join('.'), data.data[0].code);
              setFieldsValue(value);
            } else {
              const key: NamePath = Array.isArray(name) ? name[0] : name;
              if (key) {
                setFieldsValue({ [key]: data.data[0].code });
              }
            }
          }
          return null;
        }}
      </Form.Item>
    </>
  );
};

const CompanyActiveAssetItemMemo = React.memo(CompanyActiveAssetItem) as typeof CompanyActiveAssetItem;

export default CompanyActiveAssetItemMemo;
