import { Tag } from 'antd';
import React, { useMemo } from 'react';

import { WarningTooltip } from '@/components';
import { AddressNativeBalanceView } from '@/features/dictionary/blockchain/components';
import { useNativeBalance } from '@/features/dictionary/blockchain/hooks';
import GasWalletInvalidStateMessage from '@/features/gas-wallets/components/GasWalletInvalidStateMessage';
import withWalletBalanceLoading from '@/features/gas-wallets/hocs/withWalletBalanceLoading';
import { useMerchantGasWallet } from '@/features/gas-wallets/hooks';

import type { GasWalletNativeBalanceViewProps } from './types';

const GasWalletNativeBalanceView: React.FC<GasWalletNativeBalanceViewProps> = ({ wallet, 'data-test': dataTest }) => {
  const { forceRefresh } = useMerchantGasWallet(wallet.bt);
  const {
    data: { data: balance },
  } = useNativeBalance(wallet);
  const isBalanceLow = useMemo(
    () => !!balance && balance.amount.lt(wallet.lowWatermark),
    [balance, wallet.lowWatermark],
  );
  return isBalanceLow ? (
    <WarningTooltip title={<GasWalletInvalidStateMessage wallet={wallet} mode="full" type="native-balance" />}>
      <Tag color="orange" style={{ marginRight: 0 }}>
        <AddressNativeBalanceView
          address={wallet.address}
          bt={wallet.bt}
          data-test={dataTest}
          onRefresh={forceRefresh}
          withCurrency
        />
      </Tag>
    </WarningTooltip>
  ) : (
    <Tag color="blue" style={{ marginRight: 0 }}>
      <AddressNativeBalanceView address={wallet.address} bt={wallet.bt} data-test={dataTest} withCurrency />
    </Tag>
  );
};

const GasWalletNativeBalanceViewHOCed = withWalletBalanceLoading(GasWalletNativeBalanceView);

const GasWalletNativeBalanceViewMemo = React.memo(
  GasWalletNativeBalanceViewHOCed,
) as typeof GasWalletNativeBalanceViewHOCed;

export default GasWalletNativeBalanceViewMemo;
