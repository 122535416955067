/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3276
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AssetBalancesAPIModel
 */
export interface AssetBalancesAPIModel {
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetBalancesAPIModel
     */
    balances?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetBalancesAPIModel
     */
    availableBalances?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetBalancesAPIModel
     */
    lockedBalances?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AssetBalancesAPIModel
     */
    total: string;
    /**
     * 
     * @type {string}
     * @memberof AssetBalancesAPIModel
     */
    totalAvailable: string;
    /**
     * 
     * @type {string}
     * @memberof AssetBalancesAPIModel
     */
    totalLocked: string;
}

/**
 * Check if a given object implements the AssetBalancesAPIModel interface.
 */
export function instanceOfAssetBalancesAPIModel(value: object): boolean {
    if (!('total' in value)) return false;
    if (!('totalAvailable' in value)) return false;
    if (!('totalLocked' in value)) return false;
    return true;
}

export function AssetBalancesAPIModelFromJSON(json: any): AssetBalancesAPIModel {
    return AssetBalancesAPIModelFromJSONTyped(json, false);
}

export function AssetBalancesAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssetBalancesAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'balances': json['balances'] == null ? undefined : json['balances'],
        'availableBalances': json['availableBalances'] == null ? undefined : json['availableBalances'],
        'lockedBalances': json['lockedBalances'] == null ? undefined : json['lockedBalances'],
        'total': json['total'],
        'totalAvailable': json['totalAvailable'],
        'totalLocked': json['totalLocked'],
    };
}

export function AssetBalancesAPIModelToJSON(value?: AssetBalancesAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'balances': value['balances'],
        'availableBalances': value['availableBalances'],
        'lockedBalances': value['lockedBalances'],
        'total': value['total'],
        'totalAvailable': value['totalAvailable'],
        'totalLocked': value['totalLocked'],
    };
}

