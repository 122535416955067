import { useSettlementSchedule } from '@/features/settlements/hooks';
import type { SettlementSchedule } from '@/features/settlements/types';

import type React from 'react';

const emptyComponent = () => null;

const withSettlementScheduleLoad =
  <T extends { schedule: SettlementSchedule }>(
    Component: React.ComponentType<Omit<T, 'schedule'> & { schedule: SettlementSchedule }>,
    EmptyComponent: React.ComponentType<Omit<T, 'schedule'>>,
  ) =>
  (props: Omit<T, 'schedule'>) => {
    const schedule = useSettlementSchedule();
    // eslint-disable-next-line react/jsx-props-no-spreading
    return schedule.data.data ? <Component {...props} schedule={schedule.data.data} /> : <EmptyComponent {...props} />;
  };

const withSettlementSchedule =
  <T extends { schedule: SettlementSchedule }>(
    EmptyComponent: React.ComponentType<Omit<T, 'schedule'>> = emptyComponent,
  ) =>
  (Component: React.ComponentType<Omit<T, 'schedule'> & { schedule: SettlementSchedule }>) => {
    const LoadComponent = withSettlementScheduleLoad<T>(Component, EmptyComponent);
    return (props: T | Omit<T, 'schedule'>) => {
      const { schedule } = props as Partial<T>;
      // eslint-disable-next-line react/jsx-props-no-spreading
      return schedule ? <Component {...props} schedule={schedule} /> : <LoadComponent {...props} />;
    };
  };

export default withSettlementSchedule;
