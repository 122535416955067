import React from 'react';

import { FormattedMessage, Link } from '@/components';
import { I18nPageAudit } from '@/generated/i18n/i18n';
import { auditWalletTransfersLink } from '@/pages/audit/routes';

import type { MerchantWalletRunningBalancesLinkProps } from './types';


const MerchantWalletRunningBalancesLinkPropsRaw: React.FC<MerchantWalletRunningBalancesLinkProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  icon,
  title,
}) => (
  <Link
    data-test={dataTest}
    to={auditWalletTransfersLink()}
    mode={mode}
    title={title || <FormattedMessage id={I18nPageAudit.BALANCES_COMPONENTS_LINK_TITLE} />}
    icon={icon}
  />
);

const MerchantWalletRunningBalancesLink = React.memo(MerchantWalletRunningBalancesLinkPropsRaw);

export default MerchantWalletRunningBalancesLink;
