import { useEffect, useMemo, useRef } from 'react';
import { useConnections, useConnectors } from 'wagmi';

import type { Web3Api } from '@/features/web3/types';
import { disconnectConnectorSupport, localConnectorSupport } from '@/features/web3/utils';
import { useStateMountSafe } from '@/hooks';
import { onlyUnique } from '@/infrastructure/utils/functions';

export interface UseWeb3 extends Web3Api {}

export default function useWeb3(): UseWeb3 {
  const connections = useConnections();
  const baseConnectors = useConnectors();
  const [ready, setReady] = useStateMountSafe<string[]>([]);
  const setUpFor = useRef(0);
  useEffect(() => {
    if (setUpFor.current !== baseConnectors.length) {
      setUpFor.current = baseConnectors.length;
      baseConnectors.forEach(async (c) => {
        await c.setup?.();
        if (await c.getProvider()) {
          setReady((prev) => [...prev, c.id].filter(onlyUnique));
        }
      });
    }
  }, [baseConnectors, setReady]);
  const connectors: Web3Api['connectors'] = useMemo(
    () =>
      baseConnectors.map((connector) => ({
        id: connector.id,
        connector,
        available: ready.includes(connector.id),
        connection: connections.find((connection) => connection.connector.id === connector.id),
        isLocal: localConnectorSupport(connector).isSupported,
        disconnectable: disconnectConnectorSupport(connector),
      })),
    [baseConnectors, ready, connections],
  );
  const orderedConnectors = useMemo(
    () => [...connectors.filter(({ available }) => available), ...connectors.filter(({ available }) => !available)],
    [connectors],
  );

  return { connectors, orderedConnectors };
}
