import { Drawer } from 'antd';
import React, { useCallback, useEffect } from 'react';

import { useStateMountSafe } from '@/hooks';

import type { LoadableDrawerProps } from './types';

const LoadableDrawerRaw: React.FC<LoadableDrawerProps> = ({ children, width, ...props }) => {
  const [visible, setVisible] = useStateMountSafe<boolean>(false);
  useEffect(() => {
    setVisible(true);
  });

  const [maxWidth, setMaxWidth] = useStateMountSafe(
    typeof width === 'number' ? Math.min(width, window.innerWidth) : width,
  );
  const resize = useCallback(() => {
    if (typeof width === 'number') {
      setMaxWidth(Math.min(width, window.innerWidth));
    }
  }, [setMaxWidth, width]);
  useEffect(() => {
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
  });
  return (
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    <Drawer open={visible} width={maxWidth} {...props}>
      {children}
    </Drawer>
  );
};

const LoadableDrawer = React.memo(LoadableDrawerRaw) as typeof LoadableDrawerRaw;

export default LoadableDrawer;
