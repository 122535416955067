import { Form } from 'antd';
import React from 'react';

import PayoutDestinationsTable from '../PayoutDestinationsTable';

import type { PayoutDestinationsItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const PayoutDestinationsItem = <Values extends Store = Store>({
  'data-test': dataTest,
  name,
  assetItemName,
  readonly,
  ItemProps = {},
}: PayoutDestinationsItemProps<Values>) => (
  <Form.Item<Values> noStyle dependencies={[assetItemName]}>
    {({ getFieldValue }) => (
      <Form.Item<Values> noStyle {...ItemProps} name={name}>
        {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
        <PayoutDestinationsTable data-test={dataTest} asset={getFieldValue(assetItemName)} disabled={readonly} />
      </Form.Item>
    )}
  </Form.Item>
);

const PayoutDestinationsItemMemo = React.memo(PayoutDestinationsItem) as typeof PayoutDestinationsItem;

export default PayoutDestinationsItemMemo;
