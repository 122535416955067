import React from 'react';

import { FormattedMessage } from '@/components';
import { DocumentationBanner } from '@/features/user/components';
import { I18nFeaturePayments } from '@/generated/i18n/i18n';

import PaymentDocumentationLink from '../PaymentsDocumentationLink';

import type { PaymentsDocumentationBannerProps } from './types';

const PaymentsDocumentationBannerRaw: React.FC<PaymentsDocumentationBannerProps> = React.memo(
  ({ 'data-test': dataTest, pageSize }) => (
    <DocumentationBanner
      PageProps={{ pageSize }}
      data-test={dataTest}
      type="payments"
      message={
        <FormattedMessage
          id={I18nFeaturePayments.COMPONENTS_DOCUMENTATION_BANNER_MESSAGE}
          values={{
            lk: (message: React.ReactNode) => <PaymentDocumentationLink title={message} mode="text" />,
          }}
        />
      }
    />
  ),
);

const PaymentsDocumentationBanner = React.memo(PaymentsDocumentationBannerRaw);

export default PaymentsDocumentationBanner;
