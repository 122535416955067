/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3276
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { BlockchainTypeAPIModel } from './BlockchainTypeAPIModel';
import {
    BlockchainTypeAPIModelFromJSON,
    BlockchainTypeAPIModelFromJSONTyped,
    BlockchainTypeAPIModelToJSON,
} from './BlockchainTypeAPIModel';

/**
 * 
 * @export
 * @interface PayoutAPIModel
 */
export interface PayoutAPIModel {
    /**
     * 
     * @type {string}
     * @memberof PayoutAPIModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PayoutAPIModel
     */
    companyId: string;
    /**
     * 
     * @type {string}
     * @memberof PayoutAPIModel
     */
    withdrawalId: string;
    /**
     * 
     * @type {string}
     * @memberof PayoutAPIModel
     */
    destination: string;
    /**
     * 
     * @type {BlockchainTypeAPIModel}
     * @memberof PayoutAPIModel
     */
    blockchain: BlockchainTypeAPIModel;
    /**
     * 
     * @type {string}
     * @memberof PayoutAPIModel
     */
    amount: string;
    /**
     * 
     * @type {Date}
     * @memberof PayoutAPIModel
     */
    created_at: Date;
}

/**
 * Check if a given object implements the PayoutAPIModel interface.
 */
export function instanceOfPayoutAPIModel(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('companyId' in value)) return false;
    if (!('withdrawalId' in value)) return false;
    if (!('destination' in value)) return false;
    if (!('blockchain' in value)) return false;
    if (!('amount' in value)) return false;
    if (!('created_at' in value)) return false;
    return true;
}

export function PayoutAPIModelFromJSON(json: any): PayoutAPIModel {
    return PayoutAPIModelFromJSONTyped(json, false);
}

export function PayoutAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PayoutAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'withdrawalId': json['withdrawalId'],
        'destination': json['destination'],
        'blockchain': BlockchainTypeAPIModelFromJSON(json['blockchain']),
        'amount': json['amount'],
        'created_at': (new Date(json['created_at'])),
    };
}

export function PayoutAPIModelToJSON(value?: PayoutAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'withdrawalId': value['withdrawalId'],
        'destination': value['destination'],
        'blockchain': BlockchainTypeAPIModelToJSON(value['blockchain']),
        'amount': value['amount'],
        'created_at': ((value['created_at']).toISOString()),
    };
}

