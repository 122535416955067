import React from 'react';

import { FormattedMessage } from '@/components';
import { MerchantWalletTransferKindAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nPageAudit } from '@/generated/i18n/i18n';
import { storedDataError } from '@/infrastructure/model';
import { noop } from '@/infrastructure/utils/functions';

import EntityNotFoundAlert from '../EntityNotFoundAlert';

import type { PayoutEntityLinkProps } from './types';

// FIXME: I'm just a stub, charge link is required
const data = {
  data: storedDataError<{ id: string }>('notfound'),
  forceRefresh: noop,
  loading: false,
};
const PayoutEntityLink: React.FC<PayoutEntityLinkProps> = React.memo(({ 'data-test': dataTest }) => {
  // const data = usePaymentTransfer(
  //   useMemo(() => ({ bt: value.blockchain, hash: value.txHash }), [value.blockchain, value.txHash]),
  // );
  // const id = useMemo<PaymentTransferSearchIdAPIModel | undefined>(
  //   () => (data.data?.data?.txId ? { paymentId: data.data.data.paymentId, hash: data.data.data.txId } : undefined),
  //   [data.data?.data?.paymentId, data.data?.data?.txId],
  // );

  if (data.data.isDirty || data.loading) {
    return <FormattedMessage id={I18nPageAudit.TRANSFER_ENTITY_COMPONENTS_ENTITY_CARD_LOADING} />;
  }

  if (data.data.data?.id) {
    return null; // <Navigate to={createPaymentTransferLink(data.data.data.id)} />;
  }

  return (
    <EntityNotFoundAlert
      data-test={dataTest}
      value={MerchantWalletTransferKindAPIModel.Payout}
      onRetry={data.forceRefresh}
    />
  );
});

export default PayoutEntityLink;
