import { Steps } from 'antd';
import React, { useMemo } from 'react';

import { FormattedMessage } from '@/components';
import { I18nFeatureOnboard } from '@/generated/i18n/i18n';
import { OnboardingStep } from '@/pages/onboard/main/types';

import type { OnboardingStepsProps } from './types';

const OnboardingStepsRaw: React.FC<OnboardingStepsProps> = React.memo(
  ({ 'data-test': dataTest, selected, className, style }) => (
    <Steps
      data-test={dataTest}
      className={className}
      style={style}
      current={selected}
      items={useMemo(
        () => [
          {
            title: <FormattedMessage id={I18nFeatureOnboard.COMPONENTS_STEPS_COMPANY_TITLE} />,
          },
          {
            title: <FormattedMessage id={I18nFeatureOnboard.COMPONENTS_STEPS_CONGRATULATIONS_TITLE} />,
            disabled: selected !== OnboardingStep.congratulations,
          },
        ],
        [selected],
      )}
    />
  ),
);

const OnboardingSteps = React.memo(OnboardingStepsRaw);

export default OnboardingSteps;
