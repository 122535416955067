import { FIELD_ELEMENT_HEX_LEN } from '@web3auth/mpc-core-kit';
import { isNil } from 'lodash';

import type {
  SupportedStorageType as BaseSupportedStorageType,
  IStorage,
  Web3AuthMPCCoreKit,
} from '@web3auth/mpc-core-kit';
import type BN from 'bn.js';

export type SupportedStorageType = Extract<BaseSupportedStorageType, 'local' | 'session'>;
type StorageKey = `${string}_${SupportedStorageType}`;

class BrowserStorage {
  private readonly _storage;
  private readonly _storeKey;

  constructor(key: string, storage: IStorage) {
    this._storage = storage;
    this._storeKey = key;
  }

  toJSON(): string {
    const result = this._storage.getItem(this._storeKey);
    if (!result) throw new Error(`storage ${this._storeKey} is null`);
    return result;
  }

  resetStore(): Record<string, unknown> {
    const currStore = this.getStore();
    this._storage.setItem(this._storeKey, JSON.stringify({}));
    return currStore;
  }

  getStore(): Record<string, unknown> {
    return JSON.parse(this._storage.getItem(this._storeKey) || '{}') as Record<string, unknown>;
  }

  get<T>(key: string): T {
    const store: Record<string, unknown> = JSON.parse(this._storage.getItem(this._storeKey) || '{}');
    return store[key] as T;
  }

  set<T>(key: string, value: T): void {
    const store: Record<string, unknown> = JSON.parse(this._storage.getItem(this._storeKey) || '{}');
    if (!isNil(value)) {
      store[key] = value;
    } else {
      delete store[key];
    }
    this._storage.setItem(this._storeKey, JSON.stringify(store));
  }

  remove(key: string): void {
    const store: Record<string, unknown> = JSON.parse(this._storage.getItem(this._storeKey) || '{}');
    delete store[key];
    this._storage.setItem(this._storeKey, JSON.stringify(store));
  }
}

const storageCache: Partial<Record<StorageKey, BrowserStorage>> = {};

const getInstance = (storeKey: string, storageType: SupportedStorageType) => {
  const storageKey = `${storeKey}_${storageType}` as const;
  if (!storageCache[storageKey]) {
    storageCache[storageKey] = new BrowserStorage(storeKey, storageType === 'local' ? localStorage : sessionStorage);
  }
  return storageCache[storageKey];
};

// FIXME: there is a bug in the @web3auth/mpc-core-kit#BrowserStorage: this storage is tied to the firstly initiated one
//  and it ignores the parameters for secondary calls, so there is no chance to store deviceKey and session in the separate storages

// eslint-disable-next-line @typescript-eslint/require-await
export async function storeWebBrowserFactor(
  factorKey: BN,
  mpcCoreKit: Web3AuthMPCCoreKit,
  storageKey: SupportedStorageType,
) {
  const metadata = mpcCoreKit.tKey.getMetadata();
  const currentStorage = getInstance('mpc_corekit_store', storageKey);
  const tkeyPubX = metadata.pubKey.x!.toString(16, FIELD_ELEMENT_HEX_LEN);
  currentStorage.set(
    tkeyPubX,
    JSON.stringify({
      factorKey: factorKey.toString('hex').padStart(64, '0'),
    }),
  );
}

// eslint-disable-next-line @typescript-eslint/require-await
export async function getWebBrowserFactor(mpcCoreKit: Web3AuthMPCCoreKit, storageKey: SupportedStorageType) {
  const metadata = mpcCoreKit.tKey.getMetadata();
  const currentStorage = getInstance('mpc_corekit_store', storageKey);
  const tkeyPubX = metadata.pubKey.x!.toString(16, FIELD_ELEMENT_HEX_LEN);
  const tKeyLocalStoreString = currentStorage.get<string>(tkeyPubX);
  const tKeyLocalStore: Partial<Record<string, string>> = JSON.parse(tKeyLocalStoreString || '{}');
  return tKeyLocalStore.factorKey;
}

// eslint-disable-next-line @typescript-eslint/require-await
export async function removeWebBrowserFactor(mpcCoreKit: Web3AuthMPCCoreKit, storageKey: SupportedStorageType) {
  const metadata = mpcCoreKit.tKey.getMetadata();
  const currentStorage = getInstance('mpc_corekit_store', storageKey);
  const tkeyPubX = metadata.pubKey.x!.toString(16, FIELD_ELEMENT_HEX_LEN);
  currentStorage.set(tkeyPubX, undefined);
}
