import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Space, Switch } from 'antd';
import isNil from 'lodash/isNil';
import React from 'react';

import type { BooleanSwitchProps } from '@/components/BooleanItem/types';

const BooleanSwitch = React.memo(
  React.forwardRef<HTMLButtonElement, BooleanSwitchProps>(
    ({ 'data-test': dataTest, readonly, value, onChange, SwitchProps, prefix, suffix }, ref) => (
      <Space>
        {prefix}
        <Switch
          ref={ref}
          onChange={onChange}
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...(!isNil(value) ? { checked: value } : {})}
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          data-test={dataTest}
          disabled={readonly}
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...SwitchProps}
        />
        {suffix}
      </Space>
    ),
  ),
);

export default BooleanSwitch;
