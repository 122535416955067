import React from 'react';

import { FormattedMessage, Link } from '@/components';
import { I18nPageSubscriptions } from '@/generated/i18n/i18n';
import { subscriptionChargeViewLink } from '@/pages/subscriptions/routes';

import type { SubscriptionChargeLinkProps } from './types';


const SubscriptionChargeLink: React.FC<SubscriptionChargeLinkProps> = React.memo(
  ({ 'data-test': dataTest, mode = 'inline', value, title }) => (
    <Link
      data-test={dataTest}
      to={subscriptionChargeViewLink(value)}
      mode={mode}
      title={title ?? <FormattedMessage id={I18nPageSubscriptions.CHARGE_VIEW_COMPONENTS_LINK_TITLE} />}
    />
  ),
);

const SubscriptionChargeLinkMemo = React.memo(SubscriptionChargeLink);

export default SubscriptionChargeLinkMemo;
