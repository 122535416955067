import React from 'react';

import { FormattedMessage, Link } from '@/components';
import { I18nPageSubscriptions } from '@/generated/i18n/i18n';
import { subscriptionViewLink } from '@/pages/subscriptions/routes';

import type { SubscriptionLinkProps } from './types';

const SubscriptionLink: React.FC<SubscriptionLinkProps> = React.memo(
  ({ 'data-test': dataTest, mode = 'inline', value, title }) => (
    <Link
      data-test={dataTest}
      to={subscriptionViewLink(value)}
      mode={mode}
      title={title ?? <FormattedMessage id={I18nPageSubscriptions.SUBSCRIPTION_VIEW_COMPONENTS_LINK_TITLE} />}
    />
  ),
);

const SubscriptionLinkMemo = React.memo(SubscriptionLink);

export default SubscriptionLinkMemo;
