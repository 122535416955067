import dayjs from 'dayjs';
import { createSelector } from 'reselect';

import type { AppRootState } from '@/app/store';
import { makeSelectSelectedNetwork } from '@/features/dictionary/blockchain/selectors';
import type { MerchantWalletTransfer } from '@/features/merchant-wallet-transfers/types';
import type { MerchantWalletTransferSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { createNormalizedFullSelectors } from '@/infrastructure/model/full/selectors';
import { createNormalizedListSelectors } from '@/infrastructure/model/list/selectors';
import type { ListSortBy } from '@/infrastructure/model/list/types';
import { dateComparator } from '@/infrastructure/utils/comparators';

import { defaultMerchantWalletRunningBalanceTransfersState, NAMESPACE } from './types';

import type {
  MerchantWalletRunningBalanceTransferFilterPredicate,
  MerchantWalletRunningBalanceTransfersState,
} from './types';

export const {
  makeSelectMerchantWalletRunningBalanceListParameters: baseMakeSelectMerchantWalletRunningBalanceListParameters,
  makeSelectMerchantWalletRunningBalanceListData,
  makeSelectMerchantWalletRunningBalance,
  makeSelectMultipleMerchantWalletRunningBalance,
  makeSelectDirtyMerchantWalletRunningBalanceIds,
} = createNormalizedListSelectors(
  (state: AppRootState) => state[NAMESPACE].list,
  (state: AppRootState) => state[NAMESPACE].entities,
  'MerchantWalletRunningBalance' as const,
  undefined,
);

export const makeSelectMerchantWalletRunningBalanceListParameters: typeof baseMakeSelectMerchantWalletRunningBalanceListParameters =
  () =>
    createSelector(
      baseMakeSelectMerchantWalletRunningBalanceListParameters(),
      makeSelectSelectedNetwork(),
      ({ filter, ...parameters }, networkEq) => ({
        ...parameters,
        filter: { ...filter, networkEq },
      }),
    );

export const {
  makeSelectTransfersForRunningBalanceFullData,
  makeSelectTransfersForRunningBalanceFilteredData,
  makeSelectTransfersForRunningBalanceFullParameters,
} = createNormalizedFullSelectors(
  (state: AppRootState, balanceId: string | undefined): MerchantWalletRunningBalanceTransfersState =>
    state[NAMESPACE].transfers.data[balanceId!] ?? defaultMerchantWalletRunningBalanceTransfersState,
  (state) => state[NAMESPACE].transfers.columnState,
  (state) => state['merchant-wallet-transfers'].entities,
  'TransfersForRunningBalance' as const,
  (sortBy: ListSortBy<MerchantWalletTransferSortByAPIModel>) =>
    dateComparator(({ broadcastAt }: MerchantWalletTransfer) => broadcastAt ?? new Date())(
      sortBy.broadcastAt ?? 'DESC',
    ),
  (filter: MerchantWalletRunningBalanceTransferFilterPredicate) => (tx: MerchantWalletTransfer) => {
    if (filter.kindIn?.length && !filter.kindIn.includes(tx.kind)) {
      return false;
    }
    if (filter.directionEq && filter.directionEq !== tx.direction) {
      return false;
    }
    if (filter.broadcastAtRange?.from && dayjs(tx.broadcastAt).isBefore(filter.broadcastAtRange.from)) {
      return false;
    }
    if (filter.broadcastAtRange?.to && dayjs(tx.broadcastAt).isAfter(filter.broadcastAtRange.to)) {
      return false;
    }
    return true;
  },
);
