import { createReducer } from '@reduxjs/toolkit';

import listenerMiddleware from '@/app/listenerMiddleware';
import { notifyAuthTokenUpdated } from '@/features/auth/actions';
import { makeSelectAuthToken } from '@/features/auth/selectors';
import { notifyNetworkUpdated } from '@/features/dictionary/blockchain/actions';
import { makeSelectPendingSubscriptions } from '@/features/subscriptions/selectors';
import { extractSubscriptionId } from '@/features/subscriptions/utils';
import { defaultPage } from '@/infrastructure/api';
import { createNestedNormalizedListReducers, createNormalizedListReducers } from '@/infrastructure/model/list/reducers';
import { storedDirtyListDataTyped } from '@/infrastructure/model/list/utils';
import { createSingleReducers } from '@/infrastructure/model/single/reducers';

import {
  markSubscriptionDirty,
  markSubscriptionsForPlanListDirty,
  markSubscriptionsListDirty,
  storeMultipleSubscription,
  storeSubscription,
  storeSubscriptionsForPlanListData,
  storeSubscriptionsForPlanListParameters,
  storeSubscriptionsListData,
  storeSubscriptionsListParameters,
  markChargesForSubscriptionDirty,
  storeChargesForSubscription,
  fetchMultipleSubscription,
} from './actions';
import { defaultSortBy, defaultSubscriptionForPlanState, type SubscriptionsState } from './types';

import type { Draft } from 'immer';

const initialState: SubscriptionsState = {
  charges: {},
  statusChangingSubscriptions: [],
  list: {
    data: storedDirtyListDataTyped(),
    filter: {},
    sortBy: defaultSortBy,
    columnState: {},
    page: defaultPage,
  },
  entities: {},

  byPlan: {
    columnState: {},
    data: {},
  },
};

const { markChargesForSubscriptionDirtyReducer, storeChargesForSubscriptionReducer } = createSingleReducers(
  'ChargesForSubscription',
  (state: Draft<SubscriptionsState>) => state.charges,
  (global, charges) => ({ ...global, charges }),
);

const { storeSubscriptionReducer, storeMultipleSubscriptionReducer, markSubscriptionDirtyReducer } =
  createSingleReducers(
    'Subscription',
    (state: Draft<SubscriptionsState>) => state.entities,
    (global, entities) => ({ ...global, entities }),
  );

const {
  storeSubscriptionsListDataReducer,
  markSubscriptionsListDirtyReducer,
  storeSubscriptionsListParametersReducer,
} = createNormalizedListReducers(
  'Subscriptions',
  (state: Draft<SubscriptionsState>) => state.list,
  (state, list) => ({ ...state, list }),
  initialState.list,
  (state: Draft<SubscriptionsState>) => state.entities as SubscriptionsState['entities'],
  (global, entities) => ({ ...global, entities }),
  extractSubscriptionId,
);

const {
  storeSubscriptionsForPlanListDataReducer,
  markSubscriptionsForPlanListDirtyReducer,
  storeSubscriptionsForPlanListParametersReducer,
} = createNestedNormalizedListReducers(
  'SubscriptionsForPlan',
  (state: Draft<SubscriptionsState>, subscriptionId: string | undefined) => state.byPlan.data[subscriptionId!],
  (state, subscriptionId, newListState) => ({
    ...state,
    byPlan: { ...state.byPlan, data: { ...state.byPlan.data, [subscriptionId]: newListState } },
  }),
  (state) => state.byPlan.columnState,
  (state, columnState) => ({ ...state, byPlan: { ...state.byPlan, columnState } }),
  defaultSubscriptionForPlanState,
  extractSubscriptionId,
);

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(markSubscriptionDirty, markSubscriptionDirtyReducer)
    .addCase(storeSubscription, storeSubscriptionReducer)
    .addCase(storeMultipleSubscription, storeMultipleSubscriptionReducer)

    .addCase(storeSubscriptionsForPlanListData, storeSubscriptionsForPlanListDataReducer)
    .addCase(storeSubscriptionsForPlanListParameters, storeSubscriptionsForPlanListParametersReducer)
    .addCase(markSubscriptionsForPlanListDirty, markSubscriptionsForPlanListDirtyReducer)

    .addCase(storeSubscriptionsListData, storeSubscriptionsListDataReducer)
    .addCase(storeSubscriptionsListParameters, storeSubscriptionsListParametersReducer)
    .addCase(markSubscriptionsListDirty, markSubscriptionsListDirtyReducer)

    .addCase(markChargesForSubscriptionDirty, markChargesForSubscriptionDirtyReducer)
    .addCase(storeChargesForSubscription, storeChargesForSubscriptionReducer)

    .addCase(notifyNetworkUpdated, () => initialState)
    .addCase(notifyAuthTokenUpdated, (state, { payload: { previous, current } }) =>
      previous?.address !== current?.address ? initialState : state,
    );

  listenerMiddleware.startListening({
    actionCreator: notifyAuthTokenUpdated,
    effect: async ({ payload: { current } }, listenerApi) => {
      if (!current) {
        return;
      }
      listenerApi.unsubscribe();
      for (;;) {
        const arePending = makeSelectPendingSubscriptions()(listenerApi.getState());
        if (arePending.length) {
          // eslint-disable-next-line no-await-in-loop
          await listenerApi.dispatch(
            fetchMultipleSubscription({
              ids: arePending.map(extractSubscriptionId),
              force: true,
            }),
          );
        }
        // eslint-disable-next-line no-await-in-loop
        await listenerApi.delay(5_000);
        if (!makeSelectAuthToken()(listenerApi.getState()).data) {
          return;
        }
      }
    },
  });
});

export default reducer;
