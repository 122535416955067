/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3276
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { InvoiceProofAPIModel } from './InvoiceProofAPIModel';
import {
    InvoiceProofAPIModelFromJSON,
    InvoiceProofAPIModelFromJSONTyped,
    InvoiceProofAPIModelToJSON,
} from './InvoiceProofAPIModel';
import type { WithdrawalStatusAPIModel } from './WithdrawalStatusAPIModel';
import {
    WithdrawalStatusAPIModelFromJSON,
    WithdrawalStatusAPIModelFromJSONTyped,
    WithdrawalStatusAPIModelToJSON,
} from './WithdrawalStatusAPIModel';

/**
 * 
 * @export
 * @interface WithdrawalBatchAPIModel
 */
export interface WithdrawalBatchAPIModel {
    /**
     * 
     * @type {string}
     * @memberof WithdrawalBatchAPIModel
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof WithdrawalBatchAPIModel
     */
    batchNum: number;
    /**
     * 
     * @type {Array<InvoiceProofAPIModel>}
     * @memberof WithdrawalBatchAPIModel
     */
    invoiceProofs?: Array<InvoiceProofAPIModel>;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalBatchAPIModel
     */
    hash?: string;
    /**
     * 
     * @type {WithdrawalStatusAPIModel}
     * @memberof WithdrawalBatchAPIModel
     */
    status: WithdrawalStatusAPIModel;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalBatchAPIModel
     */
    totalAmount: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalBatchAPIModel
     */
    totalAmountRaw: string;
    /**
     * 
     * @type {boolean}
     * @memberof WithdrawalBatchAPIModel
     */
    isPush: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WithdrawalBatchAPIModel
     */
    isPull: boolean;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalBatchAPIModel
     */
    gwtId?: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalBatchAPIModel
     */
    btcTxId?: string;
}

/**
 * Check if a given object implements the WithdrawalBatchAPIModel interface.
 */
export function instanceOfWithdrawalBatchAPIModel(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('batchNum' in value)) return false;
    if (!('status' in value)) return false;
    if (!('totalAmount' in value)) return false;
    if (!('totalAmountRaw' in value)) return false;
    if (!('isPush' in value)) return false;
    if (!('isPull' in value)) return false;
    return true;
}

export function WithdrawalBatchAPIModelFromJSON(json: any): WithdrawalBatchAPIModel {
    return WithdrawalBatchAPIModelFromJSONTyped(json, false);
}

export function WithdrawalBatchAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): WithdrawalBatchAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'batchNum': json['batchNum'],
        'invoiceProofs': json['invoiceProofs'] == null ? undefined : ((json['invoiceProofs'] as Array<any>).map(InvoiceProofAPIModelFromJSON)),
        'hash': json['hash'] == null ? undefined : json['hash'],
        'status': WithdrawalStatusAPIModelFromJSON(json['status']),
        'totalAmount': json['totalAmount'],
        'totalAmountRaw': json['totalAmountRaw'],
        'isPush': json['isPush'],
        'isPull': json['isPull'],
        'gwtId': json['gwtId'] == null ? undefined : json['gwtId'],
        'btcTxId': json['btcTxId'] == null ? undefined : json['btcTxId'],
    };
}

export function WithdrawalBatchAPIModelToJSON(value?: WithdrawalBatchAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'batchNum': value['batchNum'],
        'invoiceProofs': value['invoiceProofs'] == null ? undefined : ((value['invoiceProofs'] as Array<any>).map(InvoiceProofAPIModelToJSON)),
        'hash': value['hash'],
        'status': WithdrawalStatusAPIModelToJSON(value['status']),
        'totalAmount': value['totalAmount'],
        'totalAmountRaw': value['totalAmountRaw'],
        'isPush': value['isPush'],
        'isPull': value['isPull'],
        'gwtId': value['gwtId'],
        'btcTxId': value['btcTxId'],
    };
}

