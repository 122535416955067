/* eslint-disable no-nested-ternary */
import React from 'react';

import { FormattedMessage } from '@/components';
import GasWalletSupportEmail from '@/features/gas-wallets/components/GasWalletSupportEmail';
import OperationCreateGasWallet from '@/features/gas-wallets/components/OperationCreateGasWallet';
import { I18nFeatureGasWallets } from '@/generated/i18n/i18n';
import GasWalletLink from '@/pages/settings/company/gas-wallet/components/GasWalletLink';

import { GasWalletDerivedBalanceMessage, GasWalletNativeBalanceMessage } from './components';

import type { GasWalletInvalidStateMessageProps } from './types';
import type { ReactNode } from 'react';

// TODO: it's a little bit weird to use a page link here, but I have no idea how to make it better"
const GasWalletInvalidStateMessage: React.FC<GasWalletInvalidStateMessageProps> = (props) => {
  const { 'data-test': dataTest, wallet, bt, type, mode } = props;
  if (type === 'presence') {
    return mode === 'short' ? (
      <FormattedMessage
        data-test={dataTest}
        id={I18nFeatureGasWallets.COMPONENTS_INVALID_STATE_MESSAGE_PRESENCE_SHORT}
      />
    ) : mode === 'tag' ? (
      <FormattedMessage data-test={dataTest} id={I18nFeatureGasWallets.COMPONENTS_INVALID_STATE_MESSAGE_PRESENCE_TAG} />
    ) : (
      <FormattedMessage
        id={I18nFeatureGasWallets.COMPONENTS_INVALID_STATE_MESSAGE_PRESENCE_FULL}
        values={{
          op: (text: ReactNode) => (
            <OperationCreateGasWallet data-test={dataTest && `${dataTest}-op`} bt={bt} mode="link" title={text} />
          ),
        }}
      />
    );
  }
  if (type === 'refunding') {
    return mode === 'short' ? (
      <FormattedMessage
        data-test={dataTest}
        id={I18nFeatureGasWallets.COMPONENTS_INVALID_STATE_MESSAGE_REFUNDING_SHORT}
      />
    ) : mode === 'tag' ? (
      <FormattedMessage
        data-test={dataTest}
        id={I18nFeatureGasWallets.COMPONENTS_INVALID_STATE_MESSAGE_REFUNDING_TAG}
      />
    ) : (
      <FormattedMessage
        id={I18nFeatureGasWallets.COMPONENTS_INVALID_STATE_MESSAGE_REFUNDING_FULL}
        values={{
          ln: (text: ReactNode) => (
            <GasWalletLink data-test={dataTest && `${dataTest}-link`} value={wallet.bt} mode="text" title={text} />
          ),
        }}
      />
    );
  }
  if (type === 'any-service') {
    return mode === 'short' ? (
      <FormattedMessage
        data-test={dataTest}
        id={I18nFeatureGasWallets.COMPONENTS_INVALID_STATE_MESSAGE_ANY_SERVICE_SHORT}
      />
    ) : mode === 'tag' ? (
      <FormattedMessage
        data-test={dataTest}
        id={I18nFeatureGasWallets.COMPONENTS_INVALID_STATE_MESSAGE_ANY_SERVICE_TAG}
      />
    ) : (
      <FormattedMessage
        id={I18nFeatureGasWallets.COMPONENTS_INVALID_STATE_MESSAGE_ANY_SERVICE_FULL}
        values={{
          support: (text: ReactNode) => (
            <GasWalletSupportEmail
              data-test={dataTest && `${dataTest}-support`}
              subject={`Derived wallet out of service for ${wallet.bt}`}
              title={text}
            />
          ),
          ln: (text: ReactNode) => (
            <GasWalletLink data-test={dataTest && `${dataTest}-link`} value={wallet.bt} mode="text" title={text} />
          ),
        }}
      />
    );
  }
  if (type === 'all-service') {
    return mode === 'short' ? (
      <FormattedMessage
        data-test={dataTest}
        id={I18nFeatureGasWallets.COMPONENTS_INVALID_STATE_MESSAGE_ALL_SERVICE_SHORT}
      />
    ) : mode === 'tag' ? (
      <FormattedMessage
        data-test={dataTest}
        id={I18nFeatureGasWallets.COMPONENTS_INVALID_STATE_MESSAGE_ALL_SERVICE_TAG}
      />
    ) : (
      <FormattedMessage
        id={I18nFeatureGasWallets.COMPONENTS_INVALID_STATE_MESSAGE_ALL_SERVICE_FULL}
        values={{
          support: (text: ReactNode) => (
            <GasWalletSupportEmail
              data-test={dataTest && `${dataTest}-support`}
              subject={`Wallet out of service for ${wallet.bt}`}
              title={text}
            />
          ),
          ln: (text: ReactNode) => (
            <GasWalletLink data-test={dataTest && `${dataTest}-link`} value={wallet.bt} mode="text" title={text} />
          ),
        }}
      />
    );
  }
  if (type === 'derived-balance') {
    return <GasWalletDerivedBalanceMessage {...props} />;
  }

  return <GasWalletNativeBalanceMessage {...props} />;
};

const GasWalletInvalidStateMessageMemo = React.memo(GasWalletInvalidStateMessage);

export default GasWalletInvalidStateMessageMemo;
