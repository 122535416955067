/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3276
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { MerchantWalletTransferAPIModel } from './MerchantWalletTransferAPIModel';
import {
    MerchantWalletTransferAPIModelFromJSON,
    MerchantWalletTransferAPIModelFromJSONTyped,
    MerchantWalletTransferAPIModelToJSON,
} from './MerchantWalletTransferAPIModel';
import type { PageMerchantWalletTransferSortByAPIModel } from './PageMerchantWalletTransferSortByAPIModel';
import {
    PageMerchantWalletTransferSortByAPIModelFromJSON,
    PageMerchantWalletTransferSortByAPIModelFromJSONTyped,
    PageMerchantWalletTransferSortByAPIModelToJSON,
} from './PageMerchantWalletTransferSortByAPIModel';

/**
 * 
 * @export
 * @interface SliceMerchantWalletTransferMerchantWalletTransferSortByAPIModel
 */
export interface SliceMerchantWalletTransferMerchantWalletTransferSortByAPIModel {
    /**
     * 
     * @type {Array<MerchantWalletTransferAPIModel>}
     * @memberof SliceMerchantWalletTransferMerchantWalletTransferSortByAPIModel
     */
    list?: Array<MerchantWalletTransferAPIModel>;
    /**
     * 
     * @type {PageMerchantWalletTransferSortByAPIModel}
     * @memberof SliceMerchantWalletTransferMerchantWalletTransferSortByAPIModel
     */
    page: PageMerchantWalletTransferSortByAPIModel;
    /**
     * 
     * @type {number}
     * @memberof SliceMerchantWalletTransferMerchantWalletTransferSortByAPIModel
     */
    total?: number;
}

/**
 * Check if a given object implements the SliceMerchantWalletTransferMerchantWalletTransferSortByAPIModel interface.
 */
export function instanceOfSliceMerchantWalletTransferMerchantWalletTransferSortByAPIModel(value: object): boolean {
    if (!('page' in value)) return false;
    return true;
}

export function SliceMerchantWalletTransferMerchantWalletTransferSortByAPIModelFromJSON(json: any): SliceMerchantWalletTransferMerchantWalletTransferSortByAPIModel {
    return SliceMerchantWalletTransferMerchantWalletTransferSortByAPIModelFromJSONTyped(json, false);
}

export function SliceMerchantWalletTransferMerchantWalletTransferSortByAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SliceMerchantWalletTransferMerchantWalletTransferSortByAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'list': json['list'] == null ? undefined : ((json['list'] as Array<any>).map(MerchantWalletTransferAPIModelFromJSON)),
        'page': PageMerchantWalletTransferSortByAPIModelFromJSON(json['page']),
        'total': json['total'] == null ? undefined : json['total'],
    };
}

export function SliceMerchantWalletTransferMerchantWalletTransferSortByAPIModelToJSON(value?: SliceMerchantWalletTransferMerchantWalletTransferSortByAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'list': value['list'] == null ? undefined : ((value['list'] as Array<any>).map(MerchantWalletTransferAPIModelToJSON)),
        'page': PageMerchantWalletTransferSortByAPIModelToJSON(value['page']),
        'total': value['total'],
    };
}

