import { DeleteOutlined, EditOutlined, PlusOutlined, SwapOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { Button, Form, Space, Table, Typography } from 'antd';
import BigNumber from 'bignumber.js';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { v4 as uuid } from 'uuid';

import type { MoneyAmountInputValue } from '@/components';
import { FormattedMessage, Operation } from '@/components';
import { AddressLink, AssetAmount } from '@/features/dictionary/blockchain/components';
import { I18nComponents, I18nFeatureSettlementPayouts } from '@/generated/i18n/i18n';
import { withAntDesignInputMemo } from '@/hocs';
import { useForm, useStateMountSafe } from '@/hooks';
import type { AllNonNullable } from '@/infrastructure/utils/ts';

import { EditableCell, PayoutPersistOperation, PayoutReorderOperation } from './components';
import { usePayoutDestinationFilter, usePayoutTableActionsMode } from './hooks';

import type { PayoutDestinationsTableProps, PayoutDestinationItem, EditableRowFormValues } from './types';
import type { TableColumnType } from 'antd';

const isFormItemEmpty = (item: PayoutDestinationItem | undefined) =>
  !item || item.address === '' || item.amount.isZero();

const PayoutDestinationsTable: React.FC<PayoutDestinationsTableProps> = ({
  'data-test': dataTest,
  asset,
  disabled,
  value,
  onChange,
}) => {
  const { formatMessage: i18n } = useIntl();

  const { form } = useForm<EditableRowFormValues>();
  const [data, setData] = useStateMountSafe<PayoutDestinationItem[]>([]);
  const [editingKey, setEditingKey] = useStateMountSafe<React.Key>('');
  const [curPagination, setCurPagination] = useStateMountSafe<{ page: number; pageSize: number }>();

  const inEditMode = editingKey !== '';
  const noAsset = !asset;

  // reorder mode
  const [inReorderMode, setInReorderMode] = useStateMountSafe(false);

  const {
    isFiltered,
    filterColumnMeta,
    Banner: FilterBanner,
  } = usePayoutDestinationFilter({
    'data-test': dataTest && `${dataTest}-filter`,
    disabled: inEditMode || noAsset,
  });

  // external control
  useEffect(() => {
    if (value !== undefined) {
      setData(value);
    }
  }, [setData, value]);

  useEffect(() => {
    onChange?.(data);
  }, [data, onChange]);

  const isEditing = useCallback((record: PayoutDestinationItem) => record.key === editingKey, [editingKey]);

  const deleteRow = useCallback(
    (key: React.Key) => {
      const newData = data.filter((item) => item.key !== key);
      if (newData.length !== data.length) {
        setData(newData);
      }
    },
    [data, setData],
  );

  const editRow = useCallback(
    ({ amount, address, key }: PayoutDestinationItem) => {
      // restore form state for current item
      const amountInput: AllNonNullable<MoneyAmountInputValue> = {
        inputValue: amount.toString(),
        valid: true,
        value: amount,
      };
      const newValue: EditableRowFormValues = { address, amount: amountInput };
      // https://github.com/ant-design/ant-design/issues/43305
      // https://github.com/react-component/field-form/pull/600
      form.setFieldsValue(newValue as Parameters<(typeof form)['setFieldsValue']>[0]);
      setEditingKey(key);
    },
    [form, setEditingKey],
  );

  const addRow = useCallback(() => {
    const newItem: PayoutDestinationItem = { key: uuid(), address: '', amount: BigNumber(0) };
    const offset = !curPagination ? 0 : (curPagination.page - 1) * curPagination.pageSize;
    const newData = [...data];
    newData.splice(offset, 0, newItem);
    setData(newData);
    editRow(newItem);
  }, [curPagination, data, setData, editRow]);

  const cancelEditAndRemoveStubs = useCallback(() => {
    if (editingKey === '') {
      return;
    }
    setEditingKey('');
    const newData = data.filter((item) => !isFormItemEmpty(item));
    setData(newData);
  }, [editingKey, setEditingKey, data, setData]);

  const saveEdit = useCallback(
    async (key: React.Key) => {
      try {
        const { address, amount: amountData } = await form.validateFields();
        const amount = amountData.value!;
        const newData = [...data];
        const index = newData.findIndex((item) => key === item.key);
        if (index > -1) {
          const item = newData[index];
          newData.splice(index, 1, { ...item, address, amount });
        } else {
          newData.push({ key: uuid(), address, amount });
        }
        setData(newData);
        setEditingKey('');
      } catch (errInfo) {
        console.warn('Validation failed:', errInfo);
      }
    },
    [form, data, setData, setEditingKey],
  );

  const columns = useMemo<
    (Omit<TableColumnType<PayoutDestinationItem>, 'title'> & {
      title?: string;
      editable?: boolean;
    })[]
  >(
    () => [
      {
        ...filterColumnMeta,
        title: i18n({
          id: isFiltered
            ? I18nFeatureSettlementPayouts.COMPONENTS_CREATE_PAYOUT_FORM_DESTINATIONS_TABLE_COL_ADDRESS_FILTERED_TITLE
            : I18nFeatureSettlementPayouts.COMPONENTS_CREATE_PAYOUT_FORM_DESTINATIONS_TABLE_COL_ADDRESS_TITLE,
        }),
        dataIndex: 'address',
        className: css`
          max-width: 100px;
        `,
        editable: true,
        render: (_, { address }: PayoutDestinationItem) => <AddressLink address={address} assetId={asset!} />,
      },
      {
        title: i18n({ id: I18nFeatureSettlementPayouts.COMPONENTS_CREATE_PAYOUT_FORM_DESTINATIONS_TABLE_COL_AMOUNT }),
        dataIndex: 'amount',
        width: 150,
        editable: true,
        render: (_, { amount }) => <AssetAmount assetId={asset!} value={amount} withCurrency />,
      },
      {
        title: i18n({ id: I18nComponents.TABLE_ROW_OPTIONS }),
        dataIndex: 'operation',
        width: 105,
        render: (_, record: PayoutDestinationItem) => {
          const editable = isEditing(record);
          return editable ? (
            <Space>
              <Button
                type="primary"
                data-test={dataTest && `${dataTest}-save`}
                size="small"
                onClick={() => saveEdit(record.key)}
                disabled={disabled}
              >
                <FormattedMessage
                  id={I18nFeatureSettlementPayouts.COMPONENTS_CREATE_PAYOUT_FORM_DESTINATIONS_TABLE_EDIT_OPERATION_SAVE}
                />
              </Button>
              <Button
                data-test={dataTest && `${dataTest}-cancel`}
                size="small"
                onClick={cancelEditAndRemoveStubs}
                disabled={disabled}
              >
                <FormattedMessage
                  id={
                    I18nFeatureSettlementPayouts.COMPONENTS_CREATE_PAYOUT_FORM_DESTINATIONS_TABLE_EDIT_OPERATION_CANCEL
                  }
                />
              </Button>
            </Space>
          ) : (
            <Space>
              {!inReorderMode && (
                <>
                  <Operation
                    disabled={inEditMode || noAsset || disabled}
                    title={
                      <FormattedMessage
                        id={
                          I18nFeatureSettlementPayouts.COMPONENTS_CREATE_PAYOUT_FORM_DESTINATIONS_TABLE_EDIT_OPERATION_TITLE
                        }
                      />
                    }
                    icon={<EditOutlined />}
                    onClick={() => editRow(record)}
                    data-test={dataTest && `${dataTest}-edit`}
                    mode="inline"
                  />
                  <Operation
                    disabled={inEditMode || noAsset || disabled}
                    confirmation={{
                      title: (
                        <FormattedMessage
                          id={
                            I18nFeatureSettlementPayouts.COMPONENTS_CREATE_PAYOUT_FORM_DESTINATIONS_TABLE_DELETE_OPERATION_CONFIRMATION
                          }
                        />
                      ),
                    }}
                    title={
                      <FormattedMessage
                        id={
                          I18nFeatureSettlementPayouts.COMPONENTS_CREATE_PAYOUT_FORM_DESTINATIONS_TABLE_DELETE_OPERATION_TITLE
                        }
                      />
                    }
                    icon={<DeleteOutlined />}
                    onClick={() => deleteRow(record.key)}
                    mode="inline"
                  />
                </>
              )}
              {inReorderMode && (
                <PayoutReorderOperation
                  data-test={dataTest && `${dataTest}-reorder`}
                  data={data}
                  onChange={setData}
                  disabled={inEditMode || noAsset || disabled}
                  selected={record}
                />
              )}
            </Space>
          );
        },
      },
    ],
    [
      asset,
      cancelEditAndRemoveStubs,
      data,
      dataTest,
      deleteRow,
      disabled,
      editRow,
      filterColumnMeta,
      i18n,
      inEditMode,
      inReorderMode,
      isEditing,
      isFiltered,
      noAsset,
      saveEdit,
      setData,
    ],
  );

  const mergedColumns = useMemo(
    () =>
      columns.map(({ editable, ...col }) => {
        if (!editable) {
          return col;
        }
        // noinspection JSUnusedGlobalSymbols
        return {
          ...col,
          onCell: (record: PayoutDestinationItem) => ({
            record,
            dataIndex: col.dataIndex,
            title: col.title || undefined,
            editing: isEditing(record),
            asset,
            existItems: data,
          }),
        };
      }),
    [asset, columns, data, isEditing],
  );

  // cleanup table on unknown asset state
  useEffect(() => {
    if (noAsset) {
      cancelEditAndRemoveStubs();
    }
  }, [noAsset, cancelEditAndRemoveStubs]);

  const { ref, reorderMode, addMode, persistMode } = usePayoutTableActionsMode();

  return (
    <Space direction="vertical" style={{ width: '100%', margin: '8px 0', minHeight: '262px' }} ref={ref}>
      <span style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
        <PayoutPersistOperation
          data-test={dataTest && `${dataTest}-persist`}
          value={data}
          onChange={setData}
          disabled={inEditMode || noAsset || isFiltered || inReorderMode}
          mode={persistMode}
        />
        <span style={{ flexGrow: 10, flexShrink: 10 }} />
        <Operation
          data-test={dataTest && `${dataTest}-addDestination`}
          title={
            <FormattedMessage
              id={I18nFeatureSettlementPayouts.COMPONENTS_CREATE_PAYOUT_FORM_DESTINATIONS_TABLE_ADD_OPERATION_TITLE}
              tagName="span"
            />
          }
          disabled={inEditMode || noAsset || isFiltered || inReorderMode || disabled}
          onClick={addRow}
          icon={<PlusOutlined />}
          mode={addMode}
        />
        <Operation
          data-test={dataTest && `${dataTest}-reorder`}
          disabled={inEditMode || noAsset || isFiltered || data.length < 2 || disabled}
          onClick={() => {
            setInReorderMode((prevState) => !prevState);
          }}
          icon={inReorderMode ? <EditOutlined /> : <SwapOutlined />}
          title={
            inReorderMode ? (
              <FormattedMessage
                id={
                  I18nFeatureSettlementPayouts.COMPONENTS_CREATE_PAYOUT_FORM_DESTINATIONS_TABLE_EDIT_OPERATION_REORDER_DONE
                }
                tagName="span"
              />
            ) : (
              <FormattedMessage
                id={
                  I18nFeatureSettlementPayouts.COMPONENTS_CREATE_PAYOUT_FORM_DESTINATIONS_TABLE_EDIT_OPERATION_REORDER
                }
                tagName="span"
              />
            )
          }
          mode={reorderMode}
        />
      </span>
      {FilterBanner}
      <Form<EditableRowFormValues> form={form} component={false}>
        <Table<PayoutDestinationItem>
          components={{ body: { cell: EditableCell } }}
          bordered
          dataSource={data}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={{
            disabled: inEditMode || disabled,
            defaultPageSize: 10,
            showSizeChanger: true,
            onChange: (page, pageSize) => {
              setCurPagination({ page, pageSize });
              cancelEditAndRemoveStubs();
            },
          }}
          footer={useCallback(
            () => (
              <Typography.Text>
                {i18n(
                  { id: I18nFeatureSettlementPayouts.COMPONENTS_CREATE_PAYOUT_FORM_DESTINATIONS_TABLE_FOOTER_TOTAL },
                  { total: data.length },
                )}
              </Typography.Text>
            ),
            [data.length, i18n],
          )}
        />
      </Form>
    </Space>
  );
};

const PayoutDestinationsTableInput = withAntDesignInputMemo<PayoutDestinationItem[], PayoutDestinationsTableProps>(
  PayoutDestinationsTable,
);

const PayoutDestinationsTableMemo = React.memo(PayoutDestinationsTableInput) as typeof PayoutDestinationsTableInput;

export default PayoutDestinationsTableMemo;
