import { Space } from 'antd';
import { useCallback, useRef, useState } from 'react';

import { FormattedMessage, PageCard } from '@/components';
import { SelectedNetworkLabel } from '@/features/dictionary/blockchain/components';
import { CreatePayoutModal, CreatePayoutForm, PayoutSucceededModal } from '@/features/settlements/components';
import type { CreatePayoutResp } from '@/features/settlements/hooks';
import type { NewPayoutData } from '@/features/settlements/types';
import { I18nPageSettlements } from '@/generated/i18n/i18n';
import { useFormVisible } from '@/hooks';
import { goalReached, YMGoals } from '@/infrastructure/ym';

import type { SettlementAddPayoutCardProps } from './types';
import type React from 'react';

const SettlementAddPayoutCard: React.FC<SettlementAddPayoutCardProps> = ({ 'data-test': dataTest }) => {
  const ref = useRef<HTMLDivElement>(null);
  const formControl = useFormVisible(false);
  const [payoutData, setPayoutData] = useState<NewPayoutData>();
  const [showPayoutModal, setShowPayoutModal] = useState(false);
  const [successTx, setSuccessTx] = useState<CreatePayoutResp>();

  const onSubmit = useCallback((data: NewPayoutData) => {
    setPayoutData(data);
    setShowPayoutModal(true);
  }, []);

  const onSuccess = useCallback((tx: CreatePayoutResp) => {
    setShowPayoutModal(false);
    setSuccessTx(tx);
    goalReached(YMGoals.WITHDRAWAL_PAYOUT_CREATED);
  }, []);

  const onCancel = useCallback(() => {
    setShowPayoutModal(false);
  }, []);

  return (
    <PageCard
      ref={ref}
      title={
        <Space>
          <FormattedMessage id={I18nPageSettlements.ADD_PAYOUT_PAYOUTS_CARD_TITLE} />
          <SelectedNetworkLabel mode="short" data-test={dataTest && `${dataTest}-network`} />
        </Space>
      }
      cardSize="big"
    >
      <CreatePayoutForm
        data-test={dataTest && `${dataTest}-makePayoutForm`}
        onReset={formControl.hide}
        onSubmit={onSubmit}
        disabled={showPayoutModal}
      />
      {showPayoutModal && payoutData && (
        <CreatePayoutModal payoutData={payoutData} onSuccess={onSuccess} onCancel={onCancel} />
      )}
      {successTx && (
        <PayoutSucceededModal
          data-test={dataTest && `${dataTest}-success`}
          data={successTx}
          onClose={() => setSuccessTx(undefined)}
        />
      )}
    </PageCard>
  );
};

export default SettlementAddPayoutCard;
