import { QuestionCircleOutlined } from '@ant-design/icons';
import { Form, Input, Space, Tooltip } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';

import { FormattedMessage } from '@/components';
import { OperationSetSettlementSchedule } from '@/features/settlements/components';
import { useSettlementSchedule } from '@/features/settlements/hooks';
import { WithdrawalPeriodAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nPageSettings } from '@/generated/i18n/i18n';
import ActualBalancesLink from '@/pages/balances/actual/components/ActualBalancesLink';
import SettlementScheduleLink from '@/pages/settlements/schedule/components/SettlementScheduleLink';

import type { SettlementScheduleItemProps } from './types';

const SettlementScheduleItem: React.FC<SettlementScheduleItemProps> = ({ 'data-test': dataTest, style, className }) => {
  const {
    data: { data: schedule },
  } = useSettlementSchedule();
  const { formatMessage } = useIntl();
  return (
    <Form.Item
      label={
        <>
          <FormattedMessage
            id={I18nPageSettings.COMPANY_SETTINGS_COMPONENTS_GENERAL_CARD_SCHEDULE_ITEM_LABEL}
            tagName="span"
          />
          &nbsp;
          <Tooltip
            title={
              <FormattedMessage
                id={I18nPageSettings.COMPANY_SETTINGS_COMPONENTS_GENERAL_CARD_SCHEDULE_ITEM_HELP}
                values={{
                  balance: (text: React.ReactNode) => <ActualBalancesLink title={text} mode="text" />,
                  schedule: (text: React.ReactNode) => <SettlementScheduleLink title={text} mode="text" />,
                }}
              />
            }
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </>
      }
    >
      <Input
        style={style}
        className={className}
        disabled
        data-test={dataTest}
        value={
          !schedule || schedule.period === WithdrawalPeriodAPIModel.NotSet
            ? formatMessage({ id: I18nPageSettings.COMPANY_SETTINGS_COMPONENTS_GENERAL_CARD_SCHEDULE_ITEM_VALUE_UNSET })
            : formatMessage(
                { id: I18nPageSettings.COMPANY_SETTINGS_COMPONENTS_GENERAL_CARD_SCHEDULE_ITEM_VALUE_SET },
                { period: schedule.period, amount: schedule.unifiedMinAmount },
              )
        }
        suffix={
          <Space>
            <OperationSetSettlementSchedule
              data-test={dataTest && `${dataTest}-opSet`}
              schedule={schedule}
              mode="icon"
              style={{ color: '#1890ff' }}
            />
            <SettlementScheduleLink
              data-test={dataTest && `${dataTest}-link`}
              mode="inline"
              title={
                <FormattedMessage id={I18nPageSettings.COMPANY_SETTINGS_COMPONENTS_GENERAL_CARD_SCHEDULE_ITEM_LINK} />
              }
            />
          </Space>
        }
      />
    </Form.Item>
  );
};

const SettlementScheduleItemMemo = React.memo(SettlementScheduleItem);

export default SettlementScheduleItemMemo;
