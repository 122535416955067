import { useSettlementIntent } from '@/features/settlements/hooks';
import type { SettlementIntent } from '@/features/settlements/types';

import type React from 'react';

const emptyComponent = () => null;

const withSettlementIntentLoad =
  <T extends { intent: SettlementIntent }>(
    Component: React.ComponentType<Omit<T, 'intent'> & { intent: SettlementIntent }>,
    EmptyComponent: React.ComponentType<Omit<T, 'intent'>>,
  ) =>
  (props: Omit<T, 'intent'> & { intentId: string }) => {
    const { intentId } = props;
    const intent = useSettlementIntent(intentId);
    // eslint-disable-next-line react/jsx-props-no-spreading
    return intent.data.data ? <Component {...props} intent={intent.data.data} /> : <EmptyComponent {...props} />;
  };

const withSettlementIntent =
  <T extends { intent: SettlementIntent }>(EmptyComponent: React.ComponentType<Omit<T, 'intent'>> = emptyComponent) =>
  (Component: React.ComponentType<Omit<T, 'intent'> & { intent: SettlementIntent }>) => {
    const LoadComponent = withSettlementIntentLoad<T>(Component, EmptyComponent);
    return (props: T | (Omit<T, 'intent'> & { intentId: string })) => {
      const { intent } = props as Partial<T>;
      const { intentId } = props as { intentId: string };
      // eslint-disable-next-line react/jsx-props-no-spreading
      return intent ? <Component {...props} intent={intent} /> : <LoadComponent {...props} intentId={intentId} />;
    };
  };

export default withSettlementIntent;
