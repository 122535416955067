import React from 'react';

import { FormattedMessage, Link } from '@/components';
import { I18nPageSettlements } from '@/generated/i18n/i18n';
import { SettlementsBreadcrumb } from '@/pages/settlements/components';
import { settlementsPayoutAddLink } from '@/pages/settlements/routes';

import type { SettlementAddPayoutBreadcrumbProps } from './types';

const SettlementAddPayoutBreadcrumbRaw: React.FC<SettlementAddPayoutBreadcrumbProps> = ({
  'data-test': dataTest,
  items = [],
  disabled,
}) => (
  <SettlementsBreadcrumb
    data-test={dataTest}
    items={[
      {
        title: disabled ? (
          <FormattedMessage id={I18nPageSettlements.ADD_PAYOUT_BREADCRUMB_TITLE} />
        ) : (
          <Link
            mode="text"
            to={settlementsPayoutAddLink()}
            title={<FormattedMessage id={I18nPageSettlements.ADD_PAYOUT_BREADCRUMB_TITLE} />}
          />
        ),
      },
      ...items,
    ]}
  />
);

const SettlementAddPayoutBreadcrumb = React.memo(SettlementAddPayoutBreadcrumbRaw);

export default SettlementAddPayoutBreadcrumb;
