/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3276
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { PageSubscriptionChargeSortByAPIModel } from './PageSubscriptionChargeSortByAPIModel';
import {
    PageSubscriptionChargeSortByAPIModelFromJSON,
    PageSubscriptionChargeSortByAPIModelFromJSONTyped,
    PageSubscriptionChargeSortByAPIModelToJSON,
} from './PageSubscriptionChargeSortByAPIModel';
import type { SubscriptionChargeAPIModel } from './SubscriptionChargeAPIModel';
import {
    SubscriptionChargeAPIModelFromJSON,
    SubscriptionChargeAPIModelFromJSONTyped,
    SubscriptionChargeAPIModelToJSON,
} from './SubscriptionChargeAPIModel';

/**
 * 
 * @export
 * @interface SliceSubscriptionChargeSubscriptionChargeSortByAPIModel
 */
export interface SliceSubscriptionChargeSubscriptionChargeSortByAPIModel {
    /**
     * 
     * @type {Array<SubscriptionChargeAPIModel>}
     * @memberof SliceSubscriptionChargeSubscriptionChargeSortByAPIModel
     */
    list?: Array<SubscriptionChargeAPIModel>;
    /**
     * 
     * @type {PageSubscriptionChargeSortByAPIModel}
     * @memberof SliceSubscriptionChargeSubscriptionChargeSortByAPIModel
     */
    page: PageSubscriptionChargeSortByAPIModel;
    /**
     * 
     * @type {number}
     * @memberof SliceSubscriptionChargeSubscriptionChargeSortByAPIModel
     */
    total?: number;
}

/**
 * Check if a given object implements the SliceSubscriptionChargeSubscriptionChargeSortByAPIModel interface.
 */
export function instanceOfSliceSubscriptionChargeSubscriptionChargeSortByAPIModel(value: object): boolean {
    if (!('page' in value)) return false;
    return true;
}

export function SliceSubscriptionChargeSubscriptionChargeSortByAPIModelFromJSON(json: any): SliceSubscriptionChargeSubscriptionChargeSortByAPIModel {
    return SliceSubscriptionChargeSubscriptionChargeSortByAPIModelFromJSONTyped(json, false);
}

export function SliceSubscriptionChargeSubscriptionChargeSortByAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SliceSubscriptionChargeSubscriptionChargeSortByAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'list': json['list'] == null ? undefined : ((json['list'] as Array<any>).map(SubscriptionChargeAPIModelFromJSON)),
        'page': PageSubscriptionChargeSortByAPIModelFromJSON(json['page']),
        'total': json['total'] == null ? undefined : json['total'],
    };
}

export function SliceSubscriptionChargeSubscriptionChargeSortByAPIModelToJSON(value?: SliceSubscriptionChargeSubscriptionChargeSortByAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'list': value['list'] == null ? undefined : ((value['list'] as Array<any>).map(SubscriptionChargeAPIModelToJSON)),
        'page': PageSubscriptionChargeSortByAPIModelToJSON(value['page']),
        'total': value['total'],
    };
}

