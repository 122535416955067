/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3276
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AssetBalancesHistoryAPIModel
 */
export interface AssetBalancesHistoryAPIModel {
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetBalancesHistoryAPIModel
     */
    balances?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AssetBalancesHistoryAPIModel
     */
    total: string;
}

/**
 * Check if a given object implements the AssetBalancesHistoryAPIModel interface.
 */
export function instanceOfAssetBalancesHistoryAPIModel(value: object): boolean {
    if (!('total' in value)) return false;
    return true;
}

export function AssetBalancesHistoryAPIModelFromJSON(json: any): AssetBalancesHistoryAPIModel {
    return AssetBalancesHistoryAPIModelFromJSONTyped(json, false);
}

export function AssetBalancesHistoryAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssetBalancesHistoryAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'balances': json['balances'] == null ? undefined : json['balances'],
        'total': json['total'],
    };
}

export function AssetBalancesHistoryAPIModelToJSON(value?: AssetBalancesHistoryAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'balances': value['balances'],
        'total': value['total'],
    };
}

