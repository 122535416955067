/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3276
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { MerchantWalletRunningBalancePredicatesAPIModel } from './MerchantWalletRunningBalancePredicatesAPIModel';
import {
    MerchantWalletRunningBalancePredicatesAPIModelFromJSON,
    MerchantWalletRunningBalancePredicatesAPIModelFromJSONTyped,
    MerchantWalletRunningBalancePredicatesAPIModelToJSON,
} from './MerchantWalletRunningBalancePredicatesAPIModel';
import type { PageMerchantWalletRunningBalanceSortByAPIModel } from './PageMerchantWalletRunningBalanceSortByAPIModel';
import {
    PageMerchantWalletRunningBalanceSortByAPIModelFromJSON,
    PageMerchantWalletRunningBalanceSortByAPIModelFromJSONTyped,
    PageMerchantWalletRunningBalanceSortByAPIModelToJSON,
} from './PageMerchantWalletRunningBalanceSortByAPIModel';

/**
 * 
 * @export
 * @interface MerchantWalletRunningBalanceFilterAPIModel
 */
export interface MerchantWalletRunningBalanceFilterAPIModel {
    /**
     * 
     * @type {MerchantWalletRunningBalancePredicatesAPIModel}
     * @memberof MerchantWalletRunningBalanceFilterAPIModel
     */
    predicates: MerchantWalletRunningBalancePredicatesAPIModel;
    /**
     * 
     * @type {PageMerchantWalletRunningBalanceSortByAPIModel}
     * @memberof MerchantWalletRunningBalanceFilterAPIModel
     */
    page: PageMerchantWalletRunningBalanceSortByAPIModel;
}

/**
 * Check if a given object implements the MerchantWalletRunningBalanceFilterAPIModel interface.
 */
export function instanceOfMerchantWalletRunningBalanceFilterAPIModel(value: object): boolean {
    if (!('predicates' in value)) return false;
    if (!('page' in value)) return false;
    return true;
}

export function MerchantWalletRunningBalanceFilterAPIModelFromJSON(json: any): MerchantWalletRunningBalanceFilterAPIModel {
    return MerchantWalletRunningBalanceFilterAPIModelFromJSONTyped(json, false);
}

export function MerchantWalletRunningBalanceFilterAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MerchantWalletRunningBalanceFilterAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'predicates': MerchantWalletRunningBalancePredicatesAPIModelFromJSON(json['predicates']),
        'page': PageMerchantWalletRunningBalanceSortByAPIModelFromJSON(json['page']),
    };
}

export function MerchantWalletRunningBalanceFilterAPIModelToJSON(value?: MerchantWalletRunningBalanceFilterAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'predicates': MerchantWalletRunningBalancePredicatesAPIModelToJSON(value['predicates']),
        'page': PageMerchantWalletRunningBalanceSortByAPIModelToJSON(value['page']),
    };
}

