import dayjs from 'dayjs';
import { createSelector } from 'reselect';

import type { AppRootState } from '@/app/store';
import { makeSelectSelectedNetwork } from '@/features/dictionary/blockchain/selectors';
import { WithdrawalIntentStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import {
  createNestedNormalizedListSelectors,
  createNormalizedListSelectors,
} from '@/infrastructure/model/list/selectors';
import { createNormalizedPartialDataSelectors } from '@/infrastructure/model/partial/selectors';
import { createSingleSelectors } from '@/infrastructure/model/single/selectors';
import { uniqueBy } from '@/infrastructure/utils/functions';
import { notEmpty } from '@/infrastructure/utils/ts';

import { defaultSettlementsForAssetListState, NAMESPACE } from './types';
import { extractSettlementId } from './utils';
import { createDistributeFeeKey } from './utils';

import type { SettlementIntent, SettlementForAssetListState } from './types';

export const { makeSelectSettlement, makeSelectMultipleSettlement, makeSelectDirtySettlementIds } =
  createSingleSelectors((state: AppRootState) => state[NAMESPACE].entities, 'Settlement' as const, undefined);

export const { makeSelectSettlementBatch } = createSingleSelectors(
  (state: AppRootState) => state[NAMESPACE].batches,
  'SettlementBatch' as const,
  undefined,
);

export const {
  makeSelectSettlementsListData,
  makeSelectSettlementsListParameters: baseMakeSelectSettlementsListParameters,
} = createNormalizedListSelectors(
  {
    listNoColumn: (state: AppRootState) => state[NAMESPACE].full,
    columnState: (state: AppRootState) => state[NAMESPACE].columnState,
  },
  (state) => state[NAMESPACE].entities,
  'Settlements' as const,
  extractSettlementId,
);

export const makeSelectSettlementsListParameters: typeof baseMakeSelectSettlementsListParameters = () =>
  createSelector(
    baseMakeSelectSettlementsListParameters(),
    makeSelectSelectedNetwork(),
    ({ filter, ...parameters }, network) => ({ ...parameters, filter: { ...filter, network } }),
  );

export const {
  makeSelectSettlementsForAssetListData,
  makeSelectSettlementsForAssetListParameters: baseMakeSelectSettlementsForAssetListParameters,
} = createNestedNormalizedListSelectors(
  (state: AppRootState, settlementId: string | undefined): SettlementForAssetListState =>
    state[NAMESPACE].byAsset[settlementId!] ?? defaultSettlementsForAssetListState,
  (state) => state[NAMESPACE].columnState,
  (state) => state[NAMESPACE].entities,
  'SettlementsForAsset' as const,
);

export const makeSelectSettlementsForAssetListParameters: typeof baseMakeSelectSettlementsForAssetListParameters = (
  asset,
) =>
  createSelector(
    baseMakeSelectSettlementsForAssetListParameters(asset),
    makeSelectSelectedNetwork(),
    ({ filter, ...parameters }, network) => ({ ...parameters, filter: { ...filter, asset, network } }),
  );

export const { makeSelectSettlementIntent, makeSelectDirtySettlementIntentIds, makeSelectMultipleSettlementIntent } =
  createSingleSelectors(
    (state: AppRootState) => state[NAMESPACE].intents.entities,
    'SettlementIntent' as const,
    undefined,
  );

export const { makeSelectScheduledIntentsBatch } = createNormalizedPartialDataSelectors(
  (state: AppRootState) => state[NAMESPACE].intents.scheduled,
  (state: AppRootState) => state[NAMESPACE].intents.entities,
  'ScheduledIntents' as const,
  undefined,
);

export const pendingIntentFilter = ({ status, processAt }: SettlementIntent) =>
  status === WithdrawalIntentStatusAPIModel.Pending && dayjs().isAfter(processAt);

export const activeIntentFilter = ({ status, processAt, staleAt }: SettlementIntent) =>
  status === WithdrawalIntentStatusAPIModel.Pending && dayjs().isAfter(processAt) && dayjs().isBefore(staleAt);

export const staleIntentFilter = ({ status, staleAt }: SettlementIntent) =>
  status === WithdrawalIntentStatusAPIModel.Pending && dayjs().isAfter(staleAt);

const makesSelectFilteredIntents = (filter: (intent: SettlementIntent) => boolean) => () =>
  createSelector(
    (state: AppRootState) => state[NAMESPACE].intents.entities,
    (entities) =>
      Object.values(entities)
        .map((intent) => intent?.data)
        .filter(notEmpty)
        .filter(filter)
        .filter(uniqueBy(({ id }) => id))
        .map(({ id, asset }) => ({ assetId: asset, id })),
  );

export const makeSelectActivePendingIntents = makesSelectFilteredIntents(activeIntentFilter);
export const makeSelectStalePendingIntents = makesSelectFilteredIntents(staleIntentFilter);
export const makeSelectPendingIntents = makesSelectFilteredIntents(pendingIntentFilter);

export const makeSelectSettlementSchedule = () => (state: AppRootState) => state[NAMESPACE].schedule;

export const { makeSelectDistributeFee } = createSingleSelectors(
  (state: AppRootState) => state[NAMESPACE].distributeFees,
  'DistributeFee',
  createDistributeFeeKey,
);
