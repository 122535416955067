import { useMemo } from 'react';

import type { UseAppSingleData } from '@/app/hooks';
import { useAppSingleData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { makeSelectPending } from '@/features/global/selectors';
import { fetchMerchantWalletTransfer } from '@/features/merchant-wallet-transfers/actions';
import { makeSelectMerchantWalletTransfer } from '@/features/merchant-wallet-transfers/selectors';
import type { MerchantWalletTransfer } from '@/features/merchant-wallet-transfers/types';
import { type LoadingStateWithDirty, storedDataError } from '@/infrastructure/model';

export interface UseMerchantWalletTransfer extends UseAppSingleData<MerchantWalletTransfer> {}

const noData = storedDataError<MerchantWalletTransfer>('no-data');

const fetchFactory = (
  id: string,
  force: boolean,
): AppAsyncThunkAction<
  LoadingStateWithDirty<MerchantWalletTransfer>,
  Parameters<typeof fetchMerchantWalletTransfer>[0]
> => fetchMerchantWalletTransfer({ id, force });

export default function useMerchantWalletTransfer(id: string | undefined): UseMerchantWalletTransfer {
  const dataFetchingSelector = useMemo(() => makeSelectPending(fetchMerchantWalletTransfer, id), [id]);
  return useAppSingleData(fetchFactory, makeSelectMerchantWalletTransfer, noData, id, dataFetchingSelector);
}
