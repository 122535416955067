import { Form } from 'antd';
import React from 'react';

import { defaultDialogFormLayout } from '@/components';
import type { ReportScheduleParametersViewProps } from '@/features/global/hocs/withHandlerRegistration/schedules';

import PaymentsReportScheduleParametersForm from '../PaymentsReportScheduleParametersForm';

const PaymentsReportScheduleParametersViewRaw: React.FC<ReportScheduleParametersViewProps> = React.memo(
  ({ value, 'data-test': dataTest }) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Form initialValues={{ parameters: value }} {...defaultDialogFormLayout}>
      <PaymentsReportScheduleParametersForm data-test={dataTest} name="parameters" readonly />
    </Form>
  ),
);

const PaymentsReportScheduleParametersView = React.memo(PaymentsReportScheduleParametersViewRaw);

export default PaymentsReportScheduleParametersView;
