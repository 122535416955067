import type { UseAppListDataView } from '@/app/hooks';
import useListDataView from '@/app/hooks/useAppListDataView';
import { storeMerchantWalletRunningBalanceListParameters } from '@/features/merchant-wallet-balance/actions';
import useMerchantWalletRunningBalanceList from '@/features/merchant-wallet-balance/hooks/useMerchantWalletRunningBalanceList';
import type {
  MerchantWalletRunningBalance,
  MerchantWalletRunningBalanceFilterPredicate,
} from '@/features/merchant-wallet-balance/types';
import type { MerchantWalletRunningBalanceSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';

export interface UseMerchantWalletRunningBalanceListView
  extends UseAppListDataView<
    MerchantWalletRunningBalance,
    MerchantWalletRunningBalanceFilterPredicate,
    MerchantWalletRunningBalanceSortByAPIModel
  > {}

export default function useMerchantWalletRunningBalanceListView(): UseMerchantWalletRunningBalanceListView {
  const state = useMerchantWalletRunningBalanceList();
  return useListDataView(state, storeMerchantWalletRunningBalanceListParameters);
}
