/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3276
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { OverallPlanChurnRateAPIModel } from './OverallPlanChurnRateAPIModel';
import {
    OverallPlanChurnRateAPIModelFromJSON,
    OverallPlanChurnRateAPIModelFromJSONTyped,
    OverallPlanChurnRateAPIModelToJSON,
} from './OverallPlanChurnRateAPIModel';
import type { PlanChurnRateAPIModel } from './PlanChurnRateAPIModel';
import {
    PlanChurnRateAPIModelFromJSON,
    PlanChurnRateAPIModelFromJSONTyped,
    PlanChurnRateAPIModelToJSON,
} from './PlanChurnRateAPIModel';

/**
 * 
 * @export
 * @interface ChurnRateAPIModel
 */
export interface ChurnRateAPIModel {
    /**
     * 
     * @type {Array<PlanChurnRateAPIModel>}
     * @memberof ChurnRateAPIModel
     */
    perPlan?: Array<PlanChurnRateAPIModel>;
    /**
     * 
     * @type {Array<OverallPlanChurnRateAPIModel>}
     * @memberof ChurnRateAPIModel
     */
    mean?: Array<OverallPlanChurnRateAPIModel>;
    /**
     * 
     * @type {any}
     * @memberof ChurnRateAPIModel
     */
    median?: any;
}

/**
 * Check if a given object implements the ChurnRateAPIModel interface.
 */
export function instanceOfChurnRateAPIModel(value: object): boolean {
    return true;
}

export function ChurnRateAPIModelFromJSON(json: any): ChurnRateAPIModel {
    return ChurnRateAPIModelFromJSONTyped(json, false);
}

export function ChurnRateAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChurnRateAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'perPlan': json['perPlan'] == null ? undefined : ((json['perPlan'] as Array<any>).map(PlanChurnRateAPIModelFromJSON)),
        'mean': json['mean'] == null ? undefined : ((json['mean'] as Array<any>).map(OverallPlanChurnRateAPIModelFromJSON)),
        'median': json['median'] == null ? undefined : json['median'],
    };
}

export function ChurnRateAPIModelToJSON(value?: ChurnRateAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'perPlan': value['perPlan'] == null ? undefined : ((value['perPlan'] as Array<any>).map(PlanChurnRateAPIModelToJSON)),
        'mean': value['mean'] == null ? undefined : ((value['mean'] as Array<any>).map(OverallPlanChurnRateAPIModelToJSON)),
        'median': value['median'],
    };
}

