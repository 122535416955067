import React from 'react';

import type { SettlementViewProps } from '@/features/settlements/components';
import { SettlementView } from '@/features/settlements/components';

import { withSettlementDataLoading } from './hocs';

const SettlementCardRaw = withSettlementDataLoading<SettlementViewProps>((props) => (
  <SettlementView {...props} columns={1} />
));

const SettlementCard = React.memo(SettlementCardRaw) as typeof SettlementCardRaw;

export default SettlementCard;
