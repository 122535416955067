import React from 'react';

import { Link, FormattedMessage } from '@/components';
import { I18nPageSettlements } from '@/generated/i18n/i18n';
import { settlementsHistoryLink } from '@/pages/settlements/routes';

import type { SettlementHistoryLinkProps } from './types';


const SettlementHistoryLink: React.FC<SettlementHistoryLinkProps> = ({ 'data-test': dataTest, title, ...props }) => (
  <Link
    to={settlementsHistoryLink()}
    data-test={dataTest}
    title={title || <FormattedMessage id={I18nPageSettlements.HISTORY_COMPONENTS_LINK_TITLE} />}
    {...props}
  />
);

const SettlementHistoryLinkMemo = React.memo(SettlementHistoryLink);

export default SettlementHistoryLinkMemo;
