import { QuestionCircleOutlined } from '@ant-design/icons';
import { Descriptions, Space, Tooltip } from 'antd';
import React from 'react';

import { FormattedMessage, ReadonlyComponent, ReadonlyDateTime } from '@/components';
import { AssetAmount, AddressLink, TransactionLink } from '@/features/dictionary/blockchain/components';
import SettlementStatusView from '@/features/settlements/components/SettlementStatusView';
import { WithdrawalJobStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureSettlements } from '@/generated/i18n/i18n';

import { SettlementDurationAlert } from './components';

import type { SettlementViewProps } from './types';

const SettlementView: React.FC<SettlementViewProps> = ({
  data,
  'data-test': dataTest,
  style,
  className,
  columns = { xl: 2, lg: 1, md: 1, sm: 1, xs: 1 },
}) => (
  <Descriptions
    style={style}
    className={className}
    contentStyle={{ fontWeight: 'bold', overflow: 'hidden' }}
    title={<SettlementStatusView value={data.status} data-test={dataTest && `${dataTest}-status`} />}
    column={columns}
  >
    {data.status === WithdrawalJobStatusAPIModel.Running && (
      <Descriptions.Item>
        <SettlementDurationAlert settlementId={data.id} data-test={dataTest && `${dataTest}-durationAlert`} />
      </Descriptions.Item>
    )}
    <Descriptions.Item label={<FormattedMessage id={I18nFeatureSettlements.LABELS_ROW_TITLE_SETTLEMENT_ID} />}>
      <ReadonlyComponent value={data.id} data-test={dataTest && `${dataTest}-id`} />
    </Descriptions.Item>
    {data.destinationAddress && (
      <Descriptions.Item
        label={<FormattedMessage id={I18nFeatureSettlements.LABELS_ROW_TITLE_SETTLEMENT_DESTINATION_ADDRESS} />}
        contentStyle={{
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
      >
        <AddressLink
          data-test={dataTest && `${dataTest}-address`}
          address={data.destinationAddress}
          bt={data.blockchain}
        />
      </Descriptions.Item>
    )}
    {data.hash && (
      <Descriptions.Item
        label={<FormattedMessage id={I18nFeatureSettlements.LABELS_ROW_TITLE_SETTLEMENT_TRANSACTION} />}
        contentStyle={{
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
      >
        <TransactionLink data-test={dataTest && `${dataTest}-transaction`} bt={data.blockchain} tx={data.hash} />
      </Descriptions.Item>
    )}
    <Descriptions.Item
      label={
        <Space>
          <FormattedMessage id={I18nFeatureSettlements.LABELS_ROW_TITLE_SETTLEMENT_ESTIMATED_AMOUNT} />
          <Tooltip
            title={<FormattedMessage id={I18nFeatureSettlements.COMPONENTS_SETTLEMENT_VIEW_ESTIMATED_AMOUNT_HELP} />}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </Space>
      }
    >
      <AssetAmount
        value={data.estimatedAmount.value}
        assetId={data.estimatedAmount.asset}
        withCurrency
        currencyMode="long"
        data-test={dataTest && `${dataTest}-estimatedAmount`}
      />
    </Descriptions.Item>
    <Descriptions.Item
      label={
        <Space>
          <FormattedMessage id={I18nFeatureSettlements.LABELS_ROW_TITLE_SETTLEMENT_TOTAL_AMOUNT} />
          <Tooltip
            title={<FormattedMessage id={I18nFeatureSettlements.COMPONENTS_SETTLEMENT_VIEW_TOTAL_AMOUNT_HELP} />}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </Space>
      }
    >
      <AssetAmount
        value={data.totalAmount.value}
        assetId={data.totalAmount.asset}
        withCurrency
        currencyMode="long"
        data-test={dataTest && `${dataTest}-totalAmount`}
      />
    </Descriptions.Item>
    <Descriptions.Item label={<FormattedMessage id={I18nFeatureSettlements.LABELS_ROW_TITLE_SETTLEMENT_SETTLED_AT} />}>
      <ReadonlyDateTime value={data.withdrawnAt} data-test={dataTest && `${dataTest}-withdrawnAt`} />
    </Descriptions.Item>
    {data.errorCode && (
      <Descriptions.Item label={<FormattedMessage id={I18nFeatureSettlements.LABELS_ROW_TITLE_SETTLEMENT_ERROR} />}>
        <ReadonlyComponent value={data.errorCode} data-test={dataTest && `${dataTest}-error`} />
      </Descriptions.Item>
    )}
  </Descriptions>
);

const SettlementViewMemo = React.memo(SettlementView) as typeof SettlementView;

export default SettlementViewMemo;
