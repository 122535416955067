import type { OnboardRequestAPIModel } from '@/generated/api/ncps-api';
import type { CompanyUserAPIModel, UserCompanyInfoAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { LoadingFullDataState } from '@/infrastructure/model/full/types';

import type { Address } from 'viem';

export const NAMESPACE = 'company';

export interface NewCompany extends Omit<OnboardRequestAPIModel, 'emailTokenProvider'> {}

export interface UpdateCompanyName {
  name: string;
}

export interface Company extends UserCompanyInfoAPIModel {}

export interface CompanyWithOwner extends Company {
  isOwner: boolean;
}

export interface CompanyWithSelection extends CompanyWithOwner {
  isSelected: boolean;
}

export interface CompanyUser extends Omit<CompanyUserAPIModel, 'address'> {
  address: Address;
}

export interface CompanyState {
  companies: LoadingFullDataState<Company>;
  users: LoadingFullDataState<CompanyUser>;
}
