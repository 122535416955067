import { Tag } from 'antd';
import React, { useMemo } from 'react';

import { WarningTooltip } from '@/components';
import { NativeAssetAmount } from '@/features/dictionary/blockchain/components';
import GasWalletInvalidStateMessage from '@/features/gas-wallets/components/GasWalletInvalidStateMessage';
import withWalletBalanceLoading from '@/features/gas-wallets/hocs/withWalletBalanceLoading';

import type { GasWalletDerivedBalanceViewProps } from './types';

const GasWalletDerivedBalanceView: React.FC<GasWalletDerivedBalanceViewProps> = ({ wallet, 'data-test': dataTest }) => {
  const isBalanceLow = useMemo(
    () => wallet.derivedBalance.lt(wallet.lowWatermark),
    [wallet.derivedBalance, wallet.lowWatermark],
  );
  return isBalanceLow ? (
    <WarningTooltip
      type="error"
      title={<GasWalletInvalidStateMessage wallet={wallet} mode="full" type="derived-balance" />}
    >
      <Tag color="error" style={{ marginRight: 0 }}>
        <NativeAssetAmount bt={wallet.bt} value={wallet.derivedBalance} data-test={dataTest} withCurrency />
      </Tag>
    </WarningTooltip>
  ) : (
    <Tag color="blue" style={{ marginRight: 0 }}>
      <NativeAssetAmount bt={wallet.bt} value={wallet.derivedBalance} data-test={dataTest} withCurrency />
    </Tag>
  );
};

const GasWalletDerivedBalanceViewHOCed = withWalletBalanceLoading(GasWalletDerivedBalanceView);

const GasWalletDerivedBalanceViewMemo = React.memo(
  GasWalletDerivedBalanceViewHOCed,
) as typeof GasWalletDerivedBalanceViewHOCed;

export default GasWalletDerivedBalanceViewMemo;
