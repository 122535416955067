import React from 'react';

import { FormattedMessage, Link } from '@/components';
import { I18nPageAudit } from '@/generated/i18n/i18n';
import { auditWalletTransfersLink } from '@/pages/audit/routes';

import type { MerchantWalletTransfersLinkProps } from './types';


const MerchantWalletTransfersLink: React.FC<MerchantWalletTransfersLinkProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  title,
}) => (
  <Link
    data-test={dataTest}
    to={auditWalletTransfersLink()}
    mode={mode}
    title={title || <FormattedMessage id={I18nPageAudit.TRANSFERS_COMPONENTS_LINK_TITLE} />}
  />
);

const MerchantWalletTransfersLinkMemo = React.memo(MerchantWalletTransfersLink);

export default MerchantWalletTransfersLinkMemo;
