import type { DateTimeRangeRelativeEnum, DateTimeRelativeQuickFilter } from '@/components';
import type {
  BlockchainNetworkTypeAPIModel,
  DateTimeRangeAPIModel,
  NotificationStatusAPIModel,
  PaymentAPIModel,
  PaymentCompatStatusAPIModel,
  PaymentPredicatesAPIModel,
  PaymentSortByAPIModel,
  PaymentTransactionSearchIdAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import type { ListSortBy, ListState } from '@/infrastructure/model/list/types';
import type { SingleState } from '@/infrastructure/model/single/types';

import type BigNumber from 'bignumber.js';

export const NAMESPACE = 'payments';

export const paymentExportReport = 'invoices';

export interface Payment extends Omit<PaymentAPIModel, 'amount' | 'paidAmount' | 'metadata'> {
  amount: BigNumber;
  asset: string;
  paidAmount?: BigNumber;
  paidAsset?: string;
  metadata?: object | string;
}

export interface PaymentFilterPredicate extends PaymentPredicatesAPIModel {
  createdRangeRelative?: DateTimeRelativeQuickFilter;
}

export interface PaymentReportFilterPredicate {
  createdRangeRelative?: DateTimeRangeRelativeEnum;
  statuses?: PaymentCompatStatusAPIModel[];
  paymentAddress?: string;
  createdRange?: DateTimeRangeAPIModel;
  metadata?: string;
  ids?: string[];
  network?: BlockchainNetworkTypeAPIModel;
}

export interface PaymentSortBy extends ListSortBy<PaymentSortByAPIModel> {}

export interface PaymentNotification {
  id: string;
  key: string;
  status: NotificationStatusAPIModel;
  attemptCount: number;
  attemptMinAt: Date;
  code?: number;
  url?: string;
}

export interface PaymentsState {
  entities: SingleState<Payment>;
  list: ListState<Payment, PaymentFilterPredicate, PaymentSortByAPIModel>;
  transactions: SingleState<PaymentTransactionSearchIdAPIModel[]>; // paymentId -> transaction ids
}
