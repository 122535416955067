import { useContext } from 'react';

import { useAppSelector } from '@/app/hooks';
import { PageLoading } from '@/components';
import Web3AuthContext from '@/features/auth/hocs/withWeb3AuthContext/context';
import { useAuthActions } from '@/features/auth/hooks';
import { makeSelectAuthStatus } from '@/features/auth/selectors';
import { AuthStatus } from '@/features/auth/types';
import { usePreferences, useUser } from '@/features/user/hooks';
import { useSingleRun } from '@/hooks';
import { suppressPromise } from '@/infrastructure/utils/functions';
import { namedHOC } from '@/infrastructure/utils/react';
import type { EmptyObject } from '@/infrastructure/utils/ts';
import MessageLayout from '@/layouts/MessageLayout';

import type React from 'react';

const selectAuthStatus = makeSelectAuthStatus();

const withWeb3AuthConsistencyGuard = <T extends EmptyObject>(Component: React.ComponentType<T>): React.FC<T> =>
  namedHOC(
    Component,
    'WithWeb3AuthConsistencyGuard',
  )((props) => {
    const { logout } = useAuthActions();
    const { withSingleRun, inProgress } = useSingleRun(true);
    const web3Auth = useContext(Web3AuthContext);
    const authStatus = useAppSelector(selectAuthStatus);
    const { data: preferences } = usePreferences();
    const { data: user } = useUser();
    const isJWTAuthorized = authStatus === AuthStatus.AUTHORIZED;
    const isWeb3AuthNotInitialized = web3Auth.initialized && !web3Auth.web3Auth?.isInitialized;
    const isPKNotSecuredYet = !!preferences.data && !preferences.data.privateKeySecured;
    const isUserSignedUpByWeb3Auth = !!user.data && user.data.isEmailAuth;
    if (isWeb3AuthNotInitialized && isJWTAuthorized && isPKNotSecuredYet && isUserSignedUpByWeb3Auth) {
      if (logout.available && !logout.inAction) {
        if (!inProgress) {
          suppressPromise(withSingleRun(logout.act));
        }
      }
      return (
        <MessageLayout>
          <PageLoading type="withWeb3AuthConsistencyGuard" />
        </MessageLayout>
      );
    }

    return <Component {...props} />;
  });

export default withWeb3AuthConsistencyGuard;
