/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3276
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

/**
 * 
 * @export
 * @enum {string}
 */
export enum SubscriptionPlanStatusAPIModel {
    Draft = 'Draft',
    Active = 'Active',
    Archived = 'Archived'
}


export function SubscriptionPlanStatusAPIModelFromJSON(json: any): SubscriptionPlanStatusAPIModel {
    return SubscriptionPlanStatusAPIModelFromJSONTyped(json, false);
}

export function SubscriptionPlanStatusAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubscriptionPlanStatusAPIModel {
    return json as SubscriptionPlanStatusAPIModel;
}

export function SubscriptionPlanStatusAPIModelToJSON(value?: SubscriptionPlanStatusAPIModel | null): any {
    return value as any;
}

