import { Space } from 'antd';

import { FormattedMessage } from '@/components';
import { SelectedNetworkLabel } from '@/features/dictionary/blockchain/components';
import { OperationRefreshScheduledIntents } from '@/features/settlements/components';
import { useScheduledIntents } from '@/features/settlements/hooks';
import type { SettlementIntent } from '@/features/settlements/types';
import { I18nPageSettlements } from '@/generated/i18n/i18n';
import { withCardDataLoading } from '@/hocs';
import type { TestableProps } from '@/infrastructure/utils/react';
import { namedHOC } from '@/infrastructure/utils/react';

import type React from 'react';

const withSettlementScheduleIntentsDataLoading = <T extends { data: SettlementIntent[] } & TestableProps>(
  Component: React.ComponentType<T>,
): React.FC<Omit<T, 'data'>> =>
  namedHOC<T, Omit<T, 'data'>>(
    Component,
    'WithSettlementScheduleIntentsDataLoading',
  )((props) => {
    const data = useScheduledIntents();
    return withCardDataLoading<SettlementIntent[], T>({
      ...data,
      'data-test': props['data-test'],
      title: (
        <Space>
          <FormattedMessage id={I18nPageSettlements.SCHEDULE_COMPONENTS_INTENTS_CARD_TITLE} />
          <SelectedNetworkLabel mode="short" data-test={props['data-test'] && `${props['data-test']}-network`} />
        </Space>
      ),
      CardProps: {
        extra: <OperationRefreshScheduledIntents data-test={props['data-test'] && `${props['data-test']}-opRefresh`} />,
      },
    })(Component)(props);
  });

export default withSettlementScheduleIntentsDataLoading;
