import { Space, Tooltip } from 'antd';
import React from 'react';

import { ReadonlyComponent } from '@/components';
import { withBlockchainType } from '@/features/dictionary/blockchain/hocs';
import { useBlockchain } from '@/features/dictionary/blockchain/hooks';
import type { BlockchainTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';

import BlockchainIcon from '../BlockchainIcon';
import NetworkLabel from '../NetworkLabel';

import type { BlockchainLabelProps } from './types';

const NetworkMark: React.FC<{ value: BlockchainTypeAPIModel }> = React.memo(({ value }) => {
  const blockchain = useBlockchain(value);
  const network = blockchain.data.data?.net;
  return network ? <NetworkLabel tooltipMode="short" mode="short" value={network} noSwitcher /> : null;
});

const BlockchainLabelRaw: React.FC<BlockchainLabelProps> = ({
  bt,
  'data-test': dataTest,
  mode = 'medium',
  withNetworkMark,
  className,
  style,
  copyable = true,
}) => {
  if (mode === 'icon') {
    return (
      <Space data-test={dataTest}>
        {withNetworkMark && <NetworkMark value={bt} />}
        <Tooltip title={bt}>
          <span>
            <BlockchainIcon bt={bt} className={className} style={style} />
          </span>
        </Tooltip>
      </Space>
    );
  }
  if (mode === 'medium') {
    return (
      <ReadonlyComponent
        className={className}
        style={style}
        value={
          <Space>
            {withNetworkMark && <NetworkMark value={bt} />}
            <BlockchainIcon bt={bt} />
            {bt}
          </Space>
        }
        data-test={dataTest}
        copyable={copyable ? { text: bt } : undefined}
      />
    );
  }
  return <ReadonlyComponent className={className} style={style} value={bt} data-test={dataTest} copyable={copyable} />;
};

const BlockchainLabelHOC = withBlockchainType<BlockchainLabelProps>(BlockchainLabelRaw);
const BlockchainLabelMemo = React.memo(BlockchainLabelHOC);

export default BlockchainLabelMemo;
