import { Space } from 'antd';
import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nFeatureStatistics } from '@/generated/i18n/i18n';

import BalanceStatisticsContainer from '../BalanceStatisticsContainer';

import type { ActualBalancesHeaderProps } from './types';

const style: React.CSSProperties = { width: '100%', justifyContent: 'space-between' };

const ActualBalancesHeader: React.FC<ActualBalancesHeaderProps> = ({ data, 'data-test': dataTest }) => (
  <Space style={style} wrap>
    <BalanceStatisticsContainer
      data={data.total}
      title={<FormattedMessage id={I18nFeatureStatistics.COMPONENTS_ACTUAL_BALANCES_VIEW_TOTAL_TITLE} />}
      data-test={dataTest && `${dataTest}-total`}
    />
    <BalanceStatisticsContainer
      data={data.totalAvailable}
      title={<FormattedMessage id={I18nFeatureStatistics.COMPONENTS_ACTUAL_BALANCES_VIEW_AVAILABLE_TITLE} />}
      data-test={dataTest && `${dataTest}-available`}
    />
    <BalanceStatisticsContainer
      data={data.totalLocked}
      title={<FormattedMessage id={I18nFeatureStatistics.COMPONENTS_ACTUAL_BALANCES_VIEW_LOCKED_TITLE} />}
      data-test={dataTest && `${dataTest}-locked`}
    />
  </Space>
);

const ActualBalancesHeaderMemo = React.memo(ActualBalancesHeader);

export default ActualBalancesHeaderMemo;
