import { Alert } from 'antd';
import React from 'react';

import { FormattedMessage } from '@/components';
import { MerchantWalletTransferKindAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nPageAudit } from '@/generated/i18n/i18n';

import {
  EntityLinkNotSupportedAlert,
  EntityNotFoundAlert,
  PaymentTransactionEntityLink,
  PayoutEntityLink,
  SubscriptionChargeEntityLink,
  SettlementEntityLink,
} from './components';
import { withMerchantWalletTransferDataLoading } from './hocs';

import type { MerchantWalletTransferEntityCardProps } from './types';

const MerchantWalletTransferEntityCardRaw: React.FC<MerchantWalletTransferEntityCardProps> = ({
  'data-test': dataTest,
  data,
  forceRefresh,
}) => {
  if (data.kind !== MerchantWalletTransferKindAPIModel.Unknown && !data.entityId) {
    return <EntityNotFoundAlert data-test={dataTest} value={data.kind} onRetry={forceRefresh} />;
  }

  // eslint-disable-next-line default-case
  switch (data.kind) {
    case MerchantWalletTransferKindAPIModel.Unknown:
      return (
        <Alert
          type="warning"
          message={<FormattedMessage id={I18nPageAudit.TRANSFER_ENTITY_COMPONENTS_ENTITY_CARD_UNKNOWN_TITLE} />}
          description={
            <FormattedMessage id={I18nPageAudit.TRANSFER_ENTITY_COMPONENTS_ENTITY_CARD_UNKNOWN_DESCRIPTION} />
          }
        />
      );
    case MerchantWalletTransferKindAPIModel.PaymentTransaction:
      return <PaymentTransactionEntityLink data-test={dataTest} value={data} />;
    case MerchantWalletTransferKindAPIModel.SubscriptionCharge:
      return <SubscriptionChargeEntityLink data-test={dataTest} value={data} />;
    case MerchantWalletTransferKindAPIModel.Withdrawal:
      return <SettlementEntityLink data-test={dataTest} value={data} />;
    case MerchantWalletTransferKindAPIModel.Payout:
      return <PayoutEntityLink data-test={dataTest} value={data} />;
    case MerchantWalletTransferKindAPIModel.PartnerFee:
    case MerchantWalletTransferKindAPIModel.ProcessingFee:
      return <EntityLinkNotSupportedAlert data-test={dataTest} value={data.kind} />;
  }

  return null;
};

const MerchantWalletTransferEntityCardHOC = withMerchantWalletTransferDataLoading(MerchantWalletTransferEntityCardRaw);

const MerchantWalletTransferEntityCard = React.memo(
  MerchantWalletTransferEntityCardHOC,
) as typeof MerchantWalletTransferEntityCardHOC;

export default MerchantWalletTransferEntityCard;
