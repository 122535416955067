import React, { useMemo } from 'react';

import { FormattedMessage } from '@/components';
import { AssetLabel } from '@/features/dictionary/blockchain/components';
import { I18nPageSettlements } from '@/generated/i18n/i18n';
import { SettlementsBreadcrumb } from '@/pages/settlements/components';

import SettlementHistoryLink from '../SettlementHistoryLink';

import type { SettlementHistoryBreadcrumbProps } from './types';

const SettlementHistoryBreadcrumbRaw: React.FC<SettlementHistoryBreadcrumbProps> = React.memo(
  ({ 'data-test': dataTest, items = [], disabled, assetId }) => {
    const title = useMemo(
      () =>
        assetId ? (
          <AssetLabel value={assetId} mode="medium" />
        ) : (
          <FormattedMessage id={I18nPageSettlements.HISTORY_COMPONENTS_BREADCRUMB} />
        ),
      [assetId],
    );
    return (
      <SettlementsBreadcrumb
        items={[{ title: disabled ? title : <SettlementHistoryLink data-test={dataTest} title={title} /> }, ...items]}
      />
    );
  },
);

const SettlementHistoryBreadcrumb = React.memo(SettlementHistoryBreadcrumbRaw);

export default SettlementHistoryBreadcrumb;
