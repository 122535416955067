import React from 'react';

import type { ReportParametersViewProps } from '@/features/global/hocs/withHandlerRegistration/reports';

import GasHistoryFilterForm from '../GasHistoryFilterForm';

const GasHistoryReportParametersView: React.FC<ReportParametersViewProps> = React.memo(
  ({ value, 'data-test': dataTest }) => <GasHistoryFilterForm data-test={dataTest} value={value} readonly />,
);

export default GasHistoryReportParametersView;
