import { Form } from 'antd';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import {
  defaultPageFormLayout,
  defaultPageFormTailLayout,
  EmailItem,
  ErrorFormMessage,
  FormattedMessage,
  FormCompletenessItem,
  FormFooter,
  InputItem,
} from '@/components';
import { I18nFeatureCompanySettings } from '@/generated/i18n/i18n';
import { useErrorSafeSubmitting, useForm, useStateMountSafe } from '@/hooks';
import { noop } from '@/infrastructure/utils/functions';
import { nameof } from '@/infrastructure/utils/ts';

import type { ConfirmEmailFormProps, ConfirmEmailFormValues } from './types';

const fields = [nameof<ConfirmEmailFormValues>('email'), nameof<ConfirmEmailFormValues>('token')];

const tokenMessages = {
  label: <FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_CONFIRM_EMAIL_FORM_TOKEN_LABEL} />,
};
const footerMessages = {
  submit: (
    <FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_CONFIRM_EMAIL_FORM_SUBMIT} tagName="span" />
  ),
};

const ConfirmEmailForm: React.FC<ConfirmEmailFormProps> = ({
  'data-test': dataTest,
  onSubmit,
  onReset = noop,
  layout = defaultPageFormLayout,
  tailLayout = defaultPageFormTailLayout,
}) => {
  const { formatMessage } = useIntl();
  const { form, withResetForm } = useForm<ConfirmEmailFormValues>();
  const [isFormComplete, setFormComplete] = useStateMountSafe(false);
  const { submitting, error, withSubmitting, withErrorHandling } = useErrorSafeSubmitting();
  const submit = useMemo(
    () => withSubmitting((values: ConfirmEmailFormValues): unknown => onSubmit(values)),
    [onSubmit, withSubmitting],
  );
  const reset = useMemo(() => withErrorHandling(withResetForm(onReset)), [onReset, withErrorHandling, withResetForm]);
  const errorMessage = useMemo(() => {
    if (!error) {
      return undefined;
    }

    if (error === 'ActivationTokenExpired') {
      return (
        <FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_CONFIRM_EMAIL_FORM_ERROR_EXPIRED_TOKEN} />
      );
    }
    if (error === 'ActivationTokenNotFound') {
      return (
        <FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_CONFIRM_EMAIL_FORM_ERROR_INVALID_TOKEN} />
      );
    }
    if (error === 'UserAlreadyExists') {
      return (
        <FormattedMessage
          id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_CONFIRM_EMAIL_FORM_ERROR_DUPLICATE_EMAIL}
        />
      );
    }
    return <FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_CONFIRM_EMAIL_FORM_ERROR_COMMON} />;
  }, [error]);

  return (
    <Form<ConfirmEmailFormValues>
      {...layout}
      autoComplete="off"
      form={form}
      size="middle"
      onFinish={submit}
      onReset={reset}
    >
      {errorMessage && <ErrorFormMessage content={errorMessage} data-test={dataTest && `${dataTest}-error`} />}
      <EmailItem<ConfirmEmailFormValues>
        name={nameof<ConfirmEmailFormValues>('email')}
        data-test={dataTest && `${dataTest}-email`}
        required
      />
      <InputItem<ConfirmEmailFormValues>
        name={nameof<ConfirmEmailFormValues>('token')}
        data-test={dataTest && `${dataTest}-token`}
        placeholder={formatMessage({
          id: I18nFeatureCompanySettings.COMPONENTS_OPERATION_CONFIRM_EMAIL_FORM_TOKEN_PLACEHOLDER,
        })}
        messages={tokenMessages}
        required
      />
      <FormCompletenessItem<ConfirmEmailFormValues> requiredFields={fields} onChange={setFormComplete} />
      <FormFooter
        tailLayout={tailLayout}
        submitting={submitting}
        submitDisabled={!isFormComplete}
        messages={footerMessages}
        data-test={dataTest && `${dataTest}-submit`}
      />
    </Form>
  );
};

const ConfirmEmailFormMemo = React.memo(ConfirmEmailForm);

export default ConfirmEmailFormMemo;
