/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3276
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { PageSubscriptionPlanSortByAPIModel } from './PageSubscriptionPlanSortByAPIModel';
import {
    PageSubscriptionPlanSortByAPIModelFromJSON,
    PageSubscriptionPlanSortByAPIModelFromJSONTyped,
    PageSubscriptionPlanSortByAPIModelToJSON,
} from './PageSubscriptionPlanSortByAPIModel';
import type { SubscriptionPlanAPIModel } from './SubscriptionPlanAPIModel';
import {
    SubscriptionPlanAPIModelFromJSON,
    SubscriptionPlanAPIModelFromJSONTyped,
    SubscriptionPlanAPIModelToJSON,
} from './SubscriptionPlanAPIModel';

/**
 * 
 * @export
 * @interface SliceSubscriptionPlanSubscriptionPlanSortByAPIModel
 */
export interface SliceSubscriptionPlanSubscriptionPlanSortByAPIModel {
    /**
     * 
     * @type {Array<SubscriptionPlanAPIModel>}
     * @memberof SliceSubscriptionPlanSubscriptionPlanSortByAPIModel
     */
    list?: Array<SubscriptionPlanAPIModel>;
    /**
     * 
     * @type {PageSubscriptionPlanSortByAPIModel}
     * @memberof SliceSubscriptionPlanSubscriptionPlanSortByAPIModel
     */
    page: PageSubscriptionPlanSortByAPIModel;
    /**
     * 
     * @type {number}
     * @memberof SliceSubscriptionPlanSubscriptionPlanSortByAPIModel
     */
    total?: number;
}

/**
 * Check if a given object implements the SliceSubscriptionPlanSubscriptionPlanSortByAPIModel interface.
 */
export function instanceOfSliceSubscriptionPlanSubscriptionPlanSortByAPIModel(value: object): boolean {
    if (!('page' in value)) return false;
    return true;
}

export function SliceSubscriptionPlanSubscriptionPlanSortByAPIModelFromJSON(json: any): SliceSubscriptionPlanSubscriptionPlanSortByAPIModel {
    return SliceSubscriptionPlanSubscriptionPlanSortByAPIModelFromJSONTyped(json, false);
}

export function SliceSubscriptionPlanSubscriptionPlanSortByAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SliceSubscriptionPlanSubscriptionPlanSortByAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'list': json['list'] == null ? undefined : ((json['list'] as Array<any>).map(SubscriptionPlanAPIModelFromJSON)),
        'page': PageSubscriptionPlanSortByAPIModelFromJSON(json['page']),
        'total': json['total'] == null ? undefined : json['total'],
    };
}

export function SliceSubscriptionPlanSubscriptionPlanSortByAPIModelToJSON(value?: SliceSubscriptionPlanSubscriptionPlanSortByAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'list': value['list'] == null ? undefined : ((value['list'] as Array<any>).map(SubscriptionPlanAPIModelToJSON)),
        'page': PageSubscriptionPlanSortByAPIModelToJSON(value['page']),
        'total': value['total'],
    };
}

