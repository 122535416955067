import { List, Space } from 'antd';
import React, { useCallback } from 'react';

import { FormattedMessage } from '@/components';
import { AssetAmount, AssetIcon, AssetLabel } from '@/features/dictionary/blockchain/components';
import { MerchantWalletDeploymentStatusBanner } from '@/features/merchant-wallets/components';
import OperationSettleNow from '@/features/settlements/components/OperationSettleNow';
import type { ActualAssetBalanceWithInfo } from '@/features/statistics/components';
import { I18nFeatureStatistics } from '@/generated/i18n/i18n';

import type { ActualBalancesListProps } from './types';

const ActualBalancesList: React.FC<ActualBalancesListProps> = ({ data, 'data-test': dataTest }) => (
  <List<ActualAssetBalanceWithInfo>
    itemLayout="horizontal"
    loadMore={false}
    dataSource={data}
    renderItem={useCallback(
      (item: ActualAssetBalanceWithInfo) => (
        <List.Item
          key={item.assetInfo.code}
          actions={[
            <OperationSettleNow
              key="withdraw-now"
              assetId={item.assetInfo.code}
              data-test={dataTest && `${dataTest}-${item.assetInfo.code}-withdrawOp`}
            />,
          ]}
        >
          <List.Item.Meta
            style={{ alignItems: 'center' }}
            avatar={
              <AssetIcon
                type={item.balance.asset}
                data-test={dataTest && `${dataTest}-${item.assetInfo.code}-icon`}
                style={{ fontSize: 32 }}
              />
            }
            title={
              <Space>
                <FormattedMessage
                  id={I18nFeatureStatistics.COMPONENTS_ACTUAL_BALANCES_VIEW_ROW_TITLE_AVAILABLE}
                  values={{
                    amount: (
                      <AssetAmount
                        value={item.available.value}
                        assetId={item.available.asset}
                        withCurrency
                        data-test={dataTest && `${dataTest}-${item.assetInfo.code}-available`}
                      />
                    ),
                  }}
                  tagName="span"
                />
                {!item.locked.value.isZero() && (
                  <FormattedMessage
                    id={I18nFeatureStatistics.COMPONENTS_ACTUAL_BALANCES_VIEW_ROW_TITLE_LOCKED}
                    values={{
                      amount: (
                        <AssetAmount
                          value={item.locked.value}
                          assetId={item.locked.asset}
                          withCurrency
                          data-test={dataTest && `${dataTest}-${item.assetInfo.code}-locked`}
                        />
                      ),
                    }}
                    tagName="span"
                  />
                )}
                <MerchantWalletDeploymentStatusBanner
                  data-test={dataTest && `${dataTest}-${item.assetInfo.code}-walletStatus`}
                  assetId={item.assetInfo.code}
                  mode="tag"
                />
              </Space>
            }
            description={<AssetLabel value={item.assetInfo} mode="medium-no-ico" withBlockchainMark />}
          />
        </List.Item>
      ),
      [dataTest],
    )}
  />
);

const ActualBalancesListMemo = React.memo(ActualBalancesList);

export default ActualBalancesListMemo;
