import { useMemo } from 'react';

import type { CustomizableRoute } from '@/layouts/types';
import useAuditMenuData from '@/pages/audit/hooks/useMenuData';
import useBalancesMenuData from '@/pages/balances/hooks/useMenuData';
import useDashboardMenuData from '@/pages/dashboard/hooks/useMenuData';
import useGasHistoryMenuData from '@/pages/gas-history/hooks/useMenuData';
import usePaymentsMenuData from '@/pages/payments/hooks/useMenuData';
import useReportsMenuData from '@/pages/reports/hooks/useMenuData';
import useSettingsMenuData from '@/pages/settings/hooks/useMenuData';
import useSettlementsMenuData from '@/pages/settlements/hooks/useMenuData';
import useSubscriptionsMenuData from '@/pages/subscriptions/hooks/useMenuData';

export default function useMenuData(): CustomizableRoute {
  const auditMenuData = useAuditMenuData();
  const balancesMenuData = useBalancesMenuData();
  const dashboardMenuData = useDashboardMenuData();
  const gasHistoryMenuData = useGasHistoryMenuData();
  const paymentsMenuData = usePaymentsMenuData();
  const reportsMenuData = useReportsMenuData();
  const settingsMenuData = useSettingsMenuData();
  const settlementsMenuData = useSettlementsMenuData();
  const subscriptionMenuData = useSubscriptionsMenuData();
  return useMemo(
    () => ({
      path: '/',
      children: [
        {
          key: 'statistics-group',
          name: 'Divider',
          type: 'group',
          children: [...dashboardMenuData, ...balancesMenuData],
        },
        {
          key: 'main-group',
          name: 'Divider',
          type: 'group',
          children: [...paymentsMenuData, ...subscriptionMenuData, ...settlementsMenuData],
        },
        {
          key: 'audit-group',
          name: 'Divider',
          type: 'group',
          children: [...auditMenuData, ...gasHistoryMenuData, ...reportsMenuData],
        },
        {
          key: 'settings-group',
          name: 'Divider',
          type: 'group',
          children: [...settingsMenuData],
        },
      ],
    }),
    [
      dashboardMenuData,
      balancesMenuData,
      paymentsMenuData,
      subscriptionMenuData,
      settlementsMenuData,
      auditMenuData,
      gasHistoryMenuData,
      reportsMenuData,
      settingsMenuData,
    ],
  );
}
