import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nFeatureSettlementReconciliations } from '@/generated/i18n/i18n';

const SettlementReconciliationsReportParametersTitle: React.FC = React.memo(() => (
  <FormattedMessage id={I18nFeatureSettlementReconciliations.COMPONENTS_RECONCILIATION_EXPORT_REPORT_TITLE} />
));

export default SettlementReconciliationsReportParametersTitle;
