import { PlusCircleOutlined } from '@ant-design/icons';
import { Modal, Typography } from 'antd';
import React, { useMemo } from 'react';

import { FormattedMessage, FormattedMessageWithMarkup, Operation } from '@/components';
import { useAPIKeysActions } from '@/features/company-settings/hooks';
import type { APIKeyFull } from '@/features/company-settings/types';
import { NetworkLabel } from '@/features/dictionary/blockchain/components';
import { useSelectedNetwork } from '@/features/dictionary/blockchain/hooks';
import { I18nFeatureCompanySettings } from '@/generated/i18n/i18n';
import { useFormStateVisible, useNotification } from '@/hooks';
import { withSuppressError } from '@/infrastructure/utils/functions';
import { goalReached, YMGoals } from '@/infrastructure/ym';

import type { OperationAddAPIKeyProps } from './types';

const { Paragraph } = Typography;

const notificationOptions = {
  error: () => <FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_ADD_API_KEY_MESSAGE_ERROR} />,
};

const OperationAddAPIKey: React.FC<OperationAddAPIKeyProps> = ({ 'data-test': dataTest, mode = 'inline' }) => {
  const { withNotification } = useNotification();
  const { network } = useSelectedNetwork();
  const { addAPIKey } = useAPIKeysActions(network);
  const formControl = useFormStateVisible<APIKeyFull>(undefined);

  const disabledMessage = useMemo(() => {
    switch (addAPIKey.unavailabilityReason) {
      case 'keys-limit':
        return <FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_ADD_API_KEY_ERRORS_KEYS_LIMIT} />;
      case 'no-data':
        return <FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_ADD_API_KEY_ERRORS_NO_DATA} />;
      default:
        return undefined;
    }
  }, [addAPIKey.unavailabilityReason]);

  const doCreate = useMemo(
    () =>
      withSuppressError(
        withNotification(async () => {
          const result = await addAPIKey.act(network);
          formControl.show(result);
          goalReached(YMGoals.API_KEY_GENERATED);
        }, notificationOptions),
      ),
    [addAPIKey, formControl, network, withNotification],
  );
  const doFinish = formControl.hide;

  return (
    <>
      {formControl.visible && formControl.state && (
        <Modal
          open
          maskClosable={false}
          cancelText={null}
          title={
            <FormattedMessage
              id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_ADD_API_KEY_KEY_MODAL_TITLE_GENERATED}
            />
          }
          closable
          onOk={doFinish}
          onCancel={doFinish}
        >
          <FormattedMessageWithMarkup
            id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_ADD_API_KEY_KEY_MODAL_DESCRIPTION}
            values={{
              key: (
                <Paragraph
                  data-test={dataTest && `${dataTest}-modal-key`}
                  className="ym-hide-content"
                  style={{ textAlign: 'center' }}
                  strong
                  copyable
                >
                  {formControl.state.key}
                </Paragraph>
              ),
              secret: (
                <Paragraph
                  data-test={dataTest && `${dataTest}-modal-secret`}
                  className="ym-hide-content"
                  style={{ textAlign: 'center' }}
                  strong
                  copyable
                >
                  {formControl.state.secret}
                </Paragraph>
              ),
              network: (
                <NetworkLabel
                  data-test={dataTest && `${dataTest}-modal-network`}
                  value={formControl.state.network}
                  noSwitcher
                />
              ),
            }}
          />
        </Modal>
      )}
      <Operation
        title={<FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_ADD_API_KEY_TITLE} />}
        icon={<PlusCircleOutlined />}
        onClick={doCreate}
        disabledMessage={disabledMessage}
        disabled={!addAPIKey.available}
        inProgress={addAPIKey.inAction}
        data-test={dataTest}
        mode={mode}
      />
    </>
  );
};

const OperationAddAPIKeyMemo = React.memo(OperationAddAPIKey);

export default OperationAddAPIKeyMemo;
