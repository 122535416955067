import type { Layout } from './types';

export const defaultDialogFormLayout: Layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 26 },
};

export const defaultDialogFormTailLayout: Layout = {
  wrapperCol: { offset: 6, span: 27 },
};

export const defaultPageFormLayout: Layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

export const defaultPageFormTailLayout: Layout = {
  wrapperCol: { sm: { offset: 6, span: 14 } },
};

export const noLabelFormLayout: Layout = {
  labelCol: { span: 0 },
  wrapperCol: { span: 24 },
};
