import { useMemo } from 'react';

import type { AppRootState, AppAsyncThunkAction } from '@/app/store';
import type { LoadingListDataState, ListParametersState } from '@/infrastructure/model/list/types';
import { storedListDataError } from '@/infrastructure/model/list/utils';

import useAppListData from './useAppListData';

import type { UseAppListData } from './useAppListData';

export const noIdNestedListFetchResult = storedListDataError('noParentId');

export default function useAppNestedListData<
  Value,
  Filter,
  SortBy extends string,
  FetchResult = LoadingListDataState<Value>,
>(
  // this function should be fail-safe
  fetchActionFactoryCreator:
    | ((parentId: string) => (force: boolean) => AppAsyncThunkAction<FetchResult, unknown>)
    | ((parentId: string) => (force: boolean) => Promise<FetchResult>),
  parametersSelectorCreator: (
    parentId: string | undefined,
  ) => (state: AppRootState) => ListParametersState<Filter, SortBy>,
  dataFetchingSelectorCreator: (parentId: string) => (state: AppRootState) => boolean,
  dataSelectorCreator: (parentId: string) => (state: AppRootState) => LoadingListDataState<Value>,
  noParentIdResult: FetchResult,
  parentId: string | undefined,
): UseAppListData<Value, Filter, SortBy, FetchResult> {
  const fetchActionFactory = useMemo(
    () => (parentId ? fetchActionFactoryCreator(parentId) : async () => Promise.resolve(noParentIdResult)),
    [fetchActionFactoryCreator, noParentIdResult, parentId],
  );
  const dataSelector = useMemo(
    () => (parentId ? dataSelectorCreator(parentId) : () => noIdNestedListFetchResult as LoadingListDataState<Value>),
    [dataSelectorCreator, parentId],
  );
  const parametersSelector = useMemo(() => parametersSelectorCreator(parentId), [parametersSelectorCreator, parentId]);
  const dataFetchingSelector = useMemo(
    () => (parentId ? dataFetchingSelectorCreator(parentId) : () => false),
    [dataFetchingSelectorCreator, parentId],
  );
  return useAppListData(fetchActionFactory, parametersSelector, dataFetchingSelector, dataSelector);
}
