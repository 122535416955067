import { DatePicker, Form } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { I18nComponents } from '@/generated/i18n/i18n';
import { useLocaleSettings } from '@/hooks';

import FormattedMessage from '../FormattedMessage';
import ReadonlyDateTime from '../ReadonlyDateTime';

import type { DateTimeItemProps } from './types';
import type { Dayjs } from 'dayjs';
import type { Store, Rule } from 'rc-field-form/es/interface';

const DateTimeItemRaw = <Values extends Store = Store>({
  name,
  placeholder,
  readonly,
  required,
  messages,
  'data-test': dataTest,
  rules: baseRules = [],
  InputProps = {},
  ItemProps = {},
}: DateTimeItemProps<Values>) => {
  const { formatDateTime } = useLocaleSettings();
  const { formatMessage } = useIntl();
  const formatValue = useCallback((v?: Dayjs) => formatDateTime(v?.toDate()), [formatDateTime]);
  const rules = useMemo<Rule[]>(
    () => [
      ...(!readonly && ItemProps.required
        ? [
            {
              required: true,
              message: messages?.required ?? <FormattedMessage id={I18nComponents.DATE_TIME_REQUIRED} />,
            },
            ...baseRules,
          ]
        : []),
    ],
    [readonly, ItemProps.required, messages?.required, baseRules],
  );
  return (
    <Form.Item<Values>
      label={messages?.label || <FormattedMessage id={I18nComponents.DATE_TIME_LABEL} />}
      rules={rules}
      required={!readonly && (required || ItemProps.required)}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...ItemProps}
      name={name}
    >
      {readonly ? (
        <ReadonlyDateTime data-test={dataTest} />
      ) : (
        <DatePicker
          showTime
          format={formatValue}
          size="middle"
          placeholder={placeholder || formatMessage({ id: I18nComponents.DATE_TIME_PLACEHOLDER })}
          data-test={dataTest}
          {...InputProps}
        />
      )}
    </Form.Item>
  );
};

const DateTimeItem = React.memo(DateTimeItemRaw) as typeof DateTimeItemRaw;

export default DateTimeItem;
