import { DisconnectOutlined, StopOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { Tooltip, Typography } from 'antd';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Truncate from 'react-truncate-inside';

import { FormattedMessage, Operation } from '@/components';
import { withWeb3Connector } from '@/features/web3/hocs';
import { useWeb3Connector } from '@/features/web3/hooks';
import { I18nFeatureWeb3 } from '@/generated/i18n/i18n';
import { useDimensions, useStateMountSafe } from '@/hooks';
import { timeout, withLogError, withSuppressError } from '@/infrastructure/utils/functions';
import { cancellable } from '@/infrastructure/utils/ui';

import WalletConnect from '../WalletConnect';
import WalletInProgress from '../WalletInProgress';
import Web3ProviderIcon from '../Web3ProviderIcon';

import type { WalletOperationProps } from './types';
import type { Address } from 'viem';

const { Text } = Typography;

const styles = {
  buttonsContainer: css`
    display: flex;
    width: 100%;

    > span:nth-child(1) {
      display: flex !important;
      flex: 1;

      > button {
        display: flex !important;
      }
    }
  `,

  account: css`
    width: 100%;
    white-space: nowrap;
    text-align: center;

    > div {
      padding-left: 10px;
      width: calc(100% - 100px);
      display: inline-block;
    }
  `,
};

const WalletOperation: React.FC<WalletOperationProps> = ({
  'data-test': dataTest,
  mainAction,
  onlyLocal,
  supportCancellation,
  showAccount,
  disabled,
  disabledMessage,
  requiredChain,
  requiredAccount: baseRequiredAccount,
  inProgress: baseInProgress,
  withConnect,
  inProgressMode,
  inProgressMessages,
}) => {
  const navigate = useNavigate();
  const baseRequiredAccountValue =
    typeof baseRequiredAccount === 'string' ? baseRequiredAccount : baseRequiredAccount?.value;

  const [requiredAccount, setRequiredAccount] = useStateMountSafe(baseRequiredAccountValue);
  const [ref, dimension] = useDimensions();
  useEffect(() => {
    if (!baseRequiredAccountValue) {
      return;
    }
    try {
      setRequiredAccount(baseRequiredAccountValue);
    } catch (e) {
      console.warn(`Unable to parse address ${baseRequiredAccountValue}`);
    }
  });
  const { connector, account, client, chainId, disconnectable, isConnecting, refresh } = useWeb3Connector();
  const clientRef = useRef(client);
  clientRef.current = client;

  const [onCancel, setOnCancel] = useStateMountSafe<(() => void) | undefined>();

  const doAct = useCallback(
    async (params: { account?: Address; chainId?: number }) => {
      const cancellationPromise = supportCancellation ? cancellable((cancel) => setOnCancel(() => cancel)) : undefined;

      const localClient = clientRef.current;
      if (mainAction.withClient && !localClient) {
        await timeout(500);
      }
      const client = clientRef.current;

      if (!mainAction.withClient || client) {
        await mainAction
          .onAction({ ...params, client: client! }, cancellationPromise)
          .finally(() => setOnCancel(undefined));
      }
    },
    [mainAction, setOnCancel, supportCancellation],
  );

  const doDisconnect = useMemo(
    () =>
      withSuppressError(async () => {
        await connector.disconnect();
        await refresh();
        if (await connector.isAuthorized()) {
          // dirty hack: we are reloading the page if connector is still "authorized" when disconnect called
          navigate(0);
        }
      }),
    [connector, navigate, refresh],
  );

  const onAction = useMemo(() => withLogError(async () => doAct({ account, chainId })), [account, chainId, doAct]);

  const onConnect = useMemo(
    () =>
      withSuppressError(async () => {
        const acc = await refresh();
        if (mainAction.runOnConnect) {
          await doAct(acc ?? {});
        }
      }),
    [doAct, mainAction.runOnConnect, refresh],
  );

  const inProgress = useMemo(() => baseInProgress || isConnecting, [baseInProgress, isConnecting]);

  const doSwitchNetwork = useCallback(async () => {
    if (requiredChain && connector.switchChain) {
      await connector.switchChain({ chainId: requiredChain.id });
    }
  }, [connector, requiredChain]);

  if (!account) {
    return (
      <WalletConnect
        data-test={dataTest && `${dataTest}-connect`}
        disabled={disabled}
        onlyLocal={onlyLocal}
        isConnecting={isConnecting}
        connectorId={connector.id}
        onConnect={onConnect}
        requiredAccount={requiredAccount}
        withConnect={withConnect}
        inProgressMode={inProgressMode}
      />
    );
  }

  if (requiredChain && chainId !== requiredChain.id) {
    if (connector.switchChain) {
      return (
        <WalletOperation
          data-test={dataTest}
          showAccount={showAccount}
          mainAction={{
            onAction: doSwitchNetwork,
            title: (
              <FormattedMessage
                id={I18nFeatureWeb3.COMPONENTS_WALLET_OPERATION_SWITCH_NETWORK_TITLE}
                values={{ network: requiredChain.network }}
              />
            ),
          }}
          inProgressMode={inProgressMode}
        />
      );
    }
    return (
      <WalletOperation
        data-test={dataTest}
        mainAction={mainAction}
        disabled
        showAccount={showAccount}
        disabledMessage={
          <FormattedMessage
            id={I18nFeatureWeb3.COMPONENTS_WALLET_OPERATION_SWITCH_NETWORK_MANUAL_TITLE}
            values={{ network: requiredChain.network }}
          />
        }
        inProgressMode={inProgressMode}
      />
    );
  }

  if (requiredAccount && account.toLowerCase() !== requiredAccount.toLowerCase()) {
    return (
      <WalletOperation
        data-test={dataTest}
        mainAction={mainAction}
        disabled
        showAccount={showAccount}
        disabledMessage={
          (typeof baseRequiredAccount !== 'string' && baseRequiredAccount?.title) || (
            <FormattedMessage
              id={I18nFeatureWeb3.COMPONENTS_WALLET_OPERATION_SWITCH_ACCOUNT_MANUAL_TITLE}
              values={{ account: requiredAccount }}
            />
          )
        }
        inProgressMode={inProgressMode}
      />
    );
  }

  if (inProgress && inProgressMode === 'view') {
    return (
      <WalletInProgress
        data-test={dataTest}
        connector={connector}
        cancel={onCancel && { onAction: onCancel, title: inProgressMessages?.cancel }}
        message={
          inProgressMessages?.Description ? (
            <inProgressMessages.Description provider={connector.name} account={account} />
          ) : (
            <FormattedMessage
              id={I18nFeatureWeb3.COMPONENTS_WALLET_CONNECT_DESCRIPTION}
              values={{ provider: connector.name }}
            />
          )
        }
      />
    );
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div className={styles.buttonsContainer}>
        <Operation
          ButtonProps={{ size: 'large' }}
          title={mainAction.title}
          style={{
            flex: 1,
            alignItems: 'center',
            marginLeft: 'auto',
            display: 'flex',
            justifyContent: 'flex-start',
            ...(disconnectable || (supportCancellation && onCancel)
              ? { borderTopRightRadius: 0, borderBottomRightRadius: 0 }
              : {}),
          }}
          data-test={mainAction['data-test'] || (dataTest && `${dataTest}-act`)}
          inProgress={inProgress}
          disabled={disabled}
          disabledMessage={disabledMessage}
          icon={<Web3ProviderIcon value={connector.id} style={{ display: 'flex' }} />}
          onClick={onAction}
          mode="button"
        />
        {supportCancellation && onCancel && (
          <Operation
            ButtonProps={{ size: 'large' }}
            style={!disconnectable ? { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 } : undefined}
            title={
              (typeof supportCancellation === 'object'
                && 'title' in supportCancellation
                && supportCancellation.title) || (
                <FormattedMessage id={I18nFeatureWeb3.COMPONENTS_WALLET_OPERATION_CANCEL_TITLE} />
              )
            }
            data-test={dataTest && `${dataTest}-cancel`}
            inProgress={isConnecting}
            icon={<StopOutlined />}
            onClick={onCancel}
            mode="inline"
          />
        )}
        {disconnectable && (
          <Operation
            ButtonProps={{ size: 'large' }}
            style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
            title={
              <FormattedMessage
                id={I18nFeatureWeb3.COMPONENTS_WALLET_OPERATION_DISCONNECT_TITLE}
                values={{ provider: connector.name }}
              />
            }
            data-test={dataTest && `${dataTest}-disconnect`}
            icon={<DisconnectOutlined />}
            onClick={doDisconnect}
            mode="inline"
          />
        )}
      </div>
      {showAccount && (
        <Tooltip
          title={
            (typeof showAccount === 'object' && 'tooltip' in showAccount && showAccount.tooltip) || (
              <FormattedMessage id={I18nFeatureWeb3.COMPONENTS_WALLET_OPERATION_ACCOUNT_TOOLTIP} />
            )
          }
        >
          <Text type="secondary" className={styles.account}>
            <FormattedMessage id={I18nFeatureWeb3.COMPONENTS_WALLET_OPERATION_ACCOUNT_TITLE} tagName="span" />
            <div ref={ref} data-test={dataTest && `${dataTest}-account`}>
              {dimension && (
                <Truncate
                  data-test={dataTest && `${dataTest}-account`}
                  text={account}
                  offset={8}
                  width={dimension.offsetWidth}
                />
              )}
            </div>
          </Text>
        </Tooltip>
      )}
    </div>
  );
};

const WalletOperationWithConnector = withWeb3Connector(WalletOperation);

const WalletOperationMemo = React.memo(WalletOperationWithConnector) as typeof WalletOperationWithConnector;

export default WalletOperationMemo;
