import { Space } from 'antd';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { FormattedMessage, PageCard, PageContainer } from '@/components';
import { ReportSchedulesIcon } from '@/features/report-schedules/components';
import { ReportsIcon } from '@/features/reports/components';
import { I18nPageReports } from '@/generated/i18n/i18n';
import { asType } from '@/infrastructure/utils/ts';
import ReportsBreadcrumb from '@/pages/reports/all/components/ReportsBreadcrumb';
import { reportSchedulesLink, reportsLink } from '@/pages/reports/routes';

import type { ReportPageMode, ReportPageContainerProps } from './types';

const links: Record<ReportPageMode, string> = {
  reports: reportsLink(),
  'report-schedules': reportSchedulesLink(),
};

const ReportPageContainer: React.FC<ReportPageContainerProps> = React.memo(
  ({ 'data-test': dataTest, mode, children }) => {
    const navigate = useNavigate();
    const doChangeTab = useCallback((newMode: string) => navigate(links[newMode as ReportPageMode]), [navigate]);

    return (
      <PageContainer
        data-test={dataTest}
        breadcrumb={<ReportsBreadcrumb data-test={dataTest && `${dataTest}-breadcrumb`} disabled />}
        PageContainerProps={{
          tabActiveKey: mode,
          onTabChange: doChangeTab,
          tabList: [
            {
              tab: (
                <Space size={2}>
                  <ReportsIcon />
                  <FormattedMessage id={I18nPageReports.ALL_COMPONENTS_BREADCRUMB} />
                </Space>
              ),
              key: asType<ReportPageMode>('reports'),
            },
            {
              tab: (
                <Space size={2}>
                  <ReportSchedulesIcon />
                  <FormattedMessage id={I18nPageReports.REPORT_SCHEDULES_COMPONENTS_BREADCRUMB} />
                </Space>
              ),
              key: asType<ReportPageMode>('report-schedules'),
            },
          ],
        }}
      >
        <PageCard cardSize="big">{children}</PageCard>
      </PageContainer>
    );
  },
);

export default ReportPageContainer;
