/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3276
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BigIntRageAPIModel
 */
export interface BigIntRageAPIModel {
    /**
     * 
     * @type {string}
     * @memberof BigIntRageAPIModel
     */
    gte?: string;
    /**
     * 
     * @type {string}
     * @memberof BigIntRageAPIModel
     */
    lte?: string;
}

/**
 * Check if a given object implements the BigIntRageAPIModel interface.
 */
export function instanceOfBigIntRageAPIModel(value: object): boolean {
    return true;
}

export function BigIntRageAPIModelFromJSON(json: any): BigIntRageAPIModel {
    return BigIntRageAPIModelFromJSONTyped(json, false);
}

export function BigIntRageAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): BigIntRageAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'gte': json['gte'] == null ? undefined : json['gte'],
        'lte': json['lte'] == null ? undefined : json['lte'],
    };
}

export function BigIntRageAPIModelToJSON(value?: BigIntRageAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'gte': value['gte'],
        'lte': value['lte'],
    };
}

