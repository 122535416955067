import { Descriptions, Space } from 'antd';
import BigNumber from 'bignumber.js';
import { useMemo } from 'react';
import React from 'react';

import { FormattedMessage, OperationRefresh } from '@/components';
import { AssetAmount, AssetIcon } from '@/features/dictionary/blockchain/components';
import { BlockchainLabel } from '@/features/dictionary/blockchain/components';
import { useMerchantWalletDistributeFees, usePayoutBalances } from '@/features/settlements/hooks';
import { I18nFeatureSettlementPayouts } from '@/generated/i18n/i18n';
import { mapStoredState } from '@/infrastructure/model';

import { EmptyCallDataView } from './components';

import type { PayoutSummaryViewProps } from './types';

const PayoutSummaryView: React.FC<PayoutSummaryViewProps> = ({ 'data-test': dataTest, asset, destinations }) => {
  const { balances } = usePayoutBalances();
  const source = useMemo(
    () => mapStoredState(balances, (data) => data.find(({ asset: { code } }) => code === asset)),
    [asset, balances],
  );

  const blockchain = source.data?.bc.blockchain;
  const payoutSum = useMemo(
    () => (destinations?.length ? BigNumber.sum(...destinations.map(({ amount }) => amount)) : BigNumber(0)),
    [destinations],
  );
  const {
    data: fee,
    loading: feeDataLoading,
    forceRefresh: refreshFee,
  } = useMerchantWalletDistributeFees(asset, payoutSum);

  const total = useMemo(() => (fee.data ? payoutSum.plus(fee.data) : undefined), [payoutSum, fee.data]);
  return (
    <Descriptions
      contentStyle={{ fontWeight: 'bold', overflow: 'hidden' }}
      title={<FormattedMessage id={I18nFeatureSettlementPayouts.COMPONENTS_CREATE_PAYOUT_FORM_SUMMARY_TITLE} />}
      column={1}
    >
      <Descriptions.Item
        label={<FormattedMessage id={I18nFeatureSettlementPayouts.COMPONENTS_CREATE_PAYOUT_FORM_SUMMARY_SOURCE} />}
      >
        {source.data ? (
          <Space>
            <AssetIcon
              data-test={dataTest && `${dataTest}-source`}
              type={source.data.asset.code}
              style={{ fontSize: 16 }}
            />
            <AssetAmount
              data-test={dataTest && `${dataTest}-available`}
              value={source.data.balance.value}
              assetId={source.data.balance.asset}
              withCurrency
              fraction={4}
            />
          </Space>
        ) : (
          <EmptyCallDataView data-test={dataTest && `${dataTest}-available`} />
        )}
      </Descriptions.Item>
      <Descriptions.Item
        label={<FormattedMessage id={I18nFeatureSettlementPayouts.COMPONENTS_CREATE_PAYOUT_FORM_SUMMARY_NETWORK} />}
      >
        {blockchain ? (
          <BlockchainLabel data-test={dataTest && `${dataTest}-blockchain`} bt={blockchain} mode="medium" />
        ) : (
          <EmptyCallDataView data-test={dataTest && `${dataTest}-blockchain`} />
        )}
      </Descriptions.Item>
      <Descriptions.Item
        label={
          <FormattedMessage id={I18nFeatureSettlementPayouts.COMPONENTS_CREATE_PAYOUT_FORM_SUMMARY_DESTINATIONS} />
        }
      >
        <span data-test={dataTest && `${dataTest}-destinations`}>{destinations?.length ?? 0}</span>
      </Descriptions.Item>
      <Descriptions.Item
        label={<FormattedMessage id={I18nFeatureSettlementPayouts.COMPONENTS_CREATE_PAYOUT_FORM_SUMMARY_PAYOUTS} />}
      >
        {source.data && payoutSum.isGreaterThan(0) ? (
          <Space>
            <AssetAmount
              data-test={dataTest && `${dataTest}-payouts`}
              value={payoutSum}
              assetId={source.data.asset.code}
              withCurrency
              fraction={4}
            />
          </Space>
        ) : (
          <EmptyCallDataView data-test={dataTest && `${dataTest}-payouts`} />
        )}
      </Descriptions.Item>
      <Descriptions.Item
        label={<FormattedMessage id={I18nFeatureSettlementPayouts.COMPONENTS_CREATE_PAYOUT_FORM_SUMMARY_FEE} />}
      >
        {feeDataLoading ? (
          <FormattedMessage id={I18nFeatureSettlementPayouts.COMPONENTS_CREATE_PAYOUT_FORM_SUMMARY_FEE_WAIT} />
        ) : fee.data && source.data ? (
          <AssetAmount
            data-test={dataTest && `${dataTest}-fee`}
            value={fee.data}
            assetId={source.data.asset.code}
            withCurrency
          />
        ) : fee.error ? (
          <Space>
            <FormattedMessage id={I18nFeatureSettlementPayouts.COMPONENTS_CREATE_PAYOUT_FORM_SUMMARY_FEE_ERROR} />
            <OperationRefresh data-test={dataTest && `${dataTest}-opFeeRefresh`} refresh={refreshFee} mode="icon" />
          </Space>
        ) : (
          <EmptyCallDataView data-test={dataTest && `${dataTest}-fee`} />
        )}
      </Descriptions.Item>
      <Descriptions.Item
        label={<FormattedMessage id={I18nFeatureSettlementPayouts.COMPONENTS_CREATE_PAYOUT_FORM_SUMMARY_TOTAL} />}
      >
        {source.data && total ? (
          <AssetAmount
            data-test={dataTest && `${dataTest}-total`}
            value={total}
            assetId={source.data.asset.code}
            withCurrency
          />
        ) : (
          <EmptyCallDataView data-test={dataTest && `${dataTest}-total`} />
        )}
      </Descriptions.Item>
    </Descriptions>
  );
};

const PayoutSummaryViewMemo = React.memo(PayoutSummaryView);

export default PayoutSummaryViewMemo;
