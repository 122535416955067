import type { EmptyObject } from '@/infrastructure/utils/ts';

import {
  withWeb3AuthPrivateKeySecure,
  withWeb3AuthRecoveryKeySecure,
  withWeb3AuthDeviceKeyMemorize,
  withWeb3AuthConfirmation,
  withWeb3AuthConsistencyGuard,
  withWeb3AuthInitGuard,
} from './hocs';

import type React from 'react';

// prettier-ignore
const withWeb3AuthLifecycle = <T extends EmptyObject>(Component: React.ComponentType<T>): React.FC<T> =>
  withWeb3AuthInitGuard(
    withWeb3AuthRecoveryKeySecure(
      withWeb3AuthDeviceKeyMemorize(
        withWeb3AuthPrivateKeySecure(
          withWeb3AuthConfirmation(
            withWeb3AuthConsistencyGuard(
              Component
            )
          )
        )
      )
    )
  );

export default withWeb3AuthLifecycle;
