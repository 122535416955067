import React from 'react';

import { useAppSelector } from '@/app/hooks';
import { FilterForm } from '@/components';
import { BlockchainItem, NetworkTypeItem } from '@/features/dictionary/blockchain/components';
import { makeSelectSelectedNetwork } from '@/features/dictionary/blockchain/selectors';
import type { GasHistoryFilterPredicate } from '@/features/gas-history/types';
import { nameof } from '@/infrastructure/utils/ts';

import {
  GasHistoryAddressFilterItem,
  GasHistoryCreatedAtRangeFilterItem,
  GasHistoryResultFilterItem,
  GasHistorySponsorAddressFilterItem,
} from './components';

import type { GasHistoryFilterFormProps } from './types';

const fields = [
  nameof<GasHistoryFilterPredicate>('btIn'),
  nameof<GasHistoryFilterPredicate>('addressLike'),
  nameof<GasHistoryFilterPredicate>('createdAtRange'),
  nameof<GasHistoryFilterPredicate>('createdAtRangeRelative'),
  nameof<GasHistoryFilterPredicate>('resultEq'),
  nameof<GasHistoryFilterPredicate>('addressLike'),
  nameof<GasHistoryFilterPredicate>('sponsorAddressLike'),
];

const selectNetwork = makeSelectSelectedNetwork();

const GasHistoryFilterFormRaw: React.FC<GasHistoryFilterFormProps<GasHistoryFilterPredicate>> = (props) => {
  const { 'data-test': dataTest, readonly, hidden } = props;
  const network = useAppSelector(selectNetwork);
  return (
    <FilterForm fields={fields} {...props}>
      <BlockchainItem<GasHistoryFilterPredicate>
        data-test={dataTest && `${dataTest}-blockchain`}
        name={nameof<GasHistoryFilterPredicate>('btIn')}
        multiselect
        readonly={readonly}
        network={!readonly ? network : undefined}
        required={false}
      />
      <GasHistoryCreatedAtRangeFilterItem<GasHistoryFilterPredicate>
        data-test={dataTest && `${dataTest}-createdAt`}
        name={nameof<GasHistoryFilterPredicate>('createdAtRange')}
        readonly={readonly}
        relativeUpdateKey="createdAtRangeRelative"
      />
      <GasHistoryResultFilterItem<GasHistoryFilterPredicate>
        data-test={dataTest && `${dataTest}-result`}
        name={nameof<GasHistoryFilterPredicate>('resultEq')}
        readonly={readonly}
      />
      <GasHistoryAddressFilterItem<GasHistoryFilterPredicate>
        data-test={dataTest && `${dataTest}-address`}
        name={nameof<GasHistoryFilterPredicate>('addressLike')}
        readonly={readonly}
      />
      <GasHistorySponsorAddressFilterItem<GasHistoryFilterPredicate>
        data-test={dataTest && `${dataTest}-sponsor`}
        name={nameof<GasHistoryFilterPredicate>('sponsorAddressLike')}
        readonly={readonly}
      />
      {!hidden?.network && (
        <NetworkTypeItem<GasHistoryFilterPredicate>
          data-test={dataTest && `${dataTest}-network`}
          name={nameof<GasHistoryFilterPredicate>('networkEq')}
          mode="select"
          readonly={readonly}
        />
      )}
    </FilterForm>
  );
};

const GasHistoryFilterForm = React.memo(GasHistoryFilterFormRaw);

export default GasHistoryFilterForm;
