import { PictureOutlined } from '@ant-design/icons';
import { Col, Form, Row, Space } from 'antd';

import { FormattedMessage } from '@/components';
import type { PaymentsBranding } from '@/features/branding/types';
import { withFeatureToggleGuard } from '@/features/feature-toggle/hocs';
import { I18nFeatureBranding } from '@/generated/i18n/i18n';
import { useStateMountSafe } from '@/hooks';
import { withSuppressPromise } from '@/infrastructure/utils/functions';
import { nameof } from '@/infrastructure/utils/ts';

import DataBlockContainer from '../DataBlockContainer';

import { LogoPositionItem, LogoURLItem, LogoVisibilityItem } from './components';

import type { LogoBlockProps } from './types';
import type React from 'react';

const LogoBlock: React.FC<LogoBlockProps> = ({ 'data-test': dataTest, disabled }) => {
  const [required, setRequired] = useStateMountSafe<boolean>();
  return (
    <DataBlockContainer
      icon={<PictureOutlined />}
      title={
        <Space>
          <LogoVisibilityItem data-test={dataTest && `${dataTest}-visible`} disabled={disabled} />
          <FormattedMessage id={I18nFeatureBranding.COMPONENTS_DATA_EDIT_PANEL_LOGO_BLOCK_TITLE} />
        </Space>
      }
      help={<FormattedMessage id={I18nFeatureBranding.COMPONENTS_DATA_EDIT_PANEL_LOGO_BLOCK_HELP} />}
    >
      <Row style={!required ? { display: 'none' } : undefined}>
        <Col span={24}>
          <LogoURLItem data-test={dataTest && `${dataTest}-url`} disabled={disabled || !required} />
        </Col>
        <Col span={24}>
          <Space>
            <FormattedMessage
              id={I18nFeatureBranding.COMPONENTS_DATA_EDIT_PANEL_LOGO_BLOCK_POSITION_ITEM_LABEL}
              tagName="span"
            />
            <LogoPositionItem data-test={dataTest && `${dataTest}-position`} disabled={disabled || !required} />
          </Space>
        </Col>
      </Row>
      <Form.Item
        noStyle
        hidden
        dependencies={[[nameof<PaymentsBranding>('logo'), nameof<NonNullable<PaymentsBranding['logo']>>('visible')]]}
      >
        {({ getFieldValue, validateFields }) => {
          setTimeout(() => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const value = !!getFieldValue([
              nameof<PaymentsBranding>('logo'),
              nameof<NonNullable<PaymentsBranding['logo']>>('visible'),
            ]);
            setRequired(value);
            setTimeout(() => {
              withSuppressPromise(validateFields)([
                [nameof<PaymentsBranding>('logo'), nameof<NonNullable<PaymentsBranding['logo']>>('url')],
                [nameof<PaymentsBranding>('logo'), nameof<NonNullable<PaymentsBranding['logo']>>('position')],
              ]);
            }, 200);
          });
          return null;
        }}
      </Form.Item>
    </DataBlockContainer>
  );
};

const Hidden = () => (
  <Col span={24} style={{ display: 'none' }}>
    <LogoVisibilityItem disabled />
    <LogoURLItem disabled />
    <LogoPositionItem disabled />
  </Col>
);

const LogoBlockHOC = withFeatureToggleGuard('enableBrandingLogo', true, Hidden)(LogoBlock);

export default LogoBlockHOC;
