import { configureStore, isPlain } from '@reduxjs/toolkit';
import BigNumber from 'bignumber.js';

import authReducer from '@/features/auth/reducer';
import { NAMESPACE as authName } from '@/features/auth/types';
import brandingReducer from '@/features/branding/reducer';
import { NAMESPACE as brandingName } from '@/features/branding/types';
import companyReducer from '@/features/company/reducer';
import { NAMESPACE as companyName } from '@/features/company/types';
import companySettingsReducer from '@/features/company-settings/reducer';
import { NAMESPACE as companySettingsName } from '@/features/company-settings/types';
import blockchainReducer from '@/features/dictionary/blockchain/reducer';
import { NAMESPACE as blockchainName } from '@/features/dictionary/blockchain/types';
import emailReducer from '@/features/email/reducer';
import { NAMESPACE as emailName } from '@/features/email/types';
import featureToggleReducer from '@/features/feature-toggle/reducer';
import { NAMESPACE as featureToggleName } from '@/features/feature-toggle/types';
import gasHistoryReducer from '@/features/gas-history/reducer';
import { NAMESPACE as gasHistoryName } from '@/features/gas-history/types';
import gasWalletsReducer from '@/features/gas-wallets/reducer';
import { NAMESPACE as gasWalletsName } from '@/features/gas-wallets/types';
import { globalInit } from '@/features/global/actions';
import globalReducer from '@/features/global/reducer';
import { NAMESPACE as globalName } from '@/features/global/types';
import i18nReducer from '@/features/i18n/reducer';
import { NAMESPACE as i18nName } from '@/features/i18n/types';
import maintenanceReducer from '@/features/maintenance/reducer';
import { NAMESPACE as maintenanceName } from '@/features/maintenance/types';
import merchantWalletBalanceReducer from '@/features/merchant-wallet-balance/reducer';
import { NAMESPACE as merchantWalletBalanceName } from '@/features/merchant-wallet-balance/types';
import merchantWalletTransfersReducer from '@/features/merchant-wallet-transfers/reducer';
import { NAMESPACE as merchantWalletTransfersName } from '@/features/merchant-wallet-transfers/types';
import merchantWalletsReducer from '@/features/merchant-wallets/reducer';
import { NAMESPACE as merchantWalletsName } from '@/features/merchant-wallets/types';
import paymentTransactionsReducer from '@/features/payment-transactions/reducer';
import { NAMESPACE as paymentTransactionsName } from '@/features/payment-transactions/types';
import paymentsReducer from '@/features/payments/reducer';
import { NAMESPACE as paymentsName } from '@/features/payments/types';
import recaptchaReducer from '@/features/recaptcha/reducer';
import { NAMESPACE as recaptchaName } from '@/features/recaptcha/types';
import reportSchedulesReducer from '@/features/report-schedules/reducer';
import { NAMESPACE as reportSchedulesName } from '@/features/report-schedules/types';
import reportsReducer from '@/features/reports/reducer';
import { NAMESPACE as reportsName } from '@/features/reports/types';
import settlementReconciliationsReducer from '@/features/settlement-reconciliations/reducer';
import { NAMESPACE as settlementReconciliationsName } from '@/features/settlement-reconciliations/types';
import settlementHistoryReducer from '@/features/settlements/reducer';
import { NAMESPACE as settlementHistoryName } from '@/features/settlements/types';
import statisticsReducer from '@/features/statistics/reducer';
import { NAMESPACE as statisticsName } from '@/features/statistics/types';
import subscriptionChargesReducer from '@/features/subscription-charges/reducer';
import { NAMESPACE as subscriptionChargesName } from '@/features/subscription-charges/types';
import subscriptionPlansReducer from '@/features/subscription-plans/reducer';
import { NAMESPACE as subscriptionPlansName } from '@/features/subscription-plans/types';
import subscriptionStatisticsReducer from '@/features/subscription-statistics/reducer';
import { NAMESPACE as subscriptionStatisticsName } from '@/features/subscription-statistics/types';
import subscriptionsReducer from '@/features/subscriptions/reducer';
import { NAMESPACE as subscriptionsName } from '@/features/subscriptions/types';
import userReducer from '@/features/user/reducer';
import { NAMESPACE as userName } from '@/features/user/types';

import listenerMiddleware from './listenerMiddleware';

export default () => {
  const store = configureStore({
    devTools: window.env.DEV_TOOLS_ENABLED ? { name: 'SMARTy Pay Merchant Dashboard' } : false,
    reducer: {
      [authName]: authReducer,
      [blockchainName]: blockchainReducer,
      [brandingName]: brandingReducer,
      [companyName]: companyReducer,
      [companySettingsName]: companySettingsReducer,
      [emailName]: emailReducer,
      [featureToggleName]: featureToggleReducer,
      [gasHistoryName]: gasHistoryReducer,
      [gasWalletsName]: gasWalletsReducer,
      [globalName]: globalReducer,
      [i18nName]: i18nReducer,
      [maintenanceName]: maintenanceReducer,
      [merchantWalletBalanceName]: merchantWalletBalanceReducer,
      [merchantWalletsName]: merchantWalletsReducer,
      [merchantWalletTransfersName]: merchantWalletTransfersReducer,
      [paymentsName]: paymentsReducer,
      [paymentTransactionsName]: paymentTransactionsReducer,
      [recaptchaName]: recaptchaReducer,
      [reportsName]: reportsReducer,
      [reportSchedulesName]: reportSchedulesReducer,
      [statisticsName]: statisticsReducer,
      [settlementHistoryName]: settlementHistoryReducer,
      [settlementReconciliationsName]: settlementReconciliationsReducer,
      [subscriptionChargesName]: subscriptionChargesReducer,
      [subscriptionPlansName]: subscriptionPlansReducer,
      [subscriptionStatisticsName]: subscriptionStatisticsReducer,
      [subscriptionsName]: subscriptionsReducer,
      [userName]: userReducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          // FIXME: https://github.com/reduxjs/redux-toolkit/issues/456
          isSerializable: (v: unknown) => isPlain(v) || v instanceof Date || v instanceof BigNumber,
        },
      }).prepend(listenerMiddleware.middleware),
  });
  store.dispatch(globalInit());
  return store;
};
