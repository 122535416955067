import React from 'react';

import { I18nComponents } from '@/generated/i18n/i18n';

import FormattedMessage from '../FormattedMessage';

import { DateTimeRangeRelativeEnum } from './types';

import type { DateTimeRelativeLabelProps } from './types';

export const i18n: Record<DateTimeRangeRelativeEnum, I18nComponents> = {
  [DateTimeRangeRelativeEnum.Today]: I18nComponents.DATE_TIME_RELATIVE_LABEL_VALUE_TODAY,
  [DateTimeRangeRelativeEnum.ThisWeek]: I18nComponents.DATE_TIME_RELATIVE_LABEL_VALUE_THISWEEK,
  [DateTimeRangeRelativeEnum.ThisMonth]: I18nComponents.DATE_TIME_RELATIVE_LABEL_VALUE_THISMONTH,
  [DateTimeRangeRelativeEnum.LastDay]: I18nComponents.DATE_TIME_RELATIVE_LABEL_VALUE_LASTDAY,
  [DateTimeRangeRelativeEnum.LastWeek]: I18nComponents.DATE_TIME_RELATIVE_LABEL_VALUE_LASTWEEK,
  [DateTimeRangeRelativeEnum.LastMonth]: I18nComponents.DATE_TIME_RELATIVE_LABEL_VALUE_LASTMONTH,
};

const DateTimeRelativeLabel: React.FC<DateTimeRelativeLabelProps> = React.memo(({ 'data-test': dataTest, value }) => (
  <FormattedMessage data-test={dataTest} id={i18n[value]} />
));

export default DateTimeRelativeLabel;
