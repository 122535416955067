import storeHolder from '@/app/store.holder';
import { makeSelectAuthToken } from '@/features/auth/selectors';
import { channelLoginTrigger, logoutActionTrigger } from '@/features/auth/shared-actions';
import type { JWTTokenWithInfo } from '@/infrastructure/security/types';
import { withTimeout } from '@/infrastructure/utils/functions';
import { asType } from '@/infrastructure/utils/ts';

export interface BackofficeUICommandLogout {
  type: 'logout';
}

export interface BackofficeUICommandTokenRequest {
  type: 'jwt-request';
}

export interface BackofficeUICommandLogin {
  type: 'login';
  token: JWTTokenWithInfo;
}

export interface BackofficeUICallbackReady {
  type: 'jwt-response';
  token?: JWTTokenWithInfo;
}

export type BackofficeUICommand =
  | BackofficeUICommandTokenRequest
  | BackofficeUICommandLogin
  | BackofficeUICommandLogout;
export type BackofficeUICallback = BackofficeUICallbackReady;

const channelHolder: { channel?: BroadcastChannel } = {};
const getChannel = () => {
  if (!channelHolder.channel) {
    try {
      const channel = new BroadcastChannel('ncps-backoffice-ui');
      channel.addEventListener('message', (event) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const eventData: BackofficeUICommand | undefined = event.data;

        if (eventData?.type === 'logout') {
          storeHolder.store?.dispatch(logoutActionTrigger({ silent: true }));
        } else if (eventData?.type === 'login') {
          storeHolder.store?.dispatch(channelLoginTrigger({ token: eventData.token }));
        } else if (eventData?.type === 'jwt-request') {
          const token = storeHolder.store ? makeSelectAuthToken()(storeHolder.store.getState()).data : undefined;
          if (token) {
            channel.postMessage(asType<BackofficeUICallbackReady>({ type: 'jwt-response', token }));
          }
        }
      });
      channelHolder.channel = channel;
    } catch (e) {
      console.warn(e);
    }
  }
  return channelHolder.channel;
};

export const cleanUp = () => {
  getChannel()?.postMessage({ type: 'logout' });
};

export const send = (token: JWTTokenWithInfo) => {
  getChannel()?.postMessage(asType<BackofficeUICommand>({ type: 'login', token }));
};

export const load = async () => {
  const channel = getChannel();
  if (channel) {
    return new Promise<JWTTokenWithInfo | undefined>((resolve) => {
      let listener: ((event: MessageEvent) => void) | undefined;
      const channelAwait = new Promise<JWTTokenWithInfo | undefined>((_resolve) => {
        listener = (event: MessageEvent) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const callbackData: BackofficeUICallback | undefined = event.data;
          if (callbackData?.type === 'jwt-response') {
            _resolve(callbackData.token);
          }
        };
        channel.addEventListener('message', listener);
        channel.postMessage(asType<BackofficeUICommand>({ type: 'jwt-request' }));
      });
      withTimeout(async () => {
        try {
          const token = await channelAwait;
          resolve(token);
          if (listener) {
            channel.removeEventListener('message', listener);
          }
        } catch (e) {
          resolve(undefined);
        }
      }, 2_000).catch((e) => {
        resolve(undefined);
        if ((e as Error).message !== 'Request timeout') {
          console.warn('Unable to get jwt over channel', e);
        } else if (listener) {
          channel.removeEventListener('message', listener);
        }
      });
    });
  }
  return undefined;
};
