import ms from 'ms';
import { useIdleTimer } from 'react-idle-timer';

import { useIdleControl } from '@/features/global/hooks';
import { namedHOC } from '@/infrastructure/utils/react';

import type React from 'react';
import type { PropsWithChildren } from 'react';

const timeout = ms('1s');

const withIdleControl = <P extends PropsWithChildren<unknown>>(Component: React.FC<P>) =>
  namedHOC(
    Component,
    'WithIdleControl',
  )((props) => {
    const { markIdle, markActive } = useIdleControl();
    useIdleTimer({ onIdle: markIdle, onActive: markActive, timeout });
    return <Component {...props} />;
  });

export default withIdleControl;
