export const requiredParamsParser =
  (keys: readonly string[]) =>
  <K extends (typeof keys)[number]>(params: Partial<Record<K, string | undefined>>): Record<K, string> | undefined =>
    keys.reduce(
      (result, k) => {
        const key = k as K;
        if (!result) {
          return undefined;
        }
        const value = params[key];
        return !value || typeof value !== 'string' ? undefined : { ...result, [key]: value };
      },
      {} as Record<K, string> | undefined,
    );

export const optionalParamsParser =
  (keys: readonly string[]) =>
  <K extends (typeof keys)[number]>(
    params: Partial<Record<K, string | undefined>>,
  ): Partial<Record<K, string | undefined>> =>
    params;
