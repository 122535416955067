import { useCallback, useLayoutEffect, useState } from 'react';

import type { DimensionObject, MeasureElement, UseDimensionsArgs, UseDimensionsHook } from './types';
import type React from 'react';

const getDimensionNode = (node: HTMLElement, type: MeasureElement): HTMLElement | null =>
  type === 'self' ? node : node.parentElement;

const getDimensionObject = (node: HTMLElement): DimensionObject => {
  const rect: DOMRect | undefined = node.getBoundingClientRect();
  return {
    offsetWidth: node.offsetWidth,
    scrollWidth: node.scrollWidth,
    width: rect.width,
    height: rect.height,
    top: 'x' in rect ? rect.x : (rect as DOMRect).top,
    left: 'y' in rect ? rect.y : (rect as DOMRect).left,
    x: 'x' in rect ? rect.x : (rect as DOMRect).left,
    y: 'y' in rect ? rect.y : (rect as DOMRect).top,
    right: rect.right,
    bottom: rect.bottom,
  };
};

function useDimensions<T extends HTMLElement = HTMLElement>({
  liveMeasure = true,
  elementType = 'self',
}: UseDimensionsArgs = {}): UseDimensionsHook<T> {
  const [dimensions, setDimensions] = useState<DimensionObject | null>(null);
  const [node, setNode] = useState<T | null>(null);

  const ref: React.RefCallback<T> = useCallback((newNode: T | null) => {
    setNode(newNode);
  }, []);

  // eslint-disable-next-line consistent-return
  useLayoutEffect((): void | (() => void) => {
    const dimensionNode = node ? getDimensionNode(node, elementType) : null;
    if (dimensionNode) {
      const measure = (): number =>
        window.requestAnimationFrame(() => setDimensions(getDimensionObject(dimensionNode)));
      measure();

      const onResize = liveMeasure === true || liveMeasure === 'resize';
      const onScroll = liveMeasure === true || liveMeasure === 'scorll';
      if (onResize) {
        window.addEventListener('resize', measure);
      }
      if (onScroll) {
        window.addEventListener('scroll', measure);
      }

      if (onScroll || onResize) {
        return (): void => {
          if (onResize) {
            window.removeEventListener('resize', measure);
          }
          if (onScroll) {
            window.removeEventListener('scroll', measure);
          }
        };
      }
    }
  }, [elementType, liveMeasure, node]);

  return [ref, dimensions, node];
}

export default useDimensions;
