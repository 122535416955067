/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3276
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateCompanyKeyAPIModel
 */
export interface UpdateCompanyKeyAPIModel {
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyKeyAPIModel
     */
    key: string;
}

/**
 * Check if a given object implements the UpdateCompanyKeyAPIModel interface.
 */
export function instanceOfUpdateCompanyKeyAPIModel(value: object): boolean {
    if (!('key' in value)) return false;
    return true;
}

export function UpdateCompanyKeyAPIModelFromJSON(json: any): UpdateCompanyKeyAPIModel {
    return UpdateCompanyKeyAPIModelFromJSONTyped(json, false);
}

export function UpdateCompanyKeyAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateCompanyKeyAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'key': json['key'],
    };
}

export function UpdateCompanyKeyAPIModelToJSON(value?: UpdateCompanyKeyAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'key': value['key'],
    };
}

