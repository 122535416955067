/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3276
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { BlockchainNetworkTypeAPIModel } from './BlockchainNetworkTypeAPIModel';
import {
    BlockchainNetworkTypeAPIModelFromJSON,
    BlockchainNetworkTypeAPIModelFromJSONTyped,
    BlockchainNetworkTypeAPIModelToJSON,
} from './BlockchainNetworkTypeAPIModel';
import type { WithdrawalIntentStatusAPIModel } from './WithdrawalIntentStatusAPIModel';
import {
    WithdrawalIntentStatusAPIModelFromJSON,
    WithdrawalIntentStatusAPIModelFromJSONTyped,
    WithdrawalIntentStatusAPIModelToJSON,
} from './WithdrawalIntentStatusAPIModel';

/**
 * 
 * @export
 * @interface WithdrawalIntentPredicatesAPIModel
 */
export interface WithdrawalIntentPredicatesAPIModel {
    /**
     * 
     * @type {Array<string>}
     * @memberof WithdrawalIntentPredicatesAPIModel
     */
    ids?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalIntentPredicatesAPIModel
     */
    asset?: string;
    /**
     * 
     * @type {WithdrawalIntentStatusAPIModel}
     * @memberof WithdrawalIntentPredicatesAPIModel
     */
    status?: WithdrawalIntentStatusAPIModel;
    /**
     * 
     * @type {BlockchainNetworkTypeAPIModel}
     * @memberof WithdrawalIntentPredicatesAPIModel
     */
    network?: BlockchainNetworkTypeAPIModel;
}

/**
 * Check if a given object implements the WithdrawalIntentPredicatesAPIModel interface.
 */
export function instanceOfWithdrawalIntentPredicatesAPIModel(value: object): boolean {
    return true;
}

export function WithdrawalIntentPredicatesAPIModelFromJSON(json: any): WithdrawalIntentPredicatesAPIModel {
    return WithdrawalIntentPredicatesAPIModelFromJSONTyped(json, false);
}

export function WithdrawalIntentPredicatesAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): WithdrawalIntentPredicatesAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'ids': json['ids'] == null ? undefined : json['ids'],
        'asset': json['asset'] == null ? undefined : json['asset'],
        'status': json['status'] == null ? undefined : WithdrawalIntentStatusAPIModelFromJSON(json['status']),
        'network': json['network'] == null ? undefined : BlockchainNetworkTypeAPIModelFromJSON(json['network']),
    };
}

export function WithdrawalIntentPredicatesAPIModelToJSON(value?: WithdrawalIntentPredicatesAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'ids': value['ids'],
        'asset': value['asset'],
        'status': WithdrawalIntentStatusAPIModelToJSON(value['status']),
        'network': BlockchainNetworkTypeAPIModelToJSON(value['network']),
    };
}

