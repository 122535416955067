import { useMemo } from 'react';

import type { AppAsyncThunkAction, AppRootState } from '@/app/store';
import type { LoadingStateWithDirty } from '@/infrastructure/model';
import { storedDataError } from '@/infrastructure/model';
import { isThenable } from '@/infrastructure/utils/ts';

import useAppDispatch from './useAppDispatch';
import useAppLoadableData, { type UseAppLoadableData } from './useAppLoadableData';

export interface UseAppSingleData<Value, FetchResult = LoadingStateWithDirty<Value>>
  extends UseAppLoadableData<Value, FetchResult> {}

export default function useAppSingleData<Id, Value, FetchResult = LoadingStateWithDirty<Value>>(
  fetchActionFactory:
    | ((id: Id, force: boolean) => AppAsyncThunkAction<FetchResult, unknown>)
    | ((id: Id, force: boolean) => Promise<FetchResult>),
  singleDataSelector: (id: Id) => (state: AppRootState) => LoadingStateWithDirty<Value>,
  noIdResult: FetchResult,
  id: Id | undefined,
  dataFetchingSelector?: (state: AppRootState) => boolean,
): UseAppSingleData<Value, FetchResult> {
  const noId = useMemo(() => storedDataError<Value, string>('NoId'), []);
  const dataSelector = useMemo(() => (id ? singleDataSelector(id) : () => noId), [id, noId, singleDataSelector]);
  const { dispatch } = useAppDispatch();
  const callOrDispatch = useMemo(
    () =>
      id
        ? async (force: boolean) => {
            const call = fetchActionFactory(id, force);
            // to think of unwrap the action
            return isThenable(call) ? call : dispatch(call).unwrap();
          }
        : () => Promise.resolve(noIdResult),
    [dispatch, fetchActionFactory, id, noIdResult],
  );
  const { data, forceRefresh, loading } = useAppLoadableData(callOrDispatch, dataSelector, dataFetchingSelector);
  return { data, forceRefresh, loading };
}
