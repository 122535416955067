import { createReducer } from '@reduxjs/toolkit';

import listenerMiddleware from '@/app/listenerMiddleware';
import { notifyAuthTokenUpdated } from '@/features/auth/actions';
import { makeSelectAuthToken } from '@/features/auth/selectors';
import { notifyNetworkUpdated } from '@/features/dictionary/blockchain/actions';
import { createNotification } from '@/features/global/actions';
import { markBalancesDirty } from '@/features/statistics/actions';
import { WithdrawalIntentStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureSettlements } from '@/generated/i18n/i18n';
import { storedDirtyData } from '@/infrastructure/model';
import { createLoadingDataReducers } from '@/infrastructure/model/common/reducers';
import { createNestedNormalizedListReducers, createNormalizedListReducers } from '@/infrastructure/model/list/reducers';
import { createNormalizedPartialDataReducers } from '@/infrastructure/model/partial/reducers';
import { createSingleReducers } from '@/infrastructure/model/single/reducers';
import { onlyUnique } from '@/infrastructure/utils/functions';
import { notEmpty } from '@/infrastructure/utils/ts';
import { settlementIntentViewLink } from '@/pages/settlements/routes';

import {
  fetchMultipleSettlementIntent,
  markDistributeFeeDirty,
  markScheduledIntentsBatchDirty,
  markSettlementBatchDirty,
  markSettlementDirty,
  markSettlementIntentDirty,
  markSettlementScheduleDirty,
  markSettlementsForAssetListDirty,
  markSettlementsListDirty,
  storeDistributeFee,
  storeMultipleSettlement,
  storeMultipleSettlementIntent,
  storeScheduledIntentsBatch,
  storeSettlement,
  storeSettlementBatch,
  storeSettlementIntent,
  storeSettlementSchedule,
  storeSettlementsForAssetListData,
  storeSettlementsForAssetListParameters,
  storeSettlementsListData,
  storeSettlementsListParameters,
} from './actions';
import { makeSelectMultipleSettlementIntent, makeSelectPendingIntents } from './selectors';
import { defaultSettlementsForAssetListState, defaultSettlementsListState, type SettlementsState } from './types';
import { createDistributeFeeKey, extractSettlementId, extractSettlementIntentId } from './utils';

import type { Draft } from 'immer';

const initialState: SettlementsState = {
  entities: {},
  batches: {},

  columnState: {},
  full: defaultSettlementsForAssetListState,
  byAsset: {},

  schedule: storedDirtyData,
  intents: {
    entities: {},
    scheduled: storedDirtyData,
  },

  distributeFees: {},
};

const { storeSettlementReducer, storeMultipleSettlementReducer, markSettlementDirtyReducer } = createSingleReducers(
  'Settlement',
  (state: Draft<SettlementsState>) => state.entities,
  (global, entities) => ({ ...global, entities }),
);

const { storeSettlementBatchReducer, markSettlementBatchDirtyReducer } = createSingleReducers(
  'SettlementBatch',
  (state: Draft<SettlementsState>) => state.batches,
  (global, batches) => ({ ...global, batches }),
);

const { storeSettlementsListDataReducer, markSettlementsListDirtyReducer, storeSettlementsListParametersReducer } =
  createNormalizedListReducers(
    'Settlements',
    (state: Draft<SettlementsState>) => ({ ...state.full, columnState: state.columnState }),
    (state, { columnState, ...full }) => ({ ...state, full, columnState }),
    defaultSettlementsListState,
    (state: Draft<SettlementsState>) => state.entities as SettlementsState['entities'],
    (global, entities) => ({ ...global, entities }),
    extractSettlementId,
  );

const {
  storeSettlementsForAssetListDataReducer,
  markSettlementsForAssetListDirtyReducer,
  storeSettlementsForAssetListParametersReducer,
} = createNestedNormalizedListReducers(
  'SettlementsForAsset',
  (state: Draft<SettlementsState>, settlementId: string | undefined) => state.byAsset[settlementId!],
  (state, settlementId, newListState) => ({
    ...state,
    byAsset: { ...state.byAsset, [settlementId]: newListState },
  }),
  (state) => state.columnState,
  (state, columnState) => ({ ...state, columnState }),
  defaultSettlementsForAssetListState,
  extractSettlementId,
);

const { storeSettlementIntentReducer, storeMultipleSettlementIntentReducer, markSettlementIntentDirtyReducer } =
  createSingleReducers(
    'SettlementIntent',
    (state: Draft<SettlementsState>) => state.intents.entities,
    (global, entities) => ({ ...global, intents: { ...global.intents, entities } }),
  );

const { storeScheduledIntentsBatchReducer, markScheduledIntentsBatchDirtyReducer } =
  createNormalizedPartialDataReducers(
    'ScheduledIntents',
    (state: Draft<SettlementsState>) => state.intents.scheduled,
    (global, scheduled) => ({ ...global, intents: { ...global.intents, scheduled } }),
    (state: Draft<SettlementsState>) => state.intents.entities,
    (global, entities) => ({ ...global, intents: { ...global.intents, entities } }),
    extractSettlementIntentId,
  );

const { storeSettlementScheduleReducer, markSettlementScheduleDirtyReducer } = createLoadingDataReducers(
  'SettlementSchedule',
  (state: Draft<SettlementsState>) => state.schedule,
  (global, schedule) => ({ ...global, schedule }),
);

const { storeDistributeFeeReducer, markDistributeFeeDirtyReducer } = createSingleReducers(
  'DistributeFee' as const,
  (state: Draft<SettlementsState>) => state.distributeFees,
  (state, distributeFees) => ({ ...state, distributeFees }),
  createDistributeFeeKey,
);

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(markSettlementDirty, markSettlementDirtyReducer)
    .addCase(storeSettlement, storeSettlementReducer)
    .addCase(storeMultipleSettlement, storeMultipleSettlementReducer)

    .addCase(markSettlementBatchDirty, markSettlementBatchDirtyReducer)
    .addCase(storeSettlementBatch, storeSettlementBatchReducer)

    .addCase(storeSettlementsForAssetListData, storeSettlementsForAssetListDataReducer)
    .addCase(storeSettlementsForAssetListParameters, storeSettlementsForAssetListParametersReducer)
    .addCase(markSettlementsForAssetListDirty, markSettlementsForAssetListDirtyReducer)

    .addCase(storeSettlementsListData, storeSettlementsListDataReducer)
    .addCase(storeSettlementsListParameters, storeSettlementsListParametersReducer)
    .addCase(markSettlementsListDirty, markSettlementsListDirtyReducer)

    .addCase(markSettlementIntentDirty, markSettlementIntentDirtyReducer)
    .addCase(storeSettlementIntent, storeSettlementIntentReducer)
    .addCase(storeMultipleSettlementIntent, storeMultipleSettlementIntentReducer)

    .addCase(markSettlementScheduleDirty, markSettlementScheduleDirtyReducer)
    .addCase(storeSettlementSchedule, storeSettlementScheduleReducer)

    .addCase(markScheduledIntentsBatchDirty, markScheduledIntentsBatchDirtyReducer)
    .addCase(storeScheduledIntentsBatch, storeScheduledIntentsBatchReducer)

    .addCase(markDistributeFeeDirty, markDistributeFeeDirtyReducer)
    .addCase(storeDistributeFee, storeDistributeFeeReducer)

    .addCase(notifyNetworkUpdated, () => initialState)
    .addCase(notifyAuthTokenUpdated, (state, { payload: { previous, current } }) =>
      previous?.address !== current?.address ? initialState : state,
    );

  listenerMiddleware.startListening({
    actionCreator: notifyAuthTokenUpdated,
    effect: async ({ payload: { current } }, listenerApi) => {
      if (!current) {
        return;
      }
      listenerApi.unsubscribe();
      for (;;) {
        const savedPending = makeSelectPendingIntents()(listenerApi.getState());
        // eslint-disable-next-line no-await-in-loop
        await listenerApi.delay(5_000);
        if (!makeSelectAuthToken()(listenerApi.getState()).data) {
          return;
        }
        const arePending = makeSelectPendingIntents()(listenerApi.getState());
        if (arePending.length) {
          // eslint-disable-next-line no-await-in-loop
          await listenerApi.dispatch(
            fetchMultipleSettlementIntent({
              ids: arePending.map(extractSettlementIntentId),
              force: true,
            }),
          );
        }
        if (arePending.length || savedPending.length) {
          const updated = makeSelectMultipleSettlementIntent(
            [...savedPending, ...arePending].map(extractSettlementIntentId).filter(onlyUnique),
          )(listenerApi.getState());
          const succeeded = updated
            .filter(({ data }) => data.data?.status === WithdrawalIntentStatusAPIModel.Succeeded)
            .map(({ data }) => data.data)
            .filter(notEmpty);
          const failed = updated
            .filter(({ data }) => data.data?.status === WithdrawalIntentStatusAPIModel.Failed)
            .map(({ data }) => data.data)
            .filter(notEmpty);

          succeeded.forEach((intent) =>
            listenerApi.dispatch(
              createNotification({
                severity: 'success',
                messageI18n: I18nFeatureSettlements.MESSAGES_PENDING_FINISHED_SUCCESS,
                messageI18nValues: { ln: { type: 'link', to: settlementIntentViewLink(intent.id) } },
              }),
            ),
          );

          failed.forEach((intent) =>
            listenerApi.dispatch(
              createNotification({
                severity: 'error',
                messageI18n: I18nFeatureSettlements.MESSAGES_PENDING_FINISHED_FAILURE,
                messageI18nValues: { ln: { type: 'link', to: settlementIntentViewLink(intent.id) } },
              }),
            ),
          );

          if (succeeded.length || failed.length) {
            listenerApi.dispatch(markBalancesDirty());
          }
        }
      }
    },
  });
});

export default reducer;
