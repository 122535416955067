import React from 'react';

import { ErrorPage } from '@/components';
import type { TestableProps } from '@/infrastructure/utils/react';

export const ParamsNotFound: React.FC<TestableProps> = React.memo(({ 'data-test': dataTest }) => (
  <ErrorPage data-test={dataTest} type="404" />
));

export default ParamsNotFound;
