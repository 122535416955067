import { useEffect } from 'react';

import type { Func } from '@/infrastructure/utils/ts';

export default function useFirstRenderEffect(func: Func): void {
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    func();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
