import { useCallback, useMemo } from 'react';

import { useAppListData, type UseAppListData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { useMerchantGasWallets } from '@/features/gas-wallets/hooks';
import { makeSelectPending } from '@/features/global/selectors';
import { fetchSubscriptions } from '@/features/subscriptions/actions';
import {
  makeSelectSubscriptionListData,
  makeSelectSubscriptionListParameters,
} from '@/features/subscriptions/selectors';
import type { Subscription, SubscriptionExtended, SubscriptionFilterPredicate } from '@/features/subscriptions/types';
import type { BlockchainTypeAPIModel, SubscriptionSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { LoadingListDataState } from '@/infrastructure/model/list/types';
import { mapListState } from '@/infrastructure/model/list/utils';

export interface UseSubscriptionList
  extends UseAppListData<
    SubscriptionExtended,
    SubscriptionFilterPredicate,
    SubscriptionSortByAPIModel,
    LoadingListDataState<Subscription>
  > {}

const fetchFactory = (
  force: boolean,
): AppAsyncThunkAction<LoadingListDataState<Subscription>, Parameters<typeof fetchSubscriptions>[0]> =>
  fetchSubscriptions({ force });

const dataSelector = makeSelectSubscriptionListData();
const dataFetchingSelector = makeSelectPending(fetchSubscriptions);
const parametersSelector = makeSelectSubscriptionListParameters();

export default function useSubscriptionList(): UseSubscriptionList {
  const wallets = useMerchantGasWallets();
  const isGasWalletStateInvalid = useCallback(
    (bt: BlockchainTypeAPIModel) => {
      const wallet = wallets.data.data?.find((w) => w.bt === bt);
      return !wallet?.isReadyForAction;
    },
    [wallets.data],
  );
  const { data: rawData, ...rest } = useAppListData(
    fetchFactory,
    parametersSelector,
    dataFetchingSelector,
    dataSelector,
  );
  const data = useMemo(
    () => mapListState(rawData, (d) => ({ ...d, isGasWalletStateInvalid: isGasWalletStateInvalid(d.blockchain) })),
    [isGasWalletStateInvalid, rawData],
  );
  return { ...rest, data };
}
