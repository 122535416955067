import { PageContainer } from '@/components';
import { SubscriptionPlanGasWalletAlertBanner } from '@/features/subscription-plans/components';
import { SubscriptionsDocumentationBanner } from '@/features/subscriptions/components';
import type { TestableProps } from '@/infrastructure/utils/react';
import { withSubscriptionPlanPageParams } from '@/pages/subscriptions/hocs';
import SubscriptionLink from '@/pages/subscriptions/subscription-view/components/SubscriptionLink';

import {
  SubscriptionPlanBreadcrumb,
  SubscriptionPlanCard,
  SubscriptionPlanViewOperations,
  SubscriptionsCard,
} from './components';

import type React from 'react';
import type { PropsWithChildren } from 'react';

interface SubscriptionPlanPageProps extends PropsWithChildren<TestableProps & { pageParams: { planId: string } }> {}

const SubscriptionPlanViewPage: React.FC<SubscriptionPlanPageProps> = ({
  'data-test': dataTest = 'plan-view',
  pageParams: { planId },
}) => (
  <PageContainer
    data-test={dataTest && `${dataTest}-container`}
    breadcrumb={
      <SubscriptionPlanBreadcrumb data-test={dataTest && `${dataTest}-breadcrumb`} planId={planId} disabled />
    }
  >
    <SubscriptionsDocumentationBanner data-test={dataTest && `${dataTest}-help`} pageSize="big" />
    <SubscriptionPlanCard
      data-test={dataTest && `${dataTest}-card`}
      planId={planId}
      Operations={SubscriptionPlanViewOperations}
    />
    <SubscriptionPlanGasWalletAlertBanner data-test={dataTest && `${dataTest}-alert`} planId={planId} pageSize="big" />
    <SubscriptionsCard
      data-test={dataTest && `${dataTest}-subscriptions`}
      planId={planId}
      SubscriptionLink={SubscriptionLink}
    />
  </PageContainer>
);

export default withSubscriptionPlanPageParams(SubscriptionPlanViewPage);
