import type {
  BlockchainNetworkTypeAPIModel,
  CompanyApiFullKeyAPIModel,
  CompanyApiKeyAPIModel,
  CompanySettingsAPIModel,
  ForwarderSettingsDetailsAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import type { LoadingStateWithDirty } from '@/infrastructure/model';

export const NAMESPACE = 'settings';

export interface APIKeyId {
  key: string;
  network: BlockchainNetworkTypeAPIModel;
}

export interface APIKey extends Omit<CompanyApiKeyAPIModel, 'companyId' | 'network'> {
  network: BlockchainNetworkTypeAPIModel;
}

export interface APIKeyFull extends Omit<CompanyApiFullKeyAPIModel, 'companyId' | 'network'> {
  network: BlockchainNetworkTypeAPIModel;
}

export interface NewWebhook {
  url: string;
  network: BlockchainNetworkTypeAPIModel;
}

export interface Webhook extends NewWebhook {
  id: string;
}

export interface QRSupport extends ForwarderSettingsDetailsAPIModel {}

export interface CompanySettings
  extends Omit<
    CompanySettingsAPIModel,
    'gasWallets' | 'activeAssets' | 'notificationSettings' | 'forwarderSettings' | 'defaultAsset'
  > {
  activeAssets: string[];
  webhooks: Webhook[];
  qrSupport: QRSupport[];
  statisticsAsset: string;
}

export interface RenewalTokenExpiration {
  expiresAt: Date;
}

export const MAX_API_KEYS_PER_NETWORK = 3;

export type ConfirmCompanyEmailParams =
  | { email: string; token: string; companyId?: undefined }
  | { email: string; token: string; companyId: number };

export interface CompanySettingsState {
  settings: LoadingStateWithDirty<CompanySettings>;
  keys: LoadingStateWithDirty<APIKey[]>;
}
