import { AppPageRoutes, appRoute } from '@/pages/routes';

export enum ReportsPageRoutes {
  ALL = 'all',
  REPORT_VIEW = 'view/:id',
  REPORT_SCHEDULES = 'schedules',
}

export enum ReportSchedulesRoutes {
  ADD = 'new',
  EDIT = 'edit/:id',
  VIEW = 'view/:id',
}

export const reportsRoute = (page: ReportsPageRoutes) => `${appRoute(AppPageRoutes.REPORTS)}/${page}`;

export const reportsLink = () => `${reportsRoute(ReportsPageRoutes.ALL)}`;

export const reportViewLink = (id: string) => `${appRoute(AppPageRoutes.REPORTS)}/view/${id}`;

export const reportSchedulesRoute = (page: ReportSchedulesRoutes) =>
  `${reportsRoute(ReportsPageRoutes.REPORT_SCHEDULES)}/${page}`;

export const reportSchedulesLink = () => `${reportsRoute(ReportsPageRoutes.REPORT_SCHEDULES)}`;
export const reportScheduleViewLink = (id: string) => `${reportSchedulesLink()}/view/${id}`;
export const reportScheduleEditLink = (id: string) => `${reportSchedulesLink()}/edit/${id}`;
export const reportScheduleAddLink = () => `${reportSchedulesLink()}/new`;
