import { GlobalOutlined, WarningOutlined } from '@ant-design/icons';
import { List, Space, Tag, Tooltip, Typography } from 'antd';
import React from 'react';

import { FormattedMessage } from '@/components';
import { OperationDeleteDomain, OperationUpdateDomainStatus } from '@/features/branding/components';
import type { MerchantDomain } from '@/features/branding/types';
import { withFeatureToggleGuard } from '@/features/feature-toggle/hocs';
import { I18nPageSettings } from '@/generated/i18n/i18n';

import { withDomainsDataLoading } from './hocs';

import type { DomainsCardProps } from './types';

const { Text, Paragraph } = Typography;

const DomainsCard: React.FC<DomainsCardProps> = ({ data, 'data-test': dataTest }) => (
  <>
    <Paragraph style={{ paddingBottom: '20px' }} data-test={dataTest && `${dataTest}-description`}>
      <FormattedMessage id={I18nPageSettings.BRANDING_SUMMARY_COMPONENTS_DOMAINS_CARD_DESCRIPTION} />
    </Paragraph>
    <List<MerchantDomain>
      style={{ maxHeight: 320, overflowY: 'auto' }}
      itemLayout="horizontal"
      dataSource={data}
      renderItem={(item) => (
        <List.Item key={item.id}>
          <List.Item.Meta
            style={{ alignItems: 'center' }}
            avatar={<GlobalOutlined style={{ fontSize: 32 }} />}
            title={
              <Text
                data-test={dataTest && `${dataTest}-title`}
                ellipsis
                copyable
                style={{ width: '100%' }}
                className="ym-hide-content"
              >
                {item.url}
              </Text>
            }
            description={
              <>
                {item.isActive && (
                  <Tooltip
                    title={
                      <FormattedMessage
                        id={I18nPageSettings.BRANDING_SUMMARY_COMPONENTS_DOMAINS_CARD_DOMAIN_ACTIVE_TOOLTIP}
                      />
                    }
                  >
                    <Tag color="green">
                      <FormattedMessage
                        id={I18nPageSettings.BRANDING_SUMMARY_COMPONENTS_DOMAINS_CARD_DOMAIN_ACTIVE_MESSAGE}
                      />
                    </Tag>
                  </Tooltip>
                )}
                {!item.isApproved && (
                  <Text type="secondary" data-test={dataTest && `${dataTest}-title`} ellipsis style={{ width: '100%' }}>
                    <WarningOutlined />
                    &nbsp;
                    <FormattedMessage id={I18nPageSettings.BRANDING_SUMMARY_COMPONENTS_DOMAINS_CARD_UNAPPROVED_TITLE} />
                  </Text>
                )}
              </>
            }
          />
          <Space>
            <OperationUpdateDomainStatus
              domainId={item.id}
              data-test={dataTest && `${dataTest}-${item.id}-updateStatus`}
            />
            <OperationDeleteDomain
              domainId={item.id}
              data-test={dataTest && `${dataTest}-${item.id}-delete`}
              confirmation
            />
          </Space>
        </List.Item>
      )}
    />
  </>
);

const DomainsCardHOCed = withFeatureToggleGuard('enableCompanyDomains')(
  withDomainsDataLoading<DomainsCardProps>(DomainsCard),
);
const DomainsCardMemo = React.memo(DomainsCardHOCed) as typeof DomainsCardHOCed;

export default DomainsCardMemo;
