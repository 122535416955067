import { base58 } from '@scure/base';
import aes from 'aes-js';
import pbkdf2 from 'pbkdf2';

const toKey256 = (key: string, salt: string) => pbkdf2.pbkdf2Sync(key, salt, 10_000, 256 / 8, 'sha512');
const createOperator = (key: string, salt: string) =>
  // eslint-disable-next-line new-cap
  new aes.ModeOfOperation.ctr(toKey256(key, salt), new aes.Counter(5));

export const encryptData = (raw: string, key: string, salt = 'salt') => {
  const aesCtr = createOperator(key, salt);
  const encryptedBytes = aesCtr.encrypt(aes.utils.utf8.toBytes(raw));
  return aes.utils.hex.fromBytes(encryptedBytes);
};

export const decryptData = (encrypted: string, key: string, salt = 'salt') => {
  const aesCtr = createOperator(key, salt);
  const decryptedBytes = aesCtr.decrypt(aes.utils.hex.toBytes(encrypted));
  return aes.utils.utf8.fromBytes(decryptedBytes);
};

export const uuidToBase58 = (uuid: string) => {
  const cleanUuid = uuid.replace(/-/g, '');

  const byteArray = cleanUuid.match(/.{1,2}/g)?.map((byte) => parseInt(byte, 16));
  if (!byteArray) {
    return uuid;
  }
  const bytes = new Uint8Array(byteArray);
  return base58.encode(bytes);
};
