import { Space } from 'antd';
import React from 'react';

import type { StopPropagationContainerProps } from './types';
import type { DOMAttributes } from 'react';

const doClick: DOMAttributes<HTMLDivElement>['onClick'] = (e) => {
  e.stopPropagation();
};

const StopPropagationContainerRaw: React.FC<StopPropagationContainerProps> = ({
  'data-test': dataTest,
  className,
  style,
  noSpace,
  children,
}) =>
  noSpace ? (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div data-test={dataTest} className={className} style={style} onClick={doClick}>
      {children}
    </div>
  ) : (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <Space data-test={dataTest} className={className} style={style} onClick={doClick}>
      {children}
    </Space>
  );

const StopPropagationContainer = React.memo(StopPropagationContainerRaw);

export default StopPropagationContainer;
