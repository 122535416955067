import { createSelector } from 'reselect';

import type { AppRootState } from '@/app/store';
import { makeSelectSelectedNetwork } from '@/features/dictionary/blockchain/selectors';
import { searchIdToStoreKey } from '@/features/payment-transactions/utils';
import { flatmapStoredState } from '@/infrastructure/model';
import { createListSelectors } from '@/infrastructure/model/list/selectors';
import { createSingleSelectors } from '@/infrastructure/model/single/selectors';
import { toMultipleMerged } from '@/infrastructure/model/single/utils';

import { NAMESPACE } from './types';

export const { makeSelectPayment, makeSelectMultiplePayment, makeSelectDirtyPaymentIds } = createSingleSelectors(
  (state: AppRootState) => state[NAMESPACE].entities,
  'Payment' as const,
  undefined,
);

export const { makeSelectPaymentListParameters: baseMakeSelectPaymentListParameters, makeSelectPaymentListData } =
  createListSelectors((state: AppRootState) => state[NAMESPACE].list, 'Payment' as const);

export const makeSelectPaymentListParameters: typeof baseMakeSelectPaymentListParameters = () =>
  createSelector(baseMakeSelectPaymentListParameters(), makeSelectSelectedNetwork(), (parameters, network) => ({
    ...parameters,
    filter: { ...parameters.filter, network },
  }));

export const { makeSelectTransactionIdsForPayment } = createSingleSelectors(
  (state: AppRootState) => state[NAMESPACE].transactions,
  'TransactionIdsForPayment' as const,
  undefined,
);

export const makeSelectTransactionsForPayment = (paymentId: string) => {
  const selectTransactionIdsForPayment = makeSelectTransactionIdsForPayment(paymentId);
  return createSelector(
    selectTransactionIdsForPayment,
    (state: AppRootState) => state['payment-transactions'].entities,
    (idsState, txsState) => flatmapStoredState(idsState, (ids) => toMultipleMerged(txsState, ids, searchIdToStoreKey)),
  );
};
