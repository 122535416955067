import { css } from '@emotion/css';
import { Avatar, ColorPicker, Descriptions, Space, Tag, Typography } from 'antd';
import React, { useMemo } from 'react';

import { FormattedMessage } from '@/components';
import BrandingAuthSelect from '@/features/branding/components/BrandingAuthSelect';
import { SupportedQRList } from '@/features/branding/components/BrandingDataEditPanel/components';
import BrandingLangSelect from '@/features/branding/components/BrandingLangSelect';
import {
  BrandingLogoBottomPosition,
  BrandingLogoTopPosition,
} from '@/features/branding/components/BrandingLogoPositionSwitch/components';
import BrandingOnRampSelect from '@/features/branding/components/BrandingOnRampSelect';
import { BrandingLogoPosition } from '@/features/branding/types';
import { useFeatureToggle } from '@/features/feature-toggle/hooks';
import { I18nFeatureBranding } from '@/generated/i18n/i18n';

import type { BrandingDataViewProps } from './types';

const { Text } = Typography;

const selectClassname = css`
  width: 100%;

  > div > div > div > .ant-select-selection-item > .ant-select-selection-item-content {
    color: black;
  }
`;

const BrandingDataView: React.FC<BrandingDataViewProps> = ({ 'data-test': dataTest, value }) => {
  const {
    data: { data: featureFlags },
  } = useFeatureToggle();
  return (
    <Descriptions
      column={{ xxl: 4, xl: 4, lg: 2, md: 2, sm: 2, xs: 1 }}
      size="small"
      labelStyle={{ fontWeight: 'bold', alignItems: 'center' }}
      contentStyle={{ overflow: 'hidden' }}
    >
      <Descriptions.Item
        label={<FormattedMessage id={I18nFeatureBranding.COMPONENTS_DATA_VIEW_COLORS_BG_LABEL} />}
        span={2}
      >
        <Space>
          <ColorPicker
            data-test={dataTest && `${dataTest}-bg`}
            disabled
            value={value.bgColor}
            format="hex"
            showText={useMemo(() => () => <Text strong>{value.bgColor}</Text>, [value.bgColor])}
          />
        </Space>
      </Descriptions.Item>
      <Descriptions.Item
        label={<FormattedMessage id={I18nFeatureBranding.COMPONENTS_DATA_VIEW_COLORS_ACCENT_LABEL} />}
        span={2}
      >
        <Space>
          <ColorPicker
            data-test={dataTest && `${dataTest}-accent`}
            disabled
            value={value.accentColor}
            format="hex"
            showText={useMemo(() => () => <Text strong>{value.accentColor}</Text>, [value.accentColor])}
          />
        </Space>
      </Descriptions.Item>
      <Descriptions.Item label={<FormattedMessage id={I18nFeatureBranding.COMPONENTS_DATA_VIEW_AUTH_LABEL} />} span={4}>
        <BrandingAuthSelect
          data-test={dataTest && `${dataTest}-auth`}
          className={selectClassname}
          readonly
          multiselect
          value={value.auth}
        />
      </Descriptions.Item>
      {value.qr.length !== 0 && (
        <Descriptions.Item label={<FormattedMessage id={I18nFeatureBranding.COMPONENTS_DATA_VIEW_QR_LABEL} />} span={4}>
          <SupportedQRList data-test={dataTest && `${dataTest}-qr`} className={selectClassname} />
        </Descriptions.Item>
      )}
      <Descriptions.Item label={<FormattedMessage id={I18nFeatureBranding.COMPONENTS_DATA_VIEW_I18N_LABEL} />} span={4}>
        <BrandingLangSelect
          data-test={dataTest && `${dataTest}-lang`}
          className={selectClassname}
          readonly
          multiselect
          value={value.supportedLang}
        />
      </Descriptions.Item>
      <Descriptions.Item
        label={<FormattedMessage id={I18nFeatureBranding.COMPONENTS_DATA_VIEW_ON_RAMPS_LABEL} />}
        span={4}
      >
        <BrandingOnRampSelect
          data-test={dataTest && `${dataTest}-onRamp`}
          className={selectClassname}
          readonly
          multiselect
          value={value.onRamp}
        />
      </Descriptions.Item>
      {featureFlags?.enableBrandingContacts && !!value.contactEmail && (
        <Descriptions.Item
          label={<FormattedMessage id={I18nFeatureBranding.COMPONENTS_DATA_VIEW_CONTACT_LABEL} />}
          span={value.redirectURL ? 2 : 4}
        >
          <Text data-test={dataTest && `${dataTest}-contact`} ellipsis copyable style={{ width: '100%' }}>
            {value.contactEmail}
          </Text>
        </Descriptions.Item>
      )}
      {featureFlags?.enableBrandingContacts && !!value.redirectURL && (
        <Descriptions.Item
          label={<FormattedMessage id={I18nFeatureBranding.COMPONENTS_DATA_VIEW_REDIRECT_LABEL} />}
          span={value.contactEmail ? 2 : 4}
        >
          <Text data-test={dataTest && `${dataTest}-redirect`} ellipsis copyable style={{ width: '100%' }}>
            {value.redirectURL}
          </Text>
        </Descriptions.Item>
      )}
      {featureFlags?.enableBrandingLogo && !!value.logo?.visible && (
        <Descriptions.Item
          label={<FormattedMessage id={I18nFeatureBranding.COMPONENTS_DATA_VIEW_LOGO_LABEL} />}
          span={value.poweredBy?.visible ? 2 : 4}
        >
          <Space>
            <Avatar
              data-test={dataTest && `${dataTest}-logo-url`}
              src={value.logo.url}
              shape="circle"
              style={{ height: 60, width: 60, border: 'gray dashed 1px' }}
            />
            <Tag>
              {value.logo.position === BrandingLogoPosition.top ? (
                <BrandingLogoTopPosition />
              ) : (
                <BrandingLogoBottomPosition />
              )}
            </Tag>
          </Space>
        </Descriptions.Item>
      )}
      {featureFlags?.enableBrandingPoweredBy && !!value.poweredBy?.visible && (
        <Descriptions.Item
          label={<FormattedMessage id={I18nFeatureBranding.COMPONENTS_DATA_VIEW_POWERED_BY_LABEL} />}
          span={value.logo?.visible ? 2 : 4}
          style={{ verticalAlign: 'middle' }}
        >
          <Text data-test={dataTest && `${dataTest}-contact`} ellipsis style={{ width: '100%' }}>
            {value.poweredBy.customText?.enabled ? (
              value.poweredBy.customText.text
            ) : (
              <FormattedMessage id={I18nFeatureBranding.COMPONENTS_DATA_VIEW_POWERED_BY_DEFAULT_LABEL} />
            )}
          </Text>
        </Descriptions.Item>
      )}
    </Descriptions>
  );
};

const BrandingDataViewMemo = React.memo(BrandingDataView);

export default BrandingDataViewMemo;
