import Icon, { FileUnknownOutlined, ShareAltOutlined, TeamOutlined } from '@ant-design/icons';
import CopyOutlined from '@ant-design/icons/CopyOutlined';
import ScheduleOutlined from '@ant-design/icons/ScheduleOutlined';
import React from 'react';

import DefaultLogo from '@/assets/icons/DefaultLogo';
import { StatusViewElement } from '@/components';
import { SettlementsIcon } from '@/features/settlements/components';
import { MerchantWalletTransferKindAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureMerchantWalletTransfers } from '@/generated/i18n/i18n';

import type { MerchantWalletTransferKindViewProps } from './types';

const icons: Record<MerchantWalletTransferKindAPIModel, React.ReactNode> = {
  [MerchantWalletTransferKindAPIModel.Unknown]: <FileUnknownOutlined />,
  [MerchantWalletTransferKindAPIModel.PaymentTransaction]: <CopyOutlined />,
  [MerchantWalletTransferKindAPIModel.SubscriptionCharge]: <ScheduleOutlined />,
  [MerchantWalletTransferKindAPIModel.Withdrawal]: <SettlementsIcon />,
  [MerchantWalletTransferKindAPIModel.Payout]: <ShareAltOutlined />,
  [MerchantWalletTransferKindAPIModel.PartnerFee]: <TeamOutlined />,
  [MerchantWalletTransferKindAPIModel.ProcessingFee]: <Icon component={DefaultLogo} />,
};

export const i18n: Record<MerchantWalletTransferKindAPIModel, I18nFeatureMerchantWalletTransfers> = {
  [MerchantWalletTransferKindAPIModel.Unknown]: I18nFeatureMerchantWalletTransfers.COMPONENTS_KIND_VIEW_UNKNOWN,
  [MerchantWalletTransferKindAPIModel.PaymentTransaction]:
    I18nFeatureMerchantWalletTransfers.COMPONENTS_KIND_VIEW_PAYMENTTRANSACTION,
  [MerchantWalletTransferKindAPIModel.SubscriptionCharge]:
    I18nFeatureMerchantWalletTransfers.COMPONENTS_KIND_VIEW_SUBSCRIPTIONCHARGE,
  [MerchantWalletTransferKindAPIModel.Withdrawal]: I18nFeatureMerchantWalletTransfers.COMPONENTS_KIND_VIEW_SETTLEMENT,
  [MerchantWalletTransferKindAPIModel.Payout]: I18nFeatureMerchantWalletTransfers.COMPONENTS_KIND_VIEW_PAYOUT,
  [MerchantWalletTransferKindAPIModel.PartnerFee]: I18nFeatureMerchantWalletTransfers.COMPONENTS_KIND_VIEW_PARTNERFEE,
  [MerchantWalletTransferKindAPIModel.ProcessingFee]:
    I18nFeatureMerchantWalletTransfers.COMPONENTS_KIND_VIEW_PROCESSINGFEE,
};

const MerchantWalletTransferKindViewRaw: React.FC<MerchantWalletTransferKindViewProps> = ({
  value,
  'data-test': dataTest,
  mode = 'full',
}) => (value ? <StatusViewElement data-test={dataTest} i18n={i18n[value]} icon={icons[value]} mode={mode} /> : null);

const MerchantWalletTransferKindView = React.memo(MerchantWalletTransferKindViewRaw);

export default MerchantWalletTransferKindView;
