import { useCallback, useMemo } from 'react';

import type { UseAppSingleData } from '@/app/hooks';
import { useAppLoadableData } from '@/app/hooks';
import { fetchBlockchainsSystemInfo } from '@/features/dictionary/blockchain/actions';
import { makeSelectBlockchainSystemInfos } from '@/features/dictionary/blockchain/selectors';
import type { BlockchainSystemInfo } from '@/features/dictionary/blockchain/types';
import { useFeatureToggle } from '@/features/feature-toggle/hooks';
import { makeSelectPending } from '@/features/global/selectors';
import type { BlockchainNetworkTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { BlockchainAPITypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { combine } from '@/infrastructure/model';
import { notEmpty } from '@/infrastructure/utils/ts';

import useListBlockchains from './useListBlockchains';

export interface UseNetworkBlockchainSystemInfos extends UseAppSingleData<BlockchainSystemInfo[], unknown> {}

const dataSelector = makeSelectBlockchainSystemInfos();
const dataFetchingSelector = makeSelectPending(fetchBlockchainsSystemInfo);
const fetchFactory = (force: boolean) => fetchBlockchainsSystemInfo({ force });

export default function useNetworkBlockchainSystemInfos(
  network?: BlockchainNetworkTypeAPIModel,
): UseNetworkBlockchainSystemInfos {
  const flags = useFeatureToggle();
  const blockchains = useListBlockchains(network);
  const systemInfos = useAppLoadableData(fetchFactory, dataSelector, dataFetchingSelector);

  return {
    loading: blockchains.loading || systemInfos.loading || flags.loading,
    forceRefresh: useCallback(
      () => Promise.all([blockchains.forceRefresh(), systemInfos.forceRefresh()]),
      [blockchains, systemInfos],
    ),
    data: useMemo(
      () =>
        combine(blockchains.data, systemInfos.data, (bc, sys) =>
          bc
            .map(({ blockchain }) => sys[blockchain])
            .filter(notEmpty)
            .map((data) =>
              data.apiType === BlockchainAPITypeAPIModel.Tron && flags.data.data?.disableTron
                ? { ...data, forwarder: 'not-supported' }
                : data,
            ),
        ),
      [blockchains.data, flags.data.data?.disableTron, systemInfos.data],
    ),
  };
}
