import { DualAxes } from '@ant-design/charts';
import BigNumber from 'bignumber.js';
import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { LineType, useChartPlanLabels } from '@/features/subscription-statistics/hooks';
import { I18nFeatureSubscriptionStatistics } from '@/generated/i18n/i18n';

import type { SubscriptionMonthlyRevenueGraphProps, MonthlyRevenueGraphData } from './types';

const SubscriptionMonthlyRevenueGraph: React.FC<SubscriptionMonthlyRevenueGraphProps> = ({
  'data-test': dataTest,
  data: { statistics, plans },
}) => {
  const { formatMessage: i18n } = useIntl();
  const asset = statistics.total[0]?.amount.asset;
  const yAxisLabel = useCallback((text: string) => `${text} ${asset || ''}`, [asset]);
  const { onPlanViewClick, tooltipItemValue } = useChartPlanLabels(plans);

  // TODO: 1. tooltip name configuration is not working, 2. legend configuration ruins the first render
  const tooltip = useMemo(
    () => ({
      // name: 'tooltip',
      channel: 'y',
      valueFormatter: (value?: MonthlyRevenueGraphData['value']) => `${value ?? ''} ${asset || ''}`,
    }),
    [asset],
  );
  const planData = useMemo<MonthlyRevenueGraphData[]>(
    () =>
      statistics.perPlan.flatMap(({ planId, perMonth }) =>
        perMonth.map(({ amount, month }) => ({
          period: month,
          value: amount.value.toNumber(),
          name: tooltipItemValue(planId),
          // tooltip: tooltipItemValue(planId),
        })),
      ),
    [statistics.perPlan, tooltipItemValue],
  );
  const overallData = useMemo<MonthlyRevenueGraphData[]>(
    () => [
      ...statistics.median.map(({ amount, month }) => ({
        period: month,
        value: amount.value.toNumber(),
        name: tooltipItemValue(LineType.median),
        // tooltip: tooltipItemValue(LineType.median),
      })),
      ...statistics.mean.map(({ amount, month }) => ({
        period: month,
        value: amount.value.toNumber(),
        name: LineType.mean,
        // tooltip: tooltipItemValue(LineType.mean),
      })),
      ...statistics.total.map(({ amount, month }) => ({
        period: month,
        value: amount.value.toNumber(),
        name: tooltipItemValue(LineType.total),
        // tooltip: tooltipItemValue(LineType.total),
      })),
    ],
    [statistics.mean, statistics.median, statistics.total, tooltipItemValue],
  );
  const max = useMemo(
    () =>
      statistics.total
        .map(({ amount: { value } }) => value)
        .reduce((r, v) => (r.isGreaterThan(v) ? r : v), BigNumber(0))
        .toNumber(),
    [statistics.total],
  );

  const axis = useMemo(
    () => ({
      x: { title: i18n({ id: I18nFeatureSubscriptionStatistics.COMPONENTS_REVENUE_GRAPH_X_AXIS_TITLE }) },
      y: { labelFormatter: yAxisLabel },
    }),
    [i18n, yAxisLabel],
  );

  // const legend = useMemo(() => ({ color: { itemLabelText: legendItemName } }), [legendItemName]);
  const children = useMemo(
    () => [
      {
        data: planData,
        type: 'interval',
        yField: 'value',
        colorField: 'name',
      },
      {
        data: overallData,
        type: 'line',
        yField: 'value',
        colorField: 'name',
        seriesField: 'name',
        axis: { y: { position: 'right' } },
        style: { lineWidth: 2 },
      },
    ],
    [overallData, planData],
  );

  const scale = useMemo(() => ({ y: { domainMin: 0, domainMax: max } }), [max]);

  return (
    <DualAxes
      data-test={dataTest}
      height={600}
      scale={scale}
      onEvent={onPlanViewClick}
      xField="period"
      children={children}
      axis={axis}
      tooltip={tooltip}
      // legend={legend}
    />
  );
};

const SubscriptionMonthlyRevenueGraphMemo = React.memo(SubscriptionMonthlyRevenueGraph);

export default SubscriptionMonthlyRevenueGraphMemo;
