import { List } from 'antd';
import React, { useCallback } from 'react';
import { AutoSizer, List as VList, WindowScroller } from 'react-virtualized';

import OperationRemoveCompanyUser from '../OperationRemoveCompanyUser';

import type { CompanyUsersListProps } from './types';
import type { ListRowProps } from 'react-virtualized/dist/es/List';

const CompanyUsersList: React.FC<CompanyUsersListProps> = ({ data, 'data-test': dataTest }) => {
  const renderItem = useCallback(
    ({ index, key, style: rowStyle }: ListRowProps) => {
      const item = data[index];
      return (
        <List.Item
          key={key}
          style={rowStyle}
          actions={[
            <OperationRemoveCompanyUser
              address={item.address}
              data-test={dataTest && `${dataTest}-${item.address}-remove`}
              confirmation
            />,
          ]}
        >
          <List.Item.Meta title={item.address} />
        </List.Item>
      );
    },
    [data, dataTest],
  );

  return (
    <WindowScroller data-test={dataTest}>
      {({ height, isScrolling, onChildScroll, scrollTop }) => (
        <AutoSizer disableHeight>
          {({ width }) => (
            <VList
              autoHeight
              height={height}
              isScrolling={isScrolling}
              onScroll={onChildScroll}
              overscanRowCount={2}
              rowCount={data.length}
              rowHeight={50}
              rowRenderer={renderItem}
              scrollTop={scrollTop}
              width={width}
            />
          )}
        </AutoSizer>
      )}
    </WindowScroller>
  );
};

const CompanyUsersListMemo = React.memo(CompanyUsersList);

export default CompanyUsersListMemo;
