import { MerchantWalletTransferKindAPIModel } from '@/generated/api/ncps-core/merchant-bo';

export const linkSupport: Record<MerchantWalletTransferKindAPIModel, boolean> = {
  [MerchantWalletTransferKindAPIModel.PaymentTransaction]: true,
  [MerchantWalletTransferKindAPIModel.SubscriptionCharge]: true,
  [MerchantWalletTransferKindAPIModel.Withdrawal]: true,
  [MerchantWalletTransferKindAPIModel.Payout]: false,
  [MerchantWalletTransferKindAPIModel.Unknown]: false,
  [MerchantWalletTransferKindAPIModel.PartnerFee]: false,
  [MerchantWalletTransferKindAPIModel.ProcessingFee]: false,
};
