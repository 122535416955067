import { useMemo } from 'react';
import React from 'react';

import { FormattedMessage } from '@/components';
import { AddressItem, AssetAmountItem } from '@/features/dictionary/blockchain/components';
import { useSelectedNetwork } from '@/features/dictionary/blockchain/hooks';
import { BlockchainAPITypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureSettlementPayouts } from '@/generated/i18n/i18n';
import { isSameAddress } from '@/infrastructure/utils/web3/address';

import type { EditableCellProps } from './types';

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  record,
  index,
  children,
  asset,
  existItems,
  ...restProps
}) => {
  const { network } = useSelectedNetwork();
  const rules = useMemo(
    () => [
      {
        // eslint-disable-next-line @typescript-eslint/require-await
        validator: async (_: unknown, value: string) => {
          if (value && value !== record?.address && existItems?.find((item) => isSameAddress(item.address, value))) {
            throw new Error();
          }
        },
        message: (
          <FormattedMessage
            id={
              I18nFeatureSettlementPayouts.COMPONENTS_CREATE_PAYOUT_FORM_DESTINATIONS_TABLE_EDIT_OPERATION_ERROR_ADDRESS_EXISTS
            }
          />
        ),
      },
    ],
    [existItems, record?.address],
  );

  return (
    <td {...restProps} style={{ verticalAlign: 'top' }}>
      {dataIndex === 'address' && asset && (
        <AddressItem
          name={dataIndex}
          blockchainApis={[BlockchainAPITypeAPIModel.WEB3]}
          net={network}
          ItemProps={{ label: undefined, hidden: !editing }}
          rules={rules}
        />
      )}
      {dataIndex === 'amount' && asset && (
        <AssetAmountItem
          name={dataIndex}
          nonNegative
          assetId={asset}
          ItemProps={{ label: undefined, preserve: false, hidden: !editing }}
          noLabelOnHelp
        />
      )}
      {!editing && children}
    </td>
  );
};

const EditableCellMemo = React.memo(EditableCell);

export default EditableCellMemo;
