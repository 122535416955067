import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import React, { useCallback, useMemo } from 'react';

import type { DateTimePickerWrapperProps } from './types';
import type { Dayjs } from 'dayjs';

const DateTimePickerWrapper: React.FC<DateTimePickerWrapperProps> = React.memo(
  ({ format, placeholder, timeMode, onChange, value, readonly, 'data-test': dataTest, ...rest }) => {
    const doChange = useCallback(
      (v: Dayjs | null) => {
        const newValue =
          timeMode === 'dateTime' ? v?.clone().millisecond(0).toDate() : v?.clone().startOf('day').toDate();
        return onChange?.(newValue);
      },
      [onChange, timeMode],
    );
    const normalizedValue = useMemo(() => (value ? dayjs(value) : undefined), [value]);
    return (
      <DatePicker
        disabled={readonly}
        format={format}
        onChange={doChange}
        value={normalizedValue}
        size="middle"
        placeholder={placeholder}
        data-test={dataTest}
        showTime={timeMode === 'dateTime'}
        {...rest}
      />
    );
  },
);

export default DateTimePickerWrapper;
