import BigNumber from 'bignumber.js';

import { type CommonLoadingState, storedDataError } from '@/infrastructure/model';
import { ResponseError, type ServerErrorResponse } from '@/infrastructure/request';

export interface ApiError {
  code: string;
}

export interface Page {
  page: number;
  perPage: number;
}

interface PageAPIModel<K = string> {
  limit: number;
  offset: number;
  sortBy?: { attr: K; asc: boolean }[];
}

export const defaultPage: Page = { page: 1, perPage: 20 };
export const defaultPageFn = ({ page, perPage }: Partial<Page>): Page => ({
  page: page ?? defaultPage.page,
  perPage: perPage ?? defaultPage.perPage,
});

export type OrderByType = 'ASC' | 'DESC';

export type OrderBy<K extends string = string> = Partial<Record<K, OrderByType>>;

export interface DataSlice<T, S extends string = string> {
  list: T[];
  page: Page;
  sort?: OrderBy<S>;
  total?: number;
}

export interface SliceDataRequest<T, S extends string = string> {
  filter?: T;
  page?: Page;
  orderBy?: OrderBy<S>;
}

export interface SliceDataRequestWithTotal<T, S extends string = string> extends SliceDataRequest<T, S> {
  withTotal?: boolean;
}

export const pageToAPI = <K extends string = string>(
  { page = defaultPage.page, perPage = defaultPage.perPage }: Page = defaultPage,
  sort?: OrderBy<K>,
): PageAPIModel<K> => ({
  limit: perPage,
  offset: (page - 1) * perPage,
  sortBy: sort ? Object.entries(sort).map(([attr, type]) => ({ attr: attr as K, asc: type === 'ASC' })) : [],
});

export const pageFromAPI = <S = OrderBy>({ limit, offset, sortBy }: PageAPIModel): { page: Page; sort?: S } => ({
  page: {
    perPage: limit,
    page: offset / limit + 1,
  },
  // FIXME: do not ignore
  // @ts-ignore
  sort: sortBy ? Object.fromEntries(sortBy.map(({ attr, asc }) => [attr, asc ? 'ASC' : 'DESC'])) : undefined,
});

interface SliceFromAPI {
  <Value, SortBy extends string = string>(slice: {
    list?: Value[];
    page: PageAPIModel;
    total?: number;
  }): DataSlice<Value, SortBy>;

  <Value, MappedValue, SortBy extends string = string>(
    slice: { list?: Value[]; page: PageAPIModel; total?: number },
    mapper: (value: Value) => MappedValue,
  ): DataSlice<MappedValue, SortBy>;
}

export const sliceFromAPI: SliceFromAPI = <Value, MappedValue, SortBy extends string = string>(
  { list, page, ...data }: { list?: Value[]; page: PageAPIModel; total?: number },
  mapper?: (value: Value) => MappedValue,
): DataSlice<MappedValue, SortBy> => {
  const mappedList = (mapper ? (list ?? []).map(mapper) : (list ?? [])) as MappedValue[];
  return { list: mappedList, ...pageFromAPI(page), ...data };
};

export const sliceDataRequestToAPI = <T, S extends string = string>(
  request: SliceDataRequest<T, S>,
  defaultSort?: OrderBy<S>,
) => ({
  page: pageToAPI(request.page, request.orderBy ?? defaultSort),
  filter: request.filter ? { predicates: request.filter } : undefined,
});

const maxDataTotal = 999;
export const fetchAllDataOrThrow = async <K, T>(
  fetchFunc: (page: PageAPIModel<K>) => Promise<{ list?: T[]; page: PageAPIModel; total?: number }>,
): Promise<T[]> => {
  const result = await fetchFunc({ limit: maxDataTotal, offset: 0 });
  if (result.list?.length === maxDataTotal) {
    throw new Error('too much data');
  }
  return result.list ?? [];
};
export const withFetchAllDataOrThrow =
  <V extends unknown[], Filter, SortBy extends string = string>(
    func: (page: Page) => (...args: V) => Promise<DataSlice<Filter, SortBy>>,
  ) =>
  async (...args: V): Promise<DataSlice<Filter, SortBy>> => {
    const result = await func({ page: 1, perPage: maxDataTotal })(...args);
    if (result.list.length === maxDataTotal) {
      throw new Error('too much data');
    }
    return result;
  };

const parseErrorAsResponseError = <R>(e: unknown) =>
  e instanceof ResponseError
    ? storedDataError<R, string>(
        (e.data.data as ServerErrorResponse | undefined)?.code
          || (e.data.data as ServerErrorResponse | undefined)?.message
          || e.data.statusText
          || e.message,
      )
    : undefined;

const parseErrorAsCommonError = <R>(e: unknown) =>
  e instanceof Error ? storedDataError<R, string>(e.message) : undefined;
const parseAsStringError = <R>(e: unknown) => (typeof e === 'string' ? storedDataError<R, string>(e) : undefined);

export const withAPICall =
  <V extends unknown[], R>(
    func: (...args: V) => Promise<R>,
    defaultError?: string,
  ): ((...args: V) => Promise<CommonLoadingState<R>>) =>
  async (...args: V): Promise<CommonLoadingState<R>> => {
    try {
      const data = await func(...args);
      return { data };
    } catch (e: unknown) {
      return (
        parseErrorAsResponseError(e)
        ?? parseErrorAsCommonError(e)
        ?? parseAsStringError(e)
        ?? storedDataError(defaultError || 'api call error')
      );
    }
  };

export interface AssetAmountValue {
  asset: string;
  value: BigNumber;
}

export const amountToAPI = ({ asset, value }: AssetAmountValue) => `${value.toString()} ${asset}`;
export const amountFromAPI = (apiAmount: string): AssetAmountValue => {
  const [amount, asset] = apiAmount.split(' ');
  return { value: new BigNumber(amount), asset };
};
