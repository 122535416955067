import React from 'react';

import type { TitleWithDetailsProps } from './types';

const TitleWithDetails: React.FC<TitleWithDetailsProps> = React.memo(({ title, link, 'data-test': dataTest }) => (
  <span style={{ display: 'flex' }} data-test={dataTest}>
    {title}
    <span style={{ marginLeft: 'auto' }}>{link}</span>
  </span>
));

export default TitleWithDetails;
