import { css } from '@emotion/css';
import { Affix, Alert, Button } from 'antd';
import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nFeatureGlobal } from '@/generated/i18n/i18n';

import type { CookiesBannerMessageProps } from './types';

const CookiesBannerMessage: React.FC<CookiesBannerMessageProps> = ({
  className,
  style,
  onAccept,
  'data-test': dataTest,
}) => (
  <Affix
    offsetBottom={0}
    className={css`
      > .ant-affix {
        z-index: 1000;
      }
    `}
  >
    <Alert
      type="info"
      data-test={dataTest}
      showIcon={false}
      message={<FormattedMessage id={I18nFeatureGlobal.COMPONENTS_COOKIES_BANNER_MESSAGE} />}
      banner
      className={className}
      style={style}
      action={
        <Button onClick={onAccept} data-test={dataTest && `${dataTest}-accept`}>
          <FormattedMessage id={I18nFeatureGlobal.COMPONENTS_COOKIES_BANNER_BUTTON_TITLE} />
        </Button>
      }
    />
  </Affix>
);

const CookiesBannerMessageMemo = React.memo(CookiesBannerMessage);

export default CookiesBannerMessageMemo;
