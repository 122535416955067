import { Form } from 'antd';
import React from 'react';

import { I18nComponents } from '@/generated/i18n/i18n';

import FormattedMessage from '../FormattedMessage';

import { BooleanSwitch } from './components';

import type { BooleanItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';
import type { ForwardedRef } from 'react';

const BooleanItem = <Values extends Store = Store>(
  {
    'data-test': dataTest,
    name,
    prefix,
    suffix,
    readonly,
    messages,
    SwitchProps,
    ItemProps = {},
  }: BooleanItemProps<Values>,
  ref: ForwardedRef<HTMLButtonElement>,
) => (
  <Form.Item<Values>
    label={messages?.label ?? <FormattedMessage id={I18nComponents.BOOLEAN_LABEL} />}
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...ItemProps}
    name={name}
  >
    <BooleanSwitch
      data-test={dataTest}
      readonly={readonly}
      SwitchProps={SwitchProps}
      prefix={prefix}
      suffix={suffix}
      ref={ref}
    />
  </Form.Item>
);

const BooleanItemWithRef = React.memo(React.forwardRef(BooleanItem)) as typeof BooleanItem;

export default BooleanItemWithRef;
