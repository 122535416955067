import { OperationWithConfirmation } from './components';

import type { OperationProps, OperationWithConfirmationProps } from '../types';
import type React from 'react';

const withConfirmation =
  (Component: React.ComponentType<OperationProps>): React.FC<OperationWithConfirmationProps> =>
  ({ confirmation, ...props }) => {
    if (!confirmation) {
      return <Component {...props} />;
    }
    return <OperationWithConfirmation {...props} Component={Component} confirmation={confirmation} />;
  };

export default withConfirmation;
