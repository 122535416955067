/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3276
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { BlockchainTypeAPIModel } from './BlockchainTypeAPIModel';
import {
    BlockchainTypeAPIModelFromJSON,
    BlockchainTypeAPIModelFromJSONTyped,
    BlockchainTypeAPIModelToJSON,
} from './BlockchainTypeAPIModel';

/**
 * 
 * @export
 * @interface RefundGasWalletAPIModel
 */
export interface RefundGasWalletAPIModel {
    /**
     * 
     * @type {BlockchainTypeAPIModel}
     * @memberof RefundGasWalletAPIModel
     */
    blockchain: BlockchainTypeAPIModel;
    /**
     * 
     * @type {string}
     * @memberof RefundGasWalletAPIModel
     */
    to: string;
}

/**
 * Check if a given object implements the RefundGasWalletAPIModel interface.
 */
export function instanceOfRefundGasWalletAPIModel(value: object): boolean {
    if (!('blockchain' in value)) return false;
    if (!('to' in value)) return false;
    return true;
}

export function RefundGasWalletAPIModelFromJSON(json: any): RefundGasWalletAPIModel {
    return RefundGasWalletAPIModelFromJSONTyped(json, false);
}

export function RefundGasWalletAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): RefundGasWalletAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'blockchain': BlockchainTypeAPIModelFromJSON(json['blockchain']),
        'to': json['to'],
    };
}

export function RefundGasWalletAPIModelToJSON(value?: RefundGasWalletAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'blockchain': BlockchainTypeAPIModelToJSON(value['blockchain']),
        'to': value['to'],
    };
}

