/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3276
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { ReportScheduleStatusAPIModel } from './ReportScheduleStatusAPIModel';
import {
    ReportScheduleStatusAPIModelFromJSON,
    ReportScheduleStatusAPIModelFromJSONTyped,
    ReportScheduleStatusAPIModelToJSON,
} from './ReportScheduleStatusAPIModel';

/**
 * 
 * @export
 * @interface MerchantReportScheduleAPIModel
 */
export interface MerchantReportScheduleAPIModel {
    /**
     * 
     * @type {string}
     * @memberof MerchantReportScheduleAPIModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof MerchantReportScheduleAPIModel
     */
    template: string;
    /**
     * 
     * @type {string}
     * @memberof MerchantReportScheduleAPIModel
     */
    cron: string;
    /**
     * 
     * @type {ReportScheduleStatusAPIModel}
     * @memberof MerchantReportScheduleAPIModel
     */
    status: ReportScheduleStatusAPIModel;
    /**
     * 
     * @type {string}
     * @memberof MerchantReportScheduleAPIModel
     */
    disableReason?: string;
    /**
     * 
     * @type {string}
     * @memberof MerchantReportScheduleAPIModel
     */
    createdBy: string;
    /**
     * 
     * @type {Date}
     * @memberof MerchantReportScheduleAPIModel
     */
    createdAt: Date;
    /**
     * 
     * @type {any}
     * @memberof MerchantReportScheduleAPIModel
     */
    parameters?: any;
    /**
     * 
     * @type {Date}
     * @memberof MerchantReportScheduleAPIModel
     */
    nextGenerationAt?: Date;
}

/**
 * Check if a given object implements the MerchantReportScheduleAPIModel interface.
 */
export function instanceOfMerchantReportScheduleAPIModel(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('template' in value)) return false;
    if (!('cron' in value)) return false;
    if (!('status' in value)) return false;
    if (!('createdBy' in value)) return false;
    if (!('createdAt' in value)) return false;
    return true;
}

export function MerchantReportScheduleAPIModelFromJSON(json: any): MerchantReportScheduleAPIModel {
    return MerchantReportScheduleAPIModelFromJSONTyped(json, false);
}

export function MerchantReportScheduleAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MerchantReportScheduleAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'template': json['template'],
        'cron': json['cron'],
        'status': ReportScheduleStatusAPIModelFromJSON(json['status']),
        'disableReason': json['disableReason'] == null ? undefined : json['disableReason'],
        'createdBy': json['createdBy'],
        'createdAt': (new Date(json['createdAt'])),
        'parameters': json['parameters'] == null ? undefined : json['parameters'],
        'nextGenerationAt': json['nextGenerationAt'] == null ? undefined : (new Date(json['nextGenerationAt'])),
    };
}

export function MerchantReportScheduleAPIModelToJSON(value?: MerchantReportScheduleAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'template': value['template'],
        'cron': value['cron'],
        'status': ReportScheduleStatusAPIModelToJSON(value['status']),
        'disableReason': value['disableReason'],
        'createdBy': value['createdBy'],
        'createdAt': ((value['createdAt']).toISOString()),
        'parameters': value['parameters'],
        'nextGenerationAt': value['nextGenerationAt'] == null ? undefined : ((value['nextGenerationAt']).toISOString()),
    };
}

