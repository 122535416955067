import { Form } from 'antd';
import difference from 'lodash/difference';
import React, { useCallback, useMemo } from 'react';
import isEqual from 'react-fast-compare';

import {
  defaultPageFormLayout,
  defaultPageFormTailLayout,
  ErrorFormMessage,
  FormattedMessage,
  FormCompletenessItem,
  FormFooter,
} from '@/components';
import type { CompanyActiveAssetAmountItemValue } from '@/features/company-settings/components';
import { CompanyActiveAssetAmountItem } from '@/features/company-settings/components';
import type { NewSubscriptionPlan } from '@/features/subscription-plans/types';
import { BlockchainAPITypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureSubscriptionPlans } from '@/generated/i18n/i18n';
import { useErrorSafeSubmitting, useForm, useLocaleSettings, useStateMountSafe } from '@/hooks';
import type { AssetAmountValue } from '@/infrastructure/api';
import { noop, withVoidOrThrow, wrap } from '@/infrastructure/utils/functions';
import { nameof } from '@/infrastructure/utils/ts';

import {
  DescriptionItem,
  NameItem,
  recommendedHours,
  SubscriptionPlanGraceItem,
  SubscriptionPlanScheduleItem,
  SubscriptionPlanTagsItem,
} from './components';

import type { SubscriptionPlanEditFormProps, UpdateSubscriptionPlanFormProps } from './types';
import type { FormInstance } from 'antd/es/form';
import type { NamePath } from 'rc-field-form/es/interface';

interface SubscriptionPlanEditFormData extends Omit<NewSubscriptionPlan, 'amount'> {
  amount: CompanyActiveAssetAmountItemValue;
}

const requiredFields: NamePath[] = [
  [nameof<SubscriptionPlanEditFormData>('amount'), nameof<CompanyActiveAssetAmountItemValue>('amount')],
  [nameof<SubscriptionPlanEditFormData>('amount'), nameof<CompanyActiveAssetAmountItemValue>('asset')],
  nameof<SubscriptionPlanEditFormData>('name'),
  nameof<SubscriptionPlanEditFormData>('description'),
  nameof<SubscriptionPlanEditFormData>('periodSeconds'),
];

const fieldsToValidate: NamePath[] = [
  nameof<SubscriptionPlanEditFormData>('tags'),
  nameof<SubscriptionPlanEditFormData>('gracePeriodSeconds'),
];

const formMessages = {
  submit: <FormattedMessage id={I18nFeatureSubscriptionPlans.COMPONENTS_EDIT_FORM_SUBMIT_BUTTON} tagName="span" />,
};

const SubscriptionPlanEditForm: React.FC<SubscriptionPlanEditFormProps> = ({
  'data-test': dataTest,
  className,
  style,
  submitCallback,
  onSubmitting,
  onSubmit,
  onReset = noop,
  layout = defaultPageFormLayout,
  tailLayout = defaultPageFormTailLayout,
  ...props
}) => {
  const { form, withResetForm } = useForm<SubscriptionPlanEditFormData>();
  const { formatBigNumber } = useLocaleSettings();
  const [isFormComplete, setFormComplete] = useStateMountSafe(false);
  const { submitting, error, withSubmitting, withErrorHandling, reset: resetSubmit } = useErrorSafeSubmitting();
  const doReset = useMemo(() => withResetForm(resetSubmit), [resetSubmit, withResetForm]);
  const doCancel = useMemo(() => withErrorHandling(onReset), [onReset, withErrorHandling]);
  const doSubmit = useCallback(
    ({ amount, ...rest }: SubscriptionPlanEditFormData) => {
      if (!amount.amount.value) {
        throw new Error('no value');
      }
      return onSubmit({
        ...rest,
        amount: { asset: amount.asset, value: amount.amount.value },
      });
    },
    [onSubmit],
  );
  const doSubmitForm = useMemo(() => withSubmitting(doSubmit), [doSubmit, withSubmitting]);
  const initialValues: Partial<SubscriptionPlanEditFormData> = useMemo(() => {
    const values = (props as UpdateSubscriptionPlanFormProps).initialValues ?? undefined;
    return values
      ? {
          ...values,
          amount: values.amount
            ? {
                asset: values.amount.asset,
                amount: {
                  value: values.amount.value,
                  valid: true,
                  inputValue: formatBigNumber(values.amount.value),
                },
              }
            : undefined,
        }
      : { periodSeconds: 1 * 24 * 60 * 60, gracePeriodSeconds: recommendedHours * 60 * 60 };
  }, [formatBigNumber, props]);
  const extraValidator = useMemo(() => {
    const values = (props as UpdateSubscriptionPlanFormProps).initialValues ?? undefined;
    if (!values) {
      return () => true;
    }
    return ({ getFieldValue }: FormInstance<SubscriptionPlanEditFormData>) => {
      const oldAmount = values.amount?.value;
      const newAmountField: AssetAmountValue | undefined = getFieldValue(
        nameof<SubscriptionPlanEditFormData>('amount'),
      );
      const newAmount = newAmountField?.value;
      const isSameAmounts = !!oldAmount && !!newAmount && oldAmount.isEqualTo(newAmount);

      const oldTags = values.tags;
      const newTags: string[] | undefined = getFieldValue(nameof<SubscriptionPlanEditFormData>('tags'));
      const isSameTags = oldTags?.length === newTags?.length && !difference(oldTags, newTags ?? []).length;
      return (
        newAmountField?.asset !== values.amount?.asset
        || !isSameAmounts
        || !isSameTags
        || getFieldValue(nameof<SubscriptionPlanEditFormData>('periodSeconds')) !== values.periodSeconds
        || getFieldValue(nameof<SubscriptionPlanEditFormData>('gracePeriodSeconds')) !== values.gracePeriodSeconds
        || getFieldValue(nameof<SubscriptionPlanEditFormData>('trialPeriodSeconds')) !== values.trialPeriodSeconds
        || getFieldValue(nameof<SubscriptionPlanEditFormData>('name')) !== values.name
        || getFieldValue(nameof<SubscriptionPlanEditFormData>('description')) !== values.description
      );
    };
  }, [props]);

  const updateComplete = useCallback(
    (complete: boolean) => {
      const value = form.getFieldsValue();
      const hasBeenUpdated = !isEqual(initialValues, value);
      const isCompleteAndUpdated = complete && hasBeenUpdated;
      setFormComplete(isCompleteAndUpdated);
      const submitFn = isCompleteAndUpdated
        ? withSubmitting(
            withVoidOrThrow(
              wrap(
                async () => {
                  await doSubmit(value);
                  setTimeout(doReset, 0);
                },
                () => onSubmitting?.(true),
                () => onSubmitting?.(false),
              ),
            ),
          )
        : undefined;
      submitCallback?.(submitFn);
    },
    [form, initialValues, setFormComplete, withSubmitting, submitCallback, doSubmit, doReset, onSubmitting],
  );

  return (
    <Form<SubscriptionPlanEditFormData>
      onValuesChange={resetSubmit}
      onResetCapture={doReset}
      {...layout}
      initialValues={initialValues}
      className={className}
      style={style}
      autoComplete="off"
      form={form}
      onFinish={doSubmitForm}
      onReset={doCancel}
    >
      {error && <ErrorFormMessage data-test={dataTest && `${dataTest}-error`} content={error} />}
      <CompanyActiveAssetAmountItem<SubscriptionPlanEditFormData>
        data-test={dataTest && `${dataTest}-amount`}
        name={nameof<SubscriptionPlanEditFormData>('amount')}
        excludeAPI={BlockchainAPITypeAPIModel.BTC}
      />
      <SubscriptionPlanScheduleItem<SubscriptionPlanEditFormData>
        data-test={dataTest && `${dataTest}-period`}
        name={nameof<SubscriptionPlanEditFormData>('periodSeconds')}
      />
      <SubscriptionPlanGraceItem<SubscriptionPlanEditFormData>
        data-test={dataTest && `${dataTest}-grace`}
        name={nameof<SubscriptionPlanEditFormData>('gracePeriodSeconds')}
        periodName={nameof<SubscriptionPlanEditFormData>('periodSeconds')}
      />
      <NameItem<SubscriptionPlanEditFormData>
        required
        data-test={dataTest && `${dataTest}-name`}
        name={nameof<SubscriptionPlanEditFormData>('name')}
      />
      <DescriptionItem<SubscriptionPlanEditFormData>
        required
        data-test={dataTest && `${dataTest}-description`}
        name={nameof<SubscriptionPlanEditFormData>('description')}
      />
      <SubscriptionPlanTagsItem<SubscriptionPlanEditFormData>
        data-test={dataTest && `${dataTest}-tags`}
        name={nameof<SubscriptionPlanEditFormData>('tags')}
      />
      <FormCompletenessItem
        requiredFields={requiredFields}
        fields={fieldsToValidate}
        onChange={updateComplete}
        checkIsComplete={extraValidator}
      />
      <FormFooter
        data-test={dataTest && `${dataTest}-footer`}
        noStyle={!!submitCallback}
        style={submitCallback ? { visibility: 'hidden' } : undefined}
        messages={formMessages}
        tailLayout={tailLayout}
        submitDisabled={!isFormComplete}
        submitting={submitting}
      />
    </Form>
  );
};

const SubscriptionPlanEditFormMemo = React.memo(SubscriptionPlanEditForm);

export default SubscriptionPlanEditFormMemo;
