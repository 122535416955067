import React from 'react';

import { useAsset } from '@/features/dictionary/blockchain/hooks';

import NetworkLabel from '../NetworkLabel';

import type { AssetNetworkMarkProps } from './types';

const AssetNetworkMark: React.FC<AssetNetworkMarkProps> = React.memo(({ 'data-test': dataTest, value }) => {
  const asset = useAsset(value);
  return asset.data.data?.net ? (
    <NetworkLabel data-test={dataTest} tooltipMode="short" mode="medium" value={asset.data.data.net} noSwitcher />
  ) : null;
});

export default AssetNetworkMark;
