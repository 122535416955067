import { Alert } from 'antd';
import React from 'react';

import { FormattedMessage, OperationRefresh } from '@/components';
import { merchantWalletTransferKindI18n } from '@/features/merchant-wallet-transfers/components';
import { I18nPageAudit } from '@/generated/i18n/i18n';

import type { EntityNotFoundAlertProps } from './types';

const EntityNotFoundAlert: React.FC<EntityNotFoundAlertProps> = React.memo(
  ({ value, onRetry, 'data-test': dataTest }) => (
    <Alert
      data-test={dataTest}
      type="warning"
      message={
        <FormattedMessage
          id={I18nPageAudit.TRANSFER_ENTITY_COMPONENTS_ENTITY_CARD_ENTITY_NOT_FOUND_ALERT_TITLE}
          values={{ entity: <FormattedMessage id={merchantWalletTransferKindI18n[value]} /> }}
        />
      }
      description={
        <FormattedMessage
          id={I18nPageAudit.TRANSFER_ENTITY_COMPONENTS_ENTITY_CARD_ENTITY_NOT_FOUND_ALERT_DESCRIPTION}
          values={{
            retry: (text: React.ReactNode) => (
              <OperationRefresh
                data-test={dataTest && `${dataTest}-opRefresh`}
                mode="link"
                refresh={onRetry}
                messages={{ title: text }}
              />
            ),
            entity: <FormattedMessage id={merchantWalletTransferKindI18n[value]} />,
          }}
        />
      }
    />
  ),
);

export default EntityNotFoundAlert;
