import { Form, Select } from 'antd';
import React, { useMemo } from 'react';

import DataFetchError from '../DataFetchError';

import { DefaultSelectReadonlyComponent } from './components';

import type { SelectWithReloadingItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const SelectWithReloadingItem = <FormValues extends Store = Store, T = unknown, K extends number | string = string>({
  'data-test': dataTest,
  name,
  multiselect,
  readonly,
  required = true,
  disabled,
  messages,
  loading,
  ReadonlyComponent = DefaultSelectReadonlyComponent,
  options,
  dataToOptions,
  refresh,
  ItemProps = {},
  SelectInputProps = {},
}: SelectWithReloadingItemProps<FormValues, T, K>) => {
  const rules = useMemo(
    () => [
      ...(ItemProps.rules ?? []),
      ...(!readonly && messages.required && ItemProps.required !== false && required !== false
        ? [{ required: true, message: messages.required }]
        : []),
    ],
    [ItemProps.required, ItemProps.rules, messages.required, readonly, required],
  );
  return (
    <Form.Item<FormValues>
      label={messages.label}
      name={name}
      rules={rules}
      required={required && !readonly && !!messages.required}
      {...ItemProps}
    >
      {/* eslint-disable-next-line no-nested-ternary */}
      {readonly ? (
        <ReadonlyComponent
          data-test={dataTest}
          options={{ ...options, data: options.data?.map(dataToOptions) ?? [] }}
        />
      ) : !options.error ? (
        <Select
          allowClear
          showSearch
          mode={multiselect ? 'multiple' : undefined}
          placeholder={!disabled && messages.placeholder}
          loading={loading}
          disabled={loading || disabled}
          options={(options.data ?? []).map((v) => dataToOptions(v))}
          data-test={dataTest}
          {...SelectInputProps}
        />
      ) : (
        <DataFetchError
          refresh={refresh}
          message={messages.dataEmpty}
          data-test={dataTest && `${dataTest}-error`}
          description={options.error}
        />
      )}
    </Form.Item>
  );
};

const SelectWithReloadingItemMemo = React.memo(SelectWithReloadingItem) as typeof SelectWithReloadingItem;

export default SelectWithReloadingItemMemo;
