import { Form } from 'antd';
import React from 'react';

import { defaultDialogFormLayout } from '@/components';
import type { ReportScheduleParametersViewProps } from '@/features/global/hocs/withHandlerRegistration/schedules';

import MerchantWalletTransfersScheduleParametersForm from '../MerchantWalletTransfersReportScheduleParametersForm';

const MerchantWalletTransfersScheduleParametersView: React.FC<ReportScheduleParametersViewProps> = React.memo(
  ({ value, 'data-test': dataTest }) => (
    <Form initialValues={{ parameters: value }} {...defaultDialogFormLayout}>
      <MerchantWalletTransfersScheduleParametersForm data-test={dataTest} name="parameters" readonly />
    </Form>
  ),
);

export default MerchantWalletTransfersScheduleParametersView;
