/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3276
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompanyDomainAPIModel
 */
export interface CompanyDomainAPIModel {
    /**
     * 
     * @type {string}
     * @memberof CompanyDomainAPIModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDomainAPIModel
     */
    url: string;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyDomainAPIModel
     */
    isApproved: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyDomainAPIModel
     */
    isActive: boolean;
}

/**
 * Check if a given object implements the CompanyDomainAPIModel interface.
 */
export function instanceOfCompanyDomainAPIModel(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('url' in value)) return false;
    if (!('isApproved' in value)) return false;
    if (!('isActive' in value)) return false;
    return true;
}

export function CompanyDomainAPIModelFromJSON(json: any): CompanyDomainAPIModel {
    return CompanyDomainAPIModelFromJSONTyped(json, false);
}

export function CompanyDomainAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyDomainAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'url': json['url'],
        'isApproved': json['isApproved'],
        'isActive': json['isActive'],
    };
}

export function CompanyDomainAPIModelToJSON(value?: CompanyDomainAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'url': value['url'],
        'isApproved': value['isApproved'],
        'isActive': value['isActive'],
    };
}

