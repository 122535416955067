/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3276
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { WithdrawalBatchBTCOutputInvoicePaymentAPIModel } from './WithdrawalBatchBTCOutputInvoicePaymentAPIModel';
import {
    WithdrawalBatchBTCOutputInvoicePaymentAPIModelFromJSON,
    WithdrawalBatchBTCOutputInvoicePaymentAPIModelFromJSONTyped,
    WithdrawalBatchBTCOutputInvoicePaymentAPIModelToJSON,
} from './WithdrawalBatchBTCOutputInvoicePaymentAPIModel';

/**
 * 
 * @export
 * @interface WithdrawalBatchBTCOutputTransactionAPIModel
 */
export interface WithdrawalBatchBTCOutputTransactionAPIModel {
    /**
     * 
     * @type {string}
     * @memberof WithdrawalBatchBTCOutputTransactionAPIModel
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof WithdrawalBatchBTCOutputTransactionAPIModel
     */
    batchNum: number;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalBatchBTCOutputTransactionAPIModel
     */
    txHash: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalBatchBTCOutputTransactionAPIModel
     */
    txHex: string;
    /**
     * 
     * @type {Array<WithdrawalBatchBTCOutputInvoicePaymentAPIModel>}
     * @memberof WithdrawalBatchBTCOutputTransactionAPIModel
     */
    orderedPayments?: Array<WithdrawalBatchBTCOutputInvoicePaymentAPIModel>;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalBatchBTCOutputTransactionAPIModel
     */
    totalAmount: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalBatchBTCOutputTransactionAPIModel
     */
    totalAmountRaw: string;
}

/**
 * Check if a given object implements the WithdrawalBatchBTCOutputTransactionAPIModel interface.
 */
export function instanceOfWithdrawalBatchBTCOutputTransactionAPIModel(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('batchNum' in value)) return false;
    if (!('txHash' in value)) return false;
    if (!('txHex' in value)) return false;
    if (!('totalAmount' in value)) return false;
    if (!('totalAmountRaw' in value)) return false;
    return true;
}

export function WithdrawalBatchBTCOutputTransactionAPIModelFromJSON(json: any): WithdrawalBatchBTCOutputTransactionAPIModel {
    return WithdrawalBatchBTCOutputTransactionAPIModelFromJSONTyped(json, false);
}

export function WithdrawalBatchBTCOutputTransactionAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): WithdrawalBatchBTCOutputTransactionAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'batchNum': json['batchNum'],
        'txHash': json['txHash'],
        'txHex': json['txHex'],
        'orderedPayments': json['orderedPayments'] == null ? undefined : ((json['orderedPayments'] as Array<any>).map(WithdrawalBatchBTCOutputInvoicePaymentAPIModelFromJSON)),
        'totalAmount': json['totalAmount'],
        'totalAmountRaw': json['totalAmountRaw'],
    };
}

export function WithdrawalBatchBTCOutputTransactionAPIModelToJSON(value?: WithdrawalBatchBTCOutputTransactionAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'batchNum': value['batchNum'],
        'txHash': value['txHash'],
        'txHex': value['txHex'],
        'orderedPayments': value['orderedPayments'] == null ? undefined : ((value['orderedPayments'] as Array<any>).map(WithdrawalBatchBTCOutputInvoicePaymentAPIModelToJSON)),
        'totalAmount': value['totalAmount'],
        'totalAmountRaw': value['totalAmountRaw'],
    };
}

