import { Button, Divider, Form } from 'antd';
import BigNumber from 'bignumber.js';
import noop from 'lodash/noop';
import { useCallback, useMemo, useState } from 'react';
import React from 'react';

import {
  defaultPageFormLayout,
  defaultPageFormTailLayout,
  ErrorFormMessage,
  FormattedMessage,
  FormCompletenessItem,
  FormFooter,
} from '@/components';
import { I18nFeatureSettlementPayouts } from '@/generated/i18n/i18n';
import { useErrorSafeSubmitting, useForm, useStateMountSafe } from '@/hooks';
import { assertNotNil } from '@/infrastructure/utils/functions';
import { asType, nameof } from '@/infrastructure/utils/ts';

import { PayoutSummaryView, SelectBalanceItem, PayoutDestinationsItem } from './components';

import type { PayoutDestinationItem } from './components';
import type { CreatePayoutFormProps, NewPayoutFormValues } from './types';
import type { FormInstance } from 'antd/es/form';

const formMessages = {
  submit: (
    <FormattedMessage id={I18nFeatureSettlementPayouts.COMPONENTS_CREATE_PAYOUT_FORM_SUBMIT_BUTTON} tagName="span" />
  ),
};

const checkIsComplete = ({ getFieldValue }: FormInstance<NewPayoutFormValues>) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const destinations: NewPayoutFormValues['destinations'] = getFieldValue(nameof<NewPayoutFormValues>('destinations'));
  const total = destinations?.length ? BigNumber.sum(...destinations.map(({ amount }) => amount)) : BigNumber(0);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const balance: NewPayoutFormValues['balance'] = getFieldValue(nameof<NewPayoutFormValues>('balance'));
  return !!balance && total.isGreaterThan(0) && total.isLessThanOrEqualTo(balance);
};

const fields = [nameof<NewPayoutFormValues>('asset'), nameof<NewPayoutFormValues>('destinations')];

const CreatePayoutForm: React.FC<CreatePayoutFormProps> = ({
  'data-test': dataTest,
  onSubmit,
  onReset = noop,
  disabled,
}) => {
  const { form, withResetForm } = useForm<NewPayoutFormValues>();
  const [isFormComplete, setFormComplete] = useStateMountSafe(false);
  const { error, reset: resetSubmit, withErrorHandling } = useErrorSafeSubmitting();
  const [destinationItems, setDestinationItems] = useState<PayoutDestinationItem[]>();

  const doReset = useMemo(() => withResetForm(resetSubmit), [resetSubmit, withResetForm]);
  const doCancel = useMemo(() => withErrorHandling(onReset), [onReset, withErrorHandling]);

  const doSubmit = useCallback(
    ({ asset, destinations }: NewPayoutFormValues) => {
      assertNotNil(asset, () => new Error('invalid form'));
      assertNotNil(destinations, () => new Error('invalid form'));
      onSubmit?.({ asset, destinations });
    },
    [onSubmit],
  );

  const onFormValuesChanged = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    resetSubmit();
  }, [resetSubmit]);

  const resetTable = useCallback(() => {
    setDestinationItems([]);
  }, [setDestinationItems]);

  return (
    <Form<NewPayoutFormValues>
      onValuesChange={onFormValuesChanged}
      onResetCapture={doReset}
      {...defaultPageFormLayout}
      autoComplete="off"
      form={form}
      onFinish={doSubmit}
      onReset={doCancel}
    >
      {error && <ErrorFormMessage data-test={dataTest && `${dataTest}-error`} content={error} />}
      <SelectBalanceItem<NewPayoutFormValues>
        data-test={dataTest && `${dataTest}-balance`}
        name={nameof<NewPayoutFormValues>('asset')}
        balanceName={nameof<NewPayoutFormValues>('balance')}
      />
      <PayoutDestinationsItem<NewPayoutFormValues>
        data-test={dataTest && `${dataTest}-destinations`}
        name={nameof<NewPayoutFormValues>('destinations')}
        assetItemName={nameof<NewPayoutFormValues>('asset')}
        readonly={disabled}
      />
      <Divider />
      <Form.Item<NewPayoutFormValues>
        dependencies={[nameof<NewPayoutFormValues>('asset'), nameof<NewPayoutFormValues>('destinations')]}
      >
        {({ getFieldValue }) => (
          <PayoutSummaryView
            data-test={dataTest && `${dataTest}-call-data`}
            /* eslint-disable-next-line @typescript-eslint/no-unsafe-argument */
            asset={asType<NewPayoutFormValues['asset']>(getFieldValue('asset'))}
            /* eslint-disable-next-line @typescript-eslint/no-unsafe-argument */
            destinations={asType<NewPayoutFormValues['destinations']>(getFieldValue('destinations'))}
          />
        )}
      </Form.Item>
      <FormCompletenessItem<NewPayoutFormValues>
        requiredFields={fields}
        onChange={setFormComplete}
        checkIsComplete={checkIsComplete}
      />
      <Divider />
      <FormFooter
        data-test={dataTest && `${dataTest}-footer`}
        messages={formMessages}
        extraButtons={useMemo(
          () => ({
            mid: (
              <Button
                data-test={dataTest && `${dataTest}-reset`}
                disabled={disabled || !destinationItems?.length}
                onClick={resetTable}
              >
                <FormattedMessage
                  id={I18nFeatureSettlementPayouts.COMPONENTS_CREATE_PAYOUT_FORM_RESET_TABLE}
                  tagName="span"
                />
              </Button>
            ),
          }),
          [dataTest, destinationItems?.length, disabled, resetTable],
        )}
        tailLayout={defaultPageFormTailLayout}
        submitDisabled={!isFormComplete || disabled}
        noCancel
        noStyle
      />
    </Form>
  );
};

const CreatePayoutFormMemo = React.memo(CreatePayoutForm);

export default CreatePayoutFormMemo;
