import { withPageContainer } from '@/hocs';
import type { TestableProps } from '@/infrastructure/utils/react';
import { withAuditTransferPageParams } from '@/pages/audit/hocs';

import { MerchantWalletTransferEntityBreadcrumb, MerchantWalletTransferEntityCard } from './components';

import type React from 'react';
import type { PropsWithChildren } from 'react';

interface MerchantWalletTransferEntityPageProps
  extends PropsWithChildren<TestableProps & { pageParams: { transferId: string } }> {}

const MerchantWalletTransferEntityPage: React.FC<MerchantWalletTransferEntityPageProps> = ({
  'data-test': dataTest = 'mwte',
  pageParams: { transferId },
}) => <MerchantWalletTransferEntityCard data-test={dataTest && `${dataTest}-card`} transferId={transferId} />;

export default withAuditTransferPageParams(
  withPageContainer<MerchantWalletTransferEntityPageProps>(({ pageParams: { transferId } }) => ({
    breadcrumbRender: () => <MerchantWalletTransferEntityBreadcrumb transferId={transferId} disabled />,
  }))(MerchantWalletTransferEntityPage),
);
