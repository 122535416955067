/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3276
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RenewalTokenInfoAPIModel
 */
export interface RenewalTokenInfoAPIModel {
    /**
     * 
     * @type {string}
     * @memberof RenewalTokenInfoAPIModel
     */
    token: string;
    /**
     * 
     * @type {Date}
     * @memberof RenewalTokenInfoAPIModel
     */
    expiresAt: Date;
}

/**
 * Check if a given object implements the RenewalTokenInfoAPIModel interface.
 */
export function instanceOfRenewalTokenInfoAPIModel(value: object): boolean {
    if (!('token' in value)) return false;
    if (!('expiresAt' in value)) return false;
    return true;
}

export function RenewalTokenInfoAPIModelFromJSON(json: any): RenewalTokenInfoAPIModel {
    return RenewalTokenInfoAPIModelFromJSONTyped(json, false);
}

export function RenewalTokenInfoAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): RenewalTokenInfoAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'token': json['token'],
        'expiresAt': (new Date(json['expiresAt'])),
    };
}

export function RenewalTokenInfoAPIModelToJSON(value?: RenewalTokenInfoAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'token': value['token'],
        'expiresAt': ((value['expiresAt']).toISOString()),
    };
}

