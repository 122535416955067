import { SearchOutlined } from '@ant-design/icons';
import { Alert, Button, Input, type InputRef, Space, type TableColumnType } from 'antd';
import { useCallback, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';

import type { PayoutDestinationItem } from '@/features/settlements/components/CreatePayoutForm/components';
import { I18nFeatureSettlementPayouts } from '@/generated/i18n/i18n';
import { useStateMountSafe } from '@/hooks';

import type { UsePayoutDestinationFilter } from './types';
import type { FilterDropdownProps } from 'antd/es/table/interface';

// https://ant.design/components/table#components-table-demo-custom-filter-panel
const usePayoutDestinationFilter = ({ 'data-test': dataTest, disabled }: UsePayoutDestinationFilter) => {
  const { formatMessage: i18n } = useIntl();

  const [filterText, setFilterText] = useStateMountSafe('');

  const filterInput = useRef<InputRef>(null);

  const clearFilter = useCallback(
    (confirm?: FilterDropdownProps['confirm']) => {
      confirm?.();
      setFilterText('');
    },
    [setFilterText],
  );

  const handleFilter = useCallback(
    (selectedKeys: string[], confirm?: FilterDropdownProps['confirm']) => {
      if (selectedKeys[0] && selectedKeys[0] !== '') {
        setFilterText(selectedKeys[0] || '');
        confirm?.();
      } else {
        clearFilter(confirm);
      }
    },
    [clearFilter, setFilterText],
  );

  const filterColumnMeta: Partial<TableColumnType<PayoutDestinationItem>> = useMemo(
    () => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
        <Space style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
          <Input
            data-test={dataTest && `${dataTest}-input`}
            ref={filterInput}
            placeholder={i18n({
              id: I18nFeatureSettlementPayouts.COMPONENTS_CREATE_PAYOUT_FORM_DESTINATIONS_TABLE_FILTER_ADDRESS_PLACEHOLDER,
            })}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleFilter(selectedKeys as string[], confirm)}
            disabled={disabled}
          />
          <Button
            data-test={dataTest && `${dataTest}-apply`}
            type="primary"
            onClick={() => handleFilter(selectedKeys as string[], confirm)}
            icon={<SearchOutlined />}
            size="small"
            disabled={disabled}
          />
          <Button
            data-test={dataTest && `${dataTest}-cancel`}
            onClick={() => clearFilter(confirm)}
            size="small"
            disabled={disabled}
          >
            {i18n({
              id: I18nFeatureSettlementPayouts.COMPONENTS_CREATE_PAYOUT_FORM_DESTINATIONS_TABLE_FILTER_RESET_TITLE,
            })}
          </Button>
        </Space>
      ),
      filteredValue: filterText ? [filterText] : null,
      filtered: !!filterText,
      filterIcon: (filtered: boolean) => (
        <SearchOutlined
          data-test={dataTest && `${dataTest}-icon`}
          style={{ color: filtered ? '#2A6DDA' : 'rgba(0, 0, 0, 0.85)' }}
        />
      ),
      onFilter: (_, record) => record.address.toLowerCase().includes(filterText.toLowerCase()),
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => filterInput.current?.select(), 100);
        }
      },
    }),
    [clearFilter, dataTest, disabled, filterText, handleFilter, i18n],
  );

  const Banner = useMemo(
    () =>
      filterText ? (
        <Alert
          data-test={dataTest && `${dataTest}-banner`}
          message={i18n(
            { id: I18nFeatureSettlementPayouts.COMPONENTS_CREATE_PAYOUT_FORM_DESTINATIONS_TABLE_FILTER_BANNER_TITLE },
            { text: filterText },
          )}
          type="info"
          action={
            <Button
              data-test={dataTest && `${dataTest}-cancel`}
              onClick={() => clearFilter()}
              size="small"
              disabled={disabled}
            >
              {i18n({
                id: I18nFeatureSettlementPayouts.COMPONENTS_CREATE_PAYOUT_FORM_DESTINATIONS_TABLE_FILTER_RESET_TITLE,
              })}
            </Button>
          }
        />
      ) : null,
    [filterText, dataTest, i18n, disabled, clearFilter],
  );

  return {
    isFiltered: !!filterText,
    filterColumnMeta,
    Banner,
  };
};

export default usePayoutDestinationFilter;
