import { Navigate } from 'react-router-dom';

import { usePreferences } from '@/features/user/hooks';
import { namedHOC } from '@/infrastructure/utils/react';
import { dashboardLink } from '@/pages/dashboard/routes';

import type React from 'react';


const withOnboardUnfinishedGuard = <P extends Record<string, unknown>>(Component: React.FC<P>): React.FC<P> =>
  namedHOC(
    Component,
    'WithOnboardUnfinishedGuard',
  )((props) => {
    const {
      data: { data: preferences },
    } = usePreferences();

    if (preferences?.onboarded) {
      return <Navigate to={dashboardLink()} />;
    }
    return <Component {...props} />;
  });

export default withOnboardUnfinishedGuard;
