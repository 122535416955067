import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

import useFeatureToggle from '@/features/feature-toggle/hooks/useFeatureToggle';
import { namedHOC } from '@/infrastructure/utils/react';

import type React from 'react';
import type { PropsWithChildren } from 'react';

const withTawk = <P extends PropsWithChildren<unknown>>(Component: React.FC<P>): React.FC<P> =>
  namedHOC(
    Component,
    'WithTawk',
  )((props) => {
    const { data } = useFeatureToggle();
    return (
      <>
        <Component {...props} />
        {window.env.TAWK_ENABLED
          && window.env.TAWK_WIDGET_ID
          && window.env.TAWK_PROPERTY_ID
          && !data.data?.disableTawk && (
            <TawkMessengerReact
              customStyle={{
                visibility: {
                  //for desktop only
                  desktop: {
                    position: 'br', // bottom-right
                    xOffset: 15, // 15px away from right
                    yOffset: 50, // 50px up from bottom
                  },
                  // for mobile only
                  mobile: {
                    position: 'bl', // bottom-left
                    xOffset: 5, // 5px away from left
                  },
                },
              }}
              propertyId={window.env.TAWK_PROPERTY_ID}
              widgetId={window.env.TAWK_WIDGET_ID}
              data-test="tawk-messenger"
            />
          )}
      </>
    );
  });

export default withTawk;
