import type {
  BlockchainTypeAPIModel,
  LongRunningTaskStatusAPIModel,
  SubscriptionPredicatesAPIModel,
  SubscriptionSummaryAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import { SubscriptionSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { AssetAmountValue } from '@/infrastructure/api';
import { defaultPage } from '@/infrastructure/api';
import type { ListColumnState, ListSortBy, ListState, ListStateNoColumns } from '@/infrastructure/model/list/types';
import { storedDirtyListDataTyped } from '@/infrastructure/model/list/utils';
import type { SingleState } from '@/infrastructure/model/single/types';

export const NAMESPACE = 'subscriptions';

export interface Subscription
  extends Omit<SubscriptionSummaryAPIModel, 'blockchain' | 'pendingStatuses' | 'allowance'> {
  id: string;
  blockchain: BlockchainTypeAPIModel;
  pendingStatuses: LongRunningTaskStatusAPIModel[];
  allowance: AssetAmountValue;
}

export interface SubscriptionWithActions extends Subscription {
  actions: { pausable: boolean; cancelable: boolean; unpausable: boolean };
}

export interface SubscriptionExtended extends SubscriptionWithActions {
  isGasWalletStateInvalid: boolean;
}

export interface SubscriptionFilterPredicate
  extends Pick<
    SubscriptionPredicatesAPIModel,
    'contractAddress' | 'blockchain' | 'createdAt' | 'statusIn' | 'networkType' | 'payer' | 'planId'
  > {}

export interface SubscriptionSortBy extends ListSortBy<SubscriptionSortByAPIModel> {}

export interface SubscriptionForPlanFilterPredicate extends SubscriptionFilterPredicate {}

export interface SubscriptionForPlanListState
  extends ListStateNoColumns<string, SubscriptionForPlanFilterPredicate, SubscriptionSortByAPIModel> {}

export const defaultSortBy: SubscriptionSortBy = { [SubscriptionSortByAPIModel.CreatedAt]: 'DESC' };
export const defaultSubscriptionForPlanState: SubscriptionForPlanListState = {
  data: storedDirtyListDataTyped(),
  filter: {},
  sortBy: defaultSortBy,
  page: defaultPage,
};

export interface SubscriptionsState {
  charges: SingleState<string[]>; // subscription id

  list: ListState<string, SubscriptionFilterPredicate, SubscriptionSortByAPIModel>;
  entities: SingleState<Subscription>;

  statusChangingSubscriptions: string[];

  byPlan: {
    columnState: ListColumnState;
    data: Partial<Record<string, SubscriptionForPlanListState>>; // plan Id
  };
}
