import { useSettlement } from '@/features/settlements/hooks';
import type { Settlement } from '@/features/settlements/types';
import { namedHOC } from '@/infrastructure/utils/react';

import type React from 'react';

const emptyComponent = () => null;

const withSettlementLoad = <T extends { settlement: Settlement }>(
  Component: React.ComponentType<Omit<T, 'settlement'> & { settlement: Settlement }>,
  EmptyComponent: React.ComponentType<Omit<T, 'settlement'>>,
) =>
  namedHOC<Omit<T, 'settlement'> & { settlement: Settlement }, Omit<T, 'settlement'> & { settlementId: string }>(
    Component,
    'WithSettlementLoad',
  )((props) => {
    const { settlementId } = props;
    const settlement = useSettlement(settlementId);
    return settlement.data.data ? (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Component {...props} settlement={settlement.data.data} />
    ) : (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <EmptyComponent {...props} />
    );
  });

const withSettlement =
  <T extends { settlement: Settlement }>(EmptyComponent: React.ComponentType<Omit<T, 'settlement'>> = emptyComponent) =>
  (Component: React.ComponentType<Omit<T, 'settlement'> & { settlement: Settlement }>) => {
    const LoadComponent = withSettlementLoad<T>(Component, EmptyComponent);
    return (props: T | (Omit<T, 'settlement'> & { settlementId: string })) => {
      const { settlement } = props as Partial<T>;
      const { settlementId } = props as { settlementId: string };
      return settlement ? (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Component {...props} settlement={settlement} />
      ) : (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <LoadComponent {...props} settlementId={settlementId} />
      );
    };
  };

export default withSettlement;
