import { Alert, Tag } from 'antd';
import React from 'react';

import { FormattedMessage, SupportEmail, WarningTooltip } from '@/components';
import type { SettlementReconciliationStatusBannerProps } from '@/features/settlement-reconciliations/components/SettlementReconciliationBanner/types';
import { I18nFeatureSettlementReconciliations } from '@/generated/i18n/i18n';

const SettlementReconciliationRetryingBanner: React.FC<SettlementReconciliationStatusBannerProps> = ({
  'data-test': dataTest,
  settlementId,
  companyId,
  mode,
  style,
  className,
  SettlementReconciliationLink,
}) =>
  mode === 'tag' ? (
    <WarningTooltip
      title={
        <FormattedMessage
          id={I18nFeatureSettlementReconciliations.COMPONENTS_RECONCILIATION_BANNER_RETRYING_DESCRIPTION}
          values={{
            email: (text: React.ReactNode) => (
              <SupportEmail title={text} subject={`[${companyId}]: Reconciliation ${settlementId}`} />
            ),
            ln:
              SettlementReconciliationLink
              && ((title: React.ReactNode) => (
                <SettlementReconciliationLink value={settlementId} title={title} mode="text" />
              )),
          }}
        />
      }
    >
      <Tag data-test={dataTest} color="orange" className={className} style={{ ...style, marginRight: 0 }}>
        <FormattedMessage id={I18nFeatureSettlementReconciliations.COMPONENTS_RECONCILIATION_BANNER_RETRYING_TAG} />
      </Tag>
    </WarningTooltip>
  ) : (
    <Alert
      data-test={dataTest}
      className={className}
      style={style}
      message={
        <FormattedMessage id={I18nFeatureSettlementReconciliations.COMPONENTS_RECONCILIATION_BANNER_RETRYING_TITLE} />
      }
      description={
        <FormattedMessage
          id={I18nFeatureSettlementReconciliations.COMPONENTS_RECONCILIATION_BANNER_RETRYING_DESCRIPTION}
          values={{
            email: (text: React.ReactNode) => (
              <SupportEmail title={text} subject={`[${companyId}]: Reconciliation ${settlementId}`} />
            ),
            ln:
              SettlementReconciliationLink
              && ((title: React.ReactNode) => (
                <SettlementReconciliationLink value={settlementId} title={title} mode="text" />
              )),
          }}
        />
      }
      type="warning"
      showIcon
    />
  );

const SettlementReconciliationRetryingBannerMemo = React.memo(SettlementReconciliationRetryingBanner);

export default SettlementReconciliationRetryingBannerMemo;
