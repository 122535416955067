/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3276
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/


import * as runtime from '../runtime';
import type {
  ApiErrorAPIModel,
  CompanyDomainCreateRequestAPIModel,
  CompanyDomainsResponseAPIModel,
} from '../models/index';
import {
    ApiErrorAPIModelFromJSON,
    ApiErrorAPIModelToJSON,
    CompanyDomainCreateRequestAPIModelFromJSON,
    CompanyDomainCreateRequestAPIModelToJSON,
    CompanyDomainsResponseAPIModelFromJSON,
    CompanyDomainsResponseAPIModelToJSON,
} from '../models/index';

export interface ActivateCompanyDomainRequest {
    domainId: string;
}

export interface CreateCompanyDomainRequest {
    companyDomainCreateRequestAPIModel: CompanyDomainCreateRequestAPIModel;
}

export interface DeactivateCompanyDomainRequest {
    domainId: string;
}

export interface DeleteCompanyDomainRequest {
    domainId: string;
}

/**
 * CompanyDomainsApi - interface
 * 
 * @export
 * @interface CompanyDomainsApiInterface
 */
export interface CompanyDomainsApiInterface {
    /**
     * Activates company domain
     * @param {string} domainId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyDomainsApiInterface
     */
    activateCompanyDomainRaw(requestParameters: ActivateCompanyDomainRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyDomainsResponseAPIModel>>;

    /**
     * Activates company domain
     */
    activateCompanyDomain(domainId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyDomainsResponseAPIModel>;

    /**
     * Register a new domain
     * @param {CompanyDomainCreateRequestAPIModel} companyDomainCreateRequestAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyDomainsApiInterface
     */
    createCompanyDomainRaw(requestParameters: CreateCompanyDomainRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyDomainsResponseAPIModel>>;

    /**
     * Register a new domain
     */
    createCompanyDomain(companyDomainCreateRequestAPIModel: CompanyDomainCreateRequestAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyDomainsResponseAPIModel>;

    /**
     * Deactivates company domain
     * @param {string} domainId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyDomainsApiInterface
     */
    deactivateCompanyDomainRaw(requestParameters: DeactivateCompanyDomainRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyDomainsResponseAPIModel>>;

    /**
     * Deactivates company domain
     */
    deactivateCompanyDomain(domainId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyDomainsResponseAPIModel>;

    /**
     * Deletes company domain
     * @param {string} domainId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyDomainsApiInterface
     */
    deleteCompanyDomainRaw(requestParameters: DeleteCompanyDomainRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyDomainsResponseAPIModel>>;

    /**
     * Deletes company domain
     */
    deleteCompanyDomain(domainId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyDomainsResponseAPIModel>;

    /**
     * Returns company domains
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyDomainsApiInterface
     */
    getCompanyDomainsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyDomainsResponseAPIModel>>;

    /**
     * Returns company domains
     */
    getCompanyDomains(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyDomainsResponseAPIModel>;

}

/**
 * 
 */
export class CompanyDomainsApi extends runtime.BaseAPI implements CompanyDomainsApiInterface {

    /**
     * Activates company domain
     */
    async activateCompanyDomainRaw(requestParameters: ActivateCompanyDomainRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyDomainsResponseAPIModel>> {
        if (requestParameters['domainId'] == null) {
            throw new runtime.RequiredError(
                'domainId',
                'Required parameter "domainId" was null or undefined when calling activateCompanyDomain().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/domains/{domainId}/activate`.replace(`{${"domainId"}}`, encodeURIComponent(String(requestParameters['domainId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyDomainsResponseAPIModelFromJSON(jsonValue));
    }

    /**
     * Activates company domain
     */
    async activateCompanyDomain(domainId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyDomainsResponseAPIModel> {
        const response = await this.activateCompanyDomainRaw({ domainId: domainId }, initOverrides);
        return await response.value();
    }

    /**
     * Register a new domain
     */
    async createCompanyDomainRaw(requestParameters: CreateCompanyDomainRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyDomainsResponseAPIModel>> {
        if (requestParameters['companyDomainCreateRequestAPIModel'] == null) {
            throw new runtime.RequiredError(
                'companyDomainCreateRequestAPIModel',
                'Required parameter "companyDomainCreateRequestAPIModel" was null or undefined when calling createCompanyDomain().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/domains`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyDomainCreateRequestAPIModelToJSON(requestParameters['companyDomainCreateRequestAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyDomainsResponseAPIModelFromJSON(jsonValue));
    }

    /**
     * Register a new domain
     */
    async createCompanyDomain(companyDomainCreateRequestAPIModel: CompanyDomainCreateRequestAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyDomainsResponseAPIModel> {
        const response = await this.createCompanyDomainRaw({ companyDomainCreateRequestAPIModel: companyDomainCreateRequestAPIModel }, initOverrides);
        return await response.value();
    }

    /**
     * Deactivates company domain
     */
    async deactivateCompanyDomainRaw(requestParameters: DeactivateCompanyDomainRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyDomainsResponseAPIModel>> {
        if (requestParameters['domainId'] == null) {
            throw new runtime.RequiredError(
                'domainId',
                'Required parameter "domainId" was null or undefined when calling deactivateCompanyDomain().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/domains/{domainId}/deactivate`.replace(`{${"domainId"}}`, encodeURIComponent(String(requestParameters['domainId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyDomainsResponseAPIModelFromJSON(jsonValue));
    }

    /**
     * Deactivates company domain
     */
    async deactivateCompanyDomain(domainId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyDomainsResponseAPIModel> {
        const response = await this.deactivateCompanyDomainRaw({ domainId: domainId }, initOverrides);
        return await response.value();
    }

    /**
     * Deletes company domain
     */
    async deleteCompanyDomainRaw(requestParameters: DeleteCompanyDomainRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyDomainsResponseAPIModel>> {
        if (requestParameters['domainId'] == null) {
            throw new runtime.RequiredError(
                'domainId',
                'Required parameter "domainId" was null or undefined when calling deleteCompanyDomain().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/domains/{domainId}`.replace(`{${"domainId"}}`, encodeURIComponent(String(requestParameters['domainId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyDomainsResponseAPIModelFromJSON(jsonValue));
    }

    /**
     * Deletes company domain
     */
    async deleteCompanyDomain(domainId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyDomainsResponseAPIModel> {
        const response = await this.deleteCompanyDomainRaw({ domainId: domainId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns company domains
     */
    async getCompanyDomainsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyDomainsResponseAPIModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/domains`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyDomainsResponseAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns company domains
     */
    async getCompanyDomains(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyDomainsResponseAPIModel> {
        const response = await this.getCompanyDomainsRaw(initOverrides);
        return await response.value();
    }

}
