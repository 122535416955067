import { css } from '@emotion/css';
import { Form, Space } from 'antd';
import React, { useCallback } from 'react';

import { FormattedMessage, SelectWithReloadingItem } from '@/components';
import { AssetAmount, AssetIcon, BlockchainLabel } from '@/features/dictionary/blockchain/components';
import type { PayoutBalance } from '@/features/settlements/hooks';
import { usePayoutBalances } from '@/features/settlements/hooks';
import { I18nFeatureSettlementPayouts } from '@/generated/i18n/i18n';

import type { SelectBalanceItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const messages = {
  label: <FormattedMessage id={I18nFeatureSettlementPayouts.COMPONENTS_CREATE_PAYOUT_FORM_SELECT_BALANCE_LABEL} />,
  placeholder: (
    <FormattedMessage id={I18nFeatureSettlementPayouts.COMPONENTS_CREATE_PAYOUT_FORM_SELECT_BALANCE_PLACEHOLDER} />
  ),
};

const containerStyle = css`
  justify-content: space-between;
  display: flex;
  flex: 1;
`;

const SelectBalanceItem = <Values extends Store = Store>({
  name,
  balanceName,
  'data-test': dataTest,
}: SelectBalanceItemProps<Values>) => {
  const { balances, loading, forceRefresh } = usePayoutBalances();

  return (
    <>
      <SelectWithReloadingItem<Values, PayoutBalance>
        data-test={dataTest}
        name={name}
        messages={messages}
        refresh={forceRefresh}
        loading={loading}
        options={balances}
        dataToOptions={useCallback(
          ({ balance, bc: { blockchain }, asset: { code: asset } }) => ({
            value: asset,
            disabled: !balance.value.isGreaterThan(0),
            label: (
              <Space className={containerStyle}>
                <Space>
                  <AssetIcon type={asset} data-test={dataTest && `${dataTest}-${asset}-icon`} />
                  <AssetAmount
                    value={balance.value}
                    assetId={balance.asset}
                    withCurrency
                    data-test={dataTest && `${dataTest}-${asset}-available`}
                  />
                </Space>
                <BlockchainLabel
                  bt={blockchain}
                  data-test={dataTest && `${dataTest}-${blockchain}-label`}
                  mode="short"
                  copyable={false}
                />
              </Space>
            ),
          }),
          [dataTest],
        )}
      />
      <Form.Item<Values> hidden dependencies={[name]}>
        {({ getFieldValue, setFieldValue }) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const asset: string | undefined = getFieldValue(name);
          if (asset) {
            setTimeout(() =>
              setFieldValue(balanceName, balances.data?.find((balance) => balance.asset.code === asset)?.balance.value),
            );
          }
          return null;
        }}
      </Form.Item>
    </>
  );
};

const SelectBalanceItemMemo = React.memo(SelectBalanceItem) as typeof SelectBalanceItem;

export default SelectBalanceItemMemo;
