import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import React, { useCallback } from 'react';

import { Operation } from '@/components';

import type { PayoutReorderOperationProps } from './types';

const PayoutReorderOperation: React.FC<PayoutReorderOperationProps> = ({
  'data-test': dataTest,
  data,
  selected,
  onChange,
  disabled,
}) => {
  const selectedIndex = data.findIndex((checkItem) => checkItem.key === selected.key);
  const isFirst = selectedIndex === 0;
  const isLast = selectedIndex === data.length - 1;

  const moveRow = useCallback(
    (up: boolean) => {
      if (up && isFirst) {
        return;
      }
      if (!up && isLast) {
        return;
      }
      const newIndex = up ? selectedIndex - 1 : selectedIndex + 1;
      const oldIndex = up ? selectedIndex + 1 : selectedIndex - 1;

      const valueToReplace = data.find((_, idx) => newIndex === idx)!;
      const newData = data.map((value, idx) => {
        switch (idx) {
          case newIndex:
            return selected;
          case oldIndex:
            return valueToReplace;
          default:
            return value;
        }
      });
      onChange(newData);
    },
    [isFirst, isLast, selectedIndex, data, selected, onChange],
  );

  return (
    <>
      <Operation
        disabled={isFirst || disabled}
        title={null}
        icon={<ArrowUpOutlined />}
        onClick={useCallback(() => moveRow(true), [moveRow])}
        data-test={dataTest && `${dataTest}-up`}
        mode="inline"
      />
      <Operation
        disabled={isLast || disabled}
        title={null}
        icon={<ArrowDownOutlined />}
        onClick={useCallback(() => moveRow(false), [moveRow])}
        data-test={dataTest && `${dataTest}-down`}
        mode="inline"
      />
    </>
  );
};

const PayoutReorderOperationMemo = React.memo(PayoutReorderOperation);

export default PayoutReorderOperationMemo;
