import { noIdNestedListFetchResult, useAppNestedListData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { makeSelectPending } from '@/features/global/selectors';
import { fetchSettlementsForAsset } from '@/features/settlements/actions';
import {
  makeSelectSettlementsForAssetListData,
  makeSelectSettlementsForAssetListParameters,
} from '@/features/settlements/selectors';
import type { Settlement } from '@/features/settlements/types';
import type { LoadingListDataState } from '@/infrastructure/model/list/types';

const fetchFactory =
  (asset: string) =>
  (
    force: boolean,
  ): AppAsyncThunkAction<LoadingListDataState<Settlement>, Parameters<typeof fetchSettlementsForAsset>[0]> =>
    fetchSettlementsForAsset({ asset, force });

const pendingSelectorCreator = (asset: string) => makeSelectPending(fetchSettlementsForAsset, asset);

export default function useSettlementForAssetList(asset: string | undefined) {
  return useAppNestedListData(
    fetchFactory,
    makeSelectSettlementsForAssetListParameters,
    pendingSelectorCreator,
    makeSelectSettlementsForAssetListData,
    noIdNestedListFetchResult,
    asset,
  );
}
