import { useMemo } from 'react';

import { type UseAppSingleData, useAppSingleData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { makeSelectPending } from '@/features/global/selectors';
import type { PaymentTransaction } from '@/features/payment-transactions/types';
import { fetchPaymentTransactionForPayment } from '@/features/payments/actions';
import { makeSelectTransactionsForPayment } from '@/features/payments/selectors';
import { type LoadingStateWithDirty, storedDataLoaded } from '@/infrastructure/model';

export interface UsePaymentTransactionsForPayment extends UseAppSingleData<PaymentTransaction[]> {}

const noData = storedDataLoaded([]);

const fetchFactory = (
  paymentId: string,
  force: boolean,
): AppAsyncThunkAction<
  LoadingStateWithDirty<PaymentTransaction[]>,
  Parameters<typeof fetchPaymentTransactionForPayment>[0]
> => fetchPaymentTransactionForPayment({ paymentId, force });

export default function usePaymentTransactionForPayment(
  paymentId: string | undefined,
): UsePaymentTransactionsForPayment {
  const dataFetchingSelector = useMemo(
    () => makeSelectPending(fetchPaymentTransactionForPayment, paymentId),
    [paymentId],
  );
  return useAppSingleData(fetchFactory, makeSelectTransactionsForPayment, noData, paymentId, dataFetchingSelector);
}
