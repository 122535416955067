import { useMemo } from 'react';

import { PageContainer } from '@/components';
import type { TestableProps } from '@/infrastructure/utils/react';
import { namedHOC } from '@/infrastructure/utils/react';

import type { PageContainerProps } from '@ant-design/pro-layout/lib/components/PageContainer';
import type React from 'react';

const withPageContainer =
  <T extends TestableProps>(containerPropsFactory?: (props: T) => PageContainerProps, defaultDataTest?: string) =>
  (Component: React.ComponentType<T>): React.FC<T> =>
    namedHOC(
      Component,
      'WithPageContainer',
    )((props: T) => {
      const dataTest = props['data-test'] ?? defaultDataTest;
      const pageContainerProps = useMemo(
        () => containerPropsFactory?.({ ...props, 'data-test': dataTest }),
        [dataTest, props],
      );
      return (
        <PageContainer data-test={dataTest && `${dataTest}-container`} PageContainerProps={pageContainerProps}>
          <Component {...props} data-test={dataTest} />
        </PageContainer>
      );
    });

export default withPageContainer;
