import { Space, Tooltip } from 'antd';
import React from 'react';

import FormattedMessage from '../FormattedMessage';

import type { StatusViewElementProps } from './types';

const StatusViewElement: React.FC<StatusViewElementProps> = React.memo(
  ({ 'data-test': dataTest, style, className, mode, icon, i18n, i18nValues }) => (
    <div data-test={dataTest} style={{ display: 'inline-flex', ...style }} className={className}>
      {/* FIXME: div is a workaround for unsupported data-* https://github.com/ant-design/ant-design/issues/8561 */}
      <Space>
        {mode === 'full' && icon}
        {mode === 'icon' && <Tooltip title={<FormattedMessage id={i18n} values={i18nValues} />}>{icon}</Tooltip>}
        {(mode === 'full' || mode === 'text') && <FormattedMessage id={i18n} values={i18nValues} />}
      </Space>
    </div>
  ),
);

export default StatusViewElement;
