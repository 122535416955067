/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3276
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { PaymentBlockAPIModel } from './PaymentBlockAPIModel';
import {
    PaymentBlockAPIModelFromJSON,
    PaymentBlockAPIModelFromJSONTyped,
    PaymentBlockAPIModelToJSON,
} from './PaymentBlockAPIModel';
import type { PaymentStatusAPIModel } from './PaymentStatusAPIModel';
import {
    PaymentStatusAPIModelFromJSON,
    PaymentStatusAPIModelFromJSONTyped,
    PaymentStatusAPIModelToJSON,
} from './PaymentStatusAPIModel';

/**
 * 
 * @export
 * @interface PaymentAPIModel
 */
export interface PaymentAPIModel {
    /**
     * 
     * @type {string}
     * @memberof PaymentAPIModel
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentAPIModel
     */
    sequenceNumber: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentAPIModel
     */
    companyId: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentAPIModel
     */
    paymentAddress: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentAPIModel
     */
    amount: string;
    /**
     * 
     * @type {PaymentStatusAPIModel}
     * @memberof PaymentAPIModel
     */
    status: PaymentStatusAPIModel;
    /**
     * 
     * @type {Date}
     * @memberof PaymentAPIModel
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof PaymentAPIModel
     */
    expiresAt: Date;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentAPIModel
     */
    withdrawalFeePaid: boolean;
    /**
     * 
     * @type {Date}
     * @memberof PaymentAPIModel
     */
    paidAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof PaymentAPIModel
     */
    paidAmount?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentAPIModel
     */
    errorCode?: string;
    /**
     * 
     * @type {Array<PaymentBlockAPIModel>}
     * @memberof PaymentAPIModel
     */
    createdAtBlock?: Array<PaymentBlockAPIModel>;
    /**
     * 
     * @type {object}
     * @memberof PaymentAPIModel
     */
    metadata?: object;
    /**
     * 
     * @type {string}
     * @memberof PaymentAPIModel
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentAPIModel
     */
    fullDetails?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentAPIModel
     */
    isForwarder: boolean;
}

/**
 * Check if a given object implements the PaymentAPIModel interface.
 */
export function instanceOfPaymentAPIModel(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('sequenceNumber' in value)) return false;
    if (!('companyId' in value)) return false;
    if (!('paymentAddress' in value)) return false;
    if (!('amount' in value)) return false;
    if (!('status' in value)) return false;
    if (!('createdAt' in value)) return false;
    if (!('expiresAt' in value)) return false;
    if (!('withdrawalFeePaid' in value)) return false;
    if (!('isForwarder' in value)) return false;
    return true;
}

export function PaymentAPIModelFromJSON(json: any): PaymentAPIModel {
    return PaymentAPIModelFromJSONTyped(json, false);
}

export function PaymentAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'sequenceNumber': json['sequenceNumber'],
        'companyId': json['companyId'],
        'paymentAddress': json['paymentAddress'],
        'amount': json['amount'],
        'status': PaymentStatusAPIModelFromJSON(json['status']),
        'createdAt': (new Date(json['createdAt'])),
        'expiresAt': (new Date(json['expiresAt'])),
        'withdrawalFeePaid': json['withdrawalFeePaid'],
        'paidAt': json['paidAt'] == null ? undefined : (new Date(json['paidAt'])),
        'paidAmount': json['paidAmount'] == null ? undefined : json['paidAmount'],
        'errorCode': json['errorCode'] == null ? undefined : json['errorCode'],
        'createdAtBlock': json['createdAtBlock'] == null ? undefined : ((json['createdAtBlock'] as Array<any>).map(PaymentBlockAPIModelFromJSON)),
        'metadata': json['metadata'] == null ? undefined : json['metadata'],
        'description': json['description'] == null ? undefined : json['description'],
        'fullDetails': json['fullDetails'] == null ? undefined : json['fullDetails'],
        'isForwarder': json['isForwarder'],
    };
}

export function PaymentAPIModelToJSON(value?: PaymentAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'sequenceNumber': value['sequenceNumber'],
        'companyId': value['companyId'],
        'paymentAddress': value['paymentAddress'],
        'amount': value['amount'],
        'status': PaymentStatusAPIModelToJSON(value['status']),
        'createdAt': ((value['createdAt']).toISOString()),
        'expiresAt': ((value['expiresAt']).toISOString()),
        'withdrawalFeePaid': value['withdrawalFeePaid'],
        'paidAt': value['paidAt'] == null ? undefined : ((value['paidAt']).toISOString()),
        'paidAmount': value['paidAmount'],
        'errorCode': value['errorCode'],
        'createdAtBlock': value['createdAtBlock'] == null ? undefined : ((value['createdAtBlock'] as Array<any>).map(PaymentBlockAPIModelToJSON)),
        'metadata': value['metadata'],
        'description': value['description'],
        'fullDetails': value['fullDetails'],
        'isForwarder': value['isForwarder'],
    };
}

