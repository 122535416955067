import { type MutableRefObject, useCallback, useEffect, useMemo } from 'react';

import type { BrandingData } from '@/features/branding/types';
import { asType } from '@/infrastructure/utils/ts';

import type { CheckoutUICommand, CheckoutUICallback } from '../types';

const useBrandingDataPreviewMessaging = (
  ref: MutableRefObject<HTMLIFrameElement | null>,
  onLoaded: () => void,
  initialValue?: BrandingData,
) => {
  const iframeOrigin = useMemo(() => new URL(window.env.NCPS_PAYMENTS_WIDGET_BRANDING_PREVIEW_URL).origin, []);

  const sendBrandingUpdate = useCallback(
    (data: BrandingData) => {
      if (iframeOrigin) {
        ref.current?.contentWindow?.postMessage(asType<CheckoutUICommand>({ type: 'update', data }), iframeOrigin);
      }
    },
    [iframeOrigin, ref],
  );

  useEffect(() => {
    const listener = (event: MessageEvent) => {
      if (!iframeOrigin || !event.origin.includes(iframeOrigin)) {
        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const e: CheckoutUICallback = event.data;
      // eslint-disable-next-line default-case
      switch (e.type) {
        case 'loaded': {
          onLoaded();
          if (initialValue) {
            sendBrandingUpdate(initialValue);
          }
        }
      }
    };

    window.addEventListener('message', listener);
    return () => window.removeEventListener('message', listener);
  }, [iframeOrigin, initialValue, onLoaded, ref, sendBrandingUpdate]);

  return { sendBrandingUpdate };
};

export default useBrandingDataPreviewMessaging;
