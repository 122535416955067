import { type UseAppMultipleDataMerged, useAppMultipleDataMerged } from '@/app/hooks';
import { makeSelectPending } from '@/features/global/selectors';
import { fetchMultipleSubscriptionPlan } from '@/features/subscription-plans/actions';
import {
  makeSelectDirtySubscriptionPlanIds,
  makeSelectMultipleSubscriptionPlan,
} from '@/features/subscription-plans/selectors';
import type { SubscriptionPlan } from '@/features/subscription-plans/types';

export interface UseMultipleSubscriptionPlansMerged extends UseAppMultipleDataMerged<SubscriptionPlan> {}

const dataFetchingSelector = makeSelectPending(fetchMultipleSubscriptionPlan);

export default function useMultipleSubscriptionPlansMerged(ids: string[]): UseMultipleSubscriptionPlansMerged {
  return useAppMultipleDataMerged<SubscriptionPlan>(
    fetchMultipleSubscriptionPlan,
    makeSelectMultipleSubscriptionPlan,
    makeSelectDirtySubscriptionPlanIds,
    ids,
    dataFetchingSelector,
  );
}
