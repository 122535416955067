import { useDimensions } from '@/hooks';

import type React from 'react';

export interface UsePayoutTableActionsMode {
  ref: React.RefCallback<HTMLElement>;
  persistMode: 'inline' | 'button';
  reorderMode: 'inline' | 'button';
  addMode: 'inline' | 'button';
}

export default function usePayoutTableActionsMode(): UsePayoutTableActionsMode {
  const [ref, size] = useDimensions<HTMLElement>();
  const reorderMode = size?.width && size.width < 530 ? 'inline' : 'button';
  const persistMode = size?.width && size.width < 430 ? 'inline' : 'button';
  const addMode = size?.width && size.width < 300 ? 'inline' : 'button';
  return { ref, reorderMode, persistMode, addMode };
}
