import { CheckCircleOutlined, FileUnknownOutlined, WarningOutlined } from '@ant-design/icons';
import React from 'react';

import { StatusViewElement } from '@/components';
import { TransactionResultAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureGasHistory } from '@/generated/i18n/i18n';

import type { GasHistoryResultViewProps } from './types';

const icons: Record<TransactionResultAPIModel, React.ReactNode> = {
  [TransactionResultAPIModel.Success]: <CheckCircleOutlined style={{ color: 'green' }} />,
  [TransactionResultAPIModel.Error]: <WarningOutlined style={{ color: 'red' }} />,
};

const i18n: Record<TransactionResultAPIModel, I18nFeatureGasHistory> = {
  [TransactionResultAPIModel.Success]: I18nFeatureGasHistory.COMPONENTS_RESULT_VIEW_SUCCESS,
  [TransactionResultAPIModel.Error]: I18nFeatureGasHistory.COMPONENTS_RESULT_VIEW_ERROR,
};

const GasHistoryResultViewView: React.FC<GasHistoryResultViewProps> = React.memo(
  ({ value, 'data-test': dataTest, mode = 'full' }) => (
    <StatusViewElement
      data-test={dataTest}
      i18n={(value && i18n[value]) ?? I18nFeatureGasHistory.COMPONENTS_RESULT_VIEW_UNKNOWN}
      icon={(value && icons[value]) ?? <FileUnknownOutlined />}
      mode={mode}
    />
  ),
);

export default GasHistoryResultViewView;
