import React, { useCallback, useMemo } from 'react';

import { FormattedMessage, FormattedMessageWithMarkup } from '@/components';
import { useAsset, useBlockchainSystemInfo } from '@/features/dictionary/blockchain/hooks';
import type { Web3BlockchainSystemInfo } from '@/features/dictionary/blockchain/types';
import { usePayoutActions } from '@/features/settlements/hooks';
import type { WalletOperationClientAction } from '@/features/web3/components';
import { WalletOperation } from '@/features/web3/components';
import { withWeb3Connector } from '@/features/web3/hocs';
import { useWeb3Connector } from '@/features/web3/hooks';
import { BlockchainAPITypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureSettlementPayouts } from '@/generated/i18n/i18n';
import { useNotification } from '@/hooks';
import { mapStoredState } from '@/infrastructure/model';
import { emptyWith, noopAsync, withOnSuccess } from '@/infrastructure/utils/functions';
import { isRejected } from '@/infrastructure/utils/ui';

import type { OperationCreatePayoutWithConnectorProps } from './types';

const OperationCreatePayoutWithConnector: React.FC<OperationCreatePayoutWithConnectorProps> = ({
  'data-test': dataTest,
  payoutData,
  account,
  onSuccess,
  withInProgress = emptyWith,
}) => {
  const { create: createAction } = usePayoutActions(payoutData);
  const { data: assetData } = useAsset(payoutData.asset);
  const blockchainState = useBlockchainSystemInfo(assetData.data?.blockchain);
  const targetChainId = (blockchainState.data.data as Web3BlockchainSystemInfo | undefined)?.chainId;
  const blockchain = useMemo(
    () =>
      mapStoredState(blockchainState.data, (state) =>
        state.apiType === BlockchainAPITypeAPIModel.WEB3 ? state : null,
      ),
    [blockchainState.data],
  );
  const { connector } = useWeb3Connector();
  const { withNotification } = useNotification();
  const options = useMemo(
    () => ({
      error: (error?: unknown) =>
        !isRejected(error)
          ? {
              message: (
                <FormattedMessage id={I18nFeatureSettlementPayouts.COMPONENTS_OPERATION_CREATE_PAYOUT_FAILED_MESSAGE} />
              ),
              description: (
                <FormattedMessageWithMarkup
                  id={I18nFeatureSettlementPayouts.COMPONENTS_OPERATION_CREATE_PAYOUT_FAILED_DESCRIPTION}
                  values={{ account }}
                />
              ),
            }
          : undefined,
      success: () => ({
        message: (
          <FormattedMessage id={I18nFeatureSettlementPayouts.COMPONENTS_OPERATION_CREATE_PAYOUT_SUCCESS_MESSAGE} />
        ),
        description: (
          <FormattedMessage id={I18nFeatureSettlementPayouts.COMPONENTS_OPERATION_CREATE_PAYOUT_SUCCESS_DESCRIPTION} />
        ),
      }),
    }),
    [account],
  );
  const doAction = useCallback<WalletOperationClientAction>(
    ({ client }) =>
      createAction.available
        ? withNotification(withInProgress(withOnSuccess(createAction.act, onSuccess)), options)(client)
        : noopAsync(),
    [createAction.available, createAction.act, withNotification, withInProgress, onSuccess, options],
  );
  const disabledMessage = useMemo(() => {
    if (createAction.unavailabilityReason === 'invalid-chain-id') {
      return (
        <FormattedMessage
          id={I18nFeatureSettlementPayouts.COMPONENTS_OPERATION_CREATE_PAYOUT_INVALID_CHAIN_ID}
          values={{ network: assetData.data?.blockchain, chainId: targetChainId }}
        />
      );
    }
    return undefined;
  }, [createAction.unavailabilityReason, assetData.data?.blockchain, targetChainId]);

  return (
    <WalletOperation
      data-test={dataTest}
      connectorId={connector.id}
      disabled={!createAction.available}
      disabledMessage={disabledMessage}
      requiredChain={useMemo(
        () =>
          blockchain.data?.chainId && assetData.data?.blockchain
            ? { id: blockchain.data.chainId, network: assetData.data.blockchain }
            : undefined,
        [assetData.data?.blockchain, blockchain.data?.chainId],
      )}
      requiredAccount={account}
      inProgress={createAction.inAction}
      mainAction={useMemo(
        () => ({
          withClient: true,
          onAction: doAction,
          title: (
            <FormattedMessage
              id={I18nFeatureSettlementPayouts.COMPONENTS_OPERATION_CREATE_PAYOUT_CONNECTOR_TITLE}
              values={{ connector: connector.name }}
            />
          ),
        }),
        [connector.name, doAction],
      )}
      showAccount
    />
  );
};

const OperationCreatePayoutWithConnectorLoaded = withWeb3Connector(OperationCreatePayoutWithConnector);

const OperationCreatePayoutWithConnectorLoadedMemo = React.memo(
  OperationCreatePayoutWithConnectorLoaded,
) as typeof OperationCreatePayoutWithConnectorLoaded;

export default OperationCreatePayoutWithConnectorLoadedMemo;
