import { PageLoading as BasePageLoading } from '@ant-design/pro-components';
import { css } from '@emotion/css';
import { Space } from 'antd';
import React, { useEffect } from 'react';

import Logo from '@/assets/icons/DefaultLogo';
import Label from '@/assets/icons/logo.svg?react';

import type { PageLoadingProps } from './types';

const PageLoading: React.FC<PageLoadingProps> = ({ 'data-test': dataTest, type }) => {
  useEffect(() => {
    const timeout = setTimeout(() => console.warn(`Loading is stale for "${type}"`), 1000);
    return () => clearTimeout(timeout);
  }, [type]);
  return (
    <div
      data-test={dataTest}
      className={css`
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        min-height: 420px;
        height: 100%;
      `}
    >
      <div
        className={css`
          padding: 98px;
          display: flex;
          justify-content: center;
          align-items: center;
        `}
      >
        <BasePageLoading />
      </div>
      <Space
        className={css`
          display: flex;
          justify-content: center;
          align-items: center;
        `}
      >
        <Logo width={32} />
        <Label width={83} height={14} />
      </Space>
    </div>
  );
};

const PageLoadingMemo = React.memo(PageLoading);

export default PageLoadingMemo;
