import dayjs from 'dayjs';
import React, { useMemo } from 'react';

import { FormattedMessage, ReadonlyComponent } from '@/components';
import { I18nFeatureSubscriptions } from '@/generated/i18n/i18n';
import { useLocaleSettings } from '@/hooks';

import type { SubscriptionMaxPeriodViewProps } from './types';

const SubscriptionMaxPeriodView: React.FC<SubscriptionMaxPeriodViewProps> = (props) => {
  const { value, mode = 'times', 'data-test': dataTest } = props;
  // eslint-disable-next-line react/destructuring-assignment
  const plan = 'plan' in props ? props.plan : undefined;
  const { formatDateTime } = useLocaleSettings();
  const lastChargeAt = useMemo(
    () =>
      plan
        ? formatDateTime(
            dayjs(value.startFrom)
              .add((value.maxPeriods - 1) * plan.periodSeconds, 's')
              .toDate(),
          )
        : undefined,
    [formatDateTime, plan, value],
  );
  if (mode === 'times') {
    return (
      <ReadonlyComponent
        data-test={dataTest}
        value={
          <FormattedMessage
            id={I18nFeatureSubscriptions.COMPONENTS_SUBSCRIPTION_MAX_PERIOD_VIEW_TITLE_TIMES}
            values={{ times: value.maxPeriods }}
          />
        }
      />
    );
  }
  return lastChargeAt ? (
    <ReadonlyComponent
      data-test={dataTest}
      value={
        <FormattedMessage
          id={I18nFeatureSubscriptions.COMPONENTS_SUBSCRIPTION_MAX_PERIOD_VIEW_TITLE_DATE}
          values={{
            date: lastChargeAt,
            times: value.maxPeriods,
          }}
        />
      }
    />
  ) : null;
};

const SubscriptionMaxPeriodViewMemo = React.memo(SubscriptionMaxPeriodView);

export default SubscriptionMaxPeriodViewMemo;
