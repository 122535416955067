import { createSelector } from 'reselect';

import type { AppRootState } from '@/app/store';
import { makeSelectSelectedNetwork } from '@/features/dictionary/blockchain/selectors';
import { createNormalizedListSelectors } from '@/infrastructure/model/list/selectors';

import { NAMESPACE } from './types';

export const {
  makeSelectSubscriptionPlanListData,
  makeSelectSubscriptionPlanListParameters: baseMakeSelectSubscriptionPlanListParameters,
  makeSelectSubscriptionPlan,
  makeSelectDirtySubscriptionPlanIds,
  makeSelectMultipleSubscriptionPlan,
} = createNormalizedListSelectors(
  (state: AppRootState) => state[NAMESPACE].list,
  (state) => state[NAMESPACE].entities,
  'SubscriptionPlan' as const,
  undefined,
);

export const makeSelectSubscriptionPlanListParameters: typeof baseMakeSelectSubscriptionPlanListParameters = () =>
  createSelector(
    baseMakeSelectSubscriptionPlanListParameters(),
    makeSelectSelectedNetwork(),
    ({ filter, ...parameters }, networkType) => ({ ...parameters, filter: { ...filter, networkType } }),
  );
