/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3276
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { PagePaymentSortByAPIModel } from './PagePaymentSortByAPIModel';
import {
    PagePaymentSortByAPIModelFromJSON,
    PagePaymentSortByAPIModelFromJSONTyped,
    PagePaymentSortByAPIModelToJSON,
} from './PagePaymentSortByAPIModel';
import type { PaymentAPIModel } from './PaymentAPIModel';
import {
    PaymentAPIModelFromJSON,
    PaymentAPIModelFromJSONTyped,
    PaymentAPIModelToJSON,
} from './PaymentAPIModel';

/**
 * 
 * @export
 * @interface SlicePaymentPaymentSortByAPIModel
 */
export interface SlicePaymentPaymentSortByAPIModel {
    /**
     * 
     * @type {Array<PaymentAPIModel>}
     * @memberof SlicePaymentPaymentSortByAPIModel
     */
    list?: Array<PaymentAPIModel>;
    /**
     * 
     * @type {PagePaymentSortByAPIModel}
     * @memberof SlicePaymentPaymentSortByAPIModel
     */
    page: PagePaymentSortByAPIModel;
    /**
     * 
     * @type {number}
     * @memberof SlicePaymentPaymentSortByAPIModel
     */
    total?: number;
}

/**
 * Check if a given object implements the SlicePaymentPaymentSortByAPIModel interface.
 */
export function instanceOfSlicePaymentPaymentSortByAPIModel(value: object): boolean {
    if (!('page' in value)) return false;
    return true;
}

export function SlicePaymentPaymentSortByAPIModelFromJSON(json: any): SlicePaymentPaymentSortByAPIModel {
    return SlicePaymentPaymentSortByAPIModelFromJSONTyped(json, false);
}

export function SlicePaymentPaymentSortByAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SlicePaymentPaymentSortByAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'list': json['list'] == null ? undefined : ((json['list'] as Array<any>).map(PaymentAPIModelFromJSON)),
        'page': PagePaymentSortByAPIModelFromJSON(json['page']),
        'total': json['total'] == null ? undefined : json['total'],
    };
}

export function SlicePaymentPaymentSortByAPIModelToJSON(value?: SlicePaymentPaymentSortByAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'list': value['list'] == null ? undefined : ((value['list'] as Array<any>).map(PaymentAPIModelToJSON)),
        'page': PagePaymentSortByAPIModelToJSON(value['page']),
        'total': value['total'],
    };
}

