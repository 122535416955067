/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3276
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateCompanyEmailAPIModel
 */
export interface UpdateCompanyEmailAPIModel {
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyEmailAPIModel
     */
    email: string;
}

/**
 * Check if a given object implements the UpdateCompanyEmailAPIModel interface.
 */
export function instanceOfUpdateCompanyEmailAPIModel(value: object): boolean {
    if (!('email' in value)) return false;
    return true;
}

export function UpdateCompanyEmailAPIModelFromJSON(json: any): UpdateCompanyEmailAPIModel {
    return UpdateCompanyEmailAPIModelFromJSONTyped(json, false);
}

export function UpdateCompanyEmailAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateCompanyEmailAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'],
    };
}

export function UpdateCompanyEmailAPIModelToJSON(value?: UpdateCompanyEmailAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'email': value['email'],
    };
}

