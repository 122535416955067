import { useMemo } from 'react';
import { walletActions } from 'viem';

import { useAsset, useBlockchainSystemInfo } from '@/features/dictionary/blockchain/hooks';
import type { NewPayoutData } from '@/features/settlements/types';
import { requestDistribute } from '@/features/settlements/web3-api';
import { useWeb3Connector } from '@/features/web3/hooks';
import type { BlockchainTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { useSubmitting } from '@/hooks';
import type { HookAction } from '@/infrastructure/model';
import { numberToRaw } from '@/infrastructure/utils/bigNumber';
import { assertNotNil } from '@/infrastructure/utils/functions';
import { isRejected, withRejectedByUser } from '@/infrastructure/utils/ui';

import usePayoutMerchantWallet from './usePayoutMerchantWallet';

import type { Account, Chain, Client, Transport } from 'viem';

export type CreatePayoutUnavailabilityReason = 'no-data' | 'no-blockchain-data' | 'invalid-chain-id';

export interface CreatePayoutResp {
  txHash: string;
  blockchain: BlockchainTypeAPIModel;
}

export interface UsePayoutActionsType {
  create: HookAction<[Client<Transport, Chain, Account>], CreatePayoutResp, CreatePayoutUnavailabilityReason>;
}

export default function usePayoutActions(payoutData: NewPayoutData | undefined): UsePayoutActionsType {
  const [creating, withCreating] = useSubmitting(false);
  const { chainId } = useWeb3Connector();
  const { data: assetData } = useAsset(payoutData?.asset);
  const precision = assetData.data?.formatDecimals;
  const tokenAddress = assetData.data?.address;
  const blockchain = assetData.data?.blockchain;
  const { data: blockchainData } = useBlockchainSystemInfo(blockchain);
  const targetChainId =
    blockchainData.data && 'chainId' in blockchainData.data ? blockchainData.data.chainId : undefined;
  const targetWalletAddress = usePayoutMerchantWallet(payoutData?.asset);

  const createPayoutUnavailability = useMemo<CreatePayoutUnavailabilityReason | undefined>(() => {
    if (!payoutData) {
      return 'no-data';
    }
    if (!targetChainId) {
      return 'no-blockchain-data';
    }
    if (targetChainId !== chainId) {
      return 'invalid-chain-id';
    }
    return undefined;
  }, [targetChainId, chainId, payoutData]);

  const createAction: UsePayoutActionsType['create']['act'] = useMemo(
    () =>
      withCreating(async (client) => {
        assertNotNil(payoutData, () => new Error(createPayoutUnavailability || 'no-data'));
        assertNotNil(targetWalletAddress, () => new Error(createPayoutUnavailability || 'no-data'));
        assertNotNil(precision, () => new Error(createPayoutUnavailability || 'no-data'));
        assertNotNil(tokenAddress, () => new Error(createPayoutUnavailability || 'no-data'));
        assertNotNil(blockchain, () => new Error(createPayoutUnavailability || 'no-data'));
        try {
          const walletClient = client.extend(walletActions);
          const destinations = payoutData.destinations.map(({ address }) => address);
          const amounts = payoutData.destinations.map(({ amount }) => numberToRaw(amount, precision).toString());
          const receipt = await withRejectedByUser(requestDistribute)(
            walletClient,
            targetWalletAddress,
            tokenAddress,
            destinations,
            amounts,
          );
          return { txHash: receipt.transactionHash, blockchain };
        } catch (e) {
          if (!isRejected(e)) {
            console.error('cannot distribute payouts', { targetWalletAddress, tokenAddress });
          }
          throw e;
        }
      }),
    [blockchain, payoutData, precision, targetWalletAddress, tokenAddress, createPayoutUnavailability, withCreating],
  );

  const create: UsePayoutActionsType['create'] = useMemo(
    () => ({
      act: createAction,
      available: !createPayoutUnavailability,
      unavailabilityReason: createPayoutUnavailability,
      inAction: creating,
    }),
    [createAction, createPayoutUnavailability, creating],
  );

  return { create };
}
