import { Space } from 'antd';

import { FormattedMessage } from '@/components';
import { OperationRefreshSubscriptionCharge } from '@/features/subscription-charges/components';
import { useSubscriptionCharge } from '@/features/subscription-charges/hooks';
import type { SubscriptionCharge } from '@/features/subscription-charges/types';
import { I18nPageSubscriptions } from '@/generated/i18n/i18n';
import { withCardDataLoading } from '@/hocs';
import type { TestableProps } from '@/infrastructure/utils/react';

import type React from 'react';

const withSubscriptionChargeDataLoading =
  <
    Original extends { data: SubscriptionCharge } & TestableProps,
    Wrapper extends Omit<Original, 'data'> & { subscriptionChargeId: string },
  >(
    Component: React.ComponentType<Original>,
  ): React.FC<Wrapper> =>
  (props) => {
    const data = useSubscriptionCharge(props.subscriptionChargeId);
    const dataTest = props['data-test'];
    return withCardDataLoading<SubscriptionCharge, Original>({
      ...data,
      hideBack: true,
      'data-test': dataTest,
      title: <FormattedMessage id={I18nPageSubscriptions.CHARGE_VIEW_COMPONENTS_CHARGE_CARD_TITLE} />,
      CardProps: {
        extra: (
          <Space>
            <OperationRefreshSubscriptionCharge
              subscriptionChargeId={props.subscriptionChargeId}
              data-test={dataTest && `${dataTest}-opRefresh`}
            />
          </Space>
        ),
      },
    })(Component)(props);
  };

export default withSubscriptionChargeDataLoading;
