import { Form } from 'antd';
import { get } from 'lodash';
import isNil from 'lodash/isNil';
import { useCallback, useEffect, useMemo } from 'react';
import isEqual from 'react-fast-compare';

import ErrorFormMessage from '@/components/ErrorFormMessage';
import FormCompletenessItem from '@/components/FormCompletenessItem';
import { defaultPageFormLayout } from '@/components/utils';
import { useErrorSafeSubmitting, useForm, useStateMountSafe } from '@/hooks';
import { noop } from '@/infrastructure/utils/functions';
import type { EmptyObject } from '@/infrastructure/utils/ts';

import FilterFormFooter from '../FilterFormFooter';

import type { FilterFormProps } from './types';
import type { FormInstance } from 'antd/es/form';
import type * as lodash from 'lodash';

const FilterForm = <Values extends EmptyObject = EmptyObject>({
  'data-test': dataTest,
  className,
  style,
  value,
  readonly,
  submitDisabled,
  onSubmit = noop,
  onReset = noop,
  onCancel,
  fields,
  tailLayout,
  layout = defaultPageFormLayout,
  children,
}: FilterFormProps<Values>) => {
  const { form, withResetForm } = useForm<Values>();
  const { submitting, error, withSubmitting, withErrorHandling } = useErrorSafeSubmitting();
  const [isFormComplete, setFormComplete] = useStateMountSafe(false);
  const isNew = useCallback(
    ({ getFieldValue }: FormInstance<Values>) => {
      if (!value) {
        return true;
      }
      return !!fields.find(
        (field) => isNil(field) || !isEqual(getFieldValue(field), get(value, field as lodash.PropertyPath)),
      );
    },
    [fields, value],
  );
  useEffect(() => {
    if (!isEqual(value, form.getFieldsValue())) {
      form.setFieldsValue(value ?? ({} as Values));
      if (readonly) {
        form.resetFields();
      }
    }
  }, [value, readonly, form]);

  const reset = useMemo(() => withErrorHandling(withResetForm(onReset)), [onReset, withErrorHandling, withResetForm]);

  return (
    <Form<Values>
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...layout}
      initialValues={value}
      className={className}
      style={style}
      autoComplete="off"
      form={form}
      onFinish={withSubmitting(onSubmit)}
      onReset={reset}
    >
      {!readonly && error && <ErrorFormMessage content={error} />}
      {children}
      <FormCompletenessItem<Values> fields={fields} onChange={setFormComplete} checkIsComplete={isNew} hasAnyValue />
      <FilterFormFooter
        data-test={dataTest && `${dataTest}-buttons`}
        layout={tailLayout}
        submitting={submitting}
        submitDisabled={!isFormComplete || submitDisabled}
        readonly={readonly}
        onCancel={onCancel}
      />
    </Form>
  );
};

export default FilterForm;
