/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3276
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { BlockchainTypeAPIModel } from './BlockchainTypeAPIModel';
import {
    BlockchainTypeAPIModelFromJSON,
    BlockchainTypeAPIModelFromJSONTyped,
    BlockchainTypeAPIModelToJSON,
} from './BlockchainTypeAPIModel';
import type { PaymentsModeAPIModel } from './PaymentsModeAPIModel';
import {
    PaymentsModeAPIModelFromJSON,
    PaymentsModeAPIModelFromJSONTyped,
    PaymentsModeAPIModelToJSON,
} from './PaymentsModeAPIModel';

/**
 * 
 * @export
 * @interface NewWithdrawalAPIModel
 */
export interface NewWithdrawalAPIModel {
    /**
     * 
     * @type {string}
     * @memberof NewWithdrawalAPIModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof NewWithdrawalAPIModel
     */
    destinationAddress: string;
    /**
     * 
     * @type {BlockchainTypeAPIModel}
     * @memberof NewWithdrawalAPIModel
     */
    blockchain: BlockchainTypeAPIModel;
    /**
     * 
     * @type {string}
     * @memberof NewWithdrawalAPIModel
     */
    amount: string;
    /**
     * 
     * @type {string}
     * @memberof NewWithdrawalAPIModel
     */
    estimatedAmount: string;
    /**
     * 
     * @type {Date}
     * @memberof NewWithdrawalAPIModel
     */
    estimatedAt: Date;
    /**
     * 
     * @type {PaymentsModeAPIModel}
     * @memberof NewWithdrawalAPIModel
     */
    mode?: PaymentsModeAPIModel;
}

/**
 * Check if a given object implements the NewWithdrawalAPIModel interface.
 */
export function instanceOfNewWithdrawalAPIModel(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('destinationAddress' in value)) return false;
    if (!('blockchain' in value)) return false;
    if (!('amount' in value)) return false;
    if (!('estimatedAmount' in value)) return false;
    if (!('estimatedAt' in value)) return false;
    return true;
}

export function NewWithdrawalAPIModelFromJSON(json: any): NewWithdrawalAPIModel {
    return NewWithdrawalAPIModelFromJSONTyped(json, false);
}

export function NewWithdrawalAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewWithdrawalAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'destinationAddress': json['destinationAddress'],
        'blockchain': BlockchainTypeAPIModelFromJSON(json['blockchain']),
        'amount': json['amount'],
        'estimatedAmount': json['estimatedAmount'],
        'estimatedAt': (new Date(json['estimatedAt'])),
        'mode': json['mode'] == null ? undefined : PaymentsModeAPIModelFromJSON(json['mode']),
    };
}

export function NewWithdrawalAPIModelToJSON(value?: NewWithdrawalAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'destinationAddress': value['destinationAddress'],
        'blockchain': BlockchainTypeAPIModelToJSON(value['blockchain']),
        'amount': value['amount'],
        'estimatedAmount': value['estimatedAmount'],
        'estimatedAt': ((value['estimatedAt']).toISOString()),
        'mode': PaymentsModeAPIModelToJSON(value['mode']),
    };
}

