import toString from 'lodash/toString';
import { useMemo } from 'react';

import { getAppStore } from '@/app/hocs/withStore';
import type { CommonLoadingState, LoadingStateWithDirty } from '@/infrastructure/model';
import { withExtractData } from '@/infrastructure/model';

import type { AppAsyncThunk, AppStore } from '../store';
import type { ActionCreatorWithPayload } from '@reduxjs/toolkit';

export type UseAppDispatch = () => AppStore['dispatch'] & {
  dispatch: AppStore['dispatch'];
  withExtractDataDispatch: <Returned, ThunkArg>(
    action:
      | AppAsyncThunk<CommonLoadingState<Returned>, ThunkArg>
      | AppAsyncThunk<LoadingStateWithDirty<Returned>, ThunkArg>,
  ) => (args: ThunkArg) => Promise<Returned>;
  withVoidDispatch: <Returned, ThunkArg>(
    action: AppAsyncThunk<Returned, ThunkArg>,
  ) => (args: ThunkArg) => Promise<void>;
  withDispatch: <Payload>(
    creator: ActionCreatorWithPayload<Payload>,
  ) => (args: Payload extends undefined ? void : Payload) => void;
};

const useAppDispatch: UseAppDispatch = () => {
  const { dispatch } = getAppStore();

  return useMemo(() => {
    const result = ((...args: Parameters<typeof dispatch>) => dispatch(...args)) as ReturnType<UseAppDispatch>;
    result.dispatch = dispatch;
    result.withExtractDataDispatch =
      <Returned, ThunkArg>(
        action:
          | AppAsyncThunk<CommonLoadingState<Returned>, ThunkArg>
          | AppAsyncThunk<LoadingStateWithDirty<Returned>, ThunkArg>,
      ) =>
      (args: ThunkArg) =>
        withExtractData(() => dispatch(action(args)).unwrap())();
    result.withVoidDispatch =
      <ThunkArg, Returned>(action: AppAsyncThunk<Returned, ThunkArg>) =>
      async (args: ThunkArg) => {
        const data: unknown = await dispatch(action(args)).unwrap();
        if (data && typeof data === 'object' && 'error' in data) {
          throw new Error(toString(data.error));
        }
      };
    result.withDispatch =
      <Payload>(creator: ActionCreatorWithPayload<Payload>) =>
      (args: Payload extends undefined ? void : Payload) => {
        dispatch(creator(args as Payload));
      };
    return result;
  }, [dispatch]);
};

export default useAppDispatch;
