import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { PageContainer } from '@/components';
import { reportsLink } from '@/pages/reports/routes';

import { ReportViewBreadcrumb, ReportCard } from './components';
import { withReportViewPageParams } from './hocs';

import type { ReportPageProps } from './types';
import type React from 'react';

const ReportViewPageRaw: React.FC<ReportPageProps> = ({
  'data-test': dataTest = 'report',
  pageParams: { id },
}) => {
  const navigate = useNavigate();
  const redirectOnDelete = useCallback(() => navigate(reportsLink()), [navigate]);
  return (
    <PageContainer
      data-test={dataTest && `${dataTest}-container`}
      breadcrumb={
        <ReportViewBreadcrumb data-test={dataTest && `${dataTest}-breadcrumb`} disabled reportId={id} />
      }
    >
      <ReportCard data-test={dataTest && `${dataTest}-card`} reportId={id} onDelete={redirectOnDelete} />
    </PageContainer>
  );
};

const ReportViewPage = withReportViewPageParams(ReportViewPageRaw);

export default ReportViewPage;
