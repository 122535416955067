import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nFeaturePayments } from '@/generated/i18n/i18n';

import type { PaymentErrorViewProps } from './types';

const i18n: Partial<Record<string, I18nFeaturePayments>> = {
  PaymentAddressNotFound: I18nFeaturePayments.COMPONENTS_ERROR_VIEW_CODES_PAYMENTADDRESSNOTFOUND,
  AmountNotFound: I18nFeaturePayments.COMPONENTS_ERROR_VIEW_CODES_AMOUNTNOTFOUND,
  TokenNotFound: I18nFeaturePayments.COMPONENTS_ERROR_VIEW_CODES_TOKENNOTFOUND,
  TokenNotSupported: I18nFeaturePayments.COMPONENTS_ERROR_VIEW_CODES_TOKENNOTSUPPORTED,
  InvalidAmount: I18nFeaturePayments.COMPONENTS_ERROR_VIEW_CODES_INVALIDAMOUNT,
  InvalidToken: I18nFeaturePayments.COMPONENTS_ERROR_VIEW_CODES_INVALIDTOKEN,
  UnderPaid: I18nFeaturePayments.COMPONENTS_ERROR_VIEW_CODES_UNDERPAID,
  OverPaid: I18nFeaturePayments.COMPONENTS_ERROR_VIEW_CODES_OVERPAID,
};

const PaymentErrorViewRaw: React.FC<PaymentErrorViewProps> = React.memo(({ value, 'data-test': dataTest }) =>
  value && i18n[value] ? (
    <FormattedMessage id={i18n[value]} defaultMessage={value} data-test={dataTest} tagName="span" />
  ) : null,
);

const PaymentErrorView = React.memo(PaymentErrorViewRaw);

export default PaymentErrorView;
