import type { UseAppListDataView } from '@/app/hooks';
import useListDataView from '@/app/hooks/useAppListDataView';
import { storeGasHistoryListParameters } from '@/features/gas-history/actions';
import useGasHistoryList from '@/features/gas-history/hooks/useGasHistoryList';
import type { GasHistoryRow, GasHistoryFilterPredicate } from '@/features/gas-history/types';
import type { GasHistorySortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';

export interface UseGasHistoryListView
  extends UseAppListDataView<GasHistoryRow, GasHistoryFilterPredicate, GasHistorySortByAPIModel> {}

export default function useGasHistoryListView(): UseGasHistoryListView {
  const state = useGasHistoryList();
  return useListDataView(state, storeGasHistoryListParameters);
}
