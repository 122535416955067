import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { storeEmailConfirmationState } from '@/features/email/actions';
import { makeSelectEmailConfirmationState } from '@/features/email/selectors';
import type { EmailConfirmationState } from '@/features/email/types';

export type UseEmailConfirmationType = EmailConfirmationState | undefined;

export default function useEmailConfirmation(maybeEmail: string | undefined): UseEmailConfirmationType {
  const { withDispatch } = useAppDispatch();
  const state = useAppSelector(useMemo(() => makeSelectEmailConfirmationState(maybeEmail), [maybeEmail]));
  const updateState = useCallback(
    (email: string) => withDispatch(storeEmailConfirmationState)({ email, data: undefined }),
    [withDispatch],
  );
  useEffect(() => {
    if (maybeEmail && state?.expiresAt) {
      const diff = -dayjs().diff(state.expiresAt, 'ms');
      if (diff > 0) {
        const tm = setTimeout(() => updateState(maybeEmail), diff);
        return () => clearTimeout(tm);
      }

      updateState(maybeEmail);
    }
    // eslint-disable-next-line no-useless-return,consistent-return
    return;
  }, [updateState, maybeEmail, state?.expiresAt]);

  return state;
}
