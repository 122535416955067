import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Radio } from 'antd';
import dayjs from 'dayjs';
import { useCallback, useMemo } from 'react';

import type { DateTimeRange } from '@/components/DateTimeRangeItem/types';
import DateTimeRelativeLabel from '@/components/DateTimeRelativeLabel';
import { DateTimeRangeRelativeEnum } from '@/components/DateTimeRelativeLabel/types';
import { I18nComponents } from '@/generated/i18n/i18n';
import { withSuppressPromise } from '@/infrastructure/utils/functions';
import { asType, notEmpty } from '@/infrastructure/utils/ts';

import FormattedMessage from '../FormattedMessage';

import type { DateTimeRelativeQuickFilter, OperationDateTimeRelativeQuickFilterProps } from './types';
import type { RadioChangeEvent } from 'antd';

type PeriodToSelect = DateTimeRelativeQuickFilter | 'All';

const orderedPeriodsMapping = [
  {
    value: asType<PeriodToSelect>('All'),
    label: <FormattedMessage id={I18nComponents.OPERATION_DATE_TIME_RELATIVE_QUICK_FILTER_OPTION_ALL} />,
  },
  {
    value: asType<PeriodToSelect>('Today'),
    label: <DateTimeRelativeLabel value={DateTimeRangeRelativeEnum.Today} />,
  },
  {
    value: asType<PeriodToSelect>('ThisWeek'),
    label: <DateTimeRelativeLabel value={DateTimeRangeRelativeEnum.ThisWeek} />,
  },
  {
    value: asType<PeriodToSelect>('ThisMonth'),
    label: <DateTimeRelativeLabel value={DateTimeRangeRelativeEnum.ThisMonth} />,
  },
];

const modeToOptionsCount = {
  small: orderedPeriodsMapping.length - 3,
  medium: orderedPeriodsMapping.length - 2,
  large: orderedPeriodsMapping.length - 1,
  full: orderedPeriodsMapping.length,
};

const OperationDateTimeRelativeQuickFilter = <
  Absolute extends string,
  Relative extends string,
  Value extends { [a in Absolute]?: DateTimeRange } & { [r in Relative]?: DateTimeRelativeQuickFilter } = {
    [r in Relative]?: DateTimeRelativeQuickFilter;
  } & { [a in Absolute]?: DateTimeRange },
>({
  'data-test': dataTest,
  relativeKey,
  absoluteKey,
  disabled,
  mode = 'small',
  value,
  onChange,
}: OperationDateTimeRelativeQuickFilterProps<Absolute, Relative, Value>) => {
  const radioOptionsCount = useMemo(() => modeToOptionsCount[mode] || 1, [mode]);
  const absolute = value?.[absoluteKey];
  const relative = value?.[relativeKey];
  const selected = useMemo(
    () => relative ?? ((absolute?.from ?? absolute?.to) ? undefined : asType<PeriodToSelect>('All')),
    [absolute?.from, absolute?.to, relative],
  );
  const selectedIdx = useMemo(() => orderedPeriodsMapping.findIndex((period) => period.value === relative), [relative]);
  const isSelectedInRadio = useMemo(
    () => selectedIdx && selectedIdx < radioOptionsCount,
    [radioOptionsCount, selectedIdx],
  );
  const radioOptions = useMemo(
    () =>
      [
        ...orderedPeriodsMapping.filter((_, idx) => idx < radioOptionsCount - (isSelectedInRadio ? 0 : 1)),
        !isSelectedInRadio ? orderedPeriodsMapping.find((_, idx) => selectedIdx === idx) : undefined,
      ]
        .filter(notEmpty)
        .map(({ label, ...restValue }) => ({ ...restValue, label })),
    [isSelectedInRadio, radioOptionsCount, selectedIdx],
  );
  const menuItems = useMemo(
    () =>
      orderedPeriodsMapping
        .filter((_, idx) => idx !== selectedIdx && idx >= radioOptionsCount - (isSelectedInRadio ? 0 : 1))
        .map(({ label, ...restValue }) => ({ ...restValue, label })),
    [isSelectedInRadio, radioOptionsCount, selectedIdx],
  );
  const doUpdateFilter = useCallback(
    // eslint-disable-next-line @typescript-eslint/require-await
    async (newValue?: PeriodToSelect) =>
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      onChange({
        ...value,
        [relativeKey]: !newValue || newValue === 'All' ? undefined : newValue,
        [absoluteKey]:
          newValue !== 'All'
            ? {
                from:
                  // eslint-disable-next-line no-nested-ternary
                  (newValue === 'Today'
                    ? dayjs().startOf('day')
                    : newValue === 'ThisWeek'
                      ? dayjs().startOf('week')
                      : dayjs().startOf('month')
                  ).toDate(),
                to: undefined,
              }
            : undefined,
      } as Value),
    [absoluteKey, onChange, relativeKey, value],
  );
  const doSelect = useCallback(
    (e: RadioChangeEvent) => doUpdateFilter(e.target.value as PeriodToSelect),
    [doUpdateFilter],
  );
  return (
    <Radio.Group
      value={selected}
      optionType="button"
      data-test={dataTest}
      onChange={doSelect}
      disabled={disabled}
      style={{ display: 'flex' }}
    >
      {radioOptions.map(({ label, value: optionValue }) => (
        <Radio.Button value={optionValue} key={optionValue}>
          <span style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{label}</span>
        </Radio.Button>
      ))}
      {menuItems.length && (
        <Dropdown
          menu={{
            selectedKeys: selected ? [selected] : [],
            onClick: (e) => {
              withSuppressPromise(doUpdateFilter)(e.key as PeriodToSelect);
            },
            items: menuItems.map(({ label, value: itemValue }) => ({
              'data-test': dataTest && `${dataTest}-locale-${itemValue}`,
              key: itemValue,
              label,
            })),
          }}
        >
          <Button style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}>
            <DownOutlined />
          </Button>
        </Dropdown>
      )}
    </Radio.Group>
  );
};

export default OperationDateTimeRelativeQuickFilter;
