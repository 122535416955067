import { ProLayout } from '@ant-design/pro-components';
import { css } from '@emotion/css';
import { Space } from 'antd';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import Logo from '@/assets/icons/DefaultLogo';
import { Link } from '@/components';
import SelectedNetworkLabel from '@/features/dictionary/blockchain/components/SelectedNetworkLabel';
import { I18nCommon } from '@/generated/i18n/i18n';
import ErrorBoundary from '@/hocs/withErrorBoundary/components/ErrorBoundary';
import type { TestableProps } from '@/infrastructure/utils/react';
import {
  AvatarDropdown,
  AvatarTitle,
  BackgroundTasksDropdown,
  LayoutFooter,
  SelectCompany,
  SelectLang,
  UserAlertsDropdown,
} from '@/layouts/components';
import { layoutToken } from '@/layouts/theme';
import type { CustomizableRoute } from '@/layouts/types';

import type { BaseMenuProps } from '@ant-design/pro-layout/es/components/SiderMenu/BaseMenu';
import type { SiderMenuProps } from '@ant-design/pro-layout/es/components/SiderMenu/SiderMenu';
import type { PureSettings } from '@ant-design/pro-layout/es/defaultSettings';
import type React from 'react';
import type { PropsWithChildren } from 'react';

interface BasicLayoutProps extends PropsWithChildren<TestableProps> {
  routes: CustomizableRoute;
}

const menuProps: PureSettings['menu'] = {
  collapsedShowGroupTitle: true,
};
const menuItemRender =
  (pathname: string): BaseMenuProps['menuItemRender'] =>
  ({ path, icon, pro_layout_parentKeys }, dom) => {
    const component =
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      (pro_layout_parentKeys?.length ?? 1) > 1 ? (
        <Space>
          {icon}
          {dom}
        </Space>
      ) : (
        dom
      );
    return path === pathname ? component : <Link title={component} to={path || window.env.PUBLIC_URL} />;
  };

const menuFooterRender: SiderMenuProps['menuFooterRender'] = (props) => (!props?.collapsed ? <LayoutFooter /> : null);
const menuHeaderRender: SiderMenuProps['menuHeaderRender'] = (logo, title, props) => {
  if ((props?.collapsed || props?.siderMenuType === 'group') && !props.isMobile) {
    return null;
  }
  return props?.isMobile ? (
    <Space
      className={css`
        > div > h1 {
          margin: 0;
        }
      `}
    >
      {logo}
      {title}
    </Space>
  ) : (
    logo
  );
};

const BasicLayout: React.FC<BasicLayoutProps> = ({ 'data-test': dataTest = "layout", routes, children }) => {
  const { pathname } = useLocation();
  const { formatMessage } = useIntl();
  return (
    <ProLayout
      layout="mix"
      fixedHeader
      ErrorBoundary={ErrorBoundary}
      title={formatMessage({ id: I18nCommon.TITLE })}
      className={css`
        div [role='presentation'].ant-menu-item-group-title {
          display: none;
        }
      `}
      logo={<Logo width={24} />}
      token={layoutToken}
      route={routes}
      location={useMemo(() => ({ pathname }), [pathname])}
      siderMenuType="group"
      menu={menuProps}
      menuProps={useMemo(
        () => ({
          // TODO: dirty hack caused by lack of the ability to configure the sub-menu bg color
          // https://github.com/ant-design/pro-components/blob/420bbd61f6cc84f299c4d8c3605e422992833b6e/packages/layout/src/ProLayout.tsx#L835
          rootClassName: css`
            > ul {
              background-color: ${layoutToken.header?.colorBgHeader} !important;
            }
          `,
        }),
        [],
      )}
      avatarProps={useMemo(
        () => ({
          title: <AvatarTitle data-test={dataTest && `${dataTest}-avatar`} />,
          render: (_, dom) => <AvatarDropdown data-test={dataTest && `${dataTest}-avatarMenu`} title={dom} />,
        }),
        [dataTest],
      )}
      actionsRender={useCallback(
        () => [
          <SelectedNetworkLabel key="network" data-test={dataTest && `${dataTest}-network`} />,
          <SelectCompany key="company" data-test={dataTest && `${dataTest}-company`} />,
          <UserAlertsDropdown key="alerts" data-test={dataTest && `${dataTest}-alerts`} />,
          <BackgroundTasksDropdown key="tasks" data-test={dataTest && `${dataTest}-tasks`} />,
          <SelectLang key="lang" data-test={dataTest && `${dataTest}-lang`} />,
        ],
        [dataTest],
      )}
      menuItemRender={useMemo(() => menuItemRender(pathname), [pathname])}
      menuHeaderRender={menuHeaderRender}
      menuFooterRender={menuFooterRender}
    >
      {children}
    </ProLayout>
  );
};

export default BasicLayout;
