import { Modal } from 'antd';
import React from 'react';

import { FormattedMessage } from '@/components';
import { TransactionLink } from '@/features/dictionary/blockchain/components';
import { I18nPageSettlements } from '@/generated/i18n/i18n';

import type { PayoutSucceededModalProps } from './types';

const PayoutSucceededModal: React.FC<PayoutSucceededModalProps> = ({ 'data-test': dataTest, onClose, data }) => (
  <Modal
    open
    title={<FormattedMessage id={I18nPageSettlements.ADD_PAYOUT_PAYOUTS_CARD_SUCCESS_ALERT_TITLE} />}
    data-test={dataTest && `${dataTest}-modal`}
    onOk={onClose}
    cancelButtonProps={{ style: { display: 'none' } }}
    closable={false}
  >
    <FormattedMessage id={I18nPageSettlements.ADD_PAYOUT_PAYOUTS_CARD_SUCCESS_ALERT_DESC} tagName="span" />
    <TransactionLink data-test={dataTest && `${dataTest}-tx`} bt={data.blockchain} tx={data.txHash} />
  </Modal>
);

const PayoutSucceededModalMemo = React.memo(PayoutSucceededModal);

export default PayoutSucceededModalMemo;
