import { useCallback, useMemo } from 'react';

import { DataFetchError, FormattedMessage, PageCard, PageContainer } from '@/components';
import { SubscriptionPlanEditForm } from '@/features/subscription-plans/components';
import { useSubscriptionPlan, useSubscriptionPlanActions } from '@/features/subscription-plans/hooks';
import { I18nPageSubscriptions } from '@/generated/i18n/i18n';
import { useDefaultNotification, useFormPageAction, useNotification, useStateMountSafe } from '@/hooks';
import { noop, withOnSuccess } from '@/infrastructure/utils/functions';
import type { TestableProps } from '@/infrastructure/utils/react';
import type { Func } from '@/infrastructure/utils/ts';
import { withSubscriptionPlanPageParams } from '@/pages/subscriptions/hocs';
import { subscriptionPlanViewLink } from '@/pages/subscriptions/routes';

import { SubscriptionPlanEditBreadcrumb } from './components';

import type React from 'react';
import type { PropsWithChildren } from 'react';

interface SubscriptionPlanPageProps extends PropsWithChildren<TestableProps & { pageParams: { planId: string } }> {}

const SubscriptionPlanEditPage: React.FC<SubscriptionPlanPageProps> = ({
  'data-test': dataTest = 'plan-edit',
  pageParams: { planId },
}) => {
  const { withSuccess } = useNotification();
  const { withDefaultError } = useDefaultNotification();
  const { update: updateAction } = useSubscriptionPlanActions(planId);
  const { data: initialValues, forceRefresh } = useSubscriptionPlan(planId);
  const { redirectOnSubmit } = useFormPageAction();
  const doRedirect = useMemo<Func>(
    () => redirectOnSubmit(subscriptionPlanViewLink(planId)),
    [planId, redirectOnSubmit],
  );
  const [onSubmit, setOnSubmit] = useStateMountSafe<Func | undefined>();
  const updateOnSubmit = useCallback((func?: Func) => setOnSubmit(() => func), [setOnSubmit]);
  const doSave = useMemo(
    () =>
      withOnSuccess(
        withDefaultError(
          withSuccess(updateAction.act, () => (
            <FormattedMessage id={I18nPageSubscriptions.PLAN_EDIT_COMPONENTS_SUCCESS_MESSAGE} />
          )),
          () => <FormattedMessage id={I18nPageSubscriptions.PLAN_EDIT_COMPONENTS_ERROR_MESSAGE} />,
        ),
        doRedirect,
      ),
    [withDefaultError, withSuccess, doRedirect, updateAction],
  );

  return (
    <PageContainer
      data-test={dataTest}
      breadcrumb={
        <SubscriptionPlanEditBreadcrumb data-test={dataTest && `${dataTest}-breadcrumb`} planId={planId} disabled />
      }
      submit={useMemo(() => ({ onClick: onSubmit ?? noop, disabled: !onSubmit }), [onSubmit])}
      cancel={doRedirect}
    >
      <PageCard loading={!initialValues} cardSize="big">
        {
          /* eslint-disable-next-line no-nested-ternary */
          !initialValues.data ? (
            !initialValues.isDirty ? (
              <DataFetchError refresh={forceRefresh} back message={initialValues.error} />
            ) : null
          ) : (
            <SubscriptionPlanEditForm
              data-test={dataTest && `${dataTest}-form`}
              initialValues={initialValues.data}
              onSubmit={doSave}
              onReset={doRedirect}
              submitCallback={updateOnSubmit}
            />
          )
        }
      </PageCard>
    </PageContainer>
  );
};

export default withSubscriptionPlanPageParams(SubscriptionPlanEditPage);
