import React from 'react';

import { FormattedMessage, Link } from '@/components';
import { I18nPageReports } from '@/generated/i18n/i18n';
import { reportSchedulesLink } from '@/pages/reports/routes';

import type { ReportSchedulesLinkProps } from './types';


const ReportSchedulesLinkRaw: React.FC<ReportSchedulesLinkProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  title,
}) => (
  <Link
    data-test={dataTest}
    to={reportSchedulesLink()}
    mode={mode}
    title={title ?? <FormattedMessage id={I18nPageReports.REPORT_SCHEDULES_COMPONENTS_LINK_TITLE} />}
  />
);

const ReportScheduleScheduleLink = React.memo(ReportSchedulesLinkRaw);

export default ReportScheduleScheduleLink;
