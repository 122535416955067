import type { Page } from '@/infrastructure/api';
import { defaultPage } from '@/infrastructure/api';
import type { LoadingStateWithDirty } from '@/infrastructure/model';
import { storedDirtyData } from '@/infrastructure/model';
import type { ListParametersState, UpdateListParametersPayload, ListSortBy } from '@/infrastructure/model/list/types';

export type LoadingFullDataState<Value> = LoadingStateWithDirty<Value[]>;

export interface FullParametersState<Filter, SortBy extends string> extends ListParametersState<Filter, SortBy> {}

export interface FullParametersNoColumnsState<Filter, SortBy extends string> {
  page: Page;
  filter: Filter;
  sortBy: ListSortBy<SortBy>;
}

export interface FullState<Value, Filter, SortBy extends string> extends FullParametersState<Filter, SortBy> {
  data: LoadingFullDataState<Value>;
}

export interface FullNoColumnsState<Value, Filter, SortBy extends string>
  extends FullParametersNoColumnsState<Filter, SortBy> {
  data: LoadingFullDataState<Value>;
}

export interface UpdateFullDataParametersPayload<F, S extends string> extends UpdateListParametersPayload<F, S> {}

export const defaultFullState = <Global, Filter, SortBy extends string>(
  filter: Filter,
  sortBy: ListSortBy<SortBy>,
): FullState<Global, Filter, SortBy> => ({
  data: storedDirtyData,
  filter,
  sortBy,
  columnState: {},
  page: defaultPage,
});

export interface FetchFullPayload {
  force: boolean;
}

export interface SingleRowNotification {
  id: string;
  type: 'created' | 'updated' | 'removed';
}
