import React from 'react';

import type { ReportParametersViewProps } from '@/features/global/hocs/withHandlerRegistration/reports';

import MerchantWalletRunningBalanceFilterForm from '../MerchantWalletRunningBalanceFilterForm';

const MerchantWalletRunningBalancesReportParametersView: React.FC<ReportParametersViewProps> = React.memo(
  ({ value, 'data-test': dataTest }) => (
    <MerchantWalletRunningBalanceFilterForm data-test={dataTest} value={value} readonly />
  ),
);

export default MerchantWalletRunningBalancesReportParametersView;
