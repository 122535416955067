import { PageContainer } from '@/components';
import { useActualBalances } from '@/features/statistics/hooks';
import { useFirstRenderEffect } from '@/hooks';

import { SettlementAddPayoutCard, SettlementPayoutsBreadcrumb } from './components';

import type React from 'react';

const dataTest: string | undefined = 'payout-add';

const SettlementPayoutPage: React.FC = () => {
  const balancesState = useActualBalances();
  useFirstRenderEffect(balancesState.forceRefresh);

  return (
    <PageContainer
      data-test={dataTest && `${dataTest}-container`}
      breadcrumb={<SettlementPayoutsBreadcrumb data-test={dataTest && `${dataTest}-breadcrumb`} disabled />}
    >
      <SettlementAddPayoutCard data-test={dataTest && `${dataTest}-card`} />
    </PageContainer>
  );
};

export default SettlementPayoutPage;
