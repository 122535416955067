import React, { useCallback, useMemo } from 'react';

import { FormattedMessage, SelectWithReloadingItem } from '@/components';
import { useListBlockchains, useListAssets } from '@/features/dictionary/blockchain/hooks';
import type { BlockchainInfo } from '@/features/dictionary/blockchain/types';
import { I18nFeatureDictionary } from '@/generated/i18n/i18n';
import { mapStoredState } from '@/infrastructure/model';

import BlockchainLabel from '../BlockchainLabel';

import type { BlockchainItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const inputProps = {
  optionLabelProp: 'inline',
  optionFilterProp: 'search',
};

const BlockchainItem = <Values extends Store = Store>({
  name,
  placeholder,
  readonly,
  required,
  messages,
  'data-test': dataTest,
  disabledValues,
  multiselect,
  noLabel,
  withNetworkMark,
  network,
  blockchainApis = [],
  ItemProps,
}: BlockchainItemProps<Values>) => {
  const {
    data: blockchains,
    forceRefresh: refreshBlockchains,
    loading: loadingBlockchains,
  } = useListBlockchains(network);
  const { supportedAssets, forceRefresh: refreshAssets, loading: loadingAssets } = useListAssets(network);
  const forceRefresh = useCallback(
    async () => Promise.all([refreshAssets(), refreshBlockchains()]),
    [refreshBlockchains, refreshAssets],
  );

  const data = useMemo(
    () =>
      mapStoredState(blockchains, (value) => {
        const supportedCodes = supportedAssets.data?.map(({ blockchain }) => blockchain);
        return value
          .filter(({ blockchain }) => supportedCodes?.includes(blockchain))
          .filter(({ apiType }) => !blockchainApis.length || blockchainApis.includes(apiType));
      }),
    [blockchainApis, blockchains, supportedAssets.data],
  );
  const loading = useMemo(() => loadingBlockchains || loadingAssets, [loadingAssets, loadingBlockchains]);
  return (
    <SelectWithReloadingItem<Values, BlockchainInfo>
      data-test={dataTest}
      disabled={readonly}
      required={required}
      name={name}
      messages={useMemo(
        () => ({
          label: !noLabel
            ? messages?.label || <FormattedMessage id={I18nFeatureDictionary.COMPONENTS_BLOCKCHAIN_ITEM_LABEL} />
            : undefined,
          dataEmpty: messages?.empty || (
            <FormattedMessage id={I18nFeatureDictionary.COMPONENTS_BLOCKCHAIN_ITEM_EMPTY_ERROR} />
          ),
          placeholder: placeholder || (
            <FormattedMessage id={I18nFeatureDictionary.COMPONENTS_BLOCKCHAIN_ITEM_PLACEHOLDER} />
          ),
          required: messages?.required ?? (
            <FormattedMessage id={I18nFeatureDictionary.COMPONENTS_BLOCKCHAIN_ITEM_REQUIRED} />
          ),
        }),
        [messages?.empty, messages?.label, messages?.required, noLabel, placeholder],
      )}
      multiselect={multiselect}
      refresh={forceRefresh}
      loading={loading}
      options={data}
      dataToOptions={useCallback(
        ({ blockchain }) => ({
          value: blockchain,
          label: (
            <BlockchainLabel
              withNetworkMark={withNetworkMark}
              bt={blockchain}
              data-test={dataTest && `${dataTest}-${blockchain}-label`}
              mode="medium"
            />
          ),
          inline: (
            <BlockchainLabel
              withNetworkMark={withNetworkMark}
              bt={blockchain}
              data-test={dataTest && `${dataTest}-selected`}
              mode="medium"
            />
          ),
          search: blockchain,
          disabled: disabledValues?.includes(blockchain),
        }),
        [dataTest, disabledValues, withNetworkMark],
      )}
      SelectInputProps={inputProps}
      ItemProps={ItemProps}
    />
  );
};

const BlockchainItemMemo = React.memo(BlockchainItem) as typeof BlockchainItem;

export default BlockchainItemMemo;
