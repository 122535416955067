import { PlusOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';

import { FormattedMessage, Operation } from '@/components';
import { useMerchantGasWalletsActions } from '@/features/gas-wallets/hooks';
import { I18nFeatureGasWallets } from '@/generated/i18n/i18n';
import { useDefaultNotification, useNotification } from '@/hooks';
import { noop, withOnSuccess, withSuppressError } from '@/infrastructure/utils/functions';

import type { OperationCreateGasWalletProps } from './types';

const OperationCreateGasWallet: React.FC<OperationCreateGasWalletProps> = React.memo(
  ({ 'data-test': dataTest, title, bt, onSuccess = noop, mode = 'inline' }) => {
    const { create: createAction } = useMerchantGasWalletsActions();
    const { withSuccess } = useNotification();
    const { withDefaultError } = useDefaultNotification();

    const doCreate = useMemo(
      () =>
        withSuppressError(
          withSuccess(
            withDefaultError(
              withOnSuccess(async () => createAction.act(bt), onSuccess),
              () => <FormattedMessage id={I18nFeatureGasWallets.COMPONENTS_OPERATION_CREATE_WALLET_MESSAGE_ERROR} />,
            ),
            () => <FormattedMessage id={I18nFeatureGasWallets.COMPONENTS_OPERATION_CREATE_WALLET_MESSAGE_SUCCESS} />,
          ),
        ),
      [withSuccess, withDefaultError, onSuccess, createAction, bt],
    );

    return (
      <Operation
        title={title || <FormattedMessage id={I18nFeatureGasWallets.COMPONENTS_OPERATION_CREATE_WALLET_TITLE} />}
        icon={<PlusOutlined />}
        onClick={doCreate}
        disabled={!createAction.available}
        inProgress={createAction.inAction}
        data-test={dataTest}
        mode={mode}
      />
    );
  },
);

const OperationCreateGasWalletMemo = React.memo(OperationCreateGasWallet);

export default OperationCreateGasWalletMemo;
