import React, { useMemo } from 'react';

import { FormattedMessage, MoneyAmountItem } from '@/components';
import { useAsset } from '@/features/dictionary/blockchain/hooks';
import { I18nFeatureDictionary } from '@/generated/i18n/i18n';

import AssetCode from '../AssetCode';
import AssetTooltip from '../AssetTooltip';

import type { AssetAmountItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const AssetAmountItemRaw = <Values extends Store = Store>({
  'data-test': dataTest,
  name,
  assetId,
  messages,
  nonNegative,
  min,
  max,
  readonly,
  required,
  ...props
}: AssetAmountItemProps<Values>) => {
  const { data: asset } = useAsset(assetId);
  const messagesOrDefault = useMemo(
    () => ({
      label: messages?.label ?? (
        <FormattedMessage id={I18nFeatureDictionary.COMPONENTS_ASSET_AMOUNT_ITEM_VALUE_LABEL} />
      ),
      required: (required && messages?.required) ?? (
        <FormattedMessage id={I18nFeatureDictionary.COMPONENTS_ASSET_AMOUNT_ITEM_VALUE_ERROR_REQUIRED} />
      ),
    }),
    [messages?.label, messages?.required, required],
  );

  const moneyAsset = useMemo(
    () =>
      asset.data
        ? {
            code: asset.data.code,
            title: (
              <AssetTooltip type={asset.data.code}>
                <AssetCode value={asset.data.code} />
              </AssetTooltip>
            ),
            precision: asset.data.formatDecimals,
          }
        : undefined,
    [asset.data],
  );
  if (!moneyAsset) {
    return null;
  }
  return (
    <MoneyAmountItem<Values>
      data-test={dataTest}
      name={name}
      messages={messagesOrDefault}
      min={min}
      max={max}
      readonly={readonly}
      nonNegative={nonNegative}
      asset={moneyAsset}
      {...props}
    />
  );
};

const AssetAmountItem = React.memo(AssetAmountItemRaw) as typeof AssetAmountItemRaw;

export default AssetAmountItem;
