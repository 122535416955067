/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3276
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/


import * as runtime from '../runtime';
import type {
  ApiErrorAPIModel,
  NewSubscriptionPlanAPIModel,
  SliceSubscriptionPlanSubscriptionPlanSortByAPIModel,
  SubscriptionPlanAPIModel,
  SubscriptionPlanFilterAPIModel,
  UpdateSubscriptionPlanAPIModel,
  UpdateSubscriptionPlanStatusAPIModel,
} from '../models/index';
import {
    ApiErrorAPIModelFromJSON,
    ApiErrorAPIModelToJSON,
    NewSubscriptionPlanAPIModelFromJSON,
    NewSubscriptionPlanAPIModelToJSON,
    SliceSubscriptionPlanSubscriptionPlanSortByAPIModelFromJSON,
    SliceSubscriptionPlanSubscriptionPlanSortByAPIModelToJSON,
    SubscriptionPlanAPIModelFromJSON,
    SubscriptionPlanAPIModelToJSON,
    SubscriptionPlanFilterAPIModelFromJSON,
    SubscriptionPlanFilterAPIModelToJSON,
    UpdateSubscriptionPlanAPIModelFromJSON,
    UpdateSubscriptionPlanAPIModelToJSON,
    UpdateSubscriptionPlanStatusAPIModelFromJSON,
    UpdateSubscriptionPlanStatusAPIModelToJSON,
} from '../models/index';

export interface ChangeSubscriptionPlanStatusRequest {
    id: string;
    updateSubscriptionPlanStatusAPIModel: UpdateSubscriptionPlanStatusAPIModel;
}

export interface CreateSubscriptionPlanRequest {
    newSubscriptionPlanAPIModel: NewSubscriptionPlanAPIModel;
}

export interface DeleteSubscriptionPlanRequest {
    id: string;
}

export interface GetSubscriptionPlanRequest {
    id: string;
}

export interface SearchSubscriptionPlansRequest {
    subscriptionPlanFilterAPIModel: SubscriptionPlanFilterAPIModel;
    withTotal?: boolean;
}

export interface UpdateSubscriptionPlanRequest {
    id: string;
    updateSubscriptionPlanAPIModel: UpdateSubscriptionPlanAPIModel;
}

/**
 * SubscriptionPlanApi - interface
 * 
 * @export
 * @interface SubscriptionPlanApiInterface
 */
export interface SubscriptionPlanApiInterface {
    /**
     * Updates status of the subscription plan
     * @param {string} id 
     * @param {UpdateSubscriptionPlanStatusAPIModel} updateSubscriptionPlanStatusAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionPlanApiInterface
     */
    changeSubscriptionPlanStatusRaw(requestParameters: ChangeSubscriptionPlanStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SubscriptionPlanAPIModel>>;

    /**
     * Updates status of the subscription plan
     */
    changeSubscriptionPlanStatus(id: string, updateSubscriptionPlanStatusAPIModel: UpdateSubscriptionPlanStatusAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SubscriptionPlanAPIModel>;

    /**
     * Creates new subscription plan
     * @param {NewSubscriptionPlanAPIModel} newSubscriptionPlanAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionPlanApiInterface
     */
    createSubscriptionPlanRaw(requestParameters: CreateSubscriptionPlanRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SubscriptionPlanAPIModel>>;

    /**
     * Creates new subscription plan
     */
    createSubscriptionPlan(newSubscriptionPlanAPIModel: NewSubscriptionPlanAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SubscriptionPlanAPIModel>;

    /**
     * Deletes subscription plan by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionPlanApiInterface
     */
    deleteSubscriptionPlanRaw(requestParameters: DeleteSubscriptionPlanRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>>;

    /**
     * Deletes subscription plan by id
     */
    deleteSubscriptionPlan(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object>;

    /**
     * Returns subscription plan by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionPlanApiInterface
     */
    getSubscriptionPlanRaw(requestParameters: GetSubscriptionPlanRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SubscriptionPlanAPIModel>>;

    /**
     * Returns subscription plan by id
     */
    getSubscriptionPlan(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SubscriptionPlanAPIModel>;

    /**
     * Returns subscription plans by a filter
     * @param {SubscriptionPlanFilterAPIModel} subscriptionPlanFilterAPIModel 
     * @param {boolean} [withTotal] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionPlanApiInterface
     */
    searchSubscriptionPlansRaw(requestParameters: SearchSubscriptionPlansRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SliceSubscriptionPlanSubscriptionPlanSortByAPIModel>>;

    /**
     * Returns subscription plans by a filter
     */
    searchSubscriptionPlans(subscriptionPlanFilterAPIModel: SubscriptionPlanFilterAPIModel, withTotal?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SliceSubscriptionPlanSubscriptionPlanSortByAPIModel>;

    /**
     * Updates subscription plan by id
     * @param {string} id 
     * @param {UpdateSubscriptionPlanAPIModel} updateSubscriptionPlanAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionPlanApiInterface
     */
    updateSubscriptionPlanRaw(requestParameters: UpdateSubscriptionPlanRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SubscriptionPlanAPIModel>>;

    /**
     * Updates subscription plan by id
     */
    updateSubscriptionPlan(id: string, updateSubscriptionPlanAPIModel: UpdateSubscriptionPlanAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SubscriptionPlanAPIModel>;

}

/**
 * 
 */
export class SubscriptionPlanApi extends runtime.BaseAPI implements SubscriptionPlanApiInterface {

    /**
     * Updates status of the subscription plan
     */
    async changeSubscriptionPlanStatusRaw(requestParameters: ChangeSubscriptionPlanStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SubscriptionPlanAPIModel>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling changeSubscriptionPlanStatus().'
            );
        }

        if (requestParameters['updateSubscriptionPlanStatusAPIModel'] == null) {
            throw new runtime.RequiredError(
                'updateSubscriptionPlanStatusAPIModel',
                'Required parameter "updateSubscriptionPlanStatusAPIModel" was null or undefined when calling changeSubscriptionPlanStatus().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/subscription-plans/{id}/update-status`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSubscriptionPlanStatusAPIModelToJSON(requestParameters['updateSubscriptionPlanStatusAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubscriptionPlanAPIModelFromJSON(jsonValue));
    }

    /**
     * Updates status of the subscription plan
     */
    async changeSubscriptionPlanStatus(id: string, updateSubscriptionPlanStatusAPIModel: UpdateSubscriptionPlanStatusAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SubscriptionPlanAPIModel> {
        const response = await this.changeSubscriptionPlanStatusRaw({ id: id, updateSubscriptionPlanStatusAPIModel: updateSubscriptionPlanStatusAPIModel }, initOverrides);
        return await response.value();
    }

    /**
     * Creates new subscription plan
     */
    async createSubscriptionPlanRaw(requestParameters: CreateSubscriptionPlanRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SubscriptionPlanAPIModel>> {
        if (requestParameters['newSubscriptionPlanAPIModel'] == null) {
            throw new runtime.RequiredError(
                'newSubscriptionPlanAPIModel',
                'Required parameter "newSubscriptionPlanAPIModel" was null or undefined when calling createSubscriptionPlan().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/subscription-plans`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewSubscriptionPlanAPIModelToJSON(requestParameters['newSubscriptionPlanAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubscriptionPlanAPIModelFromJSON(jsonValue));
    }

    /**
     * Creates new subscription plan
     */
    async createSubscriptionPlan(newSubscriptionPlanAPIModel: NewSubscriptionPlanAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SubscriptionPlanAPIModel> {
        const response = await this.createSubscriptionPlanRaw({ newSubscriptionPlanAPIModel: newSubscriptionPlanAPIModel }, initOverrides);
        return await response.value();
    }

    /**
     * Deletes subscription plan by id
     */
    async deleteSubscriptionPlanRaw(requestParameters: DeleteSubscriptionPlanRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteSubscriptionPlan().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/subscription-plans/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Deletes subscription plan by id
     */
    async deleteSubscriptionPlan(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.deleteSubscriptionPlanRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * Returns subscription plan by id
     */
    async getSubscriptionPlanRaw(requestParameters: GetSubscriptionPlanRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SubscriptionPlanAPIModel>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getSubscriptionPlan().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/subscription-plans/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubscriptionPlanAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns subscription plan by id
     */
    async getSubscriptionPlan(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SubscriptionPlanAPIModel> {
        const response = await this.getSubscriptionPlanRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * Returns subscription plans by a filter
     */
    async searchSubscriptionPlansRaw(requestParameters: SearchSubscriptionPlansRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SliceSubscriptionPlanSubscriptionPlanSortByAPIModel>> {
        if (requestParameters['subscriptionPlanFilterAPIModel'] == null) {
            throw new runtime.RequiredError(
                'subscriptionPlanFilterAPIModel',
                'Required parameter "subscriptionPlanFilterAPIModel" was null or undefined when calling searchSubscriptionPlans().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['withTotal'] != null) {
            queryParameters['withTotal'] = requestParameters['withTotal'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/subscription-plans/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SubscriptionPlanFilterAPIModelToJSON(requestParameters['subscriptionPlanFilterAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SliceSubscriptionPlanSubscriptionPlanSortByAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns subscription plans by a filter
     */
    async searchSubscriptionPlans(subscriptionPlanFilterAPIModel: SubscriptionPlanFilterAPIModel, withTotal?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SliceSubscriptionPlanSubscriptionPlanSortByAPIModel> {
        const response = await this.searchSubscriptionPlansRaw({ subscriptionPlanFilterAPIModel: subscriptionPlanFilterAPIModel, withTotal: withTotal }, initOverrides);
        return await response.value();
    }

    /**
     * Updates subscription plan by id
     */
    async updateSubscriptionPlanRaw(requestParameters: UpdateSubscriptionPlanRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SubscriptionPlanAPIModel>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateSubscriptionPlan().'
            );
        }

        if (requestParameters['updateSubscriptionPlanAPIModel'] == null) {
            throw new runtime.RequiredError(
                'updateSubscriptionPlanAPIModel',
                'Required parameter "updateSubscriptionPlanAPIModel" was null or undefined when calling updateSubscriptionPlan().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/subscription-plans/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSubscriptionPlanAPIModelToJSON(requestParameters['updateSubscriptionPlanAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubscriptionPlanAPIModelFromJSON(jsonValue));
    }

    /**
     * Updates subscription plan by id
     */
    async updateSubscriptionPlan(id: string, updateSubscriptionPlanAPIModel: UpdateSubscriptionPlanAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SubscriptionPlanAPIModel> {
        const response = await this.updateSubscriptionPlanRaw({ id: id, updateSubscriptionPlanAPIModel: updateSubscriptionPlanAPIModel }, initOverrides);
        return await response.value();
    }

}
