import { FolderOpenOutlined, SaveOutlined } from '@ant-design/icons';
import BigNumber from 'bignumber.js';
import React, { useCallback, useRef } from 'react';
import { v4 as uuid } from 'uuid';

import { FormattedMessage, Operation } from '@/components';
import type { PayoutDestinationItem } from '@/features/settlements/components/CreatePayoutForm/components';
import { I18nFeatureSettlementPayouts } from '@/generated/i18n/i18n';
import { asType, notEmpty } from '@/infrastructure/utils/ts';
import { isSameAddress } from '@/infrastructure/utils/web3/address';

import type { PayoutPersistOperationProps } from './types';

const inputStyle: React.CSSProperties = { visibility: 'hidden', display: 'none' };

const PayoutPersistOperation: React.FC<PayoutPersistOperationProps> = ({
  'data-test': dataTest,
  value,
  onChange,
  disabled,
  mode,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const loadDataFromFile = useCallback(
    (fileText: string) => {
      const dataToMerge: PayoutDestinationItem[] = fileText
        .split('\n')
        .map((line) => {
          try {
            const parts = line.trim().split(',');
            const address = (parts[0] || '').trim();
            const amount = new BigNumber((parts[1] || '').trim());
            if (address === '' || amount.lte(0)) return undefined;
            return asType<PayoutDestinationItem>({ key: uuid(), address, amount });
          } catch (e) {
            return undefined;
          }
        })
        .filter(notEmpty);

      if (!dataToMerge.length) {
        return;
      }

      const newData = [...value];
      dataToMerge.forEach((item) => {
        const index = newData.findIndex((checkItem) => isSameAddress(checkItem.address, item.address));
        if (index > -1) {
          newData.splice(index, 1, item);
        } else {
          newData.push(item);
        }
      });
      onChange(newData);
    },
    [onChange, value],
  );

  const doOpenFile = useCallback(() => {
    fileInputRef.current?.click();
  }, []);

  const processSelectedFile = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (!file) {
        return;
      }
      const enc = new TextDecoder('utf-8');
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target?.result) {
          loadDataFromFile(typeof e.target.result === 'string' ? e.target.result : enc.decode(e.target.result));
        }
      };
      reader.readAsText(file);
    },
    [loadDataFromFile],
  );

  const saveDataToFile = useCallback(() => {
    const csvContent = value.map((item) => `${item.address},${item.amount.toString()}`).join('\n');
    const a = document.createElement('a');
    a.href = URL.createObjectURL(new Blob([csvContent], { type: 'text/csv;charset=utf-8' }));
    a.setAttribute('download', `payout_destinations_${value.length}.csv`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }, [value]);

  return (
    <>
      <Operation
        data-test={dataTest && `${dataTest}-load-button`}
        disabled={disabled}
        onClick={doOpenFile}
        icon={<FolderOpenOutlined />}
        title={
          <FormattedMessage
            id={
              I18nFeatureSettlementPayouts.COMPONENTS_CREATE_PAYOUT_FORM_DESTINATIONS_TABLE_PERSIST_OPERATION_LOAD_TITLE
            }
            tagName="span"
          />
        }
        mode={mode}
      />
      <input
        data-test={dataTest && `${dataTest}-load-input`}
        ref={fileInputRef}
        type="file"
        accept=".csv"
        style={inputStyle}
        onChange={processSelectedFile}
      />
      <Operation
        data-test={dataTest && `${dataTest}-download-button`}
        disabled={disabled || !value.length}
        onClick={saveDataToFile}
        icon={<SaveOutlined />}
        title={
          <FormattedMessage
            id={
              I18nFeatureSettlementPayouts.COMPONENTS_CREATE_PAYOUT_FORM_DESTINATIONS_TABLE_PERSIST_OPERATION_SAVE_TITLE
            }
            tagName="span"
          />
        }
        mode={mode}
      />
    </>
  );
};

const PayoutPersistOperationMemo = React.memo(PayoutPersistOperation);

export default PayoutPersistOperationMemo;
