import { WarningOutlined } from '@ant-design/icons';
import { List, Space, Tag } from 'antd';
import React from 'react';

import { FormattedMessage } from '@/components';
import { AddressLink, NativeAssetAmount } from '@/features/dictionary/blockchain/components';
import { I18nFeatureGasWallets } from '@/generated/i18n/i18n';

import type { GasWalletDerivedItemProps } from './types';

const GasWalletDerivedItem: React.FC<GasWalletDerivedItemProps> = ({ 'data-test': dataTest, wallet }) => (
  <List.Item>
    <List.Item.Meta
      style={{ alignItems: 'center' }}
      title={
        <Space>
          {wallet.isOutOfService && (
            <Tag color="red" data-test={dataTest && `${dataTest}-outOfService`}>
              <Space>
                <WarningOutlined />
                <FormattedMessage
                  id={I18nFeatureGasWallets.COMPONENTS_INVALID_STATE_MESSAGE_ALL_SERVICE_TAG}
                  tagName="span"
                />
              </Space>
            </Tag>
          )}
        </Space>
      }
      description={
        <AddressLink bt={wallet.bt} data-test={dataTest && `${dataTest}-address`} address={wallet.address} />
      }
    />
    <NativeAssetAmount value={wallet.nativeBalance} bt={wallet.bt} withCurrency />
  </List.Item>
);

const GasWalletDerivedItemMemo = React.memo(GasWalletDerivedItem);

export default GasWalletDerivedItemMemo;
