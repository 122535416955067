import { EditTwoTone } from '@ant-design/icons';
import { Modal } from 'antd';
import React, { useMemo } from 'react';

import { defaultDialogFormLayout, defaultDialogFormTailLayout, FormattedMessage, Operation } from '@/components';
import { useCompanySettings, useCompanySettingsActions } from '@/features/company-settings/hooks';
import { I18nFeatureCompanySettings } from '@/generated/i18n/i18n';
import { useFormVisible, useNotification } from '@/hooks';
import { noop, withOnSuccess } from '@/infrastructure/utils/functions';
import { goalReached, YMGoals } from '@/infrastructure/ym';

import { UpdateCompanyWebsiteForm } from './components';

import type { OperationUpdateCompanyWebsiteProps } from './types';

const notificationOptions = {
  error: () => <FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_UPDATE_WEBSITE_MESSAGE_ERROR} />,
  success: () => ({
    message: (
      <FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_UPDATE_WEBSITE_MESSAGE_SUCCESS_TITLE} />
    ),
  }),
};

const OperationUpdateCompanyWebsite: React.FC<OperationUpdateCompanyWebsiteProps> = ({
  'data-test': dataTest,
  disabled,
  inProgress,
  onSuccess,
  mode = 'inline',
}) => {
  const { updateWebsite: updateWebsiteAction } = useCompanySettingsActions();
  const { data: settings } = useCompanySettings();
  const formControl = useFormVisible(false);
  const { withNotification } = useNotification();

  const updateWebsite = useMemo(
    () =>
      withNotification(
        formControl.withFormHide(
          withOnSuccess(async (website: string) => {
            const result = await updateWebsiteAction.act(website);
            goalReached(YMGoals.COMPANY_NAME_UPDATED);
            return result;
          }, onSuccess ?? noop),
        ),
        notificationOptions,
      ),
    [withNotification, formControl, onSuccess, updateWebsiteAction],
  );

  const showForm = useMemo(
    () => withOnSuccess(formControl.show, () => goalReached(YMGoals.COMPANY_WEBSITE_UPDATE_INIT)),
    [formControl.show],
  );

  const initialValues = useMemo(() => ({ website: settings.data?.website }), [settings.data?.website]);

  return (
    <>
      {formControl.visible && (
        <Modal
          open
          maskClosable={false}
          footer={null}
          title={<FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_UPDATE_WEBSITE_FORM_TITLE} />}
          closable
          onCancel={formControl.hide}
        >
          <UpdateCompanyWebsiteForm
            values={initialValues}
            layout={defaultDialogFormLayout}
            tailLayout={defaultDialogFormTailLayout}
            data-test={dataTest && `${dataTest}-form`}
            onReset={formControl.hide}
            onSubmit={updateWebsite}
          />
        </Modal>
      )}
      <Operation
        title={<FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_UPDATE_WEBSITE_LABEL} />}
        icon={<EditTwoTone />}
        onClick={showForm}
        disabled={disabled || !updateWebsiteAction.available}
        inProgress={inProgress || formControl.visible || updateWebsiteAction.inAction}
        data-test={dataTest}
        mode={mode}
      />
    </>
  );
};

const OperationUpdateCompanyWebsiteMemo = React.memo(OperationUpdateCompanyWebsite);

export default OperationUpdateCompanyWebsiteMemo;
