import { useMemo } from 'react';

import type { UseAppSingleData } from '@/app/hooks';
import { useAppSingleData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { makeSelectPending } from '@/features/global/selectors';
import { fetchSettlement } from '@/features/settlements/actions';
import { makeSelectSettlement } from '@/features/settlements/selectors';
import type { Settlement } from '@/features/settlements/types';
import type { LoadingStateWithDirty } from '@/infrastructure/model';
import { storedDataError } from '@/infrastructure/model';

export interface UseSettlement extends UseAppSingleData<Settlement> {}

const noData = storedDataError<Settlement>('no-data');

const fetchFactory = (
  id: string,
  force: boolean,
): AppAsyncThunkAction<LoadingStateWithDirty<Settlement>, Parameters<typeof fetchSettlement>[0]> =>
  fetchSettlement({ id, force });

export default function useSettlement(id: string | undefined): UseSettlement {
  const dataFetchingSelector = useMemo(() => makeSelectPending(fetchSettlement, id), [id]);
  return useAppSingleData(fetchFactory, makeSelectSettlement, noData, id, dataFetchingSelector);
}
