import type { UseAppListData } from '@/app/hooks';
import { useAppListData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { fetchGasHistoryList } from '@/features/gas-history/actions';
import { makeSelectGasHistoryListData, makeSelectGasHistoryListParameters } from '@/features/gas-history/selectors';
import type { GasHistoryRow, GasHistoryFilterPredicate } from '@/features/gas-history/types';
import { makeSelectPending } from '@/features/global/selectors';
import type { GasHistorySortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { LoadingListDataState } from '@/infrastructure/model/list/types';

export interface UseGasHistoryList
  extends UseAppListData<GasHistoryRow, GasHistoryFilterPredicate, GasHistorySortByAPIModel> {}

const fetchFactory = (
  force: boolean,
): AppAsyncThunkAction<LoadingListDataState<GasHistoryRow>, Parameters<typeof fetchGasHistoryList>[0]> =>
  fetchGasHistoryList({ force });

const dataSelector = makeSelectGasHistoryListData();
const dataFetchingSelector = makeSelectPending(fetchGasHistoryList);
const parametersSelector = makeSelectGasHistoryListParameters();

export default function useGasHistoryList(): UseGasHistoryList {
  return useAppListData(fetchFactory, parametersSelector, dataFetchingSelector, dataSelector);
}
