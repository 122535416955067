import { getAddress } from 'viem';

import { type AuthenticatedUserAPIModel, MerchantAuthApi, MerchantUserApi } from '@/generated/api/ncps-api';
import { apiConfigurationFactory } from '@/infrastructure/request';

import type { User, UserPreferences } from './types';

const merchantUserSecuredApi = new MerchantUserApi(apiConfigurationFactory('auth'));
const authAPIWithAuth = new MerchantAuthApi(apiConfigurationFactory('no-relogin'));

export const parseUser = ({ address, tokenExpiresAt, activeCompany, roles, ...rest }: AuthenticatedUserAPIModel): User => ({
  ...rest,
  address: getAddress(address),
});

export const queryCurrentUser = async (initOverrides?: RequestInit) =>
  parseUser(await authAPIWithAuth.getAuthenticatedUser(initOverrides));

export const queryUserPreferences = (initOverrides?: RequestInit): Promise<UserPreferences> =>
  merchantUserSecuredApi.getOwnPreferences(initOverrides);
export const requestUpdateUserPreferences = (
  preferences: UserPreferences,
  initOverrides?: RequestInit,
): Promise<UserPreferences> => merchantUserSecuredApi.saveOwnPreferences(preferences, initOverrides);
