import { Form } from 'antd';
import BigNumber from 'bignumber.js';
import React, { useCallback, useMemo } from 'react';
import isEqual from 'react-fast-compare';

import {
  FormattedMessage,
  ErrorFormMessage,
  FormCompletenessItem,
  FormFooter,
  PageLoading,
  defaultPageFormLayout,
  defaultPageFormTailLayout,
} from '@/components';
import {
  CompanyActiveAssetAmountItem,
  type CompanyActiveAssetAmountItemValue,
} from '@/features/company-settings/components';
import { I18nFeaturePayments } from '@/generated/i18n/i18n';
import { useErrorSafeSubmitting, useForm, useStateMountSafe } from '@/hooks';
import { amountToAPI } from '@/infrastructure/api';
import { noop, withVoidOrThrow, wrap } from '@/infrastructure/utils/functions';
import { nameof } from '@/infrastructure/utils/ts';

import { DescriptionItem, ExpiresAtItem, QRSupportErrorItem, MetadataItem } from './components';
import { useInitialValues } from './hooks';

import type { CreatePaymentFormData, CreatePaymentFormProps } from './types';
import type { NamePath } from 'rc-field-form/lib/interface';

const requiredFields: NamePath[] = [
  [nameof<CreatePaymentFormData>('amount'), nameof<CompanyActiveAssetAmountItemValue>('asset')],
  [nameof<CreatePaymentFormData>('amount'), nameof<CompanyActiveAssetAmountItemValue>('amount')],
  nameof<CreatePaymentFormData>('fwError'),
  nameof<CreatePaymentFormData>('expiresAt'),
];

const fieldsToValidate: NamePath[] = [
  nameof<CreatePaymentFormData>('metadata'),
  nameof<CreatePaymentFormData>('description'),
];

const formMessages = {
  submit: <FormattedMessage id={I18nFeaturePayments.COMPONENTS_CREATE_PAYMENT_FORM_SUBMIT_BUTTON} tagName="span" />,
};

const CreatePaymentForm: React.FC<CreatePaymentFormProps> = ({
  'data-test': dataTest,
  submitCallback,
  onSubmitting,
  onSubmit,
  onReset = noop,
  layout = defaultPageFormLayout,
  tailLayout = defaultPageFormTailLayout,
}) => {
  const initialValues = useInitialValues();
  const { form, withResetForm } = useForm<CreatePaymentFormData>();
  const [isFormComplete, setFormComplete] = useStateMountSafe(false);
  const { submitting, error, withSubmitting, withErrorHandling, reset: resetSubmit } = useErrorSafeSubmitting();
  const doReset = useMemo(() => withResetForm(resetSubmit), [resetSubmit, withResetForm]);
  const doCancel = useMemo(() => withErrorHandling(onReset), [onReset, withErrorHandling]);
  const doSubmit = useCallback(
    ({ amount, ...values }: CreatePaymentFormData): unknown =>
      onSubmit({
        ...values,
        amount: amountToAPI({ value: amount.amount.value ?? BigNumber(0), asset: amount.asset }),
        expiresAt: values.expiresAt.toDate(),
      }),
    [onSubmit],
  );
  const doSubmitForm = useMemo(() => withSubmitting(doSubmit), [doSubmit, withSubmitting]);

  const updateComplete = useCallback(
    (complete: boolean) => {
      const value = form.getFieldsValue();
      const hasBeenUpdated = !initialValues || !isEqual(initialValues, value);
      const isCompleteAndUpdated = complete && hasBeenUpdated;
      setFormComplete(isCompleteAndUpdated);
      const submitFn = isCompleteAndUpdated
        ? withSubmitting(
            withVoidOrThrow(
              wrap(
                async () => {
                  await doSubmit(value);
                  setTimeout(doReset, 0);
                },
                () => onSubmitting?.(true),
                () => onSubmitting?.(false),
              ),
            ),
          )
        : undefined;
      submitCallback?.(submitFn);
    },
    [form, initialValues, setFormComplete, withSubmitting, submitCallback, doSubmit, doReset, onSubmitting],
  );

  if (!initialValues) {
    return <PageLoading type="CreatePaymentForm" />;
  }

  return (
    <Form<CreatePaymentFormData>
      onValuesChange={resetSubmit}
      onResetCapture={doReset}
      {...layout}
      initialValues={initialValues}
      autoComplete="off"
      form={form}
      onFinish={doSubmitForm}
      onReset={doCancel}
    >
      {error && <ErrorFormMessage data-test={dataTest && `${dataTest}-error`} content={error} />}
      <CompanyActiveAssetAmountItem<CreatePaymentFormData>
        data-test={dataTest && `${dataTest}-mainAmount`}
        name={nameof<CreatePaymentFormData>('amount')}
      />
      <QRSupportErrorItem<CreatePaymentFormData>
        data-test={dataTest && `${dataTest}-forwarder`}
        name={nameof<CreatePaymentFormData>('fwError')}
        assetAmountName={nameof<CreatePaymentFormData>('amount')}
      />
      <ExpiresAtItem<CreatePaymentFormData>
        data-test={dataTest && `${dataTest}-expiresAt`}
        name={nameof<CreatePaymentFormData>('expiresAt')}
      />
      <MetadataItem<CreatePaymentFormData>
        data-test={dataTest && `${dataTest}-metadata`}
        name={nameof<CreatePaymentFormData>('metadata')}
      />
      <DescriptionItem<CreatePaymentFormData>
        data-test={dataTest && `${dataTest}-description`}
        name={nameof<CreatePaymentFormData>('description')}
      />

      <FormCompletenessItem<CreatePaymentFormData>
        requiredFields={requiredFields}
        fields={fieldsToValidate}
        onChange={updateComplete}
      />
      <FormFooter
        data-test={dataTest && `${dataTest}-footer`}
        noStyle={!!submitCallback}
        style={submitCallback ? { visibility: 'hidden' } : undefined}
        messages={formMessages}
        tailLayout={tailLayout}
        submitDisabled={!isFormComplete}
        submitting={submitting}
      />
    </Form>
  );
};

const CreatePaymentFormMemo = React.memo(CreatePaymentForm);

export default CreatePaymentFormMemo;
