import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { DateTimeRangeItem, FormattedMessage } from '@/components';
import { I18nFeatureMerchantWalletBalance } from '@/generated/i18n/i18n';

import type { MerchantWalletRunningBalanceBroadcastAtRangeFilterItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const MerchantWalletRunningBalanceBroadcastAtRangeFilterItemRaw = <Values extends Store = Store>({
  'data-test': dataTest,
  name,
  readonly,
  relativeUpdateKey,
  ItemProps = {},
}: MerchantWalletRunningBalanceBroadcastAtRangeFilterItemProps<Values>) => {
  const { formatMessage } = useIntl();
  return (
    <DateTimeRangeItem<Values>
      data-test={dataTest}
      name={name}
      readonly={readonly}
      messages={useMemo(
        () => ({
          from: {
            label: (
              <FormattedMessage id={I18nFeatureMerchantWalletBalance.COMPONENTS_FILTER_FORM_BROADCAST_AT_FROM_LABEL} />
            ),
            placeholder: formatMessage({
              id: I18nFeatureMerchantWalletBalance.COMPONENTS_FILTER_FORM_BROADCAST_AT_FROM_PLACEHOLDER,
            }),
          },
          to: {
            label: (
              <FormattedMessage id={I18nFeatureMerchantWalletBalance.COMPONENTS_FILTER_FORM_BROADCAST_AT_TO_LABEL} />
            ),
            placeholder: formatMessage({
              id: I18nFeatureMerchantWalletBalance.COMPONENTS_FILTER_FORM_BROADCAST_AT_TO_PLACEHOLDER,
            }),
          },
        }),
        [formatMessage],
      )}
      relativeUpdateKey={relativeUpdateKey}
      ItemProps={ItemProps}
    />
  );
};

const MerchantWalletRunningBalanceBroadcastAtRangeFilterItem = React.memo(
  MerchantWalletRunningBalanceBroadcastAtRangeFilterItemRaw,
) as typeof MerchantWalletRunningBalanceBroadcastAtRangeFilterItemRaw;

export default MerchantWalletRunningBalanceBroadcastAtRangeFilterItem;
