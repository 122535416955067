import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import type { SubscriptionPlan } from '@/features/subscription-plans/types';
import { I18nFeatureSubscriptionStatistics } from '@/generated/i18n/i18n';
import { isEnumKey } from '@/infrastructure/utils/ts';

import { LineType, type UseChartPlanLabels } from './types';

export default function useChartPlanLabels(plans: Partial<Record<string, SubscriptionPlan>>): UseChartPlanLabels {
  const { formatMessage: i18n } = useIntl();
  const overallLineLabels = useMemo(
    () => ({
      [LineType.mean]: i18n({ id: I18nFeatureSubscriptionStatistics.HOOKS_PLAN_LABELS_GRAPH_MEAN_TITLE }),
      [LineType.median]: i18n({ id: I18nFeatureSubscriptionStatistics.HOOKS_PLAN_LABELS_GRAPH_MEDIAN_TITLE }),
      [LineType.total]: i18n({ id: I18nFeatureSubscriptionStatistics.HOOKS_PLAN_LABELS_GRAPH_TOTAL_TITLE }),
    }),
    [i18n],
  );
  const legendItemName = useCallback(
    (value: { id: string } | string) => {
      const text = typeof value === 'string' ? value : value.id;
      return (
        (isEnumKey(LineType, text) && overallLineLabels[text]) || plans[text]?.description.substring(0, 30) || text
      );
    },
    [overallLineLabels, plans],
  );
  const tooltipItemValue = useCallback(
    (value: { id: string } | string) => {
      const text = typeof value === 'string' ? value : value.id;
      return (
        (isEnumKey(LineType, text) && overallLineLabels[text]) || plans[text]?.description.substring(0, 15) || text
      );
    },
    [overallLineLabels, plans],
  );
  const legendItemValue = useCallback(
    (value: { id: string } | string) => {
      const text = typeof value === 'string' ? value : value.id;
      return plans[text] ? i18n({ id: I18nFeatureSubscriptionStatistics.HOOKS_PLAN_LABELS_LEGEND_PLAN_LINK }) : null;
    },
    [plans, i18n],
  );
  // TODO: not a good link from feature to page
  const onPlanViewClick = useCallback(() => {
    // (_: Chart, event: PlotEvent) => {
    // FIXME: check the event and type
    // import { ChartEvent } from '@antv/g2';
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    // if (event.type === ChartEvent.CLICK) {
    //   console.log(`${event} has been fired`);
    //   // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
    //   // const legendItem: LegendItem | undefined = event.gEvent.target.cfg?.delegateObject?.item;
    //   // if (legendItem?.name) {
    //   //   setTimeout(() => {
    //   //     openPlan(legendItem.name);
    //   //   }, 0);
    //   // }
    // }
  }, []);

  return { overallLineLabels, legendItemName, legendItemValue, tooltipItemValue, onPlanViewClick };
}
