import { Space } from 'antd';
import { type PropsWithChildren, useRef } from 'react';

import { FormattedMessage, PageCard, PageContainer } from '@/components';
import { SelectedNetworkLabel } from '@/features/dictionary/blockchain/components';
import {
  MerchantWalletRunningBalanceFilterForm,
  MerchantWalletRunningBalancesTable,
  OperationExportMerchantWalletRunningBalances,
} from '@/features/merchant-wallet-balance/components';
import { useMerchantWalletRunningBalanceListView } from '@/features/merchant-wallet-balance/hooks';
import { I18nPageAudit } from '@/generated/i18n/i18n';
import type { TestableProps } from '@/infrastructure/utils/react';
import MerchantWalletRunningBalanceLink from '@/pages/audit/balance-view/components/MerchantWalletRunningBalanceLink';

import { MerchantWalletRunningBalancesBreadcrumb } from './components';

import type React from 'react';

interface MerchantWalletRunningBalancesPageProps extends PropsWithChildren<TestableProps> {}

const MerchantWalletRunningBalancesPage: React.FC<MerchantWalletRunningBalancesPageProps> = ({
  'data-test': dataTest = 'mwb',
}) => {
  const view = useMerchantWalletRunningBalanceListView();
  const ref = useRef<HTMLDivElement>(null);
  return (
    <PageContainer
      data-test={dataTest && `${dataTest}-container`}
      breadcrumb={<MerchantWalletRunningBalancesBreadcrumb data-test={dataTest && `${dataTest}-breadcrumb`} disabled />}
    >
      <PageCard
        ref={ref}
        title={
          <Space>
            <FormattedMessage id={I18nPageAudit.BALANCES_COMPONENTS_BALANCES_CARD_TITLE} />
            <SelectedNetworkLabel mode="short" data-test={dataTest && `${dataTest}-selectNetwork`} />
          </Space>
        }
        cardSize="big"
      >
        <MerchantWalletRunningBalancesTable
          data-test={dataTest}
          {...view}
          ExportOperation={OperationExportMerchantWalletRunningBalances}
          FilterForm={MerchantWalletRunningBalanceFilterForm}
          MerchantWalletRunningBalanceLink={MerchantWalletRunningBalanceLink}
        />
      </PageCard>
    </PageContainer>
  );
};

export default MerchantWalletRunningBalancesPage;
