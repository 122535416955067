import type { DateTimeRangeRelativeEnum, DateTimeRelativeQuickFilter } from '@/components';
import type {
  BlockchainNetworkTypeAPIModel,
  BlockchainTypeAPIModel,
  MerchantGasHistoryPredicatesAPIModel,
  MerchantGasHistoryRowAPIModel,
  TransactionResultAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import { GasHistorySortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { defaultPage } from '@/infrastructure/api';
import type { ListSortBy, ListState, ListStateNoColumns } from '@/infrastructure/model/list/types';
import { storedDirtyListDataTyped } from '@/infrastructure/model/list/utils';
import type { SingleState } from '@/infrastructure/model/single/types';

export const NAMESPACE = 'gas-history';

export const gasHistoryExportReport = 'gas-usage';

export interface GasHistoryRow extends MerchantGasHistoryRowAPIModel {}

export interface GasHistoryFilterPredicate
  extends Pick<
    MerchantGasHistoryPredicatesAPIModel,
    'networkEq' | 'createdAtRange' | 'btIn' | 'addressLike' | 'sponsorAddressLike' | 'resultEq' | 'functionNameEq'
  > {
  createdAtRangeRelative?: DateTimeRelativeQuickFilter;
}

export interface GasHistoryForMerchantFilterPredicate
  extends Omit<GasHistoryFilterPredicate, 'companyHidIn' | 'broadcastAtRangeRelative'> {}

export interface GasHistoryReportPredicates {
  merchantHidIn?: string[];
  addressLike?: string;
  btIn?: BlockchainTypeAPIModel[];
  createdAtRangeRelative?: DateTimeRangeRelativeEnum;
  resultEq?: TransactionResultAPIModel;
  networkEq?: BlockchainNetworkTypeAPIModel;
}

export type GasHistoryForMerchantState = ListStateNoColumns<
  string,
  GasHistoryForMerchantFilterPredicate,
  GasHistorySortByAPIModel
>;

export const defaultGasHistorySortBy: ListSortBy<GasHistorySortByAPIModel> = {
  [GasHistorySortByAPIModel.createdAt]: 'DESC',
};
export const defaultGasHistoryForMerchantState: GasHistoryForMerchantState = {
  data: storedDirtyListDataTyped(),
  page: defaultPage,
  filter: {},
  sortBy: defaultGasHistorySortBy,
};

export interface GasHistoryState {
  list: ListState<string, GasHistoryFilterPredicate, GasHistorySortByAPIModel>;
  entities: SingleState<GasHistoryRow>; // key - eventId
}
