import { useMerchantGasWallet } from '@/features/gas-wallets/hooks';
import type { GasWallet } from '@/features/gas-wallets/types';
import type { BlockchainTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';

import type React from 'react';

const EmptyComponentImpl: React.FC = () => null;

const withGasWalletLoad =
  <T extends { wallet: GasWallet }>(
    Component: React.ComponentType<Omit<T, 'wallet'> & { wallet: GasWallet }>,
    EmptyComponent: React.ComponentType,
  ) =>
  (props: Omit<T, 'wallet'> & { bt: BlockchainTypeAPIModel }) => {
    const { bt } = props;
    const {
      data: { data: wallet },
    } = useMerchantGasWallet(bt);
    return wallet ? <Component {...props} wallet={wallet} /> : <EmptyComponent />;
  };

const withGasWallet = <T extends { wallet: GasWallet }>(
  Component: React.ComponentType<Omit<T, 'wallet'> & { wallet: GasWallet }>,
  EmptyComponent: React.ComponentType = EmptyComponentImpl,
) => {
  const WalletComponent = withGasWalletLoad<T>(Component, EmptyComponent);
  return (props: T | (Omit<T, 'wallet'> & { bt: BlockchainTypeAPIModel })) =>
    'wallet' in props ? <Component {...props} /> : <WalletComponent {...props} />;
};

export default withGasWallet;
