import { Form, Spin } from 'antd';
import xor from 'lodash/xor';
import React, { useCallback, useMemo } from 'react';

import {
  defaultPageFormTailLayout,
  ErrorFormMessage,
  FormattedMessage,
  FormCompletenessItem,
  FormFooter,
} from '@/components';
import { I18nFeatureDictionary } from '@/generated/i18n/i18n';
import { useErrorSafeSubmitting, useForm, useStateMountSafe } from '@/hooks';
import { noop, withVoidOrThrow, wrap } from '@/infrastructure/utils/functions';
import { nameof } from '@/infrastructure/utils/ts';

import AssetsInput from '../AssetsInput';

import type { AssetsEditFormContent, AssetsEditFormProps } from './types';
import type { FormInstance } from 'antd/es/form';
import type { FormProps } from 'antd/lib/form/Form';

const AssetsEditForm: React.FC<AssetsEditFormProps> = ({
  'data-test': dataTest,
  values,
  withNetworkMark,
  className,
  messages,
  style,
  onSubmit,
  onChange = noop,
  onCancel = noop,
  submitCallback,
  onSubmitting,
  layout = {},
  tailLayout = defaultPageFormTailLayout,
}) => {
  const { form, withResetForm } = useForm<AssetsEditFormContent>();

  const [isFormComplete, setFormComplete] = useStateMountSafe(false);
  const { submitting, error, withSubmitting, withErrorHandling, reset: resetSubmit } = useErrorSafeSubmitting();
  const doReset = useMemo(() => withResetForm(resetSubmit), [resetSubmit, withResetForm]);
  const doSubmit = useCallback(
    (value: AssetsEditFormContent) =>
      withSubmitting(onSubmit)((value.assets ?? []).filter(({ isActive }) => isActive).map(({ code }) => code)),
    [onSubmit, withSubmitting],
  );
  const doCancel = useMemo(
    () => withErrorHandling(withResetForm(onCancel)),
    [onCancel, withErrorHandling, withResetForm],
  );

  const updateComplete = useCallback(
    (complete: boolean) => {
      const value = form.getFieldsValue();
      const isCompleteAndUpdated = complete;
      setFormComplete(isCompleteAndUpdated);
      const doSubmitOnCallback = isCompleteAndUpdated
        ? withSubmitting(
            withVoidOrThrow(
              wrap(
                async () => {
                  await doSubmit(value);
                  setTimeout(doReset, 0);
                },
                () => onSubmitting?.(true),
                () => onSubmitting?.(false),
              ),
            ),
          )
        : undefined;
      submitCallback?.(doSubmitOnCallback);
    },
    [form, setFormComplete, withSubmitting, submitCallback, doSubmit, doReset, onSubmitting],
  );

  const errorMessage = useMemo(() => {
    if (!error) {
      return undefined;
    }
    return <FormattedMessage id={I18nFeatureDictionary.COMPONENTS_ASSETS_FORM_EDIT_FORM_ERROR_COMMON} />;
  }, [error]);
  const initialCodes = useMemo(
    () =>
      values
        .filter(({ isActive }) => isActive)
        .map(({ code }) => code)
        .sort(),
    [values],
  );
  const checkIsFormComplete = useCallback(
    ({ getFieldsValue }: FormInstance<AssetsEditFormContent>) => {
      const value = getFieldsValue();
      const newCodes = value.assets
        ?.filter(({ isActive }) => isActive)
        .map(({ code }) => code)
        .sort();
      return !!xor(newCodes, initialCodes).length;
    },
    [initialCodes],
  );

  const initialValues = useMemo(() => ({ [nameof<AssetsEditFormContent>('assets')]: values }), [values]);
  const onValuesChange = useCallback<NonNullable<FormProps<AssetsEditFormContent>['onValuesChange']>>(
    (_, { assets }) => {
      onChange(assets ?? []);
      resetSubmit();
    },
    [onChange, resetSubmit],
  );

  return (
    <Spin spinning={submitting} className={className} style={style}>
      <Form<AssetsEditFormContent>
        onValuesChange={onValuesChange}
        onResetCapture={doReset}
        className={className}
        style={style}
        autoComplete="off"
        form={form}
        onFinish={doSubmit}
        onReset={doCancel}
        initialValues={initialValues}
        {...layout}
      >
        {errorMessage && <ErrorFormMessage content={errorMessage} />}
        <Form.Item<AssetsEditFormContent> name={nameof<AssetsEditFormContent>('assets')}>
          <AssetsInput
            data-test={dataTest && `${dataTest}-input`}
            withNetworkMark={!withNetworkMark}
            withBlockchainMark
          />
        </Form.Item>
        <FormCompletenessItem<AssetsEditFormContent> onChange={updateComplete} checkIsComplete={checkIsFormComplete} />
        <FormFooter
          data-test={dataTest && `${dataTest}-footer`}
          noStyle={!!submitCallback}
          style={submitCallback ? { visibility: 'hidden' } : undefined}
          tailLayout={tailLayout}
          submitDisabled={!isFormComplete}
          submitting={submitting}
          messages={messages}
        />
      </Form>
    </Spin>
  );
};

const AssetsEditFormMemo = React.memo(AssetsEditForm);

export default AssetsEditFormMemo;
