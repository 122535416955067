import { Alert } from 'antd';
import React from 'react';

import { FormattedMessage, Operation, PageLayout } from '@/components';
import { useDocumentationViewed } from '@/features/user/hooks';
import { I18nFeatureGlobal } from '@/generated/i18n/i18n';

import type { DocumentationBannerProps } from './types';

const DocumentationBanner: React.FC<DocumentationBannerProps> = ({
  'data-test': dataTest,
  message,
  PageProps = {},
  type,
}) => {
  const { viewed, markViewed } = useDocumentationViewed(type);
  if (viewed) {
    return null;
  }

  return (
    <PageLayout {...PageProps}>
      <Alert
        data-test={dataTest}
        message={message}
        closable={{
          closeIcon: (
            <Operation
              onClick={markViewed}
              data-test={dataTest && `${dataTest}-opView`}
              title={<FormattedMessage id={I18nFeatureGlobal.COMPONENTS_DOCUMENTATION_BANNER_OK_TITLE} />}
              icon={null}
              mode="link"
            />
          ),
        }}
        type="info"
        showIcon
      />
    </PageLayout>
  );
};

const DocumentationBannerMemo = React.memo(DocumentationBanner);

export default DocumentationBannerMemo;
