import { Form } from 'antd';
import React, { useMemo } from 'react';

import type { CompanyActiveAssetAmountItemValue } from '@/features/company-settings/components';
import { useStateMountSafe } from '@/hooks';
import { asType } from '@/infrastructure/utils/ts';

import { QRSupportItemInput } from './components';

import type { QRSupportErrorData, QRSupportErrorItemProps } from './types';
import type { FormItemProps } from 'antd/lib/form';
import type { Store } from 'rc-field-form/es/interface';

const QRSupportErrorItem = <Values extends Store = Store>({
  name,
  assetAmountName,
  'data-test': dataTest,
}: QRSupportErrorItemProps<Values>) => {
  const [error, setError] = useStateMountSafe(false);
  const rules = useMemo<FormItemProps<Values>['rules']>(
    () => [
      {
        // eslint-disable-next-line @typescript-eslint/require-await
        validator: async (_, value?: QRSupportErrorData) => {
          setError(!!value?.hasError);
          if (value?.hasError) {
            throw new Error('');
          }
        },
      },
    ],
    [setError],
  );
  return (
    <Form.Item<Values> dependencies={[assetAmountName]} noStyle>
      {({ getFieldValue }) => (
        <Form.Item<Values> labelCol={{ span: 0 }} wrapperCol={{ span: 24 }} name={name} rules={rules} noStyle={!error}>
          <QRSupportItemInput
            data-test={dataTest && `${dataTest}-input`}
            asset={asType<CompanyActiveAssetAmountItemValue | undefined>(getFieldValue(assetAmountName))?.asset}
          />
        </Form.Item>
      )}
    </Form.Item>
  );
};

const QRSupportErrorItemMemo = React.memo(QRSupportErrorItem) as typeof QRSupportErrorItem;

export default QRSupportErrorItemMemo;
