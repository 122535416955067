import { Space, Typography } from 'antd';
import React, { useCallback, useMemo, useRef } from 'react';

import { FormattedMessage } from '@/components';
import type { WalletLoginProps } from '@/features/auth/components';
import { WalletLogin } from '@/features/auth/components';
import { useAuthWeb3Actions } from '@/features/auth/hooks';
import { useWeb3 } from '@/features/web3/hooks';
import { I18nPageAuth } from '@/generated/i18n/i18n';
import { useErrorSafeSubmitting } from '@/hooks';
import type { Func } from '@/infrastructure/utils/ts';
import type { Cancellable } from '@/infrastructure/utils/ui';
import { cancellable } from '@/infrastructure/utils/ui';
import { withHideIfOtherActive } from '@/pages/auth/login/hocs';

import type { WalletsBlockProps } from './types';

const { Text } = Typography;

const web3WalletPrefix = 'web3-';

const WalletsBlock: React.FC<WalletsBlockProps> = ({
  'data-test': dataTest,
  activeProvider,
  onSelectProvider,
  withLogIn,
}) => {
  const { orderedConnectors } = useWeb3();
  const { submitting, withSubmitting } = useErrorSafeSubmitting();
  const cancellationPromise = useRef<Cancellable>();

  const withSelectProvider = useMemo(
    () =>
      (selected: string) =>
      <V extends unknown[] = unknown[], R = unknown>(func: Func<V, R>): Func<V, unknown> =>
        withSubmitting(async (...args: V) => {
          cancellationPromise.current = cancellable((onCancel) => onSelectProvider({ id: selected, onCancel }));
          try {
            return await func(...args);
          } finally {
            cancellationPromise.current = undefined;
            onSelectProvider();
          }
        }),
    [onSelectProvider, withSubmitting],
  );

  const { login: loginWeb3Action } = useAuthWeb3Actions();
  const doWeb3Login: WalletLoginProps['login'] = useCallback(
    ({ connectorId, client }) =>
      withSelectProvider(`${web3WalletPrefix}${connectorId}`)(
        withLogIn(
          (onBeforeSignUp, withRecaptcha) => () =>
            loginWeb3Action.act(
              { client, withRecaptcha },
              { onBeforeSignUp, cancellationPromise: cancellationPromise.current },
            ),
        ),
      )(),
    [loginWeb3Action, withLogIn, withSelectProvider],
  );

  return (
    <Space direction="vertical" style={{ display: 'flex', flex: 1 }} size="large">
      {!activeProvider && (
        <Text data-test={dataTest && `${dataTest}-description`}>
          <FormattedMessage id={I18nPageAuth.COMPONENTS_WALLETS_BLOCK_DESCRIPTION} />
        </Text>
      )}
      <Space direction="vertical" style={{ display: 'flex', flex: 1 }} size="middle">
        {orderedConnectors
          .filter((connector) => !activeProvider || activeProvider === `${web3WalletPrefix}${connector.id}`)
          .map((connector) => (
            <WalletLogin
              key={connector.id}
              data-test={dataTest && `${dataTest}-provider-${connector.id}`}
              connectorId={connector.id}
              withConnect={withSelectProvider(`${web3WalletPrefix}${connector.id}`)}
              inProgress={submitting && activeProvider === `${web3WalletPrefix}${connector.id}`}
              disabled={!submitting && !!activeProvider && activeProvider !== `${web3WalletPrefix}${connector.id}`}
              login={doWeb3Login}
              inProgressMode={activeProvider ? 'view' : 'button'}
            />
          ))}
      </Space>
    </Space>
  );
};

const WalletsBlockHOC = withHideIfOtherActive<WalletsBlockProps>(
  (activeProvider) => !activeProvider || activeProvider.startsWith(web3WalletPrefix),
)(WalletsBlock);

const WalletsBlockMemo: typeof WalletsBlock = React.memo(WalletsBlockHOC);

export default WalletsBlockMemo;
