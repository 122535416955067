/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3276
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { BlockchainTypeAPIModel } from './BlockchainTypeAPIModel';
import {
    BlockchainTypeAPIModelFromJSON,
    BlockchainTypeAPIModelFromJSONTyped,
    BlockchainTypeAPIModelToJSON,
} from './BlockchainTypeAPIModel';
import type { TransactionStatusAPIModel } from './TransactionStatusAPIModel';
import {
    TransactionStatusAPIModelFromJSON,
    TransactionStatusAPIModelFromJSONTyped,
    TransactionStatusAPIModelToJSON,
} from './TransactionStatusAPIModel';

/**
 * 
 * @export
 * @interface WithdrawalFeeActiveTransactionAPIModel
 */
export interface WithdrawalFeeActiveTransactionAPIModel {
    /**
     * 
     * @type {BlockchainTypeAPIModel}
     * @memberof WithdrawalFeeActiveTransactionAPIModel
     */
    blockchain: BlockchainTypeAPIModel;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalFeeActiveTransactionAPIModel
     */
    from?: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalFeeActiveTransactionAPIModel
     */
    to: string;
    /**
     * 
     * @type {TransactionStatusAPIModel}
     * @memberof WithdrawalFeeActiveTransactionAPIModel
     */
    status: TransactionStatusAPIModel;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalFeeActiveTransactionAPIModel
     */
    amount: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalFeeActiveTransactionAPIModel
     */
    originalAmount: string;
    /**
     * 
     * @type {Date}
     * @memberof WithdrawalFeeActiveTransactionAPIModel
     */
    sentAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalFeeActiveTransactionAPIModel
     */
    hash?: string;
}

/**
 * Check if a given object implements the WithdrawalFeeActiveTransactionAPIModel interface.
 */
export function instanceOfWithdrawalFeeActiveTransactionAPIModel(value: object): boolean {
    if (!('blockchain' in value)) return false;
    if (!('to' in value)) return false;
    if (!('status' in value)) return false;
    if (!('amount' in value)) return false;
    if (!('originalAmount' in value)) return false;
    return true;
}

export function WithdrawalFeeActiveTransactionAPIModelFromJSON(json: any): WithdrawalFeeActiveTransactionAPIModel {
    return WithdrawalFeeActiveTransactionAPIModelFromJSONTyped(json, false);
}

export function WithdrawalFeeActiveTransactionAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): WithdrawalFeeActiveTransactionAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'blockchain': BlockchainTypeAPIModelFromJSON(json['blockchain']),
        'from': json['from'] == null ? undefined : json['from'],
        'to': json['to'],
        'status': TransactionStatusAPIModelFromJSON(json['status']),
        'amount': json['amount'],
        'originalAmount': json['originalAmount'],
        'sentAt': json['sentAt'] == null ? undefined : (new Date(json['sentAt'])),
        'hash': json['hash'] == null ? undefined : json['hash'],
    };
}

export function WithdrawalFeeActiveTransactionAPIModelToJSON(value?: WithdrawalFeeActiveTransactionAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'blockchain': BlockchainTypeAPIModelToJSON(value['blockchain']),
        'from': value['from'],
        'to': value['to'],
        'status': TransactionStatusAPIModelToJSON(value['status']),
        'amount': value['amount'],
        'originalAmount': value['originalAmount'],
        'sentAt': value['sentAt'] == null ? undefined : ((value['sentAt']).toISOString()),
        'hash': value['hash'],
    };
}

