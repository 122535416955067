/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3276
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NewStartWithdrawalExternalAPIModel
 */
export interface NewStartWithdrawalExternalAPIModel {
    /**
     * 
     * @type {string}
     * @memberof NewStartWithdrawalExternalAPIModel
     */
    hash: string;
}

/**
 * Check if a given object implements the NewStartWithdrawalExternalAPIModel interface.
 */
export function instanceOfNewStartWithdrawalExternalAPIModel(value: object): boolean {
    if (!('hash' in value)) return false;
    return true;
}

export function NewStartWithdrawalExternalAPIModelFromJSON(json: any): NewStartWithdrawalExternalAPIModel {
    return NewStartWithdrawalExternalAPIModelFromJSONTyped(json, false);
}

export function NewStartWithdrawalExternalAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewStartWithdrawalExternalAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'hash': json['hash'],
    };
}

export function NewStartWithdrawalExternalAPIModelToJSON(value?: NewStartWithdrawalExternalAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'hash': value['hash'],
    };
}

