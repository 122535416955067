/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3276
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { CompanyNotificationSettingsAPIModel } from './CompanyNotificationSettingsAPIModel';
import {
    CompanyNotificationSettingsAPIModelFromJSON,
    CompanyNotificationSettingsAPIModelFromJSONTyped,
    CompanyNotificationSettingsAPIModelToJSON,
} from './CompanyNotificationSettingsAPIModel';

/**
 * 
 * @export
 * @interface UpdateNotificationAPIModel
 */
export interface UpdateNotificationAPIModel {
    /**
     * 
     * @type {Array<CompanyNotificationSettingsAPIModel>}
     * @memberof UpdateNotificationAPIModel
     */
    settings?: Array<CompanyNotificationSettingsAPIModel>;
}

/**
 * Check if a given object implements the UpdateNotificationAPIModel interface.
 */
export function instanceOfUpdateNotificationAPIModel(value: object): boolean {
    return true;
}

export function UpdateNotificationAPIModelFromJSON(json: any): UpdateNotificationAPIModel {
    return UpdateNotificationAPIModelFromJSONTyped(json, false);
}

export function UpdateNotificationAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateNotificationAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'settings': json['settings'] == null ? undefined : ((json['settings'] as Array<any>).map(CompanyNotificationSettingsAPIModelFromJSON)),
    };
}

export function UpdateNotificationAPIModelToJSON(value?: UpdateNotificationAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'settings': value['settings'] == null ? undefined : ((value['settings'] as Array<any>).map(CompanyNotificationSettingsAPIModelToJSON)),
    };
}

