import { Alert } from 'antd';
import React, { useCallback, useMemo } from 'react';

import { FormattedMessage, FormattedMessageWithMarkup, Operation, PageLayout } from '@/components';
import { useKYBBannerViewed } from '@/features/company/hooks';
import { I18nFeatureCompanySettings } from '@/generated/i18n/i18n';
import { useStateMountSafe } from '@/hooks';

import { KYBRequiredBannerDescriptionFull, KYBRequiredBannerDescriptionMedium } from './components';
import { withKYBRequired } from './hocs';

import type { KYBRequiredBannerProps } from './types';

const KYBRequiredBanner: React.FC<KYBRequiredBannerProps> = ({
  'data-test': dataTest,
  style,
  className,
  PageProps,
  hideable = true,
  mode = 'full',
}) => {
  const { viewed, markViewed } = useKYBBannerViewed();
  const [isDetailsShown, setDetailsShown] = useStateMountSafe(!hideable || !viewed);
  const showDetails = useCallback(() => setDetailsShown(true), [setDetailsShown]);
  const hideOrMarkViewed = useMemo(
    () => (hideable && !viewed ? markViewed : () => setDetailsShown(false)),
    [hideable, markViewed, setDetailsShown, viewed],
  );
  const alert = useMemo(
    () => (
      <Alert
        data-test={dataTest}
        type="info"
        showIcon
        style={style}
        action={
          // eslint-disable-next-line no-nested-ternary
          hideable ? (
            !isDetailsShown ? (
              <Operation
                onClick={showDetails}
                data-test={dataTest && `${dataTest}-opDetails`}
                title={<FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_KYB_REQUIRED_BANNER_DETAILS} />}
                icon={null}
                mode="link"
              />
            ) : (
              <Operation
                onClick={hideOrMarkViewed}
                data-test={dataTest && `${dataTest}-opView`}
                title={<FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_KYB_REQUIRED_BANNER_OK} />}
                icon={null}
                mode="link"
              />
            )
          ) : null
        }
        className={className}
        message={<FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_KYB_REQUIRED_BANNER_MESSAGE} />}
        description={
          // eslint-disable-next-line no-nested-ternary
          hideable || isDetailsShown ? (
            <>
              {mode === 'full' && <KYBRequiredBannerDescriptionFull />}
              {mode === 'medium' && <KYBRequiredBannerDescriptionMedium />}
              {mode === 'short' && (
                <FormattedMessageWithMarkup
                  id={I18nFeatureCompanySettings.COMPONENTS_KYB_REQUIRED_BANNER_SHORT_DESCRIPTION}
                />
              )}
            </>
          ) : null
        }
      />
    ),
    [dataTest, style, isDetailsShown, showDetails, hideOrMarkViewed, className, hideable, mode],
  );
  if (hideable && viewed) {
    return null;
  }
  return PageProps ? <PageLayout {...PageProps}>{alert}</PageLayout> : alert;
};

const KYBRequiredBannerVisibility = withKYBRequired(KYBRequiredBanner);

const KYBRequiredBannerMemo = React.memo(KYBRequiredBannerVisibility) as typeof KYBRequiredBannerVisibility;

export default KYBRequiredBannerMemo;
