import React from 'react';

import { useBrandingProfile } from '@/features/branding/hooks';

import { OperationDeactivateBrandingProfile, OperationActivateBrandingProfile } from './components';

import type { OperationUpdateBrandingProfileStatusProps } from './types';

const OperationUpdateBrandingProfileStatus: React.FC<OperationUpdateBrandingProfileStatusProps> = (props) => {
  const { profileId } = props;
  const { data: profile } = useBrandingProfile(profileId);

  return profile.data?.active ? (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <OperationDeactivateBrandingProfile {...props} />
  ) : (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <OperationActivateBrandingProfile {...props} />
  );
};

const OperationUpdateBrandingProfileStatusMemo = React.memo(OperationUpdateBrandingProfileStatus);

export default OperationUpdateBrandingProfileStatusMemo;
