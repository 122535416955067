import CloudDownloadOutlined from '@ant-design/icons/CloudDownloadOutlined';
import { Space } from 'antd';
import { useRef } from 'react';

import { FormattedMessage, PageCard, PageContainer } from '@/components';
import { SelectedNetworkLabel } from '@/features/dictionary/blockchain/components';
import {
  MerchantWalletTransferDocumentationBanner,
  MerchantWalletTransferFilterForm,
  MerchantWalletTransfersTable,
  OperationExportMerchantWalletTransfers,
} from '@/features/merchant-wallet-transfers/components';
import { useMerchantWalletTransferListView } from '@/features/merchant-wallet-transfers/hooks';
import { I18nPageAudit } from '@/generated/i18n/i18n';
import MerchantWalletTransferEntityLink from '@/pages/audit/transfer-entity/components/MerchantWalletTransferEntityLink';
import MerchantWalletTransferLink from '@/pages/audit/transfer-view/components/MerchantWalletTransferLink';
import BaseSettlementViewLink from '@/pages/settlements/settlement-view/components/SettlementViewLink';
import type { SettlementViewLinkProps } from '@/pages/settlements/settlement-view/components/SettlementViewLink/types';

import { MerchantWalletTransfersBreadcrumb } from './components';

import type React from 'react';

const SettlementViewLink = (props: SettlementViewLinkProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <BaseSettlementViewLink {...props} icon={<CloudDownloadOutlined />} />
);

const dataTest: string | undefined = 'mwt';

const MerchantWalletTransfersPage: React.FC = () => {
  const view = useMerchantWalletTransferListView();
  const ref = useRef<HTMLDivElement>(null);
  return (
    <PageContainer
      data-test={dataTest && `${dataTest}-container`}
      breadcrumb={<MerchantWalletTransfersBreadcrumb data-test={dataTest && `${dataTest}-breadcrumb`} disabled />}
    >
      <MerchantWalletTransferDocumentationBanner data-test={dataTest && `${dataTest}-docs`} pageSize="big" />
      <PageCard
        ref={ref}
        title={
          <Space>
            <FormattedMessage id={I18nPageAudit.TRANSFERS_COMPONENTS_CARD_TITLE} />
            <SelectedNetworkLabel mode="short" data-test={dataTest && `${dataTest}-selectNetwork`} />
          </Space>
        }
        cardSize="big"
      >
        <MerchantWalletTransfersTable
          data-test={dataTest && `${dataTest}-table`}
          {...view}
          SettlementLink={SettlementViewLink}
          ExportOperation={OperationExportMerchantWalletTransfers}
          EntityLink={MerchantWalletTransferEntityLink}
          FilterForm={MerchantWalletTransferFilterForm}
          MerchantWalletTransferLink={MerchantWalletTransferLink}
        />
      </PageCard>
    </PageContainer>
  );
};

export default MerchantWalletTransfersPage;
