import type { UseAppFullDataRow } from '@/app/hooks';
import { useAppFullDataRow } from '@/app/hooks';
import { fetchBlockchains } from '@/features/dictionary/blockchain/actions';
import { makeSelectBlockchain } from '@/features/dictionary/blockchain/selectors';
import type { BlockchainInfo } from '@/features/dictionary/blockchain/types';
import { extractBlockchainType } from '@/features/dictionary/blockchain/utils';
import { makeSelectPending } from '@/features/global/selectors';
import type { BlockchainTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';

export interface UseBlockchain extends UseAppFullDataRow<BlockchainInfo> {}

const fetchFactory = (force: boolean) => fetchBlockchains({ force });
const pendingSelector = makeSelectPending(fetchBlockchains);

export default function useBlockchain(type: BlockchainTypeAPIModel | undefined): UseBlockchain {
  return useAppFullDataRow(
    fetchFactory,
    makeSelectBlockchain,
    extractBlockchainType,
    type,
    (v1, v2) => v1 === v2,
    pendingSelector,
  );
}
