import { Space } from 'antd';
import { useCallback, useMemo } from 'react';

import type { UseAppSingleData } from '@/app/hooks';
import { OperationRefresh } from '@/components';
import { NoExchangeRatesAlert, OperationUpdateStatisticsAsset } from '@/features/company-settings/components';
import { SelectedNetworkLabel } from '@/features/dictionary/blockchain/components';
import { useMultipleSubscriptionPlansMerged } from '@/features/subscription-plans/hooks';
import type { MonthlyRevenueDataType } from '@/features/subscription-statistics/components';
import { useSubscriptionRevenue } from '@/features/subscription-statistics/hooks';
import { withCardDataLoading } from '@/hocs';
import { combine } from '@/infrastructure/model';
import type { TestableProps } from '@/infrastructure/utils/react';
import { namedHOC } from '@/infrastructure/utils/react';

import type React from 'react';
import type { ReactNode } from 'react';

const withMonthlyRevenueDataLoading = <
  Original extends { data: MonthlyRevenueDataType } & TestableProps,
  Wrapper extends Omit<Original, 'data'> = Omit<Original, 'data'>,
>(
  Component: React.ComponentType<Original>,
  title: ReactNode,
  help?: ReactNode,
) =>
  namedHOC<Original, Wrapper>(
    Component,
    'WithMonthlyGrowthRateDataLoading',
  )((props) => {
    const dataTest = props['data-test'];
    const statisticsState = useSubscriptionRevenue();

    const planIds = useMemo(
      () => statisticsState.data.data?.perPlan.map(({ planId }) => planId) ?? [],
      [statisticsState.data.data?.perPlan],
    );
    const plansState = useMultipleSubscriptionPlansMerged(planIds);
    const forceRefresh = useCallback(async () => {
      await Promise.all([statisticsState.forceRefresh(), plansState.forceRefresh()]);
    }, [plansState, statisticsState]);

    const data = useMemo<UseAppSingleData<MonthlyRevenueDataType, unknown>>(
      () => ({
        data: combine(statisticsState.data, plansState.data, (statistics, plans) => ({
          statistics,
          plans: Object.fromEntries(plans.map((plan) => [plan.id, plan])),
        })),
        loading: statisticsState.loading || plansState.loading,
        forceRefresh,
      }),
      [forceRefresh, plansState.data, plansState.loading, statisticsState.data, statisticsState.loading],
    );

    return withCardDataLoading<MonthlyRevenueDataType, Original>({
      ...data,
      onError: (error, defaultComponent) =>
        error === 'NoExchangeRateFound' ? (
          <NoExchangeRatesAlert
            /* eslint-disable-next-line react/destructuring-assignment */
            data-test={props['data-test'] && `${props['data-test']}-alert`}
            onUpdate={forceRefresh}
          />
        ) : (
          defaultComponent
        ),
      'data-test': dataTest,
      hideBack: true,
      title: (
        <Space>
          {title}
          <SelectedNetworkLabel mode="short" />
        </Space>
      ),
      cardSize: 'big',
      CardProps: {
        extra: (
          <Space>
            <OperationUpdateStatisticsAsset data-test={props['data-test'] && `${props['data-test']}-opUpdateAsset`} />
            <OperationRefresh
              refresh={forceRefresh}
              data-test={props['data-test'] && `${props['data-test']}-opRefresh`}
            />
            {help}
          </Space>
        ),
      },
    })(Component)(props);
  });

export default withMonthlyRevenueDataLoading;
