import { useMemo } from 'react';

import type { UseAppSingleData } from '@/app/hooks';
import { useAppSingleData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { fetchNativeBalance } from '@/features/dictionary/blockchain/actions';
import { makeSelectNativeBalance } from '@/features/dictionary/blockchain/selectors';
import type {
  BalanceData,
  BlockchainAddress,
  FetchBlockchainAddressDataPayload,
} from '@/features/dictionary/blockchain/types';
import { createBlockchainAddressKey } from '@/features/dictionary/blockchain/utils';
import { makeSelectPending } from '@/features/global/selectors';
import { type LoadingStateWithDirty, storedDataLoaded } from '@/infrastructure/model';

export interface UseSingleNativeBalanceType extends UseAppSingleData<BalanceData, unknown> {}

const noData = storedDataLoaded<BalanceData>();
const fetchFactory = (
  id: BlockchainAddress,
  force: boolean,
): AppAsyncThunkAction<LoadingStateWithDirty<BalanceData>, FetchBlockchainAddressDataPayload> =>
  fetchNativeBalance({ ...id, force });

export default function useNativeBalance(id: BlockchainAddress | undefined): UseSingleNativeBalanceType {
  return useAppSingleData(
    fetchFactory,
    makeSelectNativeBalance,
    noData,
    id,
    useMemo(() => makeSelectPending(fetchNativeBalance, id && createBlockchainAddressKey(id)), [id]),
  );
}
