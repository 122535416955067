import { Form, InputNumber } from 'antd';
import React, { useMemo } from 'react';

import { identity } from '@/infrastructure/utils/functions';

import type { InputNumberItemProps, InputNumberWrapperProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const InputNumberWrapper: React.FC<InputNumberWrapperProps> = ({
  toInput = identity,
  fromInput = identity,
  value,
  onChange,
  ...props
}) => (
  <InputNumber<number>
    onChange={(v) => onChange?.(fromInput(v ?? undefined) ?? null)}
    value={value && toInput(value)}
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...props}
  />
);

const InputNumberItemRaw = <Values extends Store = Store>({
  'data-test': dataTest,
  name,
  placeholder,
  readonly,
  messages,
  rules: extraRules = [],
  InputProps = {},
  ItemProps = {},
  transform,
}: InputNumberItemProps<Values>) => {
  const rules = useMemo(
    () => [
      ...extraRules,
      ...(!readonly && messages?.required
        ? [
            {
              transform: (value: string) => value.trim(),
              required: true,
              message: messages.required,
            },
          ]
        : []),
    ],
    [extraRules, messages?.required, readonly],
  );
  return (
    <Form.Item
      label={messages?.label}
      rules={rules}
      required={!readonly && !!messages?.required}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...ItemProps}
      name={name}
    >
      <InputNumberWrapper
        placeholder={placeholder}
        data-test={dataTest}
        readOnly={readonly}
        fromInput={transform?.fromInput}
        toInput={transform?.toInput}
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...InputProps}
      />
    </Form.Item>
  );
};

const InputNumberItem = React.memo(InputNumberItemRaw) as typeof InputNumberItemRaw;

export default InputNumberItem;
