import isNil from 'lodash/isNil';
import toString from 'lodash/toString';

import type { Func } from '@/infrastructure/utils/ts';

export enum InitStatus {
  NOT_INITIALIZED = 'NOT_INITIALIZED',
  IN_PROGRESS = 'IN_PROGRESS',
  FINISHED = 'FINISHED',
}

export interface InitState<T> {
  value: T;
  status: InitStatus;
}

export interface CommonLoadingState<T, E = string> {
  data?: T;
  error?: E;
}

export interface CommonLoadingStateWithData<T, E = string> {
  data: T;
  error?: E;
}

export interface LoadingStateWithDirty<T, E = string> extends CommonLoadingState<T, E> {
  isDirty: boolean;
}

export const loadingDataLoaded = <T, E = string>(data?: T): CommonLoadingState<T, E> => ({ data });
export const loadingDataError = <T, E = string>(error: E): CommonLoadingState<T, E> => ({ error });

export const storedDirtyData = { isDirty: true };
export const storedDirtyDataTyped = <T, E = string>(): LoadingStateWithDirty<T, E> => storedDirtyData;
export const storedDataLoaded = <T, E = string>(data?: T, isDirty?: boolean): LoadingStateWithDirty<T, E> => ({
  data,
  isDirty: !!isDirty || false,
});
export const storedDataError = <T, E = string>(error: E, isDirty?: boolean): LoadingStateWithDirty<T, E> => ({
  error,
  isDirty: !!isDirty || false,
});

export const mapLoadingState = <T, R, E = string>(
  state: CommonLoadingState<T, E>,
  mapper: (data: T) => R,
): CommonLoadingState<R, E> => {
  const { data, ...old } = state;
  return {
    ...old,
    data: data !== undefined ? mapper(data) : undefined,
  };
};

export const isLoadingStateDataLoaded = <T, E = string>(
  state: CommonLoadingState<T | undefined | null, E>,
): state is CommonLoadingStateWithData<T, E> => {
  const { data } = state;
  return !isNil(data);
};

export const flatMapLoadingState = <T, R, E = string>(
  state: CommonLoadingState<T, E>,
  mapper: (data: T) => CommonLoadingState<R, E>,
): CommonLoadingState<R, E> => {
  const { data, ...old } = state;
  return { ...old, ...(data !== undefined ? mapper(data) : {}) };
};

export const combine = <T1, T2, R, E = string>(
  m1: LoadingStateWithDirty<T1, E> | undefined,
  m2: LoadingStateWithDirty<T2, E> | undefined,
  joiner: (d1: T1, d2: T2) => R,
  {
    isM1Defined = (d1?): d1 is T1 => d1 !== undefined,
    isM2Defined = (d2?): d2 is T2 => d2 !== undefined,
  }: {
    isM1Defined?: (d1?: T1) => d1 is T1;
    isM2Defined?: (d2?: T2) => d2 is T2;
  } = {
    isM1Defined: (d1?): d1 is T1 => d1 !== undefined,
    isM2Defined: (d2?): d2 is T2 => d2 !== undefined,
  },
): LoadingStateWithDirty<R, E> => {
  const d1 = m1?.data;
  const d2 = m2?.data;
  return {
    data: isM1Defined(d1) && isM2Defined(d2) ? joiner(d1, d2) : undefined,
    isDirty: !!m1?.isDirty || !!m2?.isDirty,
    error: m1?.error ?? m2?.error,
  };
};

export const flatCombine = <T1, T2, R, E = string>(
  m1: LoadingStateWithDirty<T1, E> | undefined,
  m2: LoadingStateWithDirty<T2, E> | undefined,
  joiner: (d1: T1, d2: T2) => LoadingStateWithDirty<R>,
  {
    isM1Defined = (d1?): d1 is T1 => d1 !== undefined,
    isM2Defined = (d2?): d2 is T2 => d2 !== undefined,
  }: {
    isM1Defined?: (d1?: T1) => d1 is T1;
    isM2Defined?: (d2?: T2) => d2 is T2;
  } = {
    isM1Defined: (d1?): d1 is T1 => d1 !== undefined,
    isM2Defined: (d2?): d2 is T2 => d2 !== undefined,
  },
): LoadingStateWithDirty<R, E> => {
  const d1 = m1?.data;
  const d2 = m2?.data;
  return {
    ...(isM1Defined(d1) && isM2Defined(d2) ? joiner(d1, d2) : {}),
    isDirty: !!m1?.isDirty || !!m2?.isDirty,
    error: m1?.error ?? m2?.error,
  };
};

export const mapStoredState = <T, R, E = string>(
  state: LoadingStateWithDirty<T, E>,
  mapper: (data: T) => R | undefined,
): LoadingStateWithDirty<R, E> => {
  const { data, ...old } = state;
  return {
    ...old,
    data: data ? mapper(data) : undefined,
  };
};

export const flatmapStoredState = <Value, Mapped, E = string>(
  state: LoadingStateWithDirty<Value, E>,
  mapper: (data: Value) => LoadingStateWithDirty<Mapped, E>,
): LoadingStateWithDirty<Mapped, E> => {
  const { data, error, isDirty } = state;
  if (data) {
    const mapped = mapper(data);
    return combine(state, mapped, (_, mappedData) => mappedData);
  }
  return error ? storedDataError(error, isDirty) : storedDirtyDataTyped();
};

export interface HookAction<Params extends unknown[] = [], Result = unknown, Reason = string> {
  act: Func<Params, Result>;
  available: boolean;
  unavailabilityReason?: Reason;
  inAction: boolean;
}

export const withExtractData =
  <V extends unknown[] = [], R = void>(func: Func<V, CommonLoadingState<R>>) =>
  async (...args: V): Promise<R> => {
    const result = await func(...args);
    if (result.error) {
      throw new Error(result.error);
    }
    return result.data as R;
  };

export const permissionDeniedAction = {
  act: (): unknown => {
    throw new Error('Permission denied');
  },
  available: false,
  inAction: false,
};

export class Lock {
  private locked = false;

  lock() {
    this.locked = true;
  }

  unlock() {
    this.locked = false;
  }

  isLocked() {
    return this.locked;
  }
}

export const apiRequest = async <T>(
  func: () => Promise<T>,
  defaultError?: string,
): Promise<LoadingStateWithDirty<T>> => {
  let result: LoadingStateWithDirty<T>;
  try {
    const data = await func();
    result = storedDataLoaded(data);
  } catch (error: unknown) {
    console.warn(error);
    try {
      result = storedDataError<T, string>(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-explicit-any
        (error as any)?.data?.data?.code?.toString?.() || defaultError || toString(error),
      );
    } catch (e) {
      console.error(e);
      result = storedDataError<T, string>('unable to parse response');
    }
  }
  return result;
};

export const withApiRequest =
  <T extends unknown[] = [], R = unknown>(func: (...args: T) => Promise<R>, defaultError?: string) =>
  async (...args: T): Promise<LoadingStateWithDirty<R>> =>
    apiRequest(() => func(...args), defaultError);
