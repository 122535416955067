import toString from 'lodash/toString';

import storeHolder from '@/app/store.holder';
import { makeSelectAuthToken } from '@/features/auth/selectors';
import { logoutActionTrigger } from '@/features/auth/shared-actions';
import { makeSelectLocale } from '@/features/i18n/selectors';
import type {
  Configuration as ApiConfiguration,
  ConfigurationParameters as ApiConfigurationParameters,
} from '@/generated/api/ncps-api/runtime';
import type {
  ConfigurationParameters as CoreConfigurationParameters,
  FetchParams,
  Middleware,
  RequestContext,
  ResponseContext,
} from '@/generated/api/ncps-core/merchant-bo/runtime';
import { Configuration as CoreConfiguration } from '@/generated/api/ncps-core/merchant-bo/runtime';
import { jwt } from '@/infrastructure/security';
import { appPath, prepareRedirectURLParam, REDIRECT_QUERY_PARAM } from '@/infrastructure/utils/http';

const baseApiPath = window.env.API_PATH.replace(/\/$/, '') || `${appPath}/api`;

export const coreBasePath = `${baseApiPath}/core`;
export const apiBasePath = baseApiPath;

export const isTokenExpired = () => {
  try {
    const expiration = storeHolder.store
      ? makeSelectAuthToken()(storeHolder.store.getState()).data?.info.expiresAt
      : undefined;
    return expiration && expiration.getTime() < new Date().getTime();
  } catch (e) {
    return false;
  }
};

export const localeMiddleware: Middleware = {
  // eslint-disable-next-line @typescript-eslint/require-await
  async pre(context: RequestContext): Promise<FetchParams | void> {
    const locale = storeHolder.store ? makeSelectLocale()(storeHolder.store.getState()) : undefined;
    if (!locale) {
      return context;
    }
    const headers = { ...(context.init.headers ?? {}), [window.env.LOCALE_HEADER]: locale };
    return { url: context.url, init: { ...context.init, headers } };
  },
};

export interface ServerErrorResponse {
  status: number;
  code?: string;
  message?: string;
  serviceName: string;
  timestamp: string;
}

export interface ResponseErrorData {
  headers: Headers;
  ok: boolean;
  status: number;
  statusText: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
}

export class ResponseError extends Error {
  constructor(data: ResponseErrorData) {
    super('response error');
    // eslint-disable-next-line no-underscore-dangle
    this._data = data;
  }

  private readonly _data: ResponseErrorData;

  get data(): ResponseErrorData {
    // eslint-disable-next-line no-underscore-dangle
    return this._data;
  }
}

export const parseErrorResponse = async (error: Response): Promise<ResponseError> => {
  let data;
  try {
    data = await error.json();
  } catch (e) {
    console.warn(`unable to parse error response ${toString(e)}`);
  }
  return new ResponseError({
    data,
    headers: error.headers,
    ok: error.ok,
    status: error.status,
    statusText: error.statusText,
  });
};

export const parseErrorMiddleware: Middleware = {
  post: async (context: ResponseContext): Promise<Response | void> => {
    if (!context.response.ok) {
      let error;
      try {
        error = await parseErrorResponse(context.response);
      } catch (e) {
        console.warn(e);
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw context.response;
      }
      throw error;
    }
    return context.response;
  },
};

export const sessionTokenMiddleware: Middleware = {
  pre: async (context: RequestContext) => {
    const token = storeHolder.store ? makeSelectAuthToken()(storeHolder.store.getState()).data?.token : undefined;

    return token
      ? Promise.resolve({
          ...context,
          init: {
            ...context.init,
            headers: { ...(context.init.headers ?? {}), [window.env.AUTH_HEADER_TOKEN]: `Bearer ${token}` },
          },
        })
      : Promise.resolve(context);
  },
};

// T - workaround for multiple same (!!!) instances of runtime from openapi-codegen
export const publicConfigurationFactory = <T extends CoreConfiguration | ApiConfiguration>(
  basePath: string,
  params?: CoreConfigurationParameters | ApiConfigurationParameters,
): T =>
  new CoreConfiguration({
    ...params,
    middleware: [localeMiddleware, parseErrorMiddleware],
    basePath,
  }) as T;

export const authNoReloginConfigurationFactory = <T extends CoreConfiguration | ApiConfiguration>(
  basePath: string,
  params?: CoreConfigurationParameters | ApiConfigurationParameters,
): T =>
  new CoreConfiguration({
    credentials: 'same-origin',
    middleware: [localeMiddleware, sessionTokenMiddleware, parseErrorMiddleware],
    ...params,
    basePath,
  }) as T;

// const authServiceWithAuth = new MerchantAuthApi(authNoReloginConfigurationFactory<Configuration>(apiBasePath));
export const relogin = async () => {
  try {
    // FIXME: the right way (!!!) to process this error is to wrap every call to the service and process this on the effect layer
    await Promise.resolve(storeHolder.store?.dispatch(logoutActionTrigger({})));
  } catch (e) {
    console.warn('Unable to relogin the "right" way', e);
    await jwt.cleanUp();
    window.location.replace(`${appPath}/auth?${REDIRECT_QUERY_PARAM}=${prepareRedirectURLParam()}`);
  }
};

export const authMiddleware: Middleware = {
  pre: async (context: RequestContext): Promise<FetchParams | void> => {
    if (isTokenExpired()) {
      console.warn('TokenExpired');
      await relogin();
      throw new Error('Token is expired');
    }
    return context;
  },
  post: async (context: ResponseContext): Promise<Response | void> => {
    if (context.response.status === 403) {
      console.warn('Unauthorized');
      await relogin();
      let error;
      try {
        error = await parseErrorResponse(context.response);
      } catch (e) {
        console.warn(e);
        throw new Error('Unauthorized');
      }
      throw error;
    }
    return context.response;
  },
};

export const authConfigurationFactory = <T extends CoreConfiguration | ApiConfiguration>(
  basePath: string,
  params?: CoreConfigurationParameters | ApiConfigurationParameters,
): T =>
  new CoreConfiguration({
    credentials: 'same-origin',
    middleware: [localeMiddleware, sessionTokenMiddleware, authMiddleware, parseErrorMiddleware],
    ...params,
    basePath,
  }) as T;

type ConfigurationType = 'auth' | 'public' | 'no-relogin';

const typedConfigurationFactory = <
  T extends CoreConfiguration | ApiConfiguration,
  P extends CoreConfigurationParameters | ApiConfigurationParameters,
>(
  basePath: string,
  type: ConfigurationType,
  params?: P,
): T => {
  switch (type) {
    case 'public':
      return publicConfigurationFactory(basePath, params);
    case 'auth':
      return authConfigurationFactory(basePath, params);
    case 'no-relogin':
      return authNoReloginConfigurationFactory(basePath, params);
    default:
      throw new Error(`unknown configuration type ${toString(type)}`);
  }
};

export const apiConfigurationFactory = (
  type: ConfigurationType,
  params?: ApiConfigurationParameters,
): ApiConfiguration => typedConfigurationFactory(apiBasePath, type, params);

export const coreConfigurationFactory = (
  type: ConfigurationType,
  params?: CoreConfigurationParameters,
): CoreConfiguration => typedConfigurationFactory(coreBasePath, type, params);
