import { PageContainer } from '@/components';
import {
  SubscriptionGasWalletAlertBanner,
  SubscriptionsDocumentationBanner,
} from '@/features/subscriptions/components';
import type { TestableProps } from '@/infrastructure/utils/react';
import SubscriptionChargeLink from '@/pages/subscriptions/charge-view/components/SubscriptionChargeLink';

import {
  SubscriptionBreadcrumb,
  SubscriptionCard,
  SubscriptionChargesCard,
  SubscriptionPlanShortCard,
} from './components';
import { withSubscriptionViewPageParams } from './hocs';

import type React from 'react';
import type { PropsWithChildren } from 'react';

interface SubscriptionPageProps extends PropsWithChildren<TestableProps & { pageParams: { subscriptionId: string } }> {}

const SubscriptionPage: React.FC<SubscriptionPageProps> = ({
  'data-test': dataTest = 'subscription',
  pageParams: { subscriptionId },
}) => (
  <PageContainer
    data-test={dataTest && `${dataTest}-container`}
    breadcrumb={
      <SubscriptionBreadcrumb
        data-test={dataTest && `${dataTest}-breadcrumb`}
        subscriptionId={subscriptionId}
        disabled
      />
    }
  >
    <SubscriptionsDocumentationBanner data-test={dataTest && `${dataTest}-card`} pageSize="big" />
    <SubscriptionGasWalletAlertBanner
      subscriptionId={subscriptionId}
      data-test={dataTest && `${dataTest}-alert`}
      pageSize="big"
    />
    <SubscriptionCard subscriptionId={subscriptionId} data-test={dataTest && `${dataTest}-card`} />
    <SubscriptionPlanShortCard subscriptionId={subscriptionId} data-test={dataTest && `${dataTest}-plan`} />
    <SubscriptionChargesCard
      subscriptionId={subscriptionId}
      data-test={dataTest && `${dataTest}-charges`}
      SubscriptionChargeLink={SubscriptionChargeLink}
    />
  </PageContainer>
);

export default withSubscriptionViewPageParams(SubscriptionPage);
