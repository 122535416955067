import { Result } from 'antd';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import FormattedMessage from '@/components/FormattedMessage';
import Operation from '@/components/Operation';
import type { I18nMessages } from '@/generated/i18n/i18n';
import { I18nComponents } from '@/generated/i18n/i18n';

import type { ErrorPageProps } from './types';

const messageIdByType: Record<ErrorPageProps['type'], I18nMessages> = {
  '403': I18nComponents.ERROR_PAGE_403,
  '404': I18nComponents.ERROR_PAGE_404,
  '500': I18nComponents.ERROR_PAGE_500,
};

export const ErrorPage: React.FC<ErrorPageProps> = React.memo(({ 'data-test': dataTest, type, message }) => {
  const navigate = useNavigate();
  return (
    <Result
      data-test={dataTest && `${dataTest}-page`}
      status={type}
      title={type}
      subTitle={message ?? <FormattedMessage id={messageIdByType[type]} />}
      extra={
        <Operation
          data-test={dataTest && `${dataTest}-button`}
          primary
          mode="button"
          icon={null}
          onClick={useCallback(() => navigate(-1), [navigate])}
          title={<FormattedMessage id={I18nComponents.ERROR_PAGE_BACK} />}
        />
      }
    />
  );
});

const ErrorPageMemo = React.memo(ErrorPage);

export default ErrorPageMemo;
