import { Typography } from 'antd';
import React from 'react';
import { Link as RouterLink, useInRouterContext } from 'react-router-dom';

import type { ContextableLinkProps } from './types';

const { Link: TypographyLink } = Typography;

const ContextableLink = React.forwardRef<HTMLAnchorElement, ContextableLinkProps>(
  ({ 'data-test': testLocator, to, children }, ref) => {
    const isInContext = useInRouterContext();
    return isInContext ? (
      <RouterLink data-test={testLocator} to={to} ref={ref}>
        {children}
      </RouterLink>
    ) : (
      <TypographyLink
        data-test={testLocator}
        onClick={() => {
          window.history.pushState({}, '', to);
        }}
        ref={ref}
      >
        {children}
      </TypographyLink>
    );
  },
);

const ContextableLinkMemo = React.memo(ContextableLink);

export default ContextableLinkMemo;
