import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nFeatureMerchantWalletTransfers } from '@/generated/i18n/i18n';

const MerchantWalletTransfersReportTitle: React.FC = React.memo(() => (
  <FormattedMessage id={I18nFeatureMerchantWalletTransfers.COMPONENTS_REPORT_TITLE} />
));

export default MerchantWalletTransfersReportTitle;
