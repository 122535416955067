export const UNKNOWN_ERROR_CODE = 'UNKNOWN_ERROR';

export class ErrorWithCode extends Error {
  readonly code: string;

  readonly cause?: unknown;

  constructor(code: string, cause?: unknown) {
    super(`${code}`);
    this.code = code;
    this.cause = cause;
  }
}

export class UnknownError extends ErrorWithCode {
  constructor(cause?: unknown) {
    super(UNKNOWN_ERROR_CODE, cause);
  }
}

const userRejectedErrorMessage = 'UserRejected';

export class UserRejectedError extends Error {
  constructor() {
    super(userRejectedErrorMessage);
  }
}

export const isUserRejectedError = (e?: unknown) =>
  !!e
  && typeof e === 'object'
  && 'message' in e
  && typeof e.message === 'string'
  && e.message === userRejectedErrorMessage;
