import { AppPageRoutes, appRoute } from '@/pages/routes';

export enum SubscriptionsPageRoutes {
  ALL = 'all',
  CHARGE_VIEW = 'charge/:chargeId',
  PLANS = 'plans',
  STATISTICS = 'statistics',
  SUBSCRIPTION_VIEW = 'view/:subscriptionId',
}

export enum SubscriptionPlanRoutes {
  VIEW = 'view/:planId',
  ADD = 'new',
  EDIT = 'edit/:planId',
}

export const subscriptionsRoute = (page: SubscriptionsPageRoutes) => `${appRoute(AppPageRoutes.SUBSCRIPTIONS)}/${page}`;

export const subscriptionsLink = () => subscriptionsRoute(SubscriptionsPageRoutes.ALL);

export const subscriptionPlansLink = () => subscriptionsRoute(SubscriptionsPageRoutes.PLANS);

export const subscriptionPlanRoute = (page: SubscriptionPlanRoutes) => `${subscriptionPlansLink()}/${page}`;
export const subscriptionPlanViewLink = (planId: string) => `${subscriptionPlansLink()}/view/${planId}`;
export const subscriptionPlanAddLink = () => `${subscriptionPlansLink()}/new`;
export const subscriptionPlanEditLink = (planId: string) => `${subscriptionPlansLink()}/edit/${planId}`;

export const subscriptionStatisticsLink = () => `${subscriptionsRoute(SubscriptionsPageRoutes.STATISTICS)}`;
export const subscriptionViewLink = (id: string) => `${appRoute(AppPageRoutes.SUBSCRIPTIONS)}/view/${id}`;
export const subscriptionChargeViewLink = (id: string) => `${appRoute(AppPageRoutes.SUBSCRIPTIONS)}/charge/${id}`;
