import { Tag } from 'antd';
import React from 'react';

import { BlockchainNetworkTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';

import type { NetworkTagProps } from './types';

const NetworkTag: React.FC<NetworkTagProps> = React.memo(({ value, 'data-test': dataTest, children }) => (
  <Tag color={value === BlockchainNetworkTypeAPIModel.TestNet ? 'orange' : 'blue'} data-test={dataTest}>
    {children}
  </Tag>
));

export default NetworkTag;
