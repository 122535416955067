import { PageContainer } from '@/components';
import { KYBRequiredBanner } from '@/features/company/components';
import type { TestableProps } from '@/infrastructure/utils/react';

import { SettlementHistoryBreadcrumb, SettlementsCard, SettlementScheduleCard } from './components';
import { withSettlementHistoryPageParams } from './hocs';

import type React from 'react';
import type { PropsWithChildren } from 'react';

interface SettlementHistoryPageProps extends PropsWithChildren<TestableProps & { pageParams: { assetId?: string } }> {}

const SettlementHistoryPage: React.FC<SettlementHistoryPageProps> = ({
  'data-test': dataTest = 'settlements',
  pageParams: { assetId },
}) => (
  <PageContainer
    data-test={dataTest && `${dataTest}-container`}
    breadcrumb={
      <SettlementHistoryBreadcrumb data-test={dataTest && `${dataTest}-breadcrumb`} assetId={assetId} disabled />
    }
  >
    <KYBRequiredBanner data-test={dataTest && `${dataTest}-kyb`} PageProps={{ pageSize: 'small' }} />
    <SettlementScheduleCard data-test={dataTest && `${dataTest}-schedule`} />
    <SettlementsCard data-test={dataTest && `${dataTest}-table`} assetId={assetId} />
  </PageContainer>
);

export default withSettlementHistoryPageParams(SettlementHistoryPage);
