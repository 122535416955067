import { List, Tag } from 'antd';
import React, { useCallback } from 'react';
import { AutoSizer, List as VList, WindowScroller } from 'react-virtualized';

import { FormattedMessage } from '@/components';
import { I18nFeatureCompany } from '@/generated/i18n/i18n';

import OperationSelectCompany from '../OperationSelectCompany';

import type { CompaniesListProps } from './types';
import type { ListRowProps } from 'react-virtualized/dist/es/List';

const CompaniesList: React.FC<CompaniesListProps> = ({ data, 'data-test': dataTest }) => {
  const renderItem = useCallback(
    ({ index, key, style: rowStyle }: ListRowProps) => {
      const item = data[index];
      return (
        <List.Item
          key={key}
          style={rowStyle}
          actions={[
            <OperationSelectCompany
              value={item.id}
              disabled={item.isSelected}
              data-test={dataTest && `${dataTest}-${item.id}-select`}
            />,
          ]}
        >
          <List.Item.Meta
            title={item.name}
            description={
              item.isOwner && (
                <Tag color="green">
                  <FormattedMessage id={I18nFeatureCompany.COMPONENTS_COMPANIES_LIST_OWNER_TAG} />
                </Tag>
              )
            }
          />
        </List.Item>
      );
    },
    [data, dataTest],
  );

  return (
    <WindowScroller data-test={dataTest}>
      {({ height, isScrolling, onChildScroll, scrollTop }) => (
        <AutoSizer disableHeight>
          {({ width }) => (
            <VList
              autoHeight
              height={height}
              isScrolling={isScrolling}
              onScroll={onChildScroll}
              overscanRowCount={2}
              rowCount={data.length}
              rowHeight={50}
              rowRenderer={renderItem}
              scrollTop={scrollTop}
              width={width}
            />
          )}
        </AutoSizer>
      )}
    </WindowScroller>
  );
};

const CompaniesListMemo = React.memo(CompaniesList);

export default CompaniesListMemo;
