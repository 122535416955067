import { css } from '@emotion/css';
import { Divider, Form } from 'antd';
import cronstrue from 'cronstrue';
import React, { useCallback, useMemo } from 'react';
import Cron, { HEADER } from 'react-cron-generator';
import { useIntl } from 'react-intl';

import { FormattedMessage } from '@/components';
import { I18nFeatureReportSchedules } from '@/generated/i18n/i18n';

import ReportScheduleCronView from '../ReportScheduleCronView';

import type { ReportScheduleCronItemProps } from './types';
import type { Store, ValidatorRule } from 'rc-field-form/es/interface';

const styles = {
  item: css`
    display: flex;
    justify-content: center;

    .nav {
      display: flex;
    }

    .row {
      display: flex;
    }

    .nav-item a.nav-link.active {
      color: black;
      background-color: unset;
    }

    .well {
      align-content: center;
      min-height: 10px;
      margin-bottom: 5px;
      padding: 10px;
    }
  `,
};

const cronItemOptions = { headers: [HEADER.MONTHLY, HEADER.WEEKLY, HEADER.HOURLY, HEADER.DAILY] };

const ReportScheduleCronItemRaw = <Values extends Store = Store>({
  'data-test': dataTest,
  name,
  readonly,
  required,
}: ReportScheduleCronItemProps<Values>) => {
  const { locale, formatMessage } = useIntl();
  const simplifiedLocale = useMemo(() => locale.split('-')[0] || locale, [locale]);
  const validateExpression: ValidatorRule['validator'] = useCallback(
    async (_, expression: string) => {
      if (!expression) {
        return Promise.resolve();
      }

      const val = cronstrue.toString(expression);
      if (!val || val.search('undefined') !== -1) {
        const message = formatMessage({ id: I18nFeatureReportSchedules.COMPONENTS_CRON_ITEM_ERROR_INVALID });
        throw new Error(message);
      }
      return Promise.resolve();
    },
    [formatMessage],
  );
  const rules = useMemo(
    () =>
      !readonly
        ? [
            { validator: validateExpression },
            {
              required: true,
              message: formatMessage({ id: I18nFeatureReportSchedules.COMPONENTS_CRON_ITEM_ERROR_REQUIRED }),
            },
          ]
        : [],
    [formatMessage, readonly, validateExpression],
  );

  return (
    <>
      <Divider type="horizontal">
        <FormattedMessage id={I18nFeatureReportSchedules.COMPONENTS_CRON_ITEM_LABEL} />
      </Divider>
      <Form.Item<Values>
        data-test={!readonly ? dataTest : undefined}
        wrapperCol={{ span: 24 }}
        required={required}
        name={name}
        rules={rules}
        className={styles.item}
      >
        {readonly ? (
          <ReportScheduleCronView data-test={dataTest} />
        ) : (
          // onChange will be provided to the Cron by the Form.Item itself
          // @ts-expect-error
          <Cron locale={simplifiedLocale} showResultText showResultCron={false} options={cronItemOptions} />
        )}
      </Form.Item>
    </>
  );
};

const ReportScheduleCronItem = React.memo(ReportScheduleCronItemRaw) as typeof ReportScheduleCronItemRaw;

export default ReportScheduleCronItem;
