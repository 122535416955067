/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3276
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { PageWithdrawalReconciliationSortByAPIModel } from './PageWithdrawalReconciliationSortByAPIModel';
import {
    PageWithdrawalReconciliationSortByAPIModelFromJSON,
    PageWithdrawalReconciliationSortByAPIModelFromJSONTyped,
    PageWithdrawalReconciliationSortByAPIModelToJSON,
} from './PageWithdrawalReconciliationSortByAPIModel';
import type { WithdrawalReconciliationAPIModel } from './WithdrawalReconciliationAPIModel';
import {
    WithdrawalReconciliationAPIModelFromJSON,
    WithdrawalReconciliationAPIModelFromJSONTyped,
    WithdrawalReconciliationAPIModelToJSON,
} from './WithdrawalReconciliationAPIModel';

/**
 * 
 * @export
 * @interface SliceWithdrawalReconciliationWithdrawalReconciliationSortByAPIModel
 */
export interface SliceWithdrawalReconciliationWithdrawalReconciliationSortByAPIModel {
    /**
     * 
     * @type {Array<WithdrawalReconciliationAPIModel>}
     * @memberof SliceWithdrawalReconciliationWithdrawalReconciliationSortByAPIModel
     */
    list?: Array<WithdrawalReconciliationAPIModel>;
    /**
     * 
     * @type {PageWithdrawalReconciliationSortByAPIModel}
     * @memberof SliceWithdrawalReconciliationWithdrawalReconciliationSortByAPIModel
     */
    page: PageWithdrawalReconciliationSortByAPIModel;
    /**
     * 
     * @type {number}
     * @memberof SliceWithdrawalReconciliationWithdrawalReconciliationSortByAPIModel
     */
    total?: number;
}

/**
 * Check if a given object implements the SliceWithdrawalReconciliationWithdrawalReconciliationSortByAPIModel interface.
 */
export function instanceOfSliceWithdrawalReconciliationWithdrawalReconciliationSortByAPIModel(value: object): boolean {
    if (!('page' in value)) return false;
    return true;
}

export function SliceWithdrawalReconciliationWithdrawalReconciliationSortByAPIModelFromJSON(json: any): SliceWithdrawalReconciliationWithdrawalReconciliationSortByAPIModel {
    return SliceWithdrawalReconciliationWithdrawalReconciliationSortByAPIModelFromJSONTyped(json, false);
}

export function SliceWithdrawalReconciliationWithdrawalReconciliationSortByAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SliceWithdrawalReconciliationWithdrawalReconciliationSortByAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'list': json['list'] == null ? undefined : ((json['list'] as Array<any>).map(WithdrawalReconciliationAPIModelFromJSON)),
        'page': PageWithdrawalReconciliationSortByAPIModelFromJSON(json['page']),
        'total': json['total'] == null ? undefined : json['total'],
    };
}

export function SliceWithdrawalReconciliationWithdrawalReconciliationSortByAPIModelToJSON(value?: SliceWithdrawalReconciliationWithdrawalReconciliationSortByAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'list': value['list'] == null ? undefined : ((value['list'] as Array<any>).map(WithdrawalReconciliationAPIModelToJSON)),
        'page': PageWithdrawalReconciliationSortByAPIModelToJSON(value['page']),
        'total': value['total'],
    };
}

