import dayjs from 'dayjs';
import { useMemo } from 'react';

import { useUser } from '@/features/user/hooks';
import type { EmptyObject } from '@/infrastructure/utils/ts';

import type React from 'react';
import type { PropsWithChildren } from 'react';

const withMerchantWalletOwnershipCheckGuard =
  <T extends PropsWithChildren<EmptyObject>>(
    OriginalComponent: React.ComponentType<T>,
    OwnershipComponent: React.ComponentType<T>,
  ): React.FC<T> =>
  (props: T) => {
    const {
      data: { data: user },
    } = useUser();
    const isUserOld = useMemo(
      () => !!user?.createdAt && dayjs(user.createdAt).add(10, 'minutes').isAfter(),
      [user?.createdAt],
    );
    return isUserOld ? <OriginalComponent {...props} /> : <OwnershipComponent {...props} />;
  };

export default withMerchantWalletOwnershipCheckGuard;
