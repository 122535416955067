import type {
  MerchantReportSchedulePredicatesAPIModel,
  NewReportScheduleAPIModel,
  ReportScheduleAPIModel,
  ReportScheduleUpdateAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import { ReportScheduleSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { ListState } from '@/infrastructure/model/list/types';
import type { SingleState } from '@/infrastructure/model/single/types';

export const NAMESPACE = 'report-schedules';

export interface ReportSchedule extends Omit<ReportScheduleAPIModel, 'parameters'> {
  parameters: Record<string, unknown>;
}

export interface NewReportSchedule extends NewReportScheduleAPIModel {}

export interface UpdateReportSchedule extends ReportScheduleUpdateAPIModel {}

export interface ReportScheduleFilterPredicate extends MerchantReportSchedulePredicatesAPIModel {}

export const defaultReportScheduleSortBy = { [ReportScheduleSortByAPIModel.createdAt]: 'DESC' as const };

export interface ReportSchedulesState {
  list: ListState<string, ReportScheduleFilterPredicate, ReportScheduleSortByAPIModel>;
  entities: SingleState<ReportSchedule>;
}
